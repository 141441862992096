import React, { useEffect, useState } from 'react'
import { CodeEditor } from 'components'
import _ from 'lodash'

import { createFunctionMap, convertFunctionMapToCode, defaultSnippets } from 'OkApp/CodeProcessor'






export default function CompView(props) {
    const { input: { value, onChange }, label, snProps, startFunc, height } = props
    const [code, setCode] = useState("")
    const inputCode = {
        value: startFunc ? code || startFunc : code, onChange: setCode, onBlur: (code) => {
            setCode(code)
            let newV = createFunctionMap(code);
            if (!_.isEqual(newV, value))
                onChange(newV)
        }
    };

    const upCode = () => {
        let newV = createFunctionMap(code);
        if (!_.isEqual(newV, value)) {
            let newCode = convertFunctionMapToCode(value)
            setCode(newCode)
        }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(upCode, [value])


    return (<div style={{ display: 'flex', height: height || '100%', flex: 1, position: "relative",}}>
        <CodeEditor input={inputCode} snippets={defaultSnippets.concat(snProps || [])} label={label} />
    </div>) 
}
