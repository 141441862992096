import React from 'react'
import { PesquisaDialog } from 'components';
import { useOkAppContext } from 'OkApp/context';

export default function PesquisaOkForm({ openPesquisa, setOpenPesquisa, onSelect, titulo }) {
    const { okApp } = useOkAppContext();

    let colectionFirebase = null;
    if (okApp) {
        colectionFirebase = 'Project/' + okApp.uid + '/CustomForm';
    }

    return (
        <PesquisaDialog open={openPesquisa} handleClose={() => setOpenPesquisa(false)}
            //filterEditar
            filtraNaTela={true}
            getAll={true}
            title={titulo || "Pesquisa de OkForm"}
            colectionFirebase={colectionFirebase}
            onSelect={(selecionou) => {
                setOpenPesquisa(false);
                if (selecionou.length) {
                    onSelect(selecionou[0])
                }
            }}
            filters={[
                { label: "Nome", field: "name", },
                { label: "Rota", field: "route", },
                { label: "Descrição", field: "description", },
                { label: "Tipo", field: "type", },
            ]}
            columns={[
                { label: "Nome", field: "name", },
                { label: "Rota", field: "route", },
                { label: "Descrição", field: "description", },
                { label: "Tipo", field: "type", },
            ]} />
    )
}
