
import React from 'react'
import OkTypedComponent from 'OkApp/components/OkTypedComponent';

import OkCompAdapter from 'OkApp/components/OkCompAdapter'
import { Input } from 'components';
import { LabelInputAuto } from 'OkApp';
const modelo = {
  typeComponente: "SequencialIndexComp",
  field: "sequencialIndex",
  label: "Código",
  metaOkApp: { style: { sizeWidth: "85px" } },
  meta: { pesquisa: { sizecol: "85px" } }




}
const AdapterOkComp = (props) => {

  const { okComp ,propsComp} = props
  let disabled = true;
  if (okComp.inFilter) {
    disabled = false
  }
  return <OkCompAdapter {...props} propsComp={{ ...propsComp,disabled: disabled, type: "number" }} component={Input} />
}
const EditorOkComp = (props) => {

  return <>
    <OkTypedComponent typeComponente='InputComp' field='field' label='Nome Salvo' disabled={true} />
    <LabelInputAuto {...props} />

  </>
}

const InputComp = {
  typeComponente: "SequencialIndexComp",
  configView: {
    grupo: "Básico",
    nome: "Código Sequencial",
    descricao: "Código Sequencial",
    helpUrl: "https://okapp.com.br/app/okappdocs/sequencialindex",
    modelo
  },
  Component: AdapterOkComp,
  CompEditor: EditorOkComp,
  module: { DefaultPesquisa: { filterconf: { type: "okcomp", toValueCompare: (v) => parseInt(v) } } }

}

export default InputComp;