import React, { useCallback, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom';

import { useListOkApp, useListOkAppAdmin, useUsersListSnapAdmin, useUserSnap } from 'OkApp/UseUtils'

import { IconButton, Typography, TextField, CircularProgress, Tabs, Tab, Box, useTheme, useMediaQuery } from '@material-ui/core';
import { Edit } from '@material-ui/icons';

import _ from 'lodash'

import Tabela from 'components/Tabela Grid';
import { sortArrayObj } from 'OkApp/functions';
import { getAllOkFormList } from 'OkApp/OkFunctions';
import { useEffect } from 'react';
import { getAllReports } from 'OkReport';
import { useCrudContext } from 'crud';

export default function ListPerfilprojeto() {
    const [tab, setTab] = useState(0)
    const [filtro, setFiltro] = useState("");
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));
    const projetos = useListOkApp();
    const users = useUsersListSnapAdmin();
    const history = useHistory();

    const { setTitle } = useCrudContext()

    useEffect(() => {
        setTitle("Painel de administração")
        return () => setTitle("")
    }, [setTitle])

    const editUser = useCallback((user) => {
        history.push("/EditPerfil?uid=" + user.uid)
    }, [history])

    const editProject = useCallback((project) => {
        history.push("/Projeto?uid=" + project.uid)
    }, [history])

    const handleChange = (event, newValue) => {
        setTab(newValue);
    };

    const CellProjetos = ({ perfil }) => {
        const filter = useMemo(() => { return { 'metaOkApp.permission.owner': perfil?.uid } }, [perfil?.uid])
        const projetos = useListOkAppAdmin(filter);

        if (!projetos) {
            return <CircularProgress color="primary" size={25} />
        }

        return (
            <Typography>
                {perfil.numProject ?
                    `${projetos.length}/${perfil.numProject}`
                    :
                    "-"
                }
            </Typography>
        )
    }

    const RenderOwnerName = ({ values }) => {
        const owner = useUserSnap(values?.metaOkApp?.permission.owner);

        return (
            <>
                <Typography>{owner ? owner.name : "-"}</Typography>
            </>
        )
    }

    const RenderOwnerEmail = ({ values }) => {
        const owner = useUserSnap(values?.metaOkApp?.permission.owner);

        return (
            <>
                {owner &&
                    <Typography>{owner.email}</Typography>
                }
            </>
        )
    }

    const RenderCreatedForms = ({ values }) => {
        const [formQuantity, setFormQuantity] = useState("-")

        getAllOkFormList(values).then((list) => {
            setFormQuantity(list.size)
        })

        return (
            <>
                <Typography>{formQuantity}</Typography>
            </>
        )
    }

    const RenderCreatedReports = ({ values }) => {

        const [reportQuantity, setReportQuantity] = useState("-")

        getAllReports(values.uid).then((list) => {
            setReportQuantity((list || []).length)
        })

        return (
            <>
                <Typography>{reportQuantity}</Typography>
            </>
        )
    }

    const RenderCell = useCallback((props) => {

        const { coluna, linha, indexrow } = props

        if ("COL_EDIT_PERFIL" === coluna.field) {
            return (
                <Box style={{ display: "flex", justifyContent: mobile ? 'flex-start' : 'center', height: '100%' }}>
                    <IconButton size='small' onClick={() => editUser(linha, indexrow,)}><Edit /></IconButton>
                </Box>
            )
        }

        if ("COL_EDIT_PROJECT" === coluna.field) {
            return (
                <Box style={{ display: "flex", justifyContent: mobile ? 'flex-start' : 'center', height: '100%' }}>
                    <IconButton size='small' onClick={() => editProject(linha, indexrow,)}><Edit /></IconButton>
                </Box>
            )
        }

        if ("projetos" === coluna.field) {
            return <CellProjetos perfil={linha} />
        }

        if (coluna.Component) {
            return <coluna.Component {...props} />
        }
        let value = _.get(linha, coluna.field);
        if (!value) {
            value = "";
        }

        if (coluna.field === "owner") {
            return <RenderOwnerName values={linha} />
        }

        if (coluna.field === "ownerEmail") {
            return <RenderOwnerEmail values={linha} />
        }
        if (coluna.field === "formQuantity") {
            return <RenderCreatedForms values={linha} />
        }
        if (coluna.field === "reportQuantity") {
            return <RenderCreatedReports values={linha} />
        }

        if (value.toDate) {
            try {
                let dateV = value.toDate();
                var dia = dateV.getDate().toString();
                var diaF = (dia.length === 1) ? '0' + dia : dia;
                var mes = (dateV.getMonth() + 1).toString(); //+1 pois no getMonth() Janeiro começa com zero.
                var mesF = (mes.length === 1) ? '0' + mes : mes;
                var anoF = dateV.getFullYear();
                var hora = (dateV.getHours() < 10) ? '0' + dateV.getHours() : dateV.getHours();
                var minuto = (dateV.getMinutes() < 10) ? '0' + dateV.getMinutes() : dateV.getMinutes();
                var segundos = (dateV.getSeconds() < 10) ? '0' + dateV.getSeconds() : dateV.getSeconds();
                value = diaF + "/" + mesF + "/" + anoF + " - " + hora + ":" + minuto + ":" + segundos;
            } catch (error) {

                value = "";
            }

            // if (type === 'date') {
            //     ajustValue = ajustValue.split('T')[0]
            // }
        }

        const regexCheckDate = /^\d{4}-\d{2}-\d{2}$/
        if (value && regexCheckDate.test(value)) {
            value = value.split("-").reverse().join("/")
        }

        return <Typography component="div">{value || "-"}</Typography>

    }, [editProject, editUser, mobile])

    const getValueSort = (linha, coluna) => {
        if ("COL_TABLE_ACTIONS" === coluna.field) {
            return "";
        }
        const parseValueShow = (value, linha, coluna,) => {

            if (value) {
                if (value.constructor === String) {

                } else
                    if (value.constructor === Number) {

                    } else

                        if (value.toDate) {
                            value = value.toDate().toISOString()


                        } else
                            if (value.constructor === Object) {
                                if (coluna && coluna.funcToString) {
                                    value = coluna.funcToString(value, linha, coluna)
                                    value = parseValueShow(value, linha);
                                } else {
                                    value = JSON.stringify(value)
                                }
                            }
            }
            return value;
        }
        let value = _.get(linha, coluna.field);

        value = parseValueShow(value, linha, coluna)
        return value;
    }
    const toSlug = (txt) => {

        let str = txt.replace(/^\s+|\s+$/g, ''); // trim
        str = str.toLowerCase();

        // remove accents, swap ñ for n, etc
        var from = "ãàáäâèéëêìíïîõòóöôùúüûñç·/_,:;";
        var to = "aaaaaeeeeiiiiooooouuuunc------";
        for (var i = 0, l = from.length; i < l; i++) {
            str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
        }

        str = str.replace(/[^a-z0-9 -]/g, '').replace(/\s+/g, '-').replace(/-+/g, '-');

        return str;
    };
    const ciContem = (a, b) => {
        return toSlug(a).includes(toSlug(b));
    }

    let filterUser = users ? users.filter(u => {

        if (!filtro) {
            return true;
        }

        if (u.name && ciContem(u.name, filtro)) {
            return true
        }
        if (u.email && ciContem(u.email, filtro)) {
            return true
        }

        if (u.nomePlano && ciContem(u.nomePlano, filtro)) {
            return true
        }

        if (u.campanha && ciContem(u.campanha, filtro)) {
            return true
        }

        return false;
    }) : users;

    let filterProjects = projetos ? projetos.filter((u) => {
        if (!filtro) {
            return true;
        }

        if (u.name && ciContem(u.name, filtro)) {
            return true
        }
        if (u.route && ciContem(u.route, filtro)) {
            return true
        }
        if (u.owner && ciContem(u.owner, filtro)) {
            return true
        }
        if (u.ownerEmail && ciContem(u.ownerEmail, filtro)) {
            return true
        }

        return false;
    }) : projetos;

    if (filterUser) {
        filterUser = sortArrayObj(filterUser, [(u) => {
            return u.lastChange ? u.lastChange : u.email
        }], true)
    }

    if (filterProjects) {
        filterProjects = sortArrayObj(filterProjects, [(u) => {
            return u.lastChange ? u.lastChange : u.name
        }], true)
    }

    return (
        <div>
            <Tabs
                value={tab}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                style={{ width: 'fit-content', display: 'flex', margin: '0 auto' }}
            >
                <Tab label="Perfis" />
                <Tab label="Projetos" />
            </Tabs>
            <Box padding={2}>
                <TextField
                    label={"Filtro"}
                    type={'text'}

                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                    }}

                    style={{ marginBottom: 15 }}

                    value={filtro}
                    onChange={(v) => setFiltro(v.target.value)}
                >
                </TextField>
                {tab === 0 &&
                    <Tabela
                        sortColuns={true}
                        getValueSort={getValueSort}
                        data={filterUser}
                        columns={[
                            { field: "COL_EDIT_PERFIL", label: "Editar", sizecol: "65" },
                            { field: "sequencialIndex", label: "Índice", sizecol: "65" },
                            { field: "name", label: "Nome" },
                            { field: "email", label: "E-mail" },
                            { field: "nomePlano", label: "Nome do plano", sizecol: "130" },
                            { field: "campanha", label: "Campanha", sizecol: "100" },
                            { field: "dataFimPlano", label: "Fim do plano", sizecol: "130" },
                            { field: "projetos", label: "Projetos", sizecol: "90" },
                            { field: "numUser", label: "Limite de usuários", sizecol: "150" },
                            // { field: "firstLogin", label: "Criação" },
                            { field: "lastChange", label: "Último login" },
                            { field: "lastOkAppRoute", label: "Último app acessado" },
                        ]}
                        msgSemRegistro={"Sem Registros"}
                        onRowClick={(item, index) => {
                            console.log('onRowClick', item)
                        }}
                        rowsPer={15}
                        RenderCell={RenderCell}
                    />
                }

                {tab === 1 &&
                    <Tabela
                        sortColuns={true}
                        getValueSort={getValueSort}
                        data={filterProjects}
                        columns={[
                            { field: "COL_EDIT_PROJECT", label: "Editar", sizecol: "65" },

                            { field: "name", label: "Nome" },
                            { field: "route", label: "Rota" },
                            { field: "owner", label: "Criador" },
                            { field: "formQuantity", label: "Formulários", sizecol: "120" },
                            { field: "reportQuantity", label: "Relatórios", sizecol: "100" },
                            { field: "ownerEmail", label: "E-mail" },
                            { field: "lastChange", label: "Última alteração" },
                        ]}
                        msgSemRegistro={"Sem Registros"}
                        onRowClick={(item, index) => {
                            console.log('onRowClick', item)
                        }}
                        rowsPer={15}
                        RenderCell={RenderCell}
                    />
                }
            </Box>

        </div>
    )
}
