import { crateSnapDocument, createSnapCollection, createSnapCollectionSemPermissao, createGetCollectionSemPermissao, createSnapCollectionDatabase, createSnapCollectionSemPermissaoDatabase, createGetCollectionProps, salvarObjUidApp } from 'dbApi'
import { permiteEditar, permiteVer, permiteEditarPublico } from './modules/PermissionMeta/functions';
import firebaseUtil from 'firebaseUtil';
//#region - users
export function getUserUid() {
    if (!firebaseUtil.getCurrentUser()) {
        return null;
    }
    return firebaseUtil.getCurrentUser().uid
}


export const updatePerfilOkAppProject = (okApp, uidPerfil, dataUpdate) => {
    let obj = { uid: uidPerfil };

    if (dataUpdate) {
        obj = { ...obj, ...dataUpdate }
    }
    saveOkObj(okApp, okApp.formPerfil.uid, obj)

}
export const getPerfilUserSnap = (uidUser, okApp, resolve, reject) => {

    if (!okApp.formPerfil)
        return null;

    let colCustomForm = 'Project/' + okApp.uid + '/ValuesCustomForm/' + okApp.formPerfil.uid + "/Values";
    return crateSnapDocument(uidUser, colCustomForm,
        (r) => {
            resolve(r)
        },
        (err) => {
            console.log('getUserSnap erro', err)
            reject(err)
        }
    )
}
export const getUserSnap = (uidUser, resolve, reject) => {
    let colCustomForm = 'Users';
    return crateSnapDocument(uidUser, colCustomForm,
        (r) => {
            resolve(r)
        },
        (err) => {
            console.log('getUserSnap erro', err)
            reject(err)
        }
    )
}
export const getUsersListSnap = (strFilter, resolve, reject) => {
    let colCustomForm = 'Users';
    return createSnapCollectionSemPermissao(colCustomForm,
        (r) => {
            resolve(r)
        },
        (err) => {
            console.log('getOkFormSnap erro', err)
            reject(err)
        }, { email: strFilter + "%" }
    )
}
export const getUsersListSnapAdmin = (resolve, reject) => {
    let colCustomForm = 'Users';
    return createSnapCollectionSemPermissao(colCustomForm,
        (r) => {

            resolve(r)
        },
        (err) => {
            console.log('getOkFormSnap erro', err)
            reject(err)
        },
    )
}
//#endregion
//#region - okapp

export const getOkAppListSnap = (filtersCompare, resolve, reject) => {
    let colCustomForm = 'Project';
    return createSnapCollection(colCustomForm,
        (r) => {
            resolve(r)
        },
        (err) => {
            console.log('getOkFormSnap erro', err)
            reject(err)
        }, filtersCompare
    )
}
export const getOkAppListSnapAdmin = (filtersCompare, resolve, reject) => {
    let colCustomForm = 'Project';
    return createSnapCollectionSemPermissao(colCustomForm,
        (r) => {
            resolve(r)
        },
        (err) => {
            console.log('getOkFormSnap erro', err)
            reject(err)
        }, filtersCompare
    )
}
//#endregion

//#region - okform
export const getOkFormSnap = (okApp, uidOkForm, resolve, reject) => {
    let colCustomForm = 'Project/' + okApp.uid + '/CustomForm';
    return crateSnapDocument(uidOkForm, colCustomForm,
        (r) => {
            resolve(r)
        },
        (err) => {
            console.log('getOkFormSnap erro', err)
            reject(err)
        }
    )
}


export const getOkFormListSnap = (okApp, filtersCompare, resolve, reject) => {
    let colCustomForm = 'Project/' + okApp.uid + '/CustomForm';

    return createSnapCollection(colCustomForm,
        (r) => {
            resolve(r)
        },
        (err) => {
            console.log('getOkFormListSnap erro', err)
            reject(err)
        }, filtersCompare
    )
}
export const getOkFormListRoutesSnap = (okApp, filtersCompare, resolve, reject) => {
    let colCustomForm = 'Project/' + okApp.uid + '/CustomForm';
    // let colCustomForm = 'Project/' + okApp.uid + '/CustomFormPermission';

    return createSnapCollectionSemPermissao(colCustomForm,
        (r) => {
            resolve(r)
        },
        (err) => {
            console.log('getOkFormListSnap erro', err)
            reject(err)
        }, filtersCompare
    )
}

export const getAllOkFormList = (okApp) => {
    let colCustomForm = 'Project/' + okApp.uid + '/CustomForm';

    return createGetCollectionSemPermissao(colCustomForm).get()
}
//#endregion
//#region - okobj

export const saveOkObj = (okApp, uidOkForm, obj, resolve, reject) => {
    let colCustomForm = 'Project/' + okApp.uid + '/ValuesCustomForm/' + uidOkForm + "/Values";
    salvarObjUidApp(okApp.uid, obj, colCustomForm,
        (r) => {
            // console.log("saveOkObjSnap", r.msg);
            if (resolve) resolve(r)

        },
        (err) => {
            // console.log("saveOkObjSnap - error", err);
            if (reject) reject(err)
        })
}

export const getOkObjSnap = (okApp, uidOkForm, uidObj, resolve, reject) => {
    let colCustomForm = 'Project/' + okApp.uid + '/ValuesCustomForm/' + uidOkForm + "/Values";
    return crateSnapDocument(uidObj, colCustomForm,
        (r) => {
            resolve(r)
        },
        (err) => {
            console.log('getOkObjSnap erro', err)
            reject(err)
        }
    )
}
export const observerOkObjList = (okApp, keyOkForm, collectionFilter, resolve, reject, limite) => {


    let colCustomForm = 'Project/' + okApp.uid + '/ValuesCustomForm/' + keyOkForm + "/Values";
    let verificaPermissao = false;
    let dataBaseusar = okApp.database ? okApp.database : firebaseUtil.db
    return createGetCollectionProps(dataBaseusar, colCustomForm,
        (r) => {
            resolve(r)
        },
        (err) => {
            console.log('observerOkObjList erro', err)
            reject(err)
        }, { collectionFilter, limite, verificaPermissao }
    )
}

export const getOkObjListSnap = (okApp, uidOkForm, filtersCompare, resolve, reject, limite) => {
    return getOkObjListSnapDatabase(okApp, firebaseUtil.db, uidOkForm, filtersCompare, resolve, reject, limite)
}

export const getOkObjListSnapDatabase = (okApp, database, uidOkForm, filtersCompare, resolve, reject, limite) => {
    let colCustomForm = 'Project/' + okApp.uid + '/ValuesCustomForm/' + uidOkForm + "/Values";
    return createSnapCollectionDatabase(database, colCustomForm,
        (r) => {
            resolve(r)
        },
        (err) => {
            console.log('getOkObjListSnap erro', err)
            reject(err)
        }, filtersCompare, limite
    )
}

export const getStrCollectonsListSnap = (strCollection, filtersCompare, resolve, reject, limite, startAfter) => {
    return getStrCollectonsListSnapDatabase(firebaseUtil.db, strCollection, filtersCompare, resolve, reject, limite, startAfter)

}
export const getStrCollectonsListSnapDatabase = (database, strCollection, filtersCompare, resolve, reject, limite, startAfter) => {
    if (!database) database = firebaseUtil.db;
    return createSnapCollectionDatabase(database, strCollection,
        (r) => {
            resolve(r)
        },
        (err) => {
            console.log('getStrCollectonsListSnap erro', err)
            reject(err)
        }, filtersCompare, limite, startAfter
    )
}

export const getStrCollectonsListSnapLaterPermission = (strCollection, filtersCompare, filterEditar, resolve, reject, limite, startAfter) => {
    return getStrCollectonsListSnapLaterPermissionDatabase(firebaseUtil.db, strCollection, filtersCompare, filterEditar, resolve, reject, limite, startAfter)
}
export const getStrCollectonsListSnapLaterPermissionDatabase = (database, strCollection, filtersCompare, filterEditar, resolve, reject, limite, startAfter) => {
    if (!database) database = firebaseUtil.db;
    const validaPermicao = (r) => {
        // console.log('validaPermicao',r)
        return r.filter(item => {

            if (filterEditar) {

                if (!permiteEditar(item)) {
                    return false;
                }
            }
            return permiteVer(item)

        })

    }
    return createSnapCollectionSemPermissaoDatabase(database, strCollection,
        (r) => {
            resolve(validaPermicao(r))
        },
        (err) => {
            console.log('getStrCollectonsListSnap erro', err)
            reject(err)
        }, filtersCompare, limite, startAfter
    )
}
export const getStrCollectonsListSnapNoPermissionDatabase = (database, strCollection, filtersCompare, filterEditar, resolve, reject, limite, startAfter) => {
    if (!database) database = firebaseUtil.db;

    return createSnapCollectionSemPermissaoDatabase(database, strCollection,
        (r) => {
            resolve(r)
        },
        (err) => {
            console.log('getStrCollectonsListSnap erro', err)
            reject(err)
        }, filtersCompare, limite, startAfter
    )
}


//#endregion


export const getPermiteEditarOkForm = (okform) => {

    return permiteEditarPublico(okform)

}

