import React, { useState, useEffect, useCallback } from 'react'
import { TextField, Fab, Tooltip } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search';
import IconAbrir from '@material-ui/icons/FilterNone';
import { useOkForm, useOkApp, useCreateFunctionFromFieldCode } from 'OkApp/UseUtils';
import { PesquisaOkFormObj } from 'OkApp';
import { getOkObjSnap } from 'OkApp/OkFunctions';

import _ from 'lodash'
import { ajusteRoute } from 'OkApp/context';

export default function SeletorUnicoPorPesquisa(props) {
    const { uidOkForm, input, label, tituloPesquisa, saveFields, loadFields, toStringFields, keepUpdate, showNewTab, filterListCode, disabled, meta: { touched, error } } = props
    const [showPesquisa, setShowPesquisa] = useState(false)
    const okFormConsulta = useOkForm(uidOkForm ? uidOkForm.uid : null);
    const okApp = useOkApp();
    const toStringValue = v => {
        let str = "";
        if (toStringFields) {
            toStringFields.forEach(f => {
                let aux = v[f];
                if (aux) {
                    if (str) str += " - "
                    str += aux
                }
            });
        }

        return str
    }

    const adapterSelectedValue = useCallback(v => {
        if (!v) {
            return v;
        }

        let sel = { uid: v.uid }
        if (saveFields)
            saveFields.forEach(f => {
                sel[f] = v[f];
            });
        if (loadFields)
            loadFields.forEach(f => {
                sel[f] = v[f];
            });
        return sel
    }, [saveFields, loadFields])

    const startKeepUpdate = () => {
        let observerAux = null
        if (keepUpdate && input.value && input.value.uid) {
            observerAux = getOkObjSnap(okApp, uidOkForm.uid, input.value.uid,
                (r) => {
                    input.onChange(adapterSelectedValue(r))
                }, () => {
                    input.onChange(null);
                }
            )
        }

        return () => {
            if (observerAux) {
                observerAux()
            }
        }

    }

    let uidObj = input.value ? input.value.uid : null
    let uidForm = uidOkForm ? uidOkForm.uid : null
    // console.log('keepUpdate',keepUpdate,uidObj)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(startKeepUpdate, [keepUpdate, uidObj, okApp, uidForm, adapterSelectedValue])




    let funcFilter = useCreateFunctionFromFieldCode(filterListCode, 'filterList')
    const openNewTab = () => {
        let uid = _.get(input, "value.uid");
        window.open(ajusteRoute(okApp, okFormConsulta) + "?uid=" + uid);
    }

    return (
        <div style={{ display: 'flex', marginBottom: 15, justifyContent: "center", gap: 5 }}>
            <TextField
                value={toStringValue(input.value)}
                label={label}
                error={(touched && error) ? true : false}
                helperText={(touched && error) ? error : ""}
                fullWidth
                disabled={disabled}
                InputLabelProps={{
                    shrink: true,
                    readOnly: true,
                }}
            />
            {(showNewTab && _.get(input, "value.uid")) &&
                <Tooltip title="Abrir em nova janela">
                    <Fab size="small" color="primary" disabled={disabled} onClick={openNewTab}>
                        <IconAbrir />

                    </Fab>
                </Tooltip>
            }
            <Fab size="small" color="primary" disabled={disabled} onClick={() => { setShowPesquisa(true) }}>
                <SearchIcon />
            </Fab>

            {okFormConsulta && <PesquisaOkFormObj 
                okform={okFormConsulta}
                showPesquisa={showPesquisa}
                setShowPesquisa={setShowPesquisa}
                titulo={tituloPesquisa}
                funcFilter={funcFilter}
                onSelect={(sel) => {
                    input.onChange(adapterSelectedValue(sel[0]))
                }} />}
        </div>
    )
}
