/* eslint-disable import/prefer-default-export, no-shadow */
import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import { IconButton } from "@material-ui/core";
import { Delete } from "@material-ui/icons";

const InputPicker = (props) => {
  const { errorText } = styles;
  const {
    style,
    input: { value, onChange },
    meta: { touched, error },
    label,
    itens,
    secureTextEntry,
    multiline,
    containerStyle,
    disabled,
    remover,
    toStringItem,
    styleCssOver,
    menuItemStyle,
    ...otherProps
  } = props;

  const toStringInterno = (v) => {
    if (!v) {
      return "";
    }
    if (toStringItem) {
      if (Array.isArray(toStringItem)) {
        return toStringItem[itens.indexOf(v)];
      }
      return toStringItem(v);
    } else {
      return v;
    }
  };

  // let error1 = touched && error ? true : false;
  let lista = [];
  if (itens) {
    lista = itens;
  }

  let serviceItems = lista.map((item, index) => {
    return (
      <MenuItem
        key={index}
        style={menuItemStyle || { whiteSpace: "break-spaces" }}
        value={index}
      >
        {toStringInterno(item)}
      </MenuItem>
    );
  });
  let vv = lista
    .map(function (e) {
      return toStringInterno(e);
    })
    .indexOf(toStringInterno(value));
  if (!value) {
    vv = "";
  }
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        ...(style ? style : {}),
      }}
    >
      <div style={{ display: "flex", flexDirection: "row" }}>
        {label && <InputLabel style={styles.labelForm}>{label}</InputLabel>}
        {touched && error && (
          <div style={{ display: "flex" }}>
            <Typography style={errorText}>{error}</Typography>
          </div>
        )}
      </div>

      <div style={{ display: "flex", flexDirection: "row" }}>
        <Select
          disabled={disabled}
          value={vv}
          renderValue={(value) =>
            lista[value] ? toStringInterno(lista[value]) : ""
          }
          onChange={(event) => {
            let nv = lista[event.target.value];

            onChange(nv);
            if (props.onSelected) props.onSelected(lista[event.target.value]);
          }}
          fullWidth
          style={{ marginBottom: 15, marginTop: 4 }}
          endAdornment={
            remover && (
              <IconButton
                style={{ marginRight: 25 }}
                size="small"
                onClick={() => onChange(null)}
              >
                <Delete />
              </IconButton>
            )
          }
          {...otherProps}
        >
          {serviceItems}
        </Select>
      </div>
    </div>
  );
};

const styles = {
  inputContainer: {
    // flex: 1,
    flexDirection: "column",
    // alignItems: 'center',
    // borderBottomWidth: 1,
    // borderColor: '#ddd',
    // backgroundColor: "#0f0"
  },
  InputText: {
    color: "#000",
    paddingRight: 5,
    paddingLeft: 5,
    // fontSize: 18,
    // lineHeight: 18,
    height: 44,
    flex: 2,
  },
  errorText: {
    textAlign: "right",
    color: "#ff5964",
    fontSize: 12,
  },
  labelForm: {
    fontSize: 12,
  },
};

export default InputPicker;
