import React, { useEffect, useState } from 'react'
import OkCompAdapter from 'OkApp/components/OkCompAdapter'

import { OkTypedComponent, LabelInputAuto } from 'OkApp'
import { Button, Fab } from '@material-ui/core'
import { createDefaultFuncMapStr } from 'OkApp/CodeProcessor'
import { criaFuncPraExecutar, useAtualValues, useOkApp, useReduxFormProps } from 'OkApp/UseUtils'
import { IconSelector } from 'components'
import { Field } from 'redux-form'
import _ from 'lodash'

import * as Icons from '@material-ui/icons';
import { ajusteRoute } from 'OkApp/context'
import { getOkFormSnap } from 'OkApp/OkFunctions'
import { useHistory } from 'react-router-dom'

const typeComponente = 'ButtonComp'

const RenderBotão = (props) => {

    const { okComp, fcAction, okfunctions, input, auxOkForm, styleCssOver, newTab, ...outer } = props
    let startIcon = null;
    if (okComp.iconkey) {
        const RecIcon = Icons[okComp.iconkey]
        startIcon = <RecIcon />
    }
    if (okComp.label) {

        return <Button variant="contained" color="primary"
            startIcon={startIcon}
            {...outer}
            style={{ marginBottom: 15, marginTop: 4, whiteSpace: 'nowrap' }}
            onClick={() => {
                if (fcAction) {
                    try {
                        fcAction({ ...okfunctions, input: input })
                    } catch (error) {
                        console.log('Action error', error)
                    }
                } else {
                    console.log("Sem função de ação");
                }
            }}>{okComp.label}</Button>
    } else {
        return <Fab size="small" color="primary"  {...outer}
            onClick={() => {
                if (fcAction) {
                    try {
                        fcAction({ ...okfunctions, input: input })
                    } catch (error) {
                        console.log('Action error', error)
                    }
                } else {
                    console.log("Sem função de ação");
                }
            }}>
            {startIcon}
        </Fab>



    }
}

const AdapterOkComp = (props) => {
    const history = useHistory();
    const okApp = useOkApp();
    const { okComp, okfunctions } = props;
    const propsComp = {};
    const [auxOkFormFull, setAuxOkFormFull] = useState(null)

    const formProps = useReduxFormProps();
    const atualValues = useAtualValues();

    const { type, auxOkForm } = okComp;

    let typeUsar = type
    useEffect(() => {
        if (auxOkForm) {

            getOkFormSnap(okApp, auxOkForm.uid, (r) => {


                setAuxOkFormFull(r)
            }, (err) => {

            })
        }

        return () => {
            setAuxOkFormFull(null)
        }
    }, [auxOkForm, okApp])

    if (!typeUsar) {
        typeUsar = "function"
    }

    let fcAction = () => console.log("buttom sem type")

    if (typeUsar === "function") {
        fcAction = criaFuncPraExecutar(okComp.functionsmap, 'acaoOnClick', { ...okfunctions, formProps, atualValues })
    } else if (typeUsar === "redirect") {
        const { pathUidOkObj, newTab } = okComp
        fcAction = ({ input }) => {
            const { value } = input
            let itemuid = pathUidOkObj ? _.get(value, pathUidOkObj) : value;

            let rota2 = ajusteRoute(okApp, auxOkFormFull) + (itemuid ? "?uid=" + itemuid : "")

            if (newTab === undefined || newTab) {
                window.open(rota2);
            } else {
                history.push(rota2)
            }

        }

    } else if (typeUsar === "salvarForm") {
        fcAction = (propsi) => {
            formProps.actions["crud-actions-save"]()
        }

    }
    return <OkCompAdapter {...props} propsComp={propsComp} component={(props) => {
        return <RenderBotão {...props} fcAction={fcAction} okComp={okComp} />
    }} />

}


const EditorOkComp = (props) => {

    let type = useAtualValues("type");
    if (!type) {
        type = "function"
    }
    // let valueRef = {}
    const mapTypeEditor = {
        function: <OkTypedComponent typeComponente='FunctionsEditor' field='functionsmap' label='Ação' />,
        redirect: <div>
            <OkTypedComponent typeComponente='SeletorOkForm' field='auxOkForm' label='Formulário' />
            {/* <Field name={"pathUidOkObj"} label={"Campo/Caminho para uid Objeto"} component={SeletorDePath} data={valueRef} /> */}
            <OkTypedComponent typeComponente='SwitchComp' field='newTab' label='Abrir em nova aba' />
        </div>

    }


    return <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <div>
            <OkTypedComponent typeComponente='InputComp' field='field' label='Nome Salvo' />
        </div>
        <div>
             <LabelInputAuto {...props} />
        </div>
        <div style={{
            display: 'flex',
        }}>
            <OkTypedComponent typeComponente='ComboBoxComp' field='color' label='Cor' options={['primary', 'secondary']} toStringItem={(v) => {
                switch (v) {
                    case 'primary': return "Cor Primária"
                    case 'secondary': return "Cor Secundária"

                    default: return v;
                }
            }} />
            <Field name={"iconkey"} label={"Icone"} component={IconSelector} />


        </div>
        <div style={{
            display: 'flex',

        }}>
            <OkTypedComponent typeComponente='ComboBoxComp' field='type' label='Tipo'
                options={['function', 'redirect', 'salvarForm']}
                toStringItem={(v) => {
                    if (!v) {
                        return "Função"
                    }
                    switch (v) {

                        case 'function': return "Função"
                        case 'redirect': return "Redirecionar"
                        case 'salvarForm': return "Salvar Formulário"

                        default: return v;
                    }
                }} />
        </div>
        {/* <OkTypedComponent typeComponente='FunctionsEditor' field='functionsmap' label='Ação' /> */}
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1

        }}>

            {mapTypeEditor[type]}
        </div>

    </div >
}

const modelo = {
    typeComponente,
    field: 'acaoBotao',
    label: 'Botão',
    color: "primary",
    type: "function",
    newTab: true,
    functionsmap: createDefaultFuncMapStr("acaoOnClick", "function acaoOnClick(props) {\n\tconsole.log('this', this);\n\talert('Você clicou no botão')\n}"),
    metaOkApp: { style: { sizeWidth: "0" } }
}

const ButtonComp = {
    typeComponente,
    configView: {
        grupo: 'Básico',
        nome: 'Botão',
        descricao: 'Executa uma ação ao ser clicado. Permite que usuário redirecione telas, salve formulários e configure ações específicas.',
        helpUrl: 'https://okapp.com.br/app/okappdocs/botao1',
        modelo
    },
    Component: AdapterOkComp,
    CompEditor: EditorOkComp
}


export default ButtonComp