import Page from './components/Page'

export const routes = () => {
    return [
        { exact: true, path: "/Register", component: Page },
    ]
}
export const menus = ({ history }) => {
    return [
        // { text: "Registro", action: () => {  history.push("/Register") } },
    ]
}

