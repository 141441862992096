import React from 'react'
import OkCompAdapter from 'OkApp/components/OkCompAdapter'


import EditorComp from './components/EditorComp'
import CompView from './components/CompView'
const typeComponente = 'ImportarNfe'



const AdapterOkComp = (props) => {

    let propsComp = {};
    return <OkCompAdapter {...props} propsComp={propsComp} component={CompView} />

}




const modelo = {
    typeComponente,
    field: 'xmlNfe',
    label: 'Importar Nfe',
    produts_fields: "produtos",
    map_propriedades: [
        { propStr: "nfeProc.NFe.infNFe.emit", propVar: "emitente" },
        { propStr: "nfeProc.NFe.infNFe.dest", propVar: "destinatario" },

        { propStr: "nfeProc.NFe.infNFe.Id", propVar: "chaveNfe" },
        { propStr: "nfeProc.NFe.infNFe.ide.dhEmi", propVar: "dataEmissao" },
        { propStr: "nfeProc.NFe.infNFe.ide.nNF", propVar: "numero" },
        { propStr: "nfeProc.NFe.infNFe.ide.serie", propVar: "serie" },
    ],
    map_propriedades_produtos: [
        { propStr: "prod.cProd", propVar: "codigo" },
        { propStr: "prod.xProd", propVar: "descricao" },
        { propStr: "prod.NCM", propVar: "ncm" },
        { propStr: "prod.uCom", propVar: "und" },
        { propStr: "prod.qCom", propVar: "quantidade" },
        { propStr: "prod.vUnCom", propVar: "valorUnitario" },
        { propStr: "prod.vProd", propVar: "valorTotal" },
    ],
    exibe_aux_console: false,


}


const ImportarNfe = {
    typeComponente,
    configView: {
        grupo: 'OK Utils',
        nome: 'Importar Nfe',
        descricao: 'Importar Nfe',
        helpUrl: 'https://www.google.com.br/',
        modelo
    },
    Component: AdapterOkComp,
    CompEditor: EditorComp
}
export default ImportarNfe
