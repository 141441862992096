import React, { useState, useEffect } from 'react'
import './style.css'
import { LiveProvider, LivePreview } from 'react-live';
import { Paginador } from 'components';

import * as recharts from 'recharts'
import * as materialUiCore from '@material-ui/core';
import { Helmet } from "react-helmet";
import { useAtualValues, useReduxFormProps } from 'OkApp/UseUtils';
import EditorPlayground from './EditorPlayground';
import '@fortawesome/fontawesome-free/js/all.js';
import Style from 'style-it';
export const reactLiveHome = {
    plain: {
        color: '#e7d2ed'
    },
    styles: [
        {
            types: ['prolog', 'comment', 'doctype', 'cdata'],
            style: {
                color: 'hsl(30, 20%, 50%)'
            }
        },
        {
            types: ['property', 'tag', 'boolean', 'number', 'constant', 'symbol'],
            style: { color: '#f677e1' }
        },
        {
            types: ['attr-name', 'string', 'char', 'builtin', 'insterted'],
            style: {
                color: 'hsl(75, 70%, 70%)'
            }
        },
        {
            types: [
                'operator',
                'entity',
                'url',
                'string',
                'variable',
                'language-css'
            ],
            style: {
                color: 'hsl(40, 90%, 70%)'
            }
        },
        {
            types: ['deleted'],
            style: {
                color: 'rgb(255, 85, 85)'
            }
        },
        {
            types: ['italic'],
            style: {
                fontStyle: 'italic'
            }
        },
        {
            types: ['important', 'bold'],
            style: {
                fontWeight: 'bold'
            }
        },
        {
            types: ['regex', 'important'],
            style: {
                color: '#e90'
            }
        },
        {
            types: ['atrule', 'attr-value', 'keyword'],
            style: {
                color: '#f677e1'
            }
        },
        {
            types: ['punctuation', 'symbol'],
            style: {
                opacity: '0.7'
            }
        }
    ]
};



class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }
    static getDerivedStateFromError(error) {
        console.log('getDerivedStateFromError', error)
        error['shouldIgnore'] = true;
        // Atualiza o state para que a próxima renderização mostre a UI alternativa.
        return { hasError: true };
    }


    componentDidCatch(error, info) {
        this.setState({ hasError: true });
        console.log('Capiturou error:', error)
        console.log('Capiturou error:', info)
    }


    render() {
        if (this.state.hasError) {
            // Você pode renderizar qualquer alternativa de UI
            return <div style={{ height: 40, width: '100%', backgroundColor: "#f00", display: 'flex', justifyContent: "center", alignItems: "center" }}>Erro no component. Veja o log.</div>;
        }
        return this.props.children;
    }
}

export default function ReactCssRender(props) {

    const [over, setOver] = useState(false)
    const { code, css, developer, okfunctions, updateCodeECss, input: { value } } = props;

    const [scopeAux, setScopeAux] = useState({ ...okfunctions, value, recharts, materialUiCore, Paginador, Helmet, useAtualValues, useReduxFormProps });

    useEffect(() => {
        if (value !== scopeAux.value) {

            setScopeAux({ ...scopeAux, ...okfunctions, value })
        }
    }, [scopeAux, okfunctions, value])
    return (<>

        <Style>
            {css ? css : ".nada{}"}
            <div className={"ReactCssRenderContent"} style={{ position: "relative", minHeight: developer ? 85 : 'none' }} onMouseEnter={() => setOver(true)} onMouseLeave={() => setOver(false)}>
                <LiveProvider code={code} scope={scopeAux} noInline={true} theme={reactLiveHome} >
                    <ErrorBoundary>
                        <LivePreview className={"StyledPreview"} />
                    </ErrorBoundary>
                    {developer && <EditorPlayground code={code} css={css} updateCodeECss={updateCodeECss} scopeAux={scopeAux} mouseOver={over} />}
                </LiveProvider>
            </div>
        </Style>



    </>
    )
}
