import Page from './components/Page'

export const routes = () => {
    return [
        { exact: true, path: "/", component: Page },
    ]
}
export const menus = ({ history }) => {
    return [
        { index: 0, text: "Início", iconKey: 'Home', action: () => { history.push("/") } },
    ]
}

