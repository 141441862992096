import { InputLabel } from '@material-ui/core'
import React from 'react'




export default function JustifyContent({ styleAtual, value, onChange }) {


    return (
        <div className='OkStyleEditor-comp'>
            <InputLabel>Justify Content</InputLabel>
            <div className='OkStyleEditor-row'>
                <button className={value === 'flex-start' ? 'OkStyleEditor-buttonSelected' : ''} onClick={() => onChange('flex-start')}>flex-start</button>
                <button className={value === 'center' ? 'OkStyleEditor-buttonSelected' : ''} onClick={() => onChange('center')}> center</button>
                <button className={value === 'flex-end' ? 'OkStyleEditor-buttonSelected' : ''} onClick={() => onChange('flex-end')}> flex-end</button>
                <button className={value === 'space-between' ? 'OkStyleEditor-buttonSelected' : ''} onClick={() => onChange('space-between')}> space-between</button>
                <button className={value === 'space-around' ? 'OkStyleEditor-buttonSelected' : ''} onClick={() => onChange('space-around')}> space-around</button>
            </div>

        </div>
    )
}
