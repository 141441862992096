
import { LinearProgress, lighten, withStyles } from "@material-ui/core";

const ColorLinearProgress = withStyles(theme => ({
    root: {
        margin: 3,
        height: 10,
        backgroundColor: "#E8E8E8",
    },
    bar: {
        borderRadius: 20,
        backgroundColor: lighten(theme.palette.primary.main, 0.8),
    },
}))(LinearProgress);
export default ColorLinearProgress;