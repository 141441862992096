import React from 'react'
import OkCompAdapter from 'OkApp/components/OkCompAdapter'
import CompView from './components/CompView'
import EditorComp from './components/EditorComp'
const typeComponente = 'MapsComp' 

const AdapterOkComp = (props) => {
    let propsComp = {}
    return <OkCompAdapter {...props} propsComp={propsComp} component={CompView} />
}

const modelo = {
    typeComponente,
    field: 'map',
    label: 'Mapa',
    fieldAreaCalc:'areaMap',
    type:'poligon',//point, poligon
    minHeight:'250'
}


const MapsComp = {
    typeComponente,
   // status: 'Desenvolvimento',
    configView: {
        grupo: 'Avançado',
        nome: 'Mapas',
        descricao: 'Mapas',
        helpUrl: 'https://okapp.com.br/app/okappdocs',
        modelo
    },
    Component: AdapterOkComp,
    CompEditor: EditorComp,
    // beforeSave: (obj, okComp) => {



    //     let arrayAnexo = _.get(obj, okComp.field);
    //     if (arrayAnexo) {
    //         for (let index = 0; index < arrayAnexo.length; index++) {
    //             const anexo = arrayAnexo[index];

    //             console.log('anexo salvao', anexo)


    //         }

    //     }


    //     return obj;
    // }
}


export default MapsComp