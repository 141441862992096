import React, { useEffect, useState } from 'react'
import { TextField, Button, Typography, Card } from '@material-ui/core'
import { useUserUid } from 'OkApp/UseUtils';
import ComentarioItem from './ComentarioItem';
import { getNewSmallUid } from 'OkApp/functions';
import { serverTimestampFirestore } from 'dbApi';
import useSpeechToText from 'react-hook-speech-to-text';

export default function CompView(props) {
    const { input: { value, onChange } } = props//general
    const { label, newComenteLabel, textBtComentar, msgSemComentario } = props//okcomp
    const [text, setText] = useState("");
    const [errorTest, setErrorTest] = useState("");
    const [novos, setNovos] = useState([])
    const userUid = useUserUid();

    const {
        error,
        isRecording,
        //results,
        startSpeechToText,
        stopSpeechToText,
        interimResult
    } = useSpeechToText({
        continuous: true,
        timeout: 1000,
        // crossBrowser: true,
        speechRecognitionProperties: { interimResults: true }
    });

    useEffect(() => {

        if (interimResult !== undefined)
            setText(interimResult)
    }, [interimResult])

    if (error) return <p>Web Speech API is not available in this browser </p>;

    const addComentario = () => {
        if (isRecording) {
            stopSpeechToText()
        }
        if (text) {

            let newComment = { userUid, text }

            newComment.uid = getNewSmallUid();
            newComment.dateCreate = new Date().toString();

            onChange(v => (v || []).concat([{ ...newComment, index: (v || []).length }]))
            setNovos(n => n.concat([newComment.uid]))
            setErrorTest("")
            setText("")
        } else {
            setErrorTest("Infome o comentario")
        }
    }
    const deleteComment = (comment) => {


        onChange(v => {
            let arr = (v.slice() || []);
            let indexOf = arr.map(c => c.uid).indexOf(comment.uid)
            if (indexOf !== -1) {
                arr.splice(indexOf, 1);
                return arr;
            }

        })


    }
    const onKeyPress = (e) => {
        if (e.key === 'Enter') {
            addComentario();
        }

    }


    let listSort = value || []
    listSort.sort((a, b) => {
        let r = 0;
        if (a.dateCreate !== serverTimestampFirestore() && b.dateCreate !== serverTimestampFirestore()) {
            r = new Date(b.dateCreate) - new Date(a.dateCreate)
        } if (r === 0) {
            r = b.index - a.index
        }
        return r;
    })

    return (
        <Card style={{ padding: 5, marginBottom: 15 }}>
            <div style={{ display: 'flex', justifyContent: "space-between", paddingBottom: 10 }}>
                <div style={{ flex: 1 }}>
                    <Typography variant="h6">{label}</Typography>

                    <TextField
                        value={text}
                        onChange={event => setText(event.target.value)}
                        label={newComenteLabel || "Novo Comentário"}
                        error={(errorTest) ? true : false}
                        helperText={errorTest}
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                        style={{ marginBottom: 15 }}
                        onKeyPress={onKeyPress}
                    />
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end", flexDirection: "column", padding: "15px 10px" }}>
                    <div>
                        <Button style={{ marginRight:5 }} variant="contained" color="primary" size="small" onClick={isRecording ? stopSpeechToText : startSpeechToText}> {isRecording ? 'Ouvindo...(Parar)' : 'Falar'}</Button>
                        <Button variant="contained" color="primary" size="small" onClick={addComentario}>{textBtComentar || "Comentar"}</Button>
                    </div>
                </div>
            </div>

            {listSort.map((c, i) => <ComentarioItem key={i} comment={c} index={i} deleteComment={deleteComment} ehNovo={novos.includes(c.uid)} />)}
            {!listSort.length && <Typography style={{ textAlign: 'center' }}>{msgSemComentario || "Sem comentários"}</Typography>}

        </Card>
    )
}
