import React from 'react'
import { Typography, Fab, Tooltip } from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';


import "../../style.css"
import { RenderOkForm } from 'OkApp';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import produce from "immer";


const getItemStyle = (isDragging, draggableStyle) => ({
    // change background colour if dragging
    background: isDragging ? "lightgreen" : "",
    // styles we need to apply on draggables
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "lightblue" : "",

});

const Region = ({ getPreField, painelCardComps, regiao, itens, addNovoItem, removeItem, exibeAdd }) => {

    let listaUsar = itens || [];
    let style = {};

    if (regiao.rowspan) {
        style.gridRow = 'span ' + parseInt(regiao.rowspan)
    }

    if (regiao.colspan) {
        style.gridColumn = 'span ' + parseInt(regiao.colspan)
    }
    let exibirAdicionar = exibeAdd === undefined ? true : exibeAdd;
    return (<div className="RegioesDnD-conteinerRegion" style={style}>
        <div className="RegioesDnD-conteinerRegion-titleConteiner">
            <Typography >
                {regiao.nome}
            </Typography>
            {exibirAdicionar &&
                <Tooltip title="Adicionar novo">
                    <Fab size="small" onClick={() => addNovoItem(regiao)}>
                        <AddIcon />
                    </Fab>
                </Tooltip>
            }
        </div>
        <Droppable droppableId={regiao.nome}>
            {(provided, snapshot) => (
                <div
                    className="RegioesDnD-conteinerRegion-itensConteiner"

                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                >
                    {listaUsar.map((item, index) => {




                        let prefield = getPreField(item)
                        return <Draggable key={"itemDND" + item.uid} draggableId={item.uid} index={index}>
                            {(provided, snapshot) => (
                                <div
                                    className="RegioesDnD-conteinerItemLista"
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                    )}
                                >
                                    {exibirAdicionar &&
                                        <div className="RegioesDnD-conteinerItemLista-Delete" onClick={() => removeItem(item)}>
                                            <DeleteIcon />
                                        </div>}
                                    {painelCardComps.map((c, i) => <RenderOkForm key={"CompCard" + c.uid} prefield={prefield} okComp={c} okCompsIrmaos={painelCardComps} renderForm={false} />)}
                                </div>

                            )}
                        </Draggable>




                    })}
                </div>
            )}
        </Droppable>

    </div>)
}

export default function RegioesDnd(props) {
    // const [itens, setItens] = useState([])
    const { input: { value, onChange } } = props
    const { field, painelCard, regioes, numColunas, label, fieldRegion, gap, exibeAdd } = props

    const addNovoItem = (region) => {
        let newIndex = getAndSortList(value, region.nome).length
        onChange(l => {
            let newArray = l ? l.slice ? l.slice() : [] : [];
            let newItem = {
                uid: Math.random().toString(36).substring(7),

            }
            newItem[fieldRegion] = region.nome
            newItem[fieldRegion + "-Index"] = newIndex
            newArray.push(newItem)
            return newArray
        })
    }

    const removeItem = (item) => {
        let indexRemover = null;
        const nextState = produce((value || []), draftState => {

            for (let index = 0; index < draftState.length; index++) {
                const element = draftState[index];
                if (element.uid === item.uid) {
                    indexRemover = index;
                    break;
                }
            }
            if (indexRemover !== null)
                draftState.splice(indexRemover, 1)

            //reorder itens
            let sorList = getAndSortList(draftState, item[fieldRegion]);
            for (let index = 0; index < sorList.length; index++) {
                const element = sorList[index];
                element[fieldRegion + "-Index"] = index
            }

        })
        if (indexRemover !== null)
            onChange(nextState);

    }
    const getPreField = (item) => {
        let index = 0
        for (let i = 0; i < value.length; i++) {
            const element = value[i];
            if (element.uid === item.uid) {
                index = i;
            }
        }

        let prefield = field + "." + index + "."
        return prefield;
    }

    const onDragEnd = result => {
        if (!result.destination) {
            return;
        }
        let dragItemIndex = null;
        for (let i = 0; i < value.length; i++) {
            const element = value[i];
            if (element.uid === result.draggableId) {
                dragItemIndex = i;
                break;
            }
        }


        if (dragItemIndex === null) {
            return;
        }

        const nextState = produce((value || []), draftState => {
            let itemLista = draftState[dragItemIndex];
            let indexRemover = -1

            if (result.destination.draggableId !== result.source.draggableId) {
                indexRemover = result.source.index
            }
            let sorList = getAndSortList(draftState, result.destination.droppableId);
            console.log('indexRemover', indexRemover)
            for (let index = 0; index < sorList.length; index++) {
                const element = sorList[index];
                element[fieldRegion + "-Index"] = index
            }


            itemLista[fieldRegion] = result.destination.droppableId
            itemLista[fieldRegion + "-Index"] = result.destination.index

            sorList = getAndSortList(draftState, result.destination.droppableId);
            for (let index = 0; index < sorList.length; index++) {
                const element = sorList[index];
                element[fieldRegion + "-Index"] = index
            }


        })

        onChange(nextState);
    };
    const getAndSortList = (list, regionName) => {
        if (!list) {
            return []
        }
        let lRetorno = list.filter(l => l[fieldRegion] === regionName).sort((a, b) => a[fieldRegion + "-Index"] - b[fieldRegion + "-Index"])
        return lRetorno
    }

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <div className="RegioesDnD-conteinerRoot">
                <div className="RegioesDnD-conteinerTitle">
                    <Typography variant="h6" >
                        {label}
                    </Typography>
                </div>

                <div className="RegioesDnD-conteinerGrid" style={{ gridTemplateColumns: Array(parseInt(numColunas || 0) + 1).join("auto "), gap: gap ? parseInt(gap) : 25 }}>
                    {regioes.map((r, i) => <Region key={"RegionDND" + i}
                        regiao={r}
                        itens={getAndSortList(value, r.nome)}
                        addNovoItem={addNovoItem}
                        removeItem={removeItem}
                        getPreField={getPreField}
                        painelCardComps={painelCard}
                        exibeAdd={exibeAdd}

                    />)}

                </div>
            </div>
        </DragDropContext>
    )
}
