import React, { useEffect, useState } from 'react'
import { Field } from 'redux-form'
import { useTranslation } from 'react-i18next';
import useAuth, { usePerfil } from 'useAuth';
import { CrudForm } from 'crud'
import { Tabs, Tab, CircularProgress, Button, Typography } from '@material-ui/core';
import EditorCss from 'components/EditorCss';

import CrudControlForm from './CrudControlForm';
import EditorPermissions from 'OkApp/modules/PermissionMeta/components/EditorPermissions';
import { OkTypedComponent } from 'OkApp';
import ColorTool from '../ColorTools/ColorTool';
import OkFormSel from '../OkFormSel';
import "./style.css"
import { useHistory, useLocation } from 'react-router-dom';
import { useListMeusOkApp, useAtualValues } from 'OkApp/UseUtils';

import { salvarObjDatabase } from 'dbApi';
import useNotification from 'notification';
import app from 'firebase/compat/app';
import { Favorite } from '@material-ui/icons';
import AssinaturasForm from '../../Assinatura/components/AssinaturasForm';

const colectionFirebase = "Project";
let contClickVerified = 0;

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function MainForm(props) {
    const { isAdmin, numUsers, atigiuLimiteProjetos, perfil } = props
    // const formProps = useReduxFormProps();
    // const AtualValues = useAtualValues();

    const uid = useAtualValues('uid');
    const firebaseProjectId = useAtualValues('firebaseProjectId');
    const publicField = useAtualValues('metaOkApp.permission.public');
    const favoritoField = useAtualValues('favorito.' + perfil.uid);

    const history = useHistory();

    const query = useQuery();
    const uidQuery = query.get('uid')
    const tabUrl = query.get('tab')



    const { t } = useTranslation();
    const [tab, setTab] = useState(0)

    const { showError, showSuccess } = useNotification();


    const handleChange = (event, newValue) => {
        setTab(newValue);
    };
    let PerfisPerm = []

    let atigiuLimiteMesmo = false;
    if (!uidQuery) {
        atigiuLimiteMesmo = !uid && atigiuLimiteProjetos
    }

    let contratarPlano = () => {
        history.push('/Planos')
    }

    // let changeOwener = () => {
    //     console.log('AtualValues', AtualValues)
    //     formProps.change("metaOkApp.permission.owner", "zldjt9RQzQMqQitPbGjdsZ0Sgub2")
    // }

    useEffect(() => {
        if (tabUrl) {
            if (tabUrl === "planos") {
                setTab(6)
            }
        }


    }, [tabUrl])


    const actionVerificaIdFirebase = () => {
        let database = null;
        let config = { projectId: firebaseProjectId };

        try {
            // database = firebaseUtil.initGetDataBase(config, 'secondary')
            app.initializeApp(config, 'secondary' + contClickVerified);
            let iii = app.apps.map(item => item.name).indexOf('secondary' + contClickVerified)
            let fireApp = app.apps[iii];

            if (fireApp) {
                database = fireApp.firestore();
            }

            if (database) {
                salvarObjDatabase(database, { nome: 'valid' }, colectionFirebase,
                    (r) => {

                        console.log("CRUD - Sucesso Salvou: ", database);
                        showSuccess('Firebase ProjectId está correto')
                    },
                    (err) => {
                        console.log("CRUD - error", err);
                        showError('Firebase ProjectId está incorreto')
                    })

                contClickVerified = contClickVerified + 1;
            }
        } catch (error) {
            console.error('Erro ao conectar com o banco ', error);
        }
    }
    return (
        <>
            {atigiuLimiteMesmo ?
                <div className="msgPlano">
                    <strong className="msgTitulo" style={{ textAlign: "center" }}>Você atingiu o limite de projetos para seu plano. Contrate mais projetos para seu plano.</strong>
                    <div style={{ margin: "0 auto", paddingTop: 10 }}>
                        <Button variant="contained" color="primary" onClick={contratarPlano} >Contratar</Button>
                    </div>
                </div> :
                <div className="containerAbas">
                    <Tabs
                        orientation="vertical"
                        value={tab}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                    // centered
                    >
                        <Tab label="Dados" />
                        <Tab label="Config. Formulários" />

                        <Tab label="Config. Avançadas" />
                        <Tab label="Style" />
                        <Tab label="Visibilidade e Permissoes" />

                        <Tab label="Termos de Uso e Política de Privacidade" />
                        <Tab label="Planos de Assinatura" />

                        {isAdmin &&
                            <Tab label="Admin" />}


                    </Tabs>
                    {
                        tab === 0 && <div className="tab" style={{}}>
                            <div style={{ display: 'flex' }}>
                                <div style={{ flex: 0 }}>
                                    <OkTypedComponent typeComponente='ImageSelComp' field='logo' label='Logo' textoBotao="Selecione a logo" />
                                </div>
                                <div style={{ flex: 1 }}>
                                    <div style={{ display: 'flex' }}>
                                        <OkTypedComponent typeComponente='InputComp' field='uid' label={'UID'} disabled />
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <OkTypedComponent typeComponente='SwitchComp' field={'favorito.' + perfil.uid} label={"Favorito"} />
                                            {favoritoField && <Favorite style={{ color: '#e80c0c' }} />}
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <OkTypedComponent typeComponente='InputComp' field='name' label={t('project.name')} />
                                        <OkTypedComponent typeComponente='InputComp' field='route' label={t('project.route')}
                                            parseInputs={(v) => {
                                                return v.split('/').join('');
                                            }} />
                                    </div>
                                    <OkTypedComponent typeComponente='InputComp' field='description' label={t('project.description')} />
                                    <div style={{ display: 'flex' }}>
                                        <OkTypedComponent typeComponente='ComboBoxComp' field='type' label={t('project.typeStr')}
                                            options={[
                                                'application',
                                                'module',
                                                'functionality',
                                                'component',
                                                'test',
                                            ]}
                                            toStringItem={(v) => {
                                                return t("project.type." + v)
                                            }}


                                        />
                                        <OkTypedComponent typeComponente='ComboBoxComp' field='status' label={t('project.statusStr')}
                                            options={[
                                                'ready',
                                                'under-developmen',
                                                'under-maintenance',
                                                'discontinued',
                                            ]}
                                            toStringItem={(v) => {
                                                return t("project.status." + v)
                                            }}

                                        />
                                    </div>
                                </div>
                            </div>
                            <div style={{ marginBottom: 10 }}>
                                <fieldset style={{ padding: 0, minHeight: 200 }}>
                                    <legend>{t('project.detailedDescription')}</legend>
                                    <OkTypedComponent typeComponente='HtmlEditor' field='descricaoDetalhada' />
                                </fieldset>
                            </div>
                            <ColorTool />
                        </div>
                    }
                    {tab === 1 && <div className="tab" style={{}}>
                        <Field name="formPerfil" component={OkFormSel} label={'Formulário de Perfil'} permiteRemover={true} />
                        <Field name="formInicio" component={OkFormSel} label={'Tela após efetuar o login '} permiteRemover={true} />
                        <Field name="formInicioNotAuth" component={OkFormSel} label={'Tela de inicio sem usuario logado'} permiteRemover={true} />
                    </div>
                    }
                    {
                        tab === 2 && <div className="tab" style={{}}>
                            <div style={{ display: 'flex' }}>
                                <div style={{ flex: 1 }}>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ width: '90%' }}>
                                            <OkTypedComponent typeComponente='InputComp' field='firebaseProjectId' label={"Firebase ProjectId (Id incorreto nada salva)"} />
                                        </div>
                                        <div style={{ width: '11%' }}>
                                            <Button variant="contained" color="primary" onClick={actionVerificaIdFirebase} >Verificar Id</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }


                    {

                        tab === 3 && <div className="tab" style={{}}>
                            <OkTypedComponent typeComponente='SwitchComp' field='showLogoForm' label={"Exibir logo do app junto ao titulo dos formulários"} />
                            <div style={{ display: 'flex', flexDirection: "column", height: "100%" }}>

                                <Field name="globalStyle" component={EditorCss} label={"Global Style"} />
                            </div>

                        </div>
                    }

                    {
                        tab === 4 && <div className="tab" style={{}}>
                            <div style={{ display: 'flex' }}>
                                <OkTypedComponent typeComponente='SwitchComp' field='metaOkApp.permission.public' label={"Publico (Qualquer usuário pode acessar o aplicativo)"} disabled={!isAdmin} />
                                <OkTypedComponent typeComponente='SwitchComp' field='modelo' label={"Modelo (Usuários podem copiar este projeto)"} disabled={!isAdmin} />
                                <OkTypedComponent typeComponente='SwitchComp' field='listado' label={"Listado"} disabled={!publicField} />
                            </div>

                            <EditorPermissions labelEditor="Desenvolvedores" labelUsers="Usuários" labelPublico="Publico a todos os Usuários" labelListaPermi={"Perfis"} listPermissions={PerfisPerm} hidePublico hideGrupos maxNumUser={numUsers} />
                            <OkTypedComponent typeComponente='ListaItens' field='gruposPermissao' label={"Grupos de Permissão"} adicionavel={true} removivel={true} />
                            <div style={{ display: 'flex' }}>
                                <OkTypedComponent typeComponente='SwitchComp' field='ocultarLoginMenu' label={"Ocultar menu de Login"} />

                            </div>
                            <Typography variant='h6'>Formulários</Typography>
                            <div style={{ display: 'flex' }}>
                                <OkTypedComponent typeComponente='SwitchComp' defaultValue={true} field='okform.dadosPublicos' label={"Novos Formulários tem seus dados publicos"} />
                                <OkTypedComponent typeComponente='SwitchComp' field='okform.publicNoUser' label={"Novos Formulários iniciam como publicos sem usuário"} />
                                <OkTypedComponent typeComponente='SwitchComp' defaultValue={true} field='okform.public' label={"Novos Formulários iniciam como publicos a todos os usuários"} />
                            </div>

                        </div>
                    }

                    {tab === 5 && <div className="tab" style={{}}>
                        <div style={{}}>
                            {/* <OkTypedComponent typeComponente='PanelFlexComp' label="Descrição" fieldset={true} children={[]} /> */}
                            {/* <OkTypedComponent typeComponente='TextComp' label="Descrição" /> */}
                            <fieldset style={{ padding: 0, minHeight: '200px' }}>
                                <legend>{t('project.termsOfUse')}</legend>
                                <OkTypedComponent typeComponente='HtmlEditor' field='termosDeUso' />
                            </fieldset>
                            <fieldset style={{ padding: 0, minHeight: '200px' }}>
                                <legend>{t('project.privacyPolicies')}</legend>
                                <OkTypedComponent typeComponente='HtmlEditor' field='politicaDePrivacidade' />
                            </fieldset>
                        </div>

                    </div>}
                    {tab === 6 && <div className="tab" style={{}}>
                        <div>Em desenvolvimento...(modo teste)</div>
                        <AssinaturasForm isAdmin={isAdmin} />
                        {/* {isAdmin ? <AssinaturasForm /> : <div>Em desenvolvimento</div>} */}

                    </div>
                    }
                    {tab === 7 && <div className="tab" style={{}}>
                        {/* <div>nada aqui ...</div> */}
                        <OkTypedComponent typeComponente='InputComp' field='stripe.application_fee_percent' type="number" label={"Stripe  % OK App (default 10+tax stripe)"} />
                        <OkTypedComponent typeComponente='InputComp' field='configComps.AnexosComp.maxSize' type="number" label={"Anexos: Tamanho Máximo de um anexo(em Mb)"} />
                        {/* <OkTypedComponent typeComponente='InputComp' field='configComps.AnexosComp.maxQT' type="number" label={"Anexos: Quantidade Máxima de anexos"} /> */}
                        {/* <div style={{ margin: "0 auto", paddingTop: 10 }}>
                            <Button variant="contained" color="primary" onClick={changeOwener} >To OkAppPlatform L</Button>
                        </div> */}
                    </div>
                    }
                </div>
            }
        </>
    )
}

function ProjectForm(props) {
    const perfil = usePerfil();
    const { authenticatedOnly } = useAuth();
    const projetos = useListMeusOkApp();
    const history = useHistory();



    useEffect(() => {
        authenticatedOnly()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    //visibility

    let loading = perfil ? false : true;
    let showCadastro = true;
    let temPlano = true;
    let isAdmin = false;
    let nomePlano = null
    let numLimiteProjetos = 1;
    let numUsers = 1;
    let dataFimPLano = null;
    let atigiuLimiteProjetos = false;
    if (perfil) {
        nomePlano = perfil.nomePlano
        if (perfil.tipoPerfil === "admin") {
            isAdmin = true;
        }

        if (perfil.dataFimPlano) {
            dataFimPLano = new Date(perfil.dataFimPlano)

        }
        if (perfil.numProject) {
            numLimiteProjetos = parseInt(perfil.numProject);
        }

        if (perfil.numUser) {
            numUsers = parseInt(perfil.numUser);
        }
    }

    if (projetos && projetos.length >= numLimiteProjetos) {
        atigiuLimiteProjetos = true;
    }

    if (!nomePlano || !dataFimPLano || dataFimPLano < new Date()) {
        temPlano = false;
        showCadastro = false;
    }

    let contratarPlano = () => {
        history.push('/Planos')

    }
    return (
        <div style={{ padding: 15 }}>
            {(!loading && !temPlano) && <div className="msgPlano">
                {nomePlano ?
                    <strong className="msgTitulo" style={{ textAlign: "center" }}>Seu plano "{nomePlano}" terminou</strong> :
                    <strong className="msgTitulo" style={{ textAlign: "center" }}>Você precisa contratar um plano para criar ou editar um projeto</strong>}

                <div style={{ margin: "0 auto", paddingTop: 10 }}>
                    <Button variant="contained" color="primary" onClick={contratarPlano} >Ver planos</Button>
                </div>
            </div>}
            {showCadastro && <>
                {loading ? <CircularProgress /> :
                    <CrudForm
                        title={"Cadastro de Projeto"}
                        form={"ProjectForm"}
                        initialValues={{
                            globalStyle: '.CustomFormContainer{padding:10px; padding-bottom: 45px;}',
                            listado: true,
                            type: "application",
                            status: "under-developmen"

                        }}
                        colectionFirebase={colectionFirebase}
                        salvar novo
                        noModules
                        InsertComp={CrudControlForm}
                    >
                        <MainForm isAdmin={isAdmin} numUsers={numUsers} atigiuLimiteProjetos={atigiuLimiteProjetos} perfil={perfil} />

                    </CrudForm>}
            </>}
        </div>
    )
}

export default ProjectForm