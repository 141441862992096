import React, { useState, useRef, useEffect } from 'react'
import { Dialog, IconButton, makeStyles, Slide, Typography, Tooltip, Paper } from '@material-ui/core'


import AddIcon from '@material-ui/icons/Add';
import ListAlt from '@material-ui/icons/ListAlt';
import Input from '@material-ui/icons/LinearScale';

import { Tree } from 'antd';

import produce from "immer"
import { useOnKeyDownAlt } from 'OkApp/functions';
import { DialogAddEditComp } from 'OkApp';
import { Edit } from '@material-ui/icons';
import './style.css';

const useStyles = makeStyles(theme => ({
    BackdropProps: {
        backgroundColor: "transparent"
    },
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        width: 250
    },
    title: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,

        display: 'flex',
        alignContent: 'center',
        padding: 5
    },

}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="right" ref={ref} {...props} />;
});
const getChildren = (arrayComps, uid) => {
    if (!uid) {
        return null
    }
    for (var i = 0; i < arrayComps.length; i++) {
        let c = arrayComps[i];
        if (c.uid === uid) {
            return c;
        } else {
            if (c.children) {
                let r = getChildren(c.children, uid)
                if (r) {
                    return r;
                }

            }

        }
    }

    return null
}

export default function MetaDialog({ input: { value, onChange }, openProps, okform }) {

    const [parentComp, setParentComp] = useState(null)
    const [treeData, setTreeData] = useState([])
    const refAddCompDialog = useRef(null);
    const editComponent = (c) => {
        const nextState = produce(value, draftState => {
            let r = getChildren(draftState, c.uid)
            if (r) {
                for (const key in c) {
                    if (c.hasOwnProperty(key)) {
                        const element = c[key];
                        r[key] = element;
                    }
                }
            } else {
                console.log("Nao encontrei o componente en edicao");
            }
        })

        onChange(nextState)
    }

    const [show, setShow] = useState(false)
    const classes = useStyles();
    const handleShowAdd = () => setShow(b => !b);
    useOnKeyDownAlt('KeyT', handleShowAdd)

    useEffect(() => {
        const CompNode = ({ comp, children, parentComp }) => {
            const [over, setOver] = useState(false)
            return <span style={{ display: "flex", width: "100%" }} onMouseEnter={() => setOver(true)} onMouseLeave={() => setOver(false)}>
                {/* {children} */}
                <span style={{ display: "flex", flex: 1, alignItems: 'center', paddingLeft: 5 }}>
                    {comp.label}
                </span>
                {over && <>
                    {comp.children && <IconButton size={'small'}
                        onClick={() => {
                            setParentComp(comp);
                            refAddCompDialog.current.showAddComp();
                            setShow(false)
                        }}
                    >
                        <AddIcon />
                    </IconButton>
                    }
                    <IconButton size={'small'}
                        onClick={() => {
                            setParentComp(parentComp);
                            refAddCompDialog.current.showEditarComp(comp)
                            setShow(false)
                        }}
                    >
                        <Edit />
                    </IconButton>
                </>}
            </span>
        }

        const criaNo = (comp, level, parentComp) => {
            let node = { key: comp.uid, comp }
            node.title = <CompNode comp={comp} parentComp={parentComp} ><Input size={'small'} /></CompNode>

            if (comp.children) {
                node.title = <CompNode comp={comp} parentComp={parentComp}><ListAlt size={'small'} /></CompNode>

                node.children = []
                comp.children.forEach(f => {
                    node.children.push(criaNo(f, level + 1, comp))
                });
            }
            return node;
        }
        if (value) {
            let r = [];

            value.forEach(element => {
                r.push(criaNo(element, 0))
            });
            setTreeData(r);

        }


    }, [value])

    const salvarEdicao = (v) => {
        editComponent(v)
    };

    const addFilho = (v) => {
        let copy = { ...parentComp, children: v }
        editComponent(copy)
    };

    const updateComp = (c) => {
        const nextState = produce((parentComp.children || []), draftState => {
            let indexOf = draftState.map(c => c.uid ? c.uid : c.field).indexOf(c.uid);
            if (indexOf === -1) {
                draftState.push(c);
            } else {
                draftState[indexOf] = c;
            }

        })

        addFilho(nextState)
    }

    const onDrop = info => {
        console.log(info);
        const dropKey = info.node.props.eventKey;
        const dragKey = info.dragNode.props.eventKey;
        const dropPos = info.node.props.pos.split('-');
        const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);

        const loop = (data, key, callback) => {            
            for (let i = 0; i < data.length; i++) {                
                if (data[i].uid === key) {
                    return callback(data[i], i, data);
                }
                if (data[i].children) {
                    loop(data[i].children, key, callback);
                }
            }
        };
        const nextState = produce((value || []), data => {

            // Find dragObject
            let dragObj;
            loop(data, dragKey, (item, index, arr) => {
                arr.splice(index, 1);
                dragObj = item;
            });
            
            if (!info.dropToGap) {
                // Drop on the content
                loop(data, dropKey, item => {
                    item.children = item.children || [];
                    item.children.push(dragObj);
                });
            } else if (
                (info.node.props.children || []).length > 0 && // Has children
                info.node.props.expanded && // Is expanded
                dropPosition === 1 // On the bottom gap
            ) {
                loop(data, dropKey, item => {
                    item.children = item.children || [];
                    item.children.unshift(dragObj);
                });
            } else {
                let ar;
                let i;
                loop(data, dropKey, (item, index, arr) => {                    
                    ar = arr;
                    i = index;
                });
                if (dropPosition === -1) {
                    ar.splice(i, 0, dragObj);
                } else {
                    ar.splice(i + 1, 0, dragObj);
                }
            }


        })
        onChange(nextState)

    };


    return (
        <>
            <Tooltip title={"Arvore de Componentes (Alt + T)"}>
                <IconButton size="small" style={{ width: "100%" }} onClick={() => { setShow(true) }}>
                    <ListAlt />
                </IconButton>
            </Tooltip>
            <Dialog open={show} onClose={() => { setShow(false) }}
                TransitionComponent={Transition}
                BackdropProps={{
                    classes: {
                        root: classes.BackdropProps
                    }
                }
                }
                PaperComponent={(props) => {
                    return <Paper style={{ position: 'absolute', left: 20, height: '100%', backgroundColor: "transparent", }} {...props} />
                }}
            >
                <div className={classes.title}>
                    <Typography variant="h6" className={classes.title}>Componentes</Typography>
                </div>

                <div className={classes.root}>
                    <Tree

                        defaultExpandAll
                        draggable
                        blockNode
                        // onDragEnter={this.onDragEnter}
                        onDrop={onDrop}
                        treeData={treeData}
                    />

                </div>
            </Dialog>
            <DialogAddEditComp ref={refAddCompDialog}
                okform={okform}
                updateComp={updateComp}
                editComp={salvarEdicao}
            />
        </>
    )
}
