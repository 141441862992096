import React, { useEffect, useState } from 'react'
import { useCrudContext } from 'crud'
import { useHistory } from 'react-router-dom';

import _ from 'lodash'
import './style.css'
import { useListOkApp, useUserUid } from 'OkApp/UseUtils';

import { ContentLoading } from 'components';
import { useTranslation } from 'react-i18next';

import ViewFiltroProjetos from '../components/ViewFiltroProjetos';
import produce from "immer"

const route = "/Projeto";

export default function ConsultaProjeto() {

    const { setTitle, addAction, removeActions } = useCrudContext();
    const [projetosFilter, setProjetosFilter] = useState(null)
    const userUid = useUserUid();
    const projetos = useListOkApp();
    const history = useHistory();
    const { t } = useTranslation();
    const updateItem = (item) => {
        const nextState = produce(projetosFilter, draftState => {
            for (let key in draftState) {
                if (draftState[key].uid === item.uid) {
                    draftState[key] = item;
                }
            }
        })
        setProjetosFilter(nextState)
    }
    useEffect(() => {
        setTitle("Meus projetos")
        return () => setTitle("")
    }, [setTitle])

    useEffect(() => {
        addAction({ uid: 'crud.actions.new_project', text: t('crud.actions.new_project'), icon: null, action: () => history.push(route), props: {}, })
        return () => { removeActions(['crud.actions.new_project']) }

    }, [addAction, history, removeActions, t])

    useEffect(() => {
        const permiteEditar = (obj) => {
            let permission = _.get(obj, 'metaOkApp.permission');

            if (userUid && permission) {
                if (permission.owner === userUid || (permission.usersWrite && permission.usersWrite[userUid])) {
                    return true;
                }
            }
            return false;
        }

        if (projetos) {

            setProjetosFilter(projetos.filter((p) => {
                if (_.get(p, "metaOkApp.permission.owner") === userUid) {
                    return true
                }
                if (permiteEditar(p)) {
                    return true;
                }


                let publicp = _.get(p, "metaOkApp.permission.public");
                let e = publicp ? false : true;
                return e;
            }))



        } else {
            setProjetosFilter(null)
        }

    }, [projetos, userUid])
    let temProjetos = projetosFilter && projetosFilter.length
    return (
        <>
            {!projetosFilter && <div className="projectContainer" style={{ maxHeight: 182 }}>
                <div style={{ margin: 10 }}>
                    <ContentLoading style={{ height: 100, width: 100 }} />
                </div>
                <div className="projectData">
                    <ContentLoading style={{ height: 18, width: 250 }} />
                    <div className="projectDescription">  <ContentLoading style={{ height: 12, width: 100 }} /></div>

                </div>
            </div>}
            {temProjetos ? <ViewFiltroProjetos projetos={projetosFilter} updateItem={updateItem} />
                :
                <div style={{ textAlign: 'center', margin: "0 auto", flex: 1, padding: 25 }}>
                    <strong className="projectName" style={{ textAlign: "center" }}>Você não possui nenhum projeto. Cadastre um clicando no botão "NOVO" acima.</strong>
                    {/* <div style={{ margin: "0 auto", paddingTop: 10 }}>
                        <Button variant="contained" color="primary" onClick={novoProject} >Novo Projeto</Button>
                    </div> */}
                </div>
            }
        </>
    )
}
