import React, { useState } from 'react'
import { LiveProvider, LiveError, LivePreview } from 'react-live'
import { defaultSnippets } from 'OkApp/CodeProcessor'
import { CodeEditor, EditorCss } from 'components';
import Style from 'style-it';
import SplitPane from 'react-split-pane';
import Pane from 'react-split-pane/lib/Pane';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }
    static getDerivedStateFromError(error) {
        console.log('getDerivedStateFromError', error)
        error['shouldIgnore'] = true;
        // Atualiza o state para que a próxima renderização mostre a UI alternativa.
        return { hasError: true };
    }


    componentDidCatch(error, info) {
        this.setState({ hasError: true });
        console.log('Capiturou error:', error)
        console.log('Capiturou error:', info)
    }


    render() {
        if (this.state.hasError) {
            // Você pode renderizar qualquer alternativa de UI
            return <div style={{ height: 40, width: '100%', backgroundColor: "#f00", display: 'flex', justifyContent: "center", alignItems: "center" }}>Erro no component. Veja o log.</div>;
        }
        return this.props.children;
    }
}
export const reactLiveHome = {
    plain: {
        color: '#e7d2ed'
    },
    styles: [
        {
            types: ['prolog', 'comment', 'doctype', 'cdata'],
            style: {
                color: 'hsl(30, 20%, 50%)'
            }
        },
        {
            types: ['property', 'tag', 'boolean', 'number', 'constant', 'symbol'],
            style: { color: '#f677e1' }
        },
        {
            types: ['attr-name', 'string', 'char', 'builtin', 'insterted'],
            style: {
                color: 'hsl(75, 70%, 70%)'
            }
        },
        {
            types: [
                'operator',
                'entity',
                'url',
                'string',
                'variable',
                'language-css'
            ],
            style: {
                color: 'hsl(40, 90%, 70%)'
            }
        },
        {
            types: ['deleted'],
            style: {
                color: 'rgb(255, 85, 85)'
            }
        },
        {
            types: ['italic'],
            style: {
                fontStyle: 'italic'
            }
        },
        {
            types: ['important', 'bold'],
            style: {
                fontWeight: 'bold'
            }
        },
        {
            types: ['regex', 'important'],
            style: {
                color: '#e90'
            }
        },
        {
            types: ['atrule', 'attr-value', 'keyword'],
            style: {
                color: '#f677e1'
            }
        },
        {
            types: ['punctuation', 'symbol'],
            style: {
                opacity: '0.7'
            }
        }
    ]
};
export default function EditorPainel(props) {
    const { codeAux, setCodeAux, cssAux, setCssAux, scopeAux } = props
    const scope = { ...scopeAux };
    const [sizeH, setSizeH] = useState([1, 1])
    const [sizeV, setSizeV] = useState([1, 1])

    return (
        <div style={{
            backgroundColor: '#ccc', flex: 1, position: 'relative',
            overflow: 'auto',
            display: 'flex',
            flexDirection: 'column',
        }}>
            <ErrorBoundary>
                <LiveProvider code={codeAux} scope={scope} noInline={true}
                    theme={reactLiveHome}
                >
                    <div style={{ display: "flex", flex: 1, overflow: 'auto' }}>
                        <SplitPane split="vertical" onChange={(size) => { setSizeV(size) }} >
                            <Pane size={sizeV[0]}>
                                <SplitPane split="horizontal" onChange={(size) => { setSizeH(size) }}  >
                                    <Pane className="PaneStyleTeste1h" size={sizeH[0]}>
                                        <CodeEditor input={{ value: codeAux, onChange: setCodeAux }} useWorker={false} snippets={defaultSnippets.concat([])} />
                                    </Pane >
                                    <Pane className="PaneStyleTeste2h" size={sizeH[1]} >
                                        <EditorCss input={{ value: cssAux, onChange: setCssAux }} label="CSS" labelStyle={{ backgroundColor: "#000", color: "#fff", padding: 5, flex: 1 }} noDialog />

                                    </Pane >
                                </SplitPane>
                            </Pane >
                            <Pane size={sizeV[1]}>


                                <Style>
                                    {cssAux ? cssAux : ".nada{}"}
                                    <div style={{ flex: 1, overflow: 'auto', position: 'relative' }}>
                                        <LivePreview className={"StyledPreview"} />
                                    </div>
                                </Style>
                            </Pane>
                        </SplitPane>
                    </div>
                    <LiveError className={"StyledError"} />
                </LiveProvider>
            </ErrorBoundary>
        </div >
    )
}
