import Page from './components/Page'

export const routes = () => {
    return [
        { exact: true, path: "/Favoritos", component: Page },
    ]
}
export const menus = ({ history }) => {
    return [
        { index: 2, text: "Favoritos", iconKey: "Star", action: () => { history.push("/Favoritos") } },
    ]
}

