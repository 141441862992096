import React from 'react'

import { OkTypedComponent, LabelInputAuto } from 'OkApp'


export default function Editor(props) {


    return (<>
        <OkTypedComponent typeComponente='InputComp' field='field' label='Nome Salvo' helperText='Colocar "." no final caso seja um objeto' />
        <LabelInputAuto {...props} />
        <OkTypedComponent typeComponente='SeletorOkForm' field='auxOkForm' label='Formulário' />
        <OkTypedComponent typeComponente='CheckboxComp' field='naoValidaRequeridos' label='Não Validar Requeridos' />
        <OkTypedComponent typeComponente='CheckboxComp' field='onlyView' label='Apenas mostrar (nao vai usar variaveis da tela)(BETA)' />

    </>
    )
}
