import React, { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom';

import _ from 'lodash'
import produce from "immer"

import { useListOkAppAdmin } from 'OkApp/UseUtils';
import ViewFiltroProjetos from 'routes/private/components/ViewFiltroProjetos';

import { Box, Button, Typography } from '@material-ui/core';

export default function ListProjetosPerfil({ uidPerfil }) {
    const filter = useMemo(() => { return { 'metaOkApp.permission.owner': uidPerfil } }, [uidPerfil])
    const projetos = useListOkAppAdmin(filter);
    const history = useHistory()
    const [projetosFilter, setProjetosFilter] = useState(null)

    const novoProject = () => {
        history.push("/Projeto")
    }

    const updateItem = (item) => {
        const nextState = produce(projetosFilter, draftState => {
            for (let key in draftState) {
                if (draftState[key].uid === item.uid) {
                    draftState[key] = item;
                }
            }
        })
        setProjetosFilter(nextState)
    }

    useEffect(() => {
        if (projetos) {

            setProjetosFilter(projetos.filter((p) => {
                if (_.get(p, "metaOkApp.permission.owner") === uidPerfil) {
                    return true
                }

                return false;
            }))



        } else {
            setProjetosFilter(null)
        }

    }, [projetos, uidPerfil])

    if (projetosFilter && projetosFilter.length === 0) {
        return (
            <Box style={{
                width: "100%",
                display: 'flex',
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "25px",
                gap: 25
            }}
            >
                <Typography>Você ainda não possui nenhum projeto</Typography>
                <Button variant='contained' color='primary' style={{ maxWidth: "150px" }} onClick={novoProject}>Criar projeto</Button>
            </Box>
        )
    }

    return (
        <Box>
            <Typography variant='h6' align='center'>Projetos do Perfil</Typography>
            {projetosFilter && <ViewFiltroProjetos projetos={projetosFilter} updateItem={updateItem} admin={true} />}
        </Box>
    )
}
