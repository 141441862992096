import React from 'react'
import OkCompAdapter from 'OkApp/components/OkCompAdapter'
import EditorComp from './components/EditorComp'
import CompView from './components/CompView'
const typeComponente = 'DialogFormCompEditor'

const AdapterOkComp = (props) => {
    let propsComp = { developer: props.clearDevelorperNext ? false : props.developer }
    return <OkCompAdapter {...props} propsComp={propsComp} component={CompView} />
}

const modelo = {
    typeComponente,
    field: '',
    label: 'Dialog OkForm',
    title: "Título do Dialog",
}


const DialogFormCompEditor = {
    typeComponente,
    configView: {
        grupo: 'Avançado',
        nome: 'Dialog OkForm',
        descricao: 'Dialog OkForm',
        helpUrl: 'https://www.google.com.br/',
        modelo
    },
    Component: AdapterOkComp,
    CompEditor: EditorComp
}
export default DialogFormCompEditor
