import { Card, Typography, Button, Checkbox, IconButton } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import produce from "immer";
import { RenderOkForm } from "OkApp";
import { permiteVerOkComp } from "OkApp/modules/PermissionMeta/functions";
import { usePerfilUser } from "OkApp/UseUtils";


export default function CompView(props) {
    const { label, input: { value, onChange }, okfunctions, propscomp } = props;
    const { realProps } = propscomp;
    const { okComp, ...rest } = realProps;
    const { developer } = realProps;//separado para o rest ficar certo
    const perfilUser = usePerfilUser();

    let lista = value || [];
    let progresso = '0/' + lista.length;
    let qtFeito = 0;
    lista.forEach(l => {
        if (l.feito) {
            qtFeito++;
        }
    });

    progresso = qtFeito + '/' + lista.length;

    let filtrarComps = okComp.children.filter(comp => {
        let permiteVerVar = permiteVerOkComp(okfunctions.okform, comp, perfilUser)
        return permiteVerVar;
    })


    const addItem = () => {

        onChange((v) => {
            let newArray = v ? v.slice() : [];
            newArray.push({ feito: false });
            return newArray;
        })
    }

    const removeItem = (item, index) => {
        const { input: { value, onChange } } = props;

        const nextState = produce((value || []), draftState => {
            draftState.splice(index, 1);
        })

        onChange(nextState);
    }

    const renderItemCheckList = (item, index) => {

        return (
            <div key={index} style={{ display: 'flex', borderTop: "1px solid #ccc", paddingTop: 5, marginTop: 10, flexWrap: 'wrap' }}>
                <Checkbox
                    checked={item.feito}
                    onChange={evt => {
                        marcaItemCheckList(item, index, !item.feito);
                    }}
                />

                {filtrarComps.map((comp, idx) => {
                    // let fieldUsar = okComp.field + '.' + index + '.' + comp.field
                    let compRender = {
                        ...comp,
                        // field: fieldUsar
                    }

                    let prefield = (okComp.prefield ? okComp.prefield : "") + okComp.field + "." + index + "."

                    return <RenderOkForm {...rest} key={idx} okComp={compRender} prefield={prefield} />
                })}

                <IconButton edge="end" aria-label="delete"
                    onClick={() => removeItem(item, index)}
                >
                    <DeleteIcon />
                </IconButton>
            </div>
        )
    }

    const marcaItemCheckList = (item, index, bool) => {
        const { input: { value, onChange } } = props;

        const nextState = produce((value || []), draftState => {
            let itemLista = draftState[index];
            itemLista.feito = bool;
        })

        onChange(nextState);
    }
    let disabled = developer
    return (
        <Card style={{ padding: 5, marginBottom: 15 }}>
            <div style={{ display: 'flex' }}>
                <Typography variant="h6" style={{ flex: 2 }}>{label || "CheckLists"}</Typography>

                {progresso && <Typography variant="h6" noWrap style={{ width: 200 }} >Progresso: {progresso} </Typography>}
                <Button variant="contained" color="primary" disabled={disabled} onClick={addItem}><AddIcon /></Button>
            </div>
            {developer &&
                <fieldset>
                    <legend>Desenvolvimento de Item</legend>
                    <div style={{ display: 'flex', position: "relative" }}>

                        <Checkbox
                            disabled={true}
                        />

                        {filtrarComps.map((comp, idx) => {
                            // let fieldUsar = okComp.field + '.' + index + '.' + comp.field
                            let compRender = { ...comp }
                            return <RenderOkForm {...rest} key={idx} okComp={compRender} okCompsIrmaos={filtrarComps} />
                        })}


                        <IconButton
                            disabled={true}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </div>
                </fieldset>
            }
            {lista.map((item, index) => renderItemCheckList(item, index))}
        </Card>
    )
}