import React from 'react';
import Pagination from '@material-ui/lab/Pagination';


export default function PaginationControlled(props) {

    const { perPage, list, children } = props
    const [page, setPage] = React.useState(1);
    const handleChange = (event, value) => {
        setPage(value);
    };
 
    let indexStart = (page - 1) * perPage  
    let listPage = list.slice(indexStart, indexStart + perPage)
    let pages = Math.ceil(list.length / perPage * Math.pow(10, 0)) / Math.pow(10, 0)
    return (
        <>

            {children({listPage,indexStart})}
            <Pagination style={{ margin: '0 auto' }} count={pages} page={page} onChange={handleChange} />
        </>
    );
}