
import React from 'react'
import { TextField, Tooltip } from "@material-ui/core";
import InputMask from 'react-input-mask'
import CopyToClipboard from 'react-copy-to-clipboard';
import { FileCopy } from '@material-ui/icons';
import useNotification from 'notification';

const Icons = ({ value }) => {
    const { showSuccess } = useNotification()
    // const classes = useStyles();
    // const theme = useTheme();

    return (
        <>
            <CopyToClipboard
                text={value}
                onCopy={() => showSuccess("Texto copiado")}

            >
                <Tooltip
                    title={"Copiar"}
                    placement="top"
                >
                    <FileCopy style={{
                        margin: 5,
                        cursor: 'pointer'
                    }} />
                </Tooltip>
            </CopyToClipboard>
        </>
    );
};
export default function Input({ input, label, meta, type, required, maskField, maskChar, children, casasDecimais, parseInputs, styleCssOver, showcopybuttom, dataInicialHoje, ...custom }) {
    let touched = meta ? meta.touched : null
    let error = meta ? meta.error : null
    let exibeMascara = maskField ? true : false;
    if (type && type !== 'text') {
        exibeMascara = false;
    }



    if (exibeMascara) {
        return <InputMask mask={maskField} maskChar=" " value={input.value} onChange={input.onChange} >
            {(inputProps) => <TextField {...inputProps} type="text"
                label={label}
                error={(touched && error) ? true : false}
                helperText={(touched && error) ? error : ""}
                fullWidth
                InputLabelProps={{
                    shrink: true,
                }}
                style={{ marginBottom: 15 }}
                {...custom}

            />}
        </InputMask>
    }


    let ajustValue = input.value;

    if (['date', 'datetime-local', "time"].includes(type)) {


        if (!input.value) {
            if (dataInicialHoje) {
                let hoje = new Date();
                const offset = hoje.getTimezoneOffset()
                let hojeSemTimezone = new Date(hoje.getTime() - (offset * 60 * 1000))
                console.log('hojeSemTimezone', hojeSemTimezone)

                if (type === 'date') {
                    input.onChange(hojeSemTimezone.toISOString().split('T')[0])
                }
                if (type === 'datetime-local') {                    
                    let dh = hojeSemTimezone.toISOString().split(":")
                    dh = dh[0] + ":" + dh[1]                 
                    input.onChange(dh)
                }

                if (type === 'time') {                 
                    let h = hoje.getHours();                    
                    let m = hoje.getMinutes();

                    console.log('hh', h + ":" + m)
                    input.onChange(h + ":" + m)
                }
            }
        } else {
            try {
                if (input.value) {
                    //converte data do firestore em string ignorando o time zone
                    let dateV = input.value.toDate();
                    ajustValue = new Date(dateV.getTime() - (dateV.getTimezoneOffset() * 60000)).toISOString();
                    ajustValue = ajustValue.split('.')[0]

                    if (type === 'date') {
                        ajustValue = ajustValue.split('T')[0]
                    }
                }
            } catch (error) {

            }
            try {
                if (input.value.toISOString) {
                    ajustValue = input.value.toISOString();
                    ajustValue = ajustValue.split('.')[0]

                    if (type === 'date') {
                        ajustValue = ajustValue.split('T')[0]
                    }
                }
            } catch (error) {

            }
        }
    }
    let stInput = {}
    if (type === 'number') {
        stInput = { textAlign: 'right' }
    }

    return <TextField
        label={label}
        type={type}
        error={(touched && error) ? true : false}
        helperText={(touched && error) ? error : ""}
        fullWidth
        InputLabelProps={{
            shrink: true,
        }}
        inputProps={{ style: stInput }}
        style={{ marginBottom: 15 }}
        InputProps={{
            endAdornment: showcopybuttom ? <Icons value={ajustValue} /> : "",
        }}
        onBlur={() => {
            if (type === 'number' && casasDecimais) {
                try {
                    let newV = parseFloat(input.value).toFixed(casasDecimais);
                    if (input.value !== newV) {
                        if (parseInputs) {
                            input.onChange(parseInputs(newV))
                        } else {
                            input.onChange(newV)
                        }

                    }
                } catch (error) {

                }
            }


        }}
        {...custom}
        onChange={parseInputs ? (event) => {
            input.onChange(parseInputs(event.target.value))
        } : input.onChange}
        value={ajustValue}
    >
    </TextField>

}
