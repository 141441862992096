import React from 'react'
import OkCompAdapter from 'OkApp/components/OkCompAdapter'
import CompView from './components/CompView'
import EditorComp from './components/EditorComp'

const typeComponente = 'SpeechText'

const AdapterOkComp = (props) => {
    let propsComp = {}
    return <OkCompAdapter {...props} propsComp={propsComp} component={CompView} />
}

const modelo = {
    typeComponente,
    field: 'anotacao',
    label: 'Anotação',   
    
}



const SpeechText = {
    typeComponente,
    configView: {
        grupo: 'Avançado',
        nome: 'Anotação com fala',
        descricao: 'Esse componente trasforma fala em texto',
        helpUrl: 'https://www.google.com.br/',
        modelo
    },
    Component: AdapterOkComp,
    CompEditor: EditorComp
   
}

export default SpeechText
