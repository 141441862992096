import Layout from './components/layout'
import { createTheme } from '@material-ui/core'

class OkAppTheme {
    constructor() {
        this.Layout = Layout
    }

    getMuiTheme() {
        return createTheme()
    }
}
// eslint-disable-next-line import/no-anonymous-default-export
export default new OkAppTheme()