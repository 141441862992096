import React from 'react'
import {  IconButton, Tooltip } from '@material-ui/core';
import Popover from '@material-ui/core/Popover'
import {
    usePopupState,
    bindTrigger,
    bindPopover,
} from 'material-ui-popup-state/hooks'


export default function PopUpMenu({ children, text, Icon }) {
    const popupState = usePopupState({
        variant: 'popover',
        popupId: 'demoPopover',
    })

    return (
        <div style={{ paddingBottom: 5 }}>
            <Tooltip title={text}>
                <IconButton size="small" style={{ width: "100%" }}  {...bindTrigger(popupState)}>
                    {Icon && <Icon />}
                </IconButton >
            </Tooltip>
            <Popover                {...bindPopover(popupState)}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                {children}
            </Popover>
        </div>
    )
}
