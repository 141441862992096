import { saveOnlyNew } from "dbApi"

export const LogTracker = (key, props, perfil) => {
    let obj = {
        values: props,
        perfil: {},
        key
    }

    if (perfil) {
        obj.perfil.name = perfil.name
        obj.perfil.email = perfil.email
        obj.perfil.uid = perfil.uid
    }

    saveOnlyNew(obj, "OkTracker")
}