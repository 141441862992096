import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { getAllOkFormList } from 'OkApp/OkFunctions';
import { useOkFormListRoutesSnap } from 'OkApp/UseUtils';
import { useOkApp, useUserUid } from 'OkApp/UseUtils';
import { createSnapCollectionSemPermissao, salvarObj } from 'dbApi';
import useNotification from 'notification';

import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';


const CardRel = ({ relatorio, atualRelatorios, colectionFirebase }) => {
    const { showSuccess } = useNotification()
    // console.log('form', form)
    // console.log('atualForm', atualForm)


    const copiaForm = () => {
        salvarObj(relatorio, colectionFirebase,
            (r) => {
                console.log("Salvo Copia de relatório: ", r.msg);
                showSuccess("Salvou copia relatório: " + relatorio.nome)
            },
            (err) => {
                console.log("CRUD - error", err);

            }

        )
    }
    return <div style={{ display: 'flex', padding: 5 }}>
        <div style={{ flex: 1 }}>

            <Typography style={{ flex: 1 }}>{relatorio.nome}{relatorio.version ? " (v." + relatorio.version + ")" : ""}</Typography>
            {atualRelatorios && <div style={{ display: 'flex', gap: 5 }}>
                <Typography variant='caption'>Versão já copiada: {atualRelatorios.version || '-'} </Typography>
                <Typography variant='caption'>Data Copia: {atualRelatorios.propsCopy?.date || '__/__/____'} </Typography>
            </div>}
        </div>
        <Button onClick={copiaForm} variant='contained' color='primary'>Copiar</Button>
    </div>
}

const CardForm = ({ form, atualForm, colectionFirebase }) => {
    const { showSuccess } = useNotification()
    // console.log('form', form)
    // console.log('atualForm', atualForm)


    const copiaForm = () => {
        salvarObj(form, colectionFirebase,
            (r) => {
                console.log("Salvo Copia de Form: ", r.msg);
                showSuccess("Salvou copia form: " + form.name)
            },
            (err) => {
                console.log("CRUD - error", err);

            }

        )
    }
    return <div style={{ display: 'flex', padding: 5 }}>
        <div style={{ flex: 1 }}>

            <Typography style={{ flex: 1 }}>{form.name}{form.version ? " (v." + form.version + ")" : ""}</Typography>
            {atualForm && <div style={{ display: 'flex', gap: 5 }}>
                <Typography variant='caption'>Versão já copiada: {atualForm.version || '-'} </Typography>
                <Typography variant='caption'>Data Copia: {atualForm.propsCopy?.date || '__/__/____'} </Typography>
            </div>}
        </div>
        <Button onClick={copiaForm} variant='contained' color='primary'>Copiar</Button>
    </div>
}

export default function DialogCopiarProjeto({ projeto, handleClose }) {
    const [forms, setForms] = useState(null)
    const [relatorios, setRelatorios] = useState(null)
    const [relatoriosAtual, setRelatoriosAtual] = useState(null)
    const { showSuccess } = useNotification()
    const history = useHistory();

    const userUid = useUserUid();
    const okapp = useOkApp();

    const atualForms = useOkFormListRoutesSnap(okapp)

    let colectionFirebaseForm = 'Project/' + okapp.uid + '/CustomForm';
    let colectionFirebaseRel = 'Project/' + okapp.uid + '/OkReport';

    useEffect(() => {
        if (projeto) {

            getAllOkFormList(projeto).then((docs) => {
                let arrayForm = []

                docs.forEach(docSnapshot => {
                    const form = { ...docSnapshot.data() }
                    let permission = form.metaOkApp.permission;
                    permission.owner = userUid
                    permission.usersRead = {}
                    permission.usersRead = {}
                    delete permission.queryPermission
                    let d = new Date().toISOString().split("T")[0]

                    form.propsCopy = { projetoCopyUid: projeto.uid, user: userUid, date: d }
                    arrayForm.push(form)


                });

                setForms(arrayForm)

            })
            console.log('createSnapCollectionSemPermissao')

            createSnapCollectionSemPermissao('Project/' + projeto.uid + '/OkReport', (rels) => {
                console.log('rrels', rels)
                let arrayRel = []
                rels.forEach(r => {
                    const rel = { ...r }
                    let permission = rel.metaOkApp.permission;
                    permission.owner = userUid
                    permission.usersRead = {}
                    permission.usersRead = {}
                    delete permission.queryPermission
                    let d = new Date().toISOString().split("T")[0]

                    rel.propsCopy = { projetoCopyUid: projeto.uid, user: userUid, date: d }
                    arrayRel.push(rel)


                });
                setRelatorios(arrayRel)
            }, (e) => console.log('erro useRelatorios', e))




        } else {
            setForms(null)
        }



    }, [projeto, userUid])
    useEffect(() => {
        createSnapCollectionSemPermissao('Project/' + okapp.uid + '/OkReport', (rels) => {
            console.log('rrels Atual', rels)

            setRelatoriosAtual(rels)
        }, (e) => console.log('erro useRelatorios', e))


    }, [okapp])

    const copiarTudo = () => {
        let qtf = 0
        let qtr = 0
        const verificaMensagem = () => {

            if (qtf === forms.length && qtr === relatorios.length) {
                console.log('"Projeto copiado com sucesso!"')
                setTimeout(() => {

                    showSuccess("Projeto copiado com sucesso!")

                }, 2000);
                return true;

            }
        }
        forms.forEach(form => {
            salvarObj(form, colectionFirebaseForm,
                (r) => {
                    console.log("Salvo Copia de Form: ", r.msg);
                    showSuccess("Salvou copia form: " + form.name)
                    qtf++;
                    if (verificaMensagem()) {
                        console.log('history', history)
                        history.push('/app/' + okapp.route)

                    }
                },
                (err) => {
                    console.log("CRUD - error", err);

                }

            )
        });
        relatorios.forEach(relatorio => {
            salvarObj(relatorio, colectionFirebaseRel,
                (r) => {
                    console.log("Salvo Copia de relatório: ", r.msg);
                    showSuccess("Salvou copia relatório: " + relatorio.nome)
                    qtr++;
                    if (verificaMensagem()) {
                        console.log('history', history)
                        history.push('/app/' + okapp.route)
                        // 
                    }
                },
                (err) => {
                    console.log("CRUD - error", err);

                }

            )
        });
        showSuccess("Copiando tudo...")
    }

    return (
        <Dialog
            fullWidth
            open={projeto ? true : false}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"Copiar projeto"}</DialogTitle>
            {okapp ?
                <DialogContent>

                    {/* <DialogContentText id="alert-dialog-description">
                    Deseja realmente copiar os formularios do projeto: {projetoCopiar ? projetoCopiar.name : ""}
                </DialogContentText> */}
                    <div>
                        <Typography variant='h6'>Formulários</Typography>
                        {forms && forms.map((f, i) => {
                            return <CardForm form={f} key={i} atualForm={(atualForms || []).find(fa => fa.uid === f.uid)} colectionFirebase={colectionFirebaseForm} />
                        })}
                        <Typography variant='h6'>Relatórios</Typography>
                        {relatorios && relatorios.map((f, i) => {
                            return <CardRel relatorio={f} key={i} atualRelatorios={(relatoriosAtual || []).find(fa => fa.uid === f.uid)} colectionFirebase={colectionFirebaseRel} />
                        })}
                    </div>





                </DialogContent> : <CircularProgress />
            }
            <DialogActions>
                <Button onClick={handleClose} color="primary">Fechar</Button>
                <Button onClick={() => { handleClose(); copiarTudo() }} variant='contained' color="primary" autoFocus>
                    Copiar Tudo
                </Button>
            </DialogActions>
        </Dialog>
    )
}
