import React, { useState } from 'react'
import EditIcon from '@material-ui/icons/Edit';
import './style.css'


import AceEditor from "react-ace";
import "ace-builds/webpack-resolver";
import "ace-builds/src-noconflict/mode-css";
import "ace-builds/src-noconflict/snippets/css";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-min-noconflict/ext-searchbox";
import "ace-builds/src-min-noconflict/ext-language_tools";

import ace from 'ace-builds';
import { IconButton, Tooltip, InputLabel } from '@material-ui/core';
import DialogWindow from './DialogWindow'

var snippetManager = ace.require("ace/snippets").snippetManager;
export default function EditorCss(props) {

    const [showDialog, setShowDialog] = useState(false)
    const {
        input,
        label,
        theme = 'monokai',
        mode = 'css',
        fontSize = 14,
        snippets,
        onlyDialog,
        noDialog,
        button,
        baseFixa,
        notShow,
        labelStyle,
        ...custom
    } = props
    if (snippets) {

        var snippetsM = snippetManager.parseSnippetFile("OkFormClass");
        snippets.forEach(s => snippetsM.push(s))
        snippetManager.register(snippetsM, "css");
    }


    return (<div style={{

        display: 'flex',
        flexDirection: 'column',
        flex: 1
    }}>
        <div className="EditorCssHeader" >
            {!notShow &&
                <>
                    {button ? <Tooltip title={label}>
                        <IconButton style={{ width: "100%" }} size="small" onClick={() => { setShowDialog(true) }}>
                            <EditIcon />
                        </IconButton >
                    </Tooltip> : <>
                        <InputLabel style={labelStyle ? labelStyle : {}}>
                            {label}
                        </InputLabel>
                        {!noDialog &&
                            <EditIcon onClick={() => {
                                console.log("setShowDialog");

                                setShowDialog(true)
                            }} />
                        }
                    </>
                    }
                </>}

        </div>
        <div style={{

            position: 'relative',
            flex: 1
        }}>

            {!onlyDialog &&
                <AceEditor
                    theme={theme}
                    mode={mode}
                    fontSize={fontSize}
                    width={'100%'}
                    height={'100%'}
                    showGutter={true}
                    editorProps={{ $blockScrolling: true }}
                    setOptions={{
                        enableBasicAutocompletion: true,
                        enableLiveAutocompletion: true,
                        enableSnippets: true,
                        showLineNumbers: true,
                        tabSize: 2,
                    }}
                    {...input}
                    {...custom}
                    value={input.value || baseFixa}
                    onBlur={() => { if (input.onBlur) input.onBlur(props.input.value) }}
                />}

        </div>
        <DialogWindow {...props} open={showDialog} handleClose={() => setShowDialog(false)} />
    </div>
    )
}
