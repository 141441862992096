import React, { Suspense, useEffect, useState } from 'react'
import './style.css'
import * as ThemesOkAPP from '../themes'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { CssBaseline } from '@material-ui/core'
import { BrowserRouter as Router } from 'react-router-dom'
import { Switch, useHistory } from 'react-router-dom'
import { routes as routesFunction, menus as menusFunction } from '../routes'
import store from '../reducer'
import { Provider } from 'react-redux';
import firebaseUtil from '../firebaseUtil'
import { CrudProvider } from '../crud/context'
import { OkAppProvider, useOkAppContext, useDinamicMenus } from 'OkApp/context'
import { createTheme } from '@material-ui/core/styles';
import { NotificationProvider } from 'notification'
import { salvarPerfilLogin } from 'dbApi'
import { isAuthenticated, usePerfil } from 'useAuth'
import OKAppLoading from 'components/OKAppLoading'
const sortMenu = (menus) => {
    return menus.sort((a, b) => {
        let ac = a.index || 0;
        let bc = b.index || 0;

        if (ac < bc)
            return -1;
        if (ac > bc)
            return 1;

        ac = a.text;
        bc = b.text;
        if (ac < bc)
            return -1;
        if (ac > bc)
            return 1;

        return 0;
    });
}
const Layout = (props) => {
    let primaryColor = '#575fff'
    let secondaryColor = '#ef9c32'

    const [themeJson, setThemeJson] = useState(createTheme({
        palette: {
            primary: {
                main: primaryColor,
            },
            secondary: {
                main: secondaryColor,
            },
        },
        typography: {
            fontFamily: [
                'Poppins'
            ].join(",")
        }
    }))
    const perfilUser = usePerfil();
    const { okApp } = useOkAppContext();
    const history = useHistory();

    const [{ menus, routes }, setMenusRoutes] = useState({ menus: [], routes: [] })
    let authenticated = isAuthenticated();

    useEffect(() => {
        let r = routesFunction({ history, okApp, perfilUser })
        let m = menusFunction({ history, okApp, perfilUser })
        setMenusRoutes({ menus: m, routes: r })
    }, [history, okApp, perfilUser, authenticated])
    const dinamicMenus = useDinamicMenus();


    let ThemeUsar = null;
    if (!ThemeUsar) {
        ThemeUsar = ThemesOkAPP.OkAppTheme;
    }
    const okAppStyle = okApp ? okApp.style : null;
    useEffect(() => {
        let primaryColorUsar = '#575fff'
        let secondaryColorUsar = '#ef9c32'
        if (okAppStyle) {
            if (okAppStyle.primaryColor) {
                primaryColorUsar = okAppStyle.primaryColor
            }
            if (okAppStyle.secondaryColor) {
                secondaryColorUsar = okAppStyle.secondaryColor
            }
        }


        const themeJson = createTheme({
            palette: {
                primary: {
                    main: primaryColorUsar,
                },
                secondary: {
                    main: secondaryColorUsar,
                },
            },
        });
        setThemeJson(themeJson)

    }, [okAppStyle])


    return <MuiThemeProvider theme={themeJson}>
        <NotificationProvider>
            <ThemeUsar.Layout {...props} menus={sortMenu([...menus, ...dinamicMenus])} routes={routes} />
        </NotificationProvider>
    </MuiThemeProvider>

}



export default function App(props) {
    const [firebaseInitialized, setFirebaseInitialized] = useState(false)
    useEffect(() => {
        firebaseUtil.isInitialized().then(val => {
            setFirebaseInitialized(val)
            if (val) {
                salvarPerfilLogin(val)
            }
        })
    }, [])


    return firebaseInitialized !== false ? (
        <Suspense fallback={<OKAppLoading />}>

            <CrudProvider >

                <Provider store={store}>

                    <CssBaseline />
                    <Router>
                        <Switch>
                            <OkAppProvider>
                                <Layout title="Novo OkApp" />
                            </OkAppProvider>
                        </Switch>
                    </Router>

                </Provider>
            </CrudProvider>
        </Suspense >
    ) : <div className="loader"><OKAppLoading /></div>

}


