import React from 'react'
import OkCompAdapter from 'OkApp/components/OkCompAdapter'
import { OkTypedComponent, LabelInputAuto } from 'OkApp'
import { Typography } from '@material-ui/core'
const typeComponente = 'TextComp'

const AdapterOkComp = (props) => {

    let propsComp = {}

    const emElaboracao = props.OverComp ? true : false
    let styleOver = props.styleCssOver ? props.styleCssOver : null;
    
    return <OkCompAdapter {...props} propsComp={propsComp} component={(props => {
        const { exibeValue, styleCssOver,...propsDom } = props
        // const { input: { value } } = props
        let value = "";
        if (props.input) {
            value = props.input.value
        }

        const toString = (value) => {
            if (!emElaboracao && exibeValue) {
                if (typeof value === "string" || typeof value === "number") {
                    return value
                } else {
                    console.log('ERRO: NÃO eh texto: ', value)
                    return "ERRO: NÃO eh texto"
                }
            } else {
                return props.label;
            }
        }
       
        if (props.styleCssOver) {
            if (styleOver) {
                styleOver = { ...styleOver, ...props.styleCssOver }
            } else {
                styleOver = props.styleCssOver
            }

        }

        return <Typography style={styleOver} {...propsDom} >{toString(value)}</Typography>
    })
    } />
}
const EditorOkComp = (props) => {

    return <>
        <OkTypedComponent typeComponente='InputComp' field='field' label='Nome Salvo' />
        <LabelInputAuto {...props} />
        <OkTypedComponent typeComponente='SwitchComp' field='exibeValue' label='Exibe valor do field' />
    </>
}

const modelo = {
    typeComponente,
    field: 'titulo',
    label: 'Título',
    exibeValue: true,
}


const TextComp = {
    typeComponente,
    configView: {
        grupo: 'Básico',
        nome: 'Text Label',
        descricao: 'Exibe um texto na tela',
        helpUrl: 'https://www.google.com.br/',
        modelo
    },
    Component: AdapterOkComp,
    CompEditor: EditorOkComp
}
export default TextComp