import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, InputAdornment, TextField, Button } from '@material-ui/core';
import React from 'react'
import { ObjectInspector, ObjectLabel } from 'react-inspector';
import SearchIcon from '@material-ui/icons/Search';
import _ from 'lodash';


export default function SeletorDePath({ input, label, meta, data, required, ...custom }) {


    let touched = meta ? meta.touched : null
    let error = meta ? meta.error : null
    const [open, setOpen] = React.useState(false);
    const [lastClick, setLastClick] = React.useState(null);


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleClickItem = (path) => {
        input.onChange(path.replace("$.", ""))
        let time = new Date().getTime()
        let concatLast = time + ":" + path;
        if (lastClick) {
            let split = lastClick.split(":");
            if (split && split[1] === path) {
                if (time - split[0] < 300) {
                    handleClose()
                } else {
                    setLastClick(concatLast)
                }
            } else {
                setLastClick(concatLast)
            }

        } else {
            setLastClick(concatLast)
        }

    };
    const dataAjust = (d) => {
        if (d) {
            if (typeof d === 'string') {
                let maxSize = 40;
                if (d.length > maxSize) {

                    return d.substr(0, maxSize) + "[...]"
                } else {
                    return d
                }
            }
        }

        return d;
    }

    const defaultNodeRenderer = ({ depth, name, data, isNonenumerable, expanded, path }) =>
        depth === 0
            ? <span>{data && !_.isEmpty(data) ? "Selecione:" : "Sem dados no objeto"}</span>
            : <span style={("$." + input.value === path) ? { backgroundColor: "#CCC" } : {}} onClick={() => handleClickItem(path)}><ObjectLabel name={name} data={dataAjust(data)} isNonenumerable={isNonenumerable} /></span>;
    return (
        <div>

            <TextField
                label={label}
                error={(touched && error) ? true : false}
                helperText={(touched && error) ? error : ""}
                fullWidth
                InputLabelProps={{
                    shrink: true,
                }}
                style={{ marginBottom: 15 }}
                {...custom}
                onChange={event => input.onChange(event.target.value)}
                value={input.value}
                InputProps={{
                    endAdornment: (
                        <InputAdornment>
                            <IconButton onClick={handleClickOpen}>
                                <SearchIcon />
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            >
            </TextField>
            <Dialog open={open} onClose={handleClose}
                PaperProps={{ style: { height: 'auto', flex: 1 } }}>
                <DialogTitle>Selecione</DialogTitle>
                <DialogContent>
                    <div style={{ minWidth: 300 }}>
                        <ObjectInspector data={data} nodeRenderer={defaultNodeRenderer} expandLevel={1} sortObjectKeys={true} />
                    </div>
                </DialogContent>
                <DialogActions style={{ marginTop: 15 }}>


                    <Button onClick={handleClose} color="primary" variant='contained'>
                        OK
                    </Button>
                </DialogActions>


            </Dialog>
        </div>
    )
}
