import React from 'react'
import OkFormAbaAdapter from 'OkApp/components/OkFormAbaAdapter';
import { useIsAdmin } from 'useAuth';
import { useAtualValues, useOkApp, useReduxFormProps } from 'OkApp/UseUtils';
import HistoricoRegistro from 'OkApp/components/HistoricoRegistro';


export function OkFormMetaAba(props) {
    const isAdmin = useIsAdmin();
    const okApp = useOkApp();
    const formProps = useReduxFormProps();

    let uidForm = useAtualValues("uid")
    let colectionFirebase = null;
    if (okApp) {
        colectionFirebase = 'Project/' + okApp.uid + '/CustomForm';
    }

    const changeChildren = (c) => {
        console.log('changeChildren', c)
        console.log('formProps',formProps)
        formProps.change('children',c)

    }
    if (isAdmin) {
        return <OkFormAbaAdapter keyTab={"Histórico"} {...props}>
            <HistoricoRegistro colectionFirebase={colectionFirebase} uidItem={uidForm} mapChange={{ changeChildren: changeChildren }} />
        </OkFormAbaAdapter>
    } else {
        return ""
    }
}



export const data = { name: "HistoricoFormControl", }