import React from 'react'

import { getValueToPdfFunc2Comp } from '..'
import { SeletorDePath } from 'components'
import useCriaControlerField from '../../functionsUtil'

import _ from 'lodash';

function ForArrayReportComp({ value, onChange, valueRef, removeLinha }) {
    const criaControler = useCriaControlerField(value, onChange)

    return (
        <div>
            <div style={{ paddingBottom: 10 }}>

                <SeletorDePath {...criaControler("chave")} label='Chave/Valor/Caminho' data={valueRef} />
            </div>



        </div>

    )
}
async function convertToPdf(compConfig, json,meta) {


    let linhas = []
    let lista = _.get(json, compConfig.chave)
    if (!lista || !Array.isArray(lista)) {
        return null;
    }
    // lista.forEach(l => {

    //     compConfig.lista.forEach(async c => {

    //         linhas.push([await getValueToPdfFunc2Comp(c, l)])
    //     });
    // });
    for (let iii = 0; iii < lista.length; iii++) {
        const l = lista[iii];
        for (let iiii = 0; iiii < compConfig.lista.length; iiii++) {
            const c = compConfig.lista[iiii];
            linhas.push([await getValueToPdfFunc2Comp(c, l,meta)])
        }


    }

    let defaultConfig = {      
        margin: [0,-2],
        layout: 'noBorders',
        table: {
            body: linhas,
            widths: ["*"]
        }
    };


    return defaultConfig



}


const PainelReport = {
    typeComponent: "ForArrayReport",
    modelo: {
        nome: "Percorre lista",
        typeComponent: "ForArrayReport",
        orientacaoPainel: 'vertical',
        lista: []
    },
    Component: ForArrayReportComp,
    convertToPdfFunc: convertToPdf,
    ajustJsonRef: (json, conf) => {
        let lista = _.get(json, conf.chave)
        if (lista && lista.length) {
            return lista[0]
        }
        return null
    }

}


export default PainelReport
