import React from 'react'
import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Box, Button, CircularProgress } from '@material-ui/core';
import { useState } from 'react';
import { useEffect } from 'react';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// const stripePromise1 = loadStripe('pk_test_51KLw4SDPdH32xk2QPX57gxKVdXgcKX9dulkDU9rcJVHekSqLaToOW44cSVmmojGxBj9QKJB7UYDTZOeHfK1gVsm8003n14HclL');

const CheckoutForm = ({ setGeralLoadding, return_url, handleCancelSubscription, loading }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [loadingPayment, setLoadingPayment] = useState(false)

    const handleSubmit = async (event) => {
        setLoadingPayment(true)
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();
        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }
        try {
            await stripe.confirmPayment({
                elements,
                confirmParams: {
                    return_url: return_url,
                },
            });
        } catch (error) {
            console.log("error ", error);
        }

        // if (result.error) {
        //     // Show error to your customer (for example, payment details incomplete)
        //     console.log(result.error.message);
        // } else {
        //     // Your customer will be redirected to your `return_url`. For some payment
        //     // methods like iDEAL, your customer will be redirected to an intermediate
        //     // site first to authorize the payment, then redirected to the `return_url`.
        // }
        setLoadingPayment(false)
    };
    return (
        <form onSubmit={handleSubmit}>
            <Box style={{ width: "100%", display: "flex", flexDirection: 'column' }}>
                <Box style={{ padding: 10 }}>
                    <PaymentElement />
                </Box>
                <Box style={{ width: "100%", display: "flex", justifyContent: 'center', gap: 5 }}>
                    <Button
                        style={{ width: 250 }}
                        variant='contained'
                        color='primary'
                        onClick={handleSubmit}
                        disabled={!stripe || loadingPayment}
                    >
                        {loadingPayment ?
                            <CircularProgress size={24} />
                            :
                            "Confirmar Pagamento"
                        }
                    </Button>
                    <Button
                        style={{ width: 100, background: loading ? "#E0E0E0" : "#f44336", color: "white" }}
                        variant="contained"
                        onClick={handleCancelSubscription}
                        disabled={loading}
                    >
                        {loading ?
                            <CircularProgress size={24} />
                            :
                            "Cancelar"
                        }
                    </Button>
                </Box>
            </Box>
        </form>
    );
};
export default function PaymentIntentComp({ stripeAccount, paymentIntent, return_url, handleCancelSubscription, loading }) {
    const [stripePromise, setStripeObject] = useState(null);

    // This function will re-run if the accountId prop changes.
    useEffect(() => {
        const fetchStripeObject = async () => {
            // If there is no accountId, do not run the loadStripe function.
            if (stripeAccount) {
                const res = await loadStripe(
                    'pk_live_51KLw4SDPdH32xk2QvcKUJ3I22H6bhcSU3gobOikdE9GiWgOIKc9wdTCmM1zDS98IMZ8OWsvX9k8TzXwvutL3wshq00Kb5uO7cC',
                    {
                        stripeAccount: stripeAccount
                    }
                );

                // When we have got the Stripe object, pass it into our useState.
                setStripeObject(res);
            } else {
                const res = await loadStripe(
                    'pk_live_51KLw4SDPdH32xk2QvcKUJ3I22H6bhcSU3gobOikdE9GiWgOIKc9wdTCmM1zDS98IMZ8OWsvX9k8TzXwvutL3wshq00Kb5uO7cC'
                );
                // When we have got the Stripe object, pass it into our useState.
                setStripeObject(res);
            }
        };
        fetchStripeObject();
    }, [stripeAccount]);

    const options = {
        // passing the client secret obtained from the server
        clientSecret: paymentIntent.client_secret,
    };
    return (
        <div>
            <Elements stripe={stripePromise} options={options}>
                <CheckoutForm return_url={return_url} handleCancelSubscription={handleCancelSubscription} loading={loading} />
            </Elements>
        </div>
    )
}
