import React from 'react'

import { Tooltip } from '@material-ui/core';
import _ from 'lodash'

import { Combobox, Input, SeletorDePath } from 'components';
import useCriaControlerField from '../../functionsUtil';
import htmlToPdfmake from 'html-to-pdfmake';
import { useAtualValues } from 'OkApp/UseUtils';



function CelulaTextoReportComp({ value, onChange, valueRef, removeCelula }) {
    const criaControler = useCriaControlerField(value, onChange)
    let atualOkReport = useAtualValues()
    let listStyle = [
        { name: "Titulo", value: "cellTitulo" },
        { name: "Texto", value: "cellValor" },
    ]
    if (atualOkReport.styles) {
        listStyle = []
        for (const key in atualOkReport.styles) {
            if (Object.hasOwnProperty.call(atualOkReport.styles, key)) {
                listStyle.push({ name: key, value: key })

            }
        }

    }

    return (
        <div>
            <div style={{ paddingBottom: 10 }}>

                {/* <SeletorDePath {...criaControler("chave").input} label='Chave/Valor/Caminho' data={valueRef} /> */}
                <SeletorDePath {...criaControler("chave")} label='Chave/Valor/Caminho' data={valueRef} />
            </div>
            <div style={{ flex: 1, display: 'flex' }}>
                <Combobox style={{ flex: 1 }} {...criaControler("style")} label="Estilo" itens={listStyle.map(v => v.value)} toStringItem={listStyle.map(v => v.name)} />
                <Combobox style={{ flex: 1, paddingLeft: 5, paddingRight: 5 }} {...criaControler("tipoValor")} label="Tipo Valor" itens={['fixo', 'get']} toStringItem={['Fixo', 'Valor do objeto']} />

            </div>
            <div style={{ flex: 1, display: 'flex' }}>
                <Combobox style={{ flex: 1 }} {...criaControler("formato")} label="Formato"
                    itens={['texto', 'data', 'numero2casas', 'html']}
                    toStringItem={['Texto', 'Data', "Número (2 casas)", "HTML"]} />
                <Combobox style={{ flex: 1, paddingLeft: 5, paddingRight: 5 }} {...criaControler("alignment")} label="Alinhamento" itens={['left', 'center', 'right']} toStringItem={['Esquerda', 'Centro', 'Direita']} />

                <div style={{ flex: 1, }}>
                    <Tooltip title={"* - Maior possivel, auto - Menor possivel, 'numero' exato "}>
                        <Input style={{ whiteSpace: 'nowrap', overflow: 'hidden' }} label='Tamanho (*,auto ou numero)' {...criaControler("width")} />
                    </Tooltip>
                </div>
            </div>
        </div>
    )
}





async function convertToPdf(compConfig, json) {

    const { nome, chave, tipoValor, formato, typeComponent, width, ...custom } = compConfig
    let r = chave
    if (tipoValor === 'get') {
        r = _.get(json, chave)
    }
    if (r !== undefined && r !== null) {
        switch (formato) {
            case 'texto':
                break;
            case 'data':
                try {
                    let d = new Date(r)

                    d = d.toLocaleDateString('pt-BR', { timeZone: 'UTC' });
                    if (d === 'Invalid Date') {
                        console.log('Invalid Date', r)
                        r = "Data invalida"
                    } else {
                        r = d
                    }
                } catch (error) {

                }

                break;
            case 'numero2casas':

                if (!isNaN(r)) {
                    r = parseFloat(r).toFixed(2)
                }

                break;
            case 'html':
                let htmlRel = htmlToPdfmake(r, {
                    "removeExtraBlanks": true,
                    "defaultStyles": {
                        "p": { "margin": [0, 1, 0, 4] }
                    }
                })

                
                let tableHtml = {
                    style: custom.style,
                    layout: 'noBorders',
                    table: {
                        body: htmlRel.map(h => [h])
                    },
                };

                return tableHtml
            default:
                break;
        }
    }

    if (typeof r !== 'string') {

        if (r === undefined) {
            r = ""
        } else if (typeof r == 'number') {

        } else {

            console.log('r', r)
            r = "Não é texto"
        }
    }
    let defaultConfig = { ...custom, text: r };
    return defaultConfig



}

const CelulaTextoReport = {
    typeComponent: "CelulaTextoReport",
    modelo: {
        typeComponent: "CelulaTextoReport",
        nome: "Celula de Texto",
        style: "cellValor",
        tipoValor: "get",
        formato: 'texto',
        width: "*",
        chave: "",
        alignment: "left"
    },
    Component: CelulaTextoReportComp,
    convertToPdfFunc: convertToPdf

}
export default CelulaTextoReport
