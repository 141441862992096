import React from 'react'

import { CrudForm } from 'crud';
import { useOkAppContext } from 'OkApp/context';
import { OkCompModuleAdapter } from 'OkApp/modules';
import Style from 'style-it';
import _ from 'lodash'
import { getDinamicComponent } from 'OkApp';
import { getUserUid } from 'OkApp/OkFunctions';



function RenderOkForm(props) {
    const { okComp, okform, OverComp, developer, starter, newActions, renderForm } = props
    const { okApp } = useOkAppContext();

    if (okform && starter) {
        let colectionFirebase = null;
        if (okApp && okform) {
            colectionFirebase = 'Project/' + okApp.uid + '/ValuesCustomForm/' + okform.uid + "/Values";
        }

        let dadosFormUsar = { ...okform };
        if (developer) {

            dadosFormUsar.name = "Custon form em desenvolvimento"
            dadosFormUsar.route = "Custonormemdesenvolvimento"

        }
        if (!dadosFormUsar.children)
            dadosFormUsar.children = []

        let overCss = _.get(okform, 'metaOkApp.style.overCss');
        let typeCrud = _.get(dadosFormUsar, "meta.actions.typeCrud")
        if (typeCrud) {
            if (typeCrud !== 'itens-multipos') {
                let uidDev = null;
                if (typeCrud === 'unico-user') {
                    let userUid = getUserUid();
                    uidDev = userUid;
                }
                if (typeCrud === 'unico-app') {
                    uidDev = "UNICO"
                }
                let newIniv = dadosFormUsar.initialValues || {};
                newIniv.uid = uidDev
                dadosFormUsar.initialValues = newIniv
            }
        }
        // console.log('dadosFormUsar', dadosFormUsar)
        return (
            <CrudForm
                title={dadosFormUsar.name}
                form={"CustomForm-Route-" + dadosFormUsar.route}
                colectionFirebase={developer ? null : (dadosFormUsar.colectionFirebase || colectionFirebase)}
                developer={developer}
                noModules={developer}
                salvar novo
                newActions={newActions}
                okform={okform}
                initialValues={dadosFormUsar.initialValues}
            >
                <Style>
                    {overCss ? overCss : ".nada{}"}
                    <div className='CustomFormContainer'>
                        {dadosFormUsar.children.map((c, i) => <RenderOkForm key={"Comp" + c.uid} {...props} okComp={c} okCompsIrmaos={dadosFormUsar.children} starter={false} />)}
                    </div>
                </Style>
            </CrudForm>
        )
    }
    if (okform && renderForm) {
        let overCss = _.get(okform, 'metaOkApp.style.overCss');
        return (<>
            <Style>
                {overCss ? overCss : ".nada{}"}
                <div className='CustomFormContainer'>
                    {okform.children.map((c, i) => <RenderOkForm key={"Comp" + c.uid} {...props} okComp={c} okCompsIrmaos={okform.children} renderForm={false} />)}
                </div>
            </Style>
        </>
        )
    }


    if (okComp.constructor === Array) {
        return (<>
            {okComp.map((c, i) => <RenderOkForm key={"Comp" + (c.uid || c.field)} {...props} okComp={c} okCompsIrmaos={okComp} starter={false} />)}
        </>)
    }
    const { typeComponente } = okComp;
    let CustomCompoenteUsar = getDinamicComponent(typeComponente);
    // console.log('RenderOkForm', typeComponente, okComp.field)
    if (CustomCompoenteUsar) {
        if (OverComp) {
            return <OkCompModuleAdapter {...props} okComp={okComp} developer Component={CustomCompoenteUsar.Component} />
            // return <OverComp key={okComp.uid} {...props}> <CustomCompoenteUsar.Component {...props} okComp={okComp} developer /></OverComp>
        } else {
            return <OkCompModuleAdapter {...props} okComp={okComp} Component={CustomCompoenteUsar.Component} />
        }
    }
    // console.log('CustomCompoenteUsar', CustomCompoenteUsar)

    return (
        <div >
            {!okComp && <div>Formulario sem componetes</div>}
            {(!CustomCompoenteUsar && OverComp) && <OverComp  {...props}><div style={{ height: 55, backgroundColor: "#f00" }} >Componente desconhecido:{okComp ? okComp.typeComponente : "sem tipo"}</div></OverComp>}
        </div>
    )
}



export default React.memo(RenderOkForm)