import React from 'react'
import OkCompAdapter from 'OkApp/components/OkCompAdapter'
import EditorComp from './components/EditorComp'
import FiltroPainel from './components/FiltroPainel'
import { createDefaultFuncMapStr2 } from 'OkApp/CodeProcessor'
const typeComponente = 'FiltroPainelComp'

const AdapterOkComp = (props) => {

    let propsComp = { developer: props.developer }

    return <OkCompAdapter {...props} styleCssOver={{ flex: 0 }} propsComp={propsComp} component={FiltroPainel} />
}


const modelo = {
    typeComponente,
    field: 'lista',
    label: 'Buscador',
    type:"getAll",
    msgLimite: 'Filtre a consulta. Atingiu o limite de registros na consulta: ',
    textBotao: 'Pesquisar',
    collectionQuery: createDefaultFuncMapStr2('collectionQuerry', `function collectionQuerry(c,filtros) {
        //https://cloud.google.com/firestore/docs/query-data/queries#in_and_array-contains-any ;)        
        //if(filtros.CDMATRIZ) c=c.where('CDMATRIZ', '==', parseInt(filtros.CDMATRIZ));        
        return c;
    }`,
        'ajustFilterList', `function ajustFilterList(list) {
        return list
    }
    `
    ,),
} 


const FiltroPainelComp = {
    typeComponente,
    configView: {
        grupo: 'Consulta',
        nome: 'Buscador',
        descricao: 'FiltroPainelComp',
        helpUrl: 'https://okapp.com.br/app/okappdocs/buscador',
        modelo
    },
    Component: AdapterOkComp,
    CompEditor: EditorComp,
    beforeSave: (obj, okComp) => {
        if (!okComp.salvarDados) {
            delete obj[okComp.field]
        }
        return obj;
    }
}
export default FiltroPainelComp