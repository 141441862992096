import React from 'react'
import { Typography, Card, Button } from '@material-ui/core'
import BotaoEmitir from '../BotaoEmitir'
import { useHistory } from 'react-router-dom';

export default function ItemRelatorio({ okApp, relatorio: r, jsonValue, auxAction, metaPre, fechaDialog, permiteElaborar }) {
    const history = useHistory()
    const elaborarNovo = () => {
        if (fechaDialog) {
            fechaDialog()
        }

        history.push("/app/" + okApp.route + "/OkReport?uid=" + r.uid, { jsonValue: jsonValue, metaPre })

    };
    return (
        <Card style={{ display: 'flex', padding: "5px 10px", marginBottom: 10 }}>
            <div style={{ flex: 1 }}>
                <Typography variant="subtitle2">{r.nome}</Typography>
                <Typography variant="caption">{r.descricao}</Typography>
            </div>
            <div style={{ display: 'flex', gap: 5 }}>
                {permiteElaborar && <Button variant='contained' onClick={elaborarNovo} color="secondary">
                    Editar
                </Button>}
                <BotaoEmitir okreport={r} jsonValue={jsonValue} auxAction={auxAction} metaPre={metaPre} />
            </div>
        </Card>
    )
}
