import React from 'react'
import OkCompAdapter from 'OkApp/components/OkCompAdapter'
import { Combobox } from 'components'
import { OkTypedComponent, LabelInputAuto } from 'OkApp'
import { useAtualValues } from 'OkApp/UseUtils'
const typeComponente = 'ComboBoxComp'

const AdapterOkComp = (props) => {
    const { okComp } = props;
    let newPropsComp = props.propsComp || {}

    return <OkCompAdapter {...props} propsComp={{ ...newPropsComp, itens: okComp.options }} component={Combobox} />
}
const EditorOkComp = (props) => {
    const toStringOptions = useAtualValues("tostringoptions")
    return <>
        <OkTypedComponent typeComponente='InputComp' field='field' label='Nome Salvo' />
        <LabelInputAuto {...props} />
        <div style={{ display: 'flex' }}>
            <OkTypedComponent typeComponente='SwitchComp' field='remover' label='Permite Remover' />
            <OkTypedComponent typeComponente='SwitchComp' field='tostringoptions' label='Ativar texto de exibição' />
        </div>
        <div style={{ display: 'flex' }}>

            <OkTypedComponent typeComponente='ListaItens' field='options' label='Opções' adicionavel={true} removivel={true} permitemover={true} />
            {toStringOptions &&
                <OkTypedComponent typeComponente='ListaItens' field='toStringItem' label='Texto de exibição para opções' adicionavel={true} removivel={true} permitemover={true} />}
        </div>

    </>
}

const modelo = {
    typeComponente,
    field: 'corFavorita',
    label: 'Cor Favorita',
    remover: false,
    options: ['Verde', 'Amarelo', 'Azul'],
    meta: { pesquisa: { filterconf: { type: 'okcomp', comparatype: "equals" } } }

}

const ComboBoxComp = {
    typeComponente,
    configView: {
        grupo: 'Básico',
        nome: 'Caixa de Seleção',
        descricao: 'Caixa de Seleção de valores pré definidos',
        helpUrl: 'https://okapp.com.br/app/okappdocs/selecao',
        modelo
    },
    Component: AdapterOkComp,
    CompEditor: EditorOkComp,
    getGptField: (comp) => comp.field + ":valores validos(" + comp.options + ")"
}

export default ComboBoxComp;