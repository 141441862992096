export function fieldset(titulo, content) {
    let conteudo = null;
    if (typeof content === 'string') {
        conteudo = [[{ text: content }]];
    } else {

        if (Array.isArray(content)) {
            conteudo = []
            content.forEach(c => conteudo.push([c]));
        } else {
            conteudo = [[content]]
        }
    }
    return {
        style: 'table',
        margin: [0, 2, 0, 2],
        table: {
            headerRows: 2,
            widths: [3, 250, '*'],
            heights: [5, 5, 'auto'],
            body: [
                [
                    {
                        text: '',
                        border: [false, false, false, false],
                    },
                    {
                        rowSpan: 2,
                        border: [true, true, true, true],
                        text: titulo,
                        style: 'fieldsetTitulo',
                    },
                    {

                        text: '',
                        border: [false, false, false, false],
                    }
                ],
                [
                    {
                        text: '',
                        border: [true, true, false, false],
                    },
                    {
                        text: '',
                        border: [false, false, false, false],
                    },
                    {
                        text: '',
                        border: [false, true, true, false],
                    }
                ],
                [
                    {
                        colSpan: 3,
                        border: [true, false, true, true],
                        table: {
                            widths: ['*'],
                            body: conteudo
                        },
                        layout: 'noBorders'

                    }
                ]
                ,
            ]
        }
    }
}

export function painel(conteudo, config, configTable) {
    let rows = [];
    let defaultConfig = {
        style: 'table',
        margin: [0, 0, 0, 1],
        layout: 'noBorders'
    };
    if (Array.isArray(conteudo)) {
        if (Array.isArray(conteudo[0])) {
            rows = conteudo;
        } else {
            rows.push(conteudo);
        }
    } else {
        defaultConfig = { ...defaultConfig, widths: ['*'] }
        rows.push([conteudo])
    }


    if (config) {
        defaultConfig = { ...defaultConfig, ...config }
    }

    return {
        // margin: defaultConfig.margin,
        // style: defaultConfig.style,
        table: {
            body: rows,
            widths: defaultConfig.widths,
            ...configTable
        },
        // layout: defaultConfig.layout,
        ...defaultConfig
    }
}
