import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useAuth from '../../../../../useAuth'

import FavoritosProprio from '../FavoritosProprio';
import UltimosProjetos from '../UltimosProjetos';
import PainelPublicoHome from 'routes/private/components/PainelPublicoHome';
import { useCrudContext } from 'crud';
import { useListMeusOkApp } from 'OkApp/UseUtils';

import { Box, Button, Typography } from '@material-ui/core';

const route = "/Projeto";

export default function PageHome(props) {
    const { t } = useTranslation();
    const { userName, authenticatedOnly } = useAuth();
    const { addAction, removeActions } = useCrudContext();

    const { setTitle } = useCrudContext()

    useEffect(() => {
        setTitle("OkApp")
        return () => setTitle("")

    }, [setTitle])

    const history = useHistory();

    authenticatedOnly();

    const meusApps = useListMeusOkApp()

    const novoProject = () => {
        history.push("/Projeto")
    }

    useEffect(() => {
        addAction({ uid: 'crud.actions.new_project', text: t('crud.actions.new_project'), icon: null, action: () => history.push(route), props: {}, })
        // addAction({ uid: 'crud.actions.profile', text: t('crud.actions.profile'), icon: null, props: {}, })
        return () => { removeActions(['crud.actions.new_project']) }

    }, [addAction, history, removeActions, t])

    return (
        <div style={{ padding: "0px 15px 15px 15px", width: "100%" }}>

            <Typography style={{ textAlign: "center", fontSize: 18, padding: "20px", fontFamily: 'poppins' }}>
                👋 Bem vindo, {userName()}
            </Typography>

            <span style={{ background: "lightgray", height: "1px", width: "100%", display: "block", marginBottom: "10px" }} />

            {meusApps && meusApps.length === 0 ?
                <Box display="flex" flexDirection="column" alignItems="center" style={{ gap: 25, margin: "25px 0 25px 0" }}>
                    <Typography align='center'>Você não possui nenhum projeto criado</Typography>
                    <Button variant='contained' color='primary' style={{ maxWidth: "150px" }} onClick={novoProject}>Criar projeto</Button>
                </Box>
                :
                <></>
            }

            <FavoritosProprio />

            <UltimosProjetos />

            <PainelPublicoHome label={"Projetos públicos"} />

        </div >
    )
}
