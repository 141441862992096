import React from 'react'
import OkCompAdapter from 'OkApp/components/OkCompAdapter'
import { OkTypedComponent, LabelInputAuto } from 'OkApp'

import { Button } from '@material-ui/core'
import { salvarObjDatabase } from 'dbApi'
import { useOkAppContext } from 'OkApp/context'
import { useOkApp } from 'OkApp/UseUtils'
import useNotification from 'notification'

const typeComponente = 'ControleOutroOkObjComp'


const ControleOutroOkObj = (props) => {
    const { database } = useOkAppContext();
    const okApp = useOkApp();
    const {  label, input: { value }, type, auxOkForm } = props
    const uidOkForm = auxOkForm ? auxOkForm.uid : null;
    const { showError, showSuccess } = useNotification();


    let colectionFirebase = null;
    if (okApp && uidOkForm) {
        colectionFirebase = 'Project/' + okApp.uid + '/ValuesCustomForm/' + uidOkForm + "/Values";
    }



    const salvarObj = () => {
        if (type === 'multiplo') {
            let listaSalvar = value.map(v => { return { obj: v, strCollection: colectionFirebase } })

            salvarObjDatabase(database, value[0], colectionFirebase,
                (r) => {
                    console.log("CRUD - Sucesso Salvou ", r.msg);
                    showSuccess(r.msg)
                },
                (err) => {
                    console.log("CRUD - error", err);
                    showError(err.message)
                },
                listaSalvar
            )

        } else {
            salvarObjDatabase(database, value, colectionFirebase,
                (r) => {
                    console.log("CRUD - Sucesso Salvou ", r.msg);
                    showSuccess(r.msg)

                },
                (err) => {
                    console.log("CRUD - error", err);
                    showError(err.message)
                })
        }
    }

    return <div style={{ paddingBottom: 20, textAling: 'center' }}>
        {/* {developer &&
            <div style={{ height: 40, width: '100%', backgroundColor: "#f0f", display: 'flex', justifyContent: "center", alignItems: "center" }}>
                Controler OkObj: {label}
            </div>
        } */}
        <Button variant='contained' color='primary' onClick={salvarObj}>{label}</Button>
    </div>
}


const AdapterOkComp = (props) => {
    let propsComp = { developer: props.developer }
    return <OkCompAdapter {...props} propsComp={propsComp} component={ControleOutroOkObj} />
}



const EditorOkComp = (props) => {

    return <div
    // style={{ display: 'flex', flexDirection: 'column', height: "100%" }}
    >

        <OkTypedComponent typeComponente='InputComp' field='field' label='Campo Observer' />
        <LabelInputAuto {...props} />
        <OkTypedComponent typeComponente='SeletorOkForm' field='auxOkForm' label='Formulário' />
        <OkTypedComponent typeComponente='ComboBoxComp' field='type' label='Tipo' options={['multiplo', 'unico']} disabled toStringItem={(v) => {
            switch (v) {
                case 'multiplo': return "Multiplo"
                case 'unico': return "unico"
                default: return "Multiplo";
            }
        }} />



    </div>
}

const modelo = {
    typeComponente,
    field: 'objs',
    label: 'Salvar Objetos',
    type: 'multiplo',
}


const ControleOutroOkObjComp = {
    typeComponente,
    configView: {
        grupo: 'Avançado',
        nome: 'ControleOutroOkObjComp',
        descricao: 'ControleOutroOkObjComp',
        helpUrl: 'https://www.google.com.br/',
        notView: true,
        modelo
    },
    Component: AdapterOkComp,
    CompEditor: EditorOkComp
}
export default ControleOutroOkObjComp
