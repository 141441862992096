import './style.css'

import React, { useEffect, useMemo, useState } from 'react'

import { useTranslation } from 'react-i18next';

import { useHistory } from 'react-router-dom';

import useAuth, { useIsAdmin, usePerfil } from 'useAuth';
import { useListOkAppAdmin } from 'OkApp/UseUtils';

import { CrudForm } from 'crud'
import { OkTypedComponent } from 'OkApp';
import CrudControlForm from './CrudControlForm';
import ListProjetosPerfil from 'routes/admin/EditPerfilprojeto/ListProjetosPerfil';
import LinearProgressWithLabel from 'components/LinearProgressBar';

import { Tabs, Tab, Typography, Button, Box, Card, CardContent, CardActions } from '@material-ui/core';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import StorageOutlinedIcon from '@material-ui/icons/StorageOutlined';
import AlbumOutlinedIcon from '@material-ui/icons/AlbumOutlined';
import DoneAllOutlinedIcon from '@material-ui/icons/DoneAllOutlined';

const colectionFirebase = "Users";

function ProjectForm(props) {
    const { t } = useTranslation();
    const { authenticatedOnly } = useAuth();

    const isAdmin = useIsAdmin()
    const history = useHistory();
    const perfil = usePerfil()

    const filter = useMemo(() => { return { 'metaOkApp.permission.owner': perfil?.uid } }, [perfil?.uid])
    const projetos = useListOkAppAdmin(filter);

    const [projectProgress, setProjectProgress] = useState(projetos?.length);
    // eslint-disable-next-line no-unused-vars
    const [storageProgress, setStorageProgress] = useState(125);
    // eslint-disable-next-line no-unused-vars
    const [readWriteProgress, setReadWriteProgress] = useState(12152);

    const [tab, setTab] = useState(0)

    const handleChange = (event, newValue) => {
        setTab(newValue);
    };

    useEffect(() => {
        authenticatedOnly()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setProjectProgress(projetos?.length)
    }, [projetos]);

    return (
        <Box style={{ padding: 15 }}>
            <CrudForm
                title={"Perfil"}
                form={"PerfilForm"}
                initialValues={perfil}
                colectionFirebase={colectionFirebase}
                salvar novo
                noModules
                InsertComp={CrudControlForm}
            >
                <Tabs
                    value={tab}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    style={{ width: 'fit-content', display: 'flex', margin: '0 auto' }}
                >
                    <Tab label="Dados" />
                    <Tab label="Meu Plano" />
                    <Tab label="Meus Projetos" />
                </Tabs>

                {tab === 0 &&
                    <Box style={{ marginTop: 10 }}>
                        <Card style={{ margin: '0 auto', maxWidth: 500 }}>
                            <CardContent>
                                <Box borderRadius={5}>
                                    <OkTypedComponent variant='rounded' typeComponente='ImageSelComp' field='avatar' label='Avatar' textoBotao="Selecione uma imagem" />
                                </Box>
                                <Box maxWidth={500} style={{ margin: '0 auto' }}>
                                    <OkTypedComponent typeComponente='InputComp' field='email' label={"Email"} disabled={true} />
                                    <OkTypedComponent typeComponente='InputComp' field='name' label={t('project.name')} />
                                    <Box display="flex">
                                        <OkTypedComponent typeComponente='InputComp' field='cidade' label="Cidade" />
                                        <OkTypedComponent typeComponente='InputComp' field='estado' label="Estado" placeholder="Ex: São Paulo ou SP" />
                                    </Box>
                                    <OkTypedComponent typeComponente='InputComp' field='phone' label="Telefone/celular" maskField="99 99999 9999" />
                                    <OkTypedComponent typeComponente='InputComp' field='ocupation' label="Ocupação" />
                                    {isAdmin &&
                                        <OkTypedComponent typeComponente='InputComp' field='defaultRoute' label={"Rota Padrão"} />
                                    }
                                </Box>
                            </CardContent>
                        </Card>
                    </Box>
                }

                {tab === 1 &&
                    <Box style={{ marginTop: 10 }}>
                        <Box display='flex' alignItems='center' justifyContent='center'>
                            {perfil?.plano?.origem === "stripe" && perfil?.plano?.status === "active" ?
                                <Box width={500}>
                                    <Card sx={{ minWidth: 275 }}>
                                        <CardContent style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>
                                            <Box display='flex' justifyContent='space-between'>
                                                <Typography style={{ fontSize: 20, fontWeight: 'bold' }} color="primary" gutterBottom>
                                                    {perfil?.plano?.nomePlano}
                                                </Typography>
                                                <Typography style={{ fontSize: 14, fontStyle: 'italic' }}>
                                                    Valido até: {perfil.plano?.validade ? perfil.plano?.validade.split("-").reverse().join('/') : "-"}
                                                </Typography>
                                            </Box>
                                            <Card style={{ width: '100%', padding: 10 }}>
                                                <Typography style={{ display: 'flex', gap: 5 }} color="textPrimary">
                                                    <AssignmentOutlinedIcon color='primary' /> Número de projetos permitidos: {perfil.numProject}
                                                </Typography>
                                                <Typography style={{ display: 'flex', gap: 5 }} color="textPrimary">
                                                    <DoneAllOutlinedIcon color='primary' /> Número de projetos criados: {projetos?.length}
                                                </Typography>
                                                <LinearProgressWithLabel value={projectProgress} maxvalue={perfil && perfil.numProject ? perfil.numProject : 3} />
                                            </Card>
                                            <Card style={{ width: '100%', padding: 10 }}>
                                                <Typography style={{ display: 'flex', gap: 5 }} color="textPrimary">
                                                    <StorageOutlinedIcon color='primary' /> Armazenamento disponível: {perfil.plano?.limiteArmazenamento || "500mb"}
                                                </Typography>
                                                <Typography style={{ display: 'flex', gap: 5 }} color="textPrimary">
                                                    <DoneAllOutlinedIcon color='primary' /> Armazenamento utilizado: 125mb
                                                </Typography>
                                                <LinearProgressWithLabel value={storageProgress} maxvalue={500} />
                                            </Card>
                                            <Card style={{ width: '100%', padding: 10 }}>
                                                <Typography style={{ display: 'flex', gap: 5 }} color="textPrimary">
                                                    <AlbumOutlinedIcon color='primary' /> Operações (gravação/leitura de dados): {perfil.plano?.limiteOperacoes || "50.000"}
                                                </Typography>
                                                <Typography style={{ display: 'flex', gap: 5 }} color="textPrimary">
                                                    <DoneAllOutlinedIcon color='primary' /> Utilizado: 12.152
                                                </Typography>
                                                <LinearProgressWithLabel value={readWriteProgress} maxvalue={50000} />
                                            </Card>
                                        </CardContent>
                                        <CardActions style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                                            <Button onClick={() => history.push('/Planos')} variant='contained' color='primary'>
                                                Gerenciar assinatura
                                            </Button>
                                        </CardActions>
                                    </Card>
                                </Box>
                                : perfil.nomePlano ?
                                    <Box width={500}>
                                        <Card sx={{ minWidth: 275 }}>
                                            <CardContent style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>
                                                <Box display='flex' justifyContent='space-between'>
                                                    <Typography style={{ fontSize: 20, fontWeight: 'bold' }} color="primary" gutterBottom>
                                                        {perfil?.nomePlano}
                                                    </Typography>
                                                    <Typography style={{ fontSize: 14, fontStyle: 'italic' }}>
                                                        Valido até: {perfil.dataFimPlano ? perfil.dataFimPlano.split("-").reverse().join('/') : "-"}
                                                    </Typography>
                                                </Box>
                                                <Card style={{ width: '100%', padding: 10 }}>
                                                    <Typography style={{ display: 'flex', gap: 5 }} color="textPrimary">
                                                        <AssignmentOutlinedIcon color='primary' /> Número de projetos permitidos: {perfil.numProject}
                                                    </Typography>
                                                    <Typography style={{ display: 'flex', gap: 5 }} color="textPrimary">
                                                        <DoneAllOutlinedIcon color='primary' /> Número de projetos criados: {projetos?.length}
                                                    </Typography>
                                                    <LinearProgressWithLabel value={projectProgress} maxvalue={perfil && perfil.numProject ? perfil.numProject : 3} />
                                                </Card>
                                                <Card style={{ width: '100%', padding: 10 }}>
                                                    <Typography style={{ display: 'flex', gap: 5 }} color="textPrimary">
                                                        <StorageOutlinedIcon color='primary' /> Armazenamento disponível: {perfil.plano?.limiteArmazenamento || "500mb"}
                                                    </Typography>
                                                    <Typography style={{ display: 'flex', gap: 5 }} color="textPrimary">
                                                        <DoneAllOutlinedIcon color='primary' /> Armazenamento utilizado: 125mb
                                                    </Typography>
                                                    <LinearProgressWithLabel value={storageProgress} maxvalue={500} />
                                                </Card>
                                                <Card style={{ width: '100%', padding: 10 }}>
                                                    <Typography style={{ display: 'flex', gap: 5 }} color="textPrimary">
                                                        <AlbumOutlinedIcon color='primary' /> Operações (gravação/leitura de dados): {perfil.plano?.limiteOperacoes || "50.000"}
                                                    </Typography>
                                                    <Typography style={{ display: 'flex', gap: 5 }} color="textPrimary">
                                                        <DoneAllOutlinedIcon color='primary' /> Utilizado: 12.152
                                                    </Typography>
                                                    <LinearProgressWithLabel value={readWriteProgress} maxvalue={50000} />
                                                </Card>
                                            </CardContent>
                                            <CardActions style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                                                <Button onClick={() => history.push('/Planos')} variant='contained' color='primary'>
                                                    Fazer upgrade
                                                </Button>
                                            </CardActions>
                                        </Card>
                                    </Box>

                                    : <Box display='flex' flexDirection='column' alignItems='center' style={{ gap: 15, marginTop: 15 }}>
                                        <Typography>Você não tem nenhum plano contratado ainda</Typography>
                                        <Button onClick={() => history.push('/Planos')} variant='contained' color='primary' style={{ width: 'fit-content' }}>Contratar</Button>
                                    </Box>
                            }
                        </Box>
                    </Box>
                }
                {tab === 2 &&
                    <Box style={{ marginTop: 10 }}>
                        <Box className="containerProfile">
                            <Box style={{ flex: 1 }} id="fields">
                                {perfil && perfil.uid && <ListProjetosPerfil uidPerfil={perfil.uid} />}
                            </Box>
                        </Box>
                    </Box>
                }
            </CrudForm>
        </Box>
    )
}

export default ProjectForm