import React, { useState, useEffect } from 'react'
import { OkTypedComponent, LabelInputAuto } from 'OkApp'
import { useAtualValues, useOkApp } from 'OkApp/UseUtils'
import { getOkFormSnap } from 'OkApp/OkFunctions';
import { componetIteratorOkForm } from 'OkApp/functions';


export default function EditorView(props) {
    const okApp = useOkApp();
    const [camposFormulario, setCamposFormulario] = useState([])
    let tipo = useAtualValues("typeOpts");
    let auxOkForm = useAtualValues("auxOkForm");
    let isTpFixo = false;
    let isTpOutroOkObj = false;
    let isTpFieldValues = false;
    if (!tipo || tipo === "fixo") isTpFixo = true;
    if (tipo === "outroOkObj") isTpOutroOkObj = true;
    if (tipo === "fieldValues") isTpFieldValues = true;


    useEffect(() => {
        if (auxOkForm) {
            getOkFormSnap(okApp, auxOkForm.uid, (r) => {
                let fields = []
                
                componetIteratorOkForm(r, (comp) => {
                    fields.push(comp.field)
                })
                console.log('fields', fields)
                setCamposFormulario(fields)
            }, (err) => {
                setCamposFormulario([])
            })
        }

    }, [auxOkForm, okApp])
    return (
        <div style={{ display: 'flex', flexDirection: "column", height: "100%" }}>
            <div>

                <OkTypedComponent typeComponente='InputComp' field='field' label='Nome Salvo' />
                <LabelInputAuto {...props} />
                <OkTypedComponent typeComponente='CheckboxComp' field='multiple' label='Multiplo' />
                <OkTypedComponent typeComponente='ComboBoxComp' field='typeOpts' label='Tipo Opções' options={['fixo', 'fieldValues', 'outroOkObj']} toStringItem={(v) => {
                    switch (v) {
                        case 'fixo': return "Lista Fixa"
                        case 'outroOkObj': return "Outro OkForm"
                        case 'fieldValues': return "Valores dos outros registros"
                        default: return v;
                    }
                }} />
            </div>

            {isTpFixo && <>
                <div style={{ display: "flex" }}>

                    <OkTypedComponent typeComponente='CheckboxComp' field='somenteOpt' label='Somente Selecionaveis' />
                    <OkTypedComponent typeComponente='InputComp' field='noOptionsText' label='Sem Opções' />

                </div>
                <OkTypedComponent typeComponente='ListaItens' field='options' label='Opções' adicionavel={true} removivel={true} />

            </>}


            {isTpOutroOkObj && <>
                <div style={{ display: 'flex' }}>
                    <OkTypedComponent typeComponente='SeletorOkForm' field='auxOkForm' label='Formulário' />

                </div>
                <div style={{ display: 'flex' }}>
                    <OkTypedComponent typeComponente='CheckboxComp' field='exibePesquisa' label='Exibir botao Pesquisa' />
                    <OkTypedComponent typeComponente='InputComp' field='tituloPesquisa' label='Titulo da Pesquisa' />
                </div>
                <div>

                    <OkTypedComponent typeComponente='AutoCompleteComp' field='campoPesquisa' label='Campo Pesquisa'
                        options={camposFormulario}
                        noOptionsText={"Nenhum item encontrado"}
                        somenteOpt={true}
                    />
                </div>
                <OkTypedComponent typeComponente='FunctionsEditor' startFunc={"function filterList(list,props) {\n\treturn list;\n}"} field='filterListCode' label='Filtrar lista' />


            </>}
            {isTpFieldValues && <>


            </>}




        </div>
    )
}
