import React from 'react'
import OkTypedComponent from 'OkApp/components/OkTypedComponent'
import LabelInputAuto from 'OkApp/components/LabelInputAuto'

export default function EditorComp(props) {

    return <>
        <OkTypedComponent typeComponente='InputComp' field='field' label='Nome Salvo' />
        <LabelInputAuto {...props} />
      
    </>
}

