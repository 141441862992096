import React, { useState, useEffect, useCallback } from 'react'
import { useTheme, useMediaQuery, Dialog, Button, makeStyles, Typography, IconButton, Checkbox } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import _ from 'lodash'
import './style.css'

import { reduxForm } from 'redux-form';

import Filtros from './Filtros';
import Tabela from 'components/Tabela';
import TabelaGrid from 'components/Tabela Grid';

import { permiteEditar } from 'OkApp/modules/PermissionMeta/functions';
import { getStrCollectonsListSnapDatabase, getStrCollectonsListSnapLaterPermissionDatabase, getStrCollectonsListSnapNoPermissionDatabase } from 'OkApp/OkFunctions';
import { includesInsensitive } from 'OkApp/functions';
import { Search } from '@material-ui/icons';



const useStyles = makeStyles(theme => ({
    dialogPaper: {
        minHeight: '80vh',
        maxHeight: '80vh',
        minWidth: '80vw',
        maxWidth: '80vw',
        overflow: 'hidden',
        height: '80vh',
        width: '80vw',
    },

    title: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        justifyContent: 'space-between',

    },

    filter: {
        // backgroundColor: theme.palette.primary.light,
        // color: theme.palette.primary.contrastText,
    },
}));

function PerquisaForm(props) {
    const { onSelect } = props
    const { multiple, open, handleClose, title, columns, colectionFirebase, handleSubmit, filters, filterEditar, funcFilter, database, filtraNaTela, getAll } = props
    const theme = useTheme();
    const classes = useStyles();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [resultsFilter, setResultsFilter] = useState([])
    const [dbFilter, setDbFilter] = useState({})
    const [showMobileFilter, setShowMobileFilter] = useState(false)

    const limit = filtraNaTela ? null : 1000
    const [results, setResults] = useState([])
    const [selected, setSelected] = useState([])

    const filterInDB = filtraNaTela ? false : true;
    const updateResultsFilter = useCallback((ar) => {
        if (funcFilter) {
            setResultsFilter(funcFilter(ar, props))
        } else {
            setResultsFilter(ar)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [funcFilter])

    const filtrarCache = useCallback((valuesFilter) => {
        setShowMobileFilter(false)
        if (results && (filters || filterEditar)) {
            if (filterInDB) {
                if (filters) {
                    let upFilterData = {};
                    for (let i = 0; i < filters.length; i++) {
                        const f = filters[i];
                        let v = valuesFilter[f.field]
                        if (v) {

                            if (f.filterconf) {
                                if (f.filterconf.toValueCompare) {

                                    v = f.filterconf.toValueCompare(v)
                                }
                                if (f.filterconf.comparatype) {
                                    if (f.filterconf.comparatype === 'equals') {
                                        upFilterData[f.field] = v
                                    }

                                    if (f.filterconf.comparatype === 'include') {
                                        upFilterData[f.field] = v + "%"
                                    }
                                } else {
                                    upFilterData[f.field] = v + "%"
                                }
                            } else {
                                upFilterData[f.field] = v + "%"
                            }
                        }
                    }
                    setDbFilter(upFilterData)
                }
            } else {

                let newArray = results.filter((item, index) => {
                    if (filterEditar) {
                        if (!permiteEditar(item)) {
                            return false;
                        }
                    }
                    if (filters) {
                        for (let i = 0; i < filters.length; i++) {
                            const f = filters[i];
                            let v = valuesFilter[f.field]
                            if (v) {
                                if (f.filterconf) {

                                    if (f.filterconf.comparatype) {
                                        if (f.filterconf.comparatype === 'equals') {
                                            if (item[f.field] !== v) {
                                                return false;
                                            }
                                        }

                                        if (f.filterconf.comparatype === 'include') {
                                            if (item[f.field]) {
                                                let vCompara = item[f.field]

                                                if (vCompara.constructor === Object) {
                                                    if (f.funcToString) {
                                                        vCompara = f.funcToString(vCompara)

                                                    } else {
                                                        vCompara = JSON.stringify(vCompara)
                                                    }
                                                }

                                                if (!includesInsensitive(vCompara, v)) {
                                                    return false;
                                                }
                                            } else {
                                                return false;
                                            }
                                        }
                                    } else {
                                        if (item[f.field]) {
                                            let vCompara = item[f.field]

                                            if (vCompara.constructor === Object) {
                                                if (f.funcToString) {
                                                    vCompara = f.funcToString(vCompara)

                                                } else {
                                                    vCompara = JSON.stringify(vCompara)
                                                }
                                            }

                                            if (!includesInsensitive(vCompara, v)) {
                                                return false;
                                            }
                                        } else {
                                            return false;
                                        }
                                    }
                                } else {
                                    if (item[f.field]) {
                                        if (!includesInsensitive(item[f.field], v)) {
                                            // if (!item[f.field].toUpperCase().includes(v).toUpperCase()) {
                                            return false;
                                        }
                                    } else {
                                        return false;
                                    }

                                }
                            }


                        }
                    }
                    return true;
                })
                updateResultsFilter(newArray)
            }
        } else {
            updateResultsFilter(results ? results.slice() : [])

        }

    }, [results, filters, filterEditar, filterInDB, updateResultsFilter])

    useEffect(() => {
        let observer = null
        if (filterInDB) {
            if (_.isEmpty(dbFilter)) {

                observer = getStrCollectonsListSnapDatabase(database, colectionFirebase, null,
                    (r) => {
                        setResults(r)

                        let newArray = r.filter((item, index) => {
                            if (filterEditar) {
                                if (!permiteEditar(item)) {
                                    return false;
                                }
                            }
                            return true;
                        })

                        updateResultsFilter(newArray);
                    }, (err) => {
                        console.log('err', err)
                        setResults([])
                    }, limit
                )
            } else {
                observer = getStrCollectonsListSnapLaterPermissionDatabase(database, colectionFirebase, dbFilter, filterEditar,
                    (r) => {
                        setResults(r)

                        updateResultsFilter(r);

                    }, (err) => {
                        console.log('err', err)
                        setResults([])
                    }, limit
                )
            }
        } else {
            // console.log('busca todos')
            if (getAll) {    
                observer = getStrCollectonsListSnapNoPermissionDatabase(database, colectionFirebase, null,null,
                    (r) => {
                        setResults(r)
                        updateResultsFilter(r);
                    }, (err) => {
                        console.log('err', err)
                        setResults([])
                    }, limit
                )
            } else {


                observer = getStrCollectonsListSnapDatabase(database, colectionFirebase, null,
                    (r) => {
                        setResults(r)
                        updateResultsFilter(r);
                    }, (err) => {
                        console.log('err', err)
                        setResults([])
                    }, limit
                )
            }
        }
        return () => {
            setResults([]);
            if (observer) observer()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [colectionFirebase, database, dbFilter, filterEditar, filterInDB, updateResultsFilter])

    const selecionar = () => {
        onSelect(selected)
        setSelected([])
    }

    const alternaSelecionaItem = (item) => {
        let indexOf = selected.map(s => s.uid).indexOf(item.uid);

        if (indexOf === -1) {
            setSelected(sels => sels.concat([item]))
        } else {
            setSelected(sels => {
                let newArray = sels.slice()
                newArray.splice(indexOf, 1)
                return newArray
            })
        }
    }


    const isSelected = useCallback((item) => {


        let indexOf = selected.map(s => s.uid).indexOf(item.uid);
        let r = indexOf !== -1

        return r;
    }, [selected])



    let colMui = columns.map((c, i) => ({ ...c, name: c.field }))
    const getValueSort = (linha, coluna) => {
        const parseValueShow = (value, linha, coluna,) => {
            if (value) {
                if (value.constructor === String) {

                } else
                    if (value.constructor === Number) {

                    } else
                        if (value.toDate) {//timestamps                           net
                            value = value.toDate().toLocaleDateString('pt-BR', { timeZone: 'UTC' })

                        } else
                            if (value.constructor === Object) {
                                if (coluna && coluna.funcToString) {
                                    value = coluna.funcToString(value, linha, coluna)
                                    value = parseValueShow(value, linha);
                                } else {
                                    value = JSON.stringify(value)
                                }
                            }
            }
            return value;
        }
        let value = _.get(linha, coluna.field);
        value = parseValueShow(value, linha, coluna)
        return value;
    }
    const RenderCell = useCallback((props) => {
        const parseValueShow = (value, linha, coluna,) => {
            if (value) {
                if (value.constructor === String) {

                } else
                    if (value.constructor === Number) {

                    } else
                        if (value.toDate) {//timestamps                           net
                            value = value.toDate().toLocaleDateString('pt-BR', { timeZone: 'UTC' })

                        } else
                            if (value.constructor === Object) {
                                if (coluna && coluna.funcToString) {

                                    try {

                                        value = coluna.funcToString(value, linha, coluna)
                                        value = parseValueShow(value, linha);
                                    } catch (error) {
                                        console.log('error', error)
                                        console.log('coluna', coluna)
                                        value = "ERROR"
                                    }
                                } else {
                                    value = JSON.stringify(value)
                                }
                            }
            }
            return value;
        }


        const { coluna, linha } = props
        if ("COL_SELECTED_ITEM" === coluna.field) {
            return <Checkbox size="small" checked={isSelected(linha)} />
        }

        let value = _.get(linha, coluna.field);
        value = parseValueShow(value, linha, coluna)

        try {
            return <Typography component="div">{value || ""}</Typography>
        } catch (error) {
            console.log('ERRO NO TIPO DE VALOR', error)
            return <Typography component="div">ERRO NO TIPO DE VALOR</Typography>
        }

    }, [isSelected])
    if (multiple) {
        colMui = [{ label: " ", field: "COL_SELECTED_ITEM", sizecol: "55" }].concat(colMui)


    }
    let showActions = multiple;

    return (
        <Dialog
            classes={{ paper: !fullScreen ? classes.dialogPaper : null }}
            fullScreen={fullScreen}
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
        >
            <div className="dialogPesquisaContainer2">
                <div className={classes.title + " dialogPesquisaTitle2"}>
                    <Typography variant="h6">
                        {title}
                    </Typography>
                    <div style={{ display: 'flex' }}>

                        {!showMobileFilter && <IconButton className="dialogMobileSearchButtom" edge="end" color="inherit" onClick={() => { setShowMobileFilter(true) }} aria-label="Search">
                            <Search />
                        </IconButton>}
                        <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                    </div>

                </div>
                <div className={filters ? "dialogPesquisaBody2" : "dialogPesquisaBodySemFilter"}>
                    {filters &&
                        <div className={classes.filter + " dialogPesquisaFilter2"}>
                            <div style={{ padding: 5 }} align="right">

                                <Button variant="contained" color="secondary" onClick={handleSubmit(filtrarCache)}>Filtrar</Button>
                            </div>
                            <Filtros filters={filters} filtrar={handleSubmit(filtrarCache)} />

                        </div>
                    }
                    <div className="dialogPesquisaResultsActions2">

                        <div className="dialogPesquisaResults2">
                            {/* <MUIDataTable 
                                data={results}
                                columns={colMui}
                                options={options}
                            /> */}
                            {showMobileFilter &&
                                <div className={"dialogPesquisaFilterMobile"}>
                                    <div style={{ padding: 5 }} align="right">

                                        <Button variant="contained" color="secondary" onClick={handleSubmit(filtrarCache)}>Filtrar</Button>
                                    </div>
                                    <Filtros filters={filters} filtrar={handleSubmit(filtrarCache)} />

                                </div>
                            }
                            {filterInDB ?

                                <Tabela
                                    data={resultsFilter}
                                    columns={colMui}
                                    RenderCell={RenderCell}
                                    onRowClick={(item, index) => {
                                        if (multiple) {
                                            alternaSelecionaItem(item)
                                        } else {

                                            onSelect([item])
                                        }
                                    }}
                                />
                                :
                                <TabelaGrid
                                    msgSemRegistro={"Sem registros para estes filtros"}
                                    sortColuns={true}
                                    data={resultsFilter}
                                    columns={colMui}
                                    RenderCell={RenderCell}
                                    getValueSort={getValueSort}
                                    onRowClick={(item, index) => {
                                        if (multiple) {
                                            alternaSelecionaItem(item)
                                        } else {

                                            onSelect([item])
                                        }
                                    }}
                                />
                            }
                        </div>
                        {showActions && <div className={classes.title + " dialogPesquisaActions2"}>
                            {multiple && <Button variant="contained" color="secondary" onClick={selecionar} > Selecionar</Button>}
                            {(results && limit === results.length) && <div>Resultado limitado a {limit} registros. </div>}
                        </div>}
                    </div>

                </div>


            </div>
        </Dialog>
    )
}


export default reduxForm({ form: "PesquisaCustomForm" })(PerquisaForm)