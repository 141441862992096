import Page from './components/Page'



export const routes = () => {
    return [
        { exact: true, path: "/PoliticasPrivacidade", component: Page },
    ]
}
export const menus = ({ history, okApp }) => {


    let m = []


    m.push({ index: 999999, text: "Políticas de Privacidade", iconKey: 'Policy', action: () => { history.push("/PoliticasPrivacidade") } })

    return m;
}

