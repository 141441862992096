import { InputLabel } from '@material-ui/core'
import React from 'react'




export default function Overflow({ styleAtual, value, onChange }) {


    return (
        <div className='OkStyleEditor-comp'>
            <InputLabel>Overflow</InputLabel>
            <div className='OkStyleEditor-row'>
                <button className={value === 'hidden' ? 'OkStyleEditor-buttonSelected' : ''} onClick={() => onChange('hidden')}>hidden</button>
                <button className={value === 'visible' ? 'OkStyleEditor-buttonSelected' : ''} onClick={() => onChange('visible')}>visible</button>
            </div>

        </div>
    )
}
