import React from 'react'
import { useOkForm } from 'OkApp/UseUtils'
import RenderOkForm from 'OkApp/components/RenderOkForm';
import { componetIteratorOkForm } from 'OkApp/functions';
import _ from 'lodash'
import { reduxForm } from 'redux-form';
import getFormValues from 'redux-form/es/getFormValues';
import { connect } from 'react-redux';
import { CircularProgress } from '@material-ui/core';

var AuxForm = function (props) {

    const { children } = props
    return <>
        {children}
    </>;
}
const mapStateToProps = (state, props) => {
    let atualValues = getFormValues(props.form)(state);

    return { atualValues };

};
AuxForm = reduxForm({})(AuxForm)

AuxForm = connect(mapStateToProps, {})(AuxForm)

export default function CompView(props) {
console.log('CompView render props',props)

    const { auxOkForm, input: { value, name }, naoValidaRequeridos, onlyView ,itemList} = props//okcomp
    let form = useOkForm(auxOkForm ? auxOkForm.uid : null);
    let prefieldNew = name;

    let developer = props.propscomp.developer

    if (props.prefield) {
        prefieldNew = props.prefield + prefieldNew
    }
    if (naoValidaRequeridos && form) {
        componetIteratorOkForm(form, comp => {
            _.set(comp, "required", false)
        })
    }


    if (onlyView) {
        let initialValues = {}
        developer =false;
        if (form && form.initialValues) {
            initialValues = form.initialValues
        }

        return (


            <div>{form ?
                <AuxForm
                    form={"CustomForm" + form.uid}
                    initialValues={initialValues}
                >
                    <RenderOkForm okform={form} developer={developer} renderForm={true} value={value} clearDevelorperNext={true} itemList={itemList}/>
                </AuxForm>
                : <div style={{ display: "flex", justifyContent: "center" }}><CircularProgress /></div>}</div>

        )
    }
    return (

        <div>{form ?
            <RenderOkForm okform={form} developer={props.propscomp.developer} renderForm={true} prefield={prefieldNew} value={value} clearDevelorperNext={true} itemList={itemList}/> :
            <div style={{ display: "flex", justifyContent: "center" }}><CircularProgress /></div>}</div>

    )
}
