import React, { useState, useEffect } from 'react'
import { useOkFormListSnap } from 'OkApp/UseUtils';
import _ from 'lodash'
import { DinamicComponentsList } from 'OkApp'
import { TextField, Button } from '@material-ui/core';
import FomComp from './FomComp';

const varPontos = "metaOkApp.gestao.pontosFuncao"
const prefixoModule = "metaOkApp.gestao."


export default function AnaliseApp(props) {
    const { okApp } = props
    const [{ totalPontos, totalPrevisto }, setTotaisApp] = useState({ totalPontos: 0, totalPrevisto: 0 })
    const [formsData, setFormsData] = useState([])
    const listOkForm = useOkFormListSnap(okApp);
    const [typePontos, setTypePontos] = useState({
        AnexosComp: "4",
        AutoCompleteComp: "4",
        ButtonComp: "4",
        CheckboxComp: "1",
        ComboBoxComp: "1",
        ComentariosComp: "3",
        FilterOkObj: "8",
        FunctionsEditor: "8",
        ImageSelComp: "4",
        InputComp: "1",
        KanbanComp: "8",
        ListaItens: "4",
        PainelAbaComp: "1",
        PanelFlexComp: "0",
        ReactRenderComp: "8",
        RichTextComp: "2",
        SeletorOkForm: "3",
        SeletorOkObjUnico: "6",
        SequencialIndexComp: "0",
        SwitchComp: "1",
        TabelaOkComp: "6",
        TextComp: "1"
    })




    const getPontosType = (c) => {
        let pontos = 0;
        if (typePontos[c.typeComponente]) {
            pontos = parseFloat(typePontos[c.typeComponente])
        }
        return pontos
    }

    //#region - Salva Csv
    const gerarArquivoDados = () => {



        let str = "";
        str += "Formulario;" +
            "Descrição;" +
            "Agrupador;" +
            "PontosPrevistos;" +
            "PontosTotais\n"
        formsData.forEach(f => {
            str += f.name + ";" +
                f.description + ";" +
                (f.agrupador || "") + ";" +
                (f.pontosPrevistos || 0) + ";" +
                f.pontosTotais +
                "\n"
        })


        gerarArquivo(str, "CsvApp-" + okApp.name + ".csv")

    }
    const gerarArquivo = (texto, nome) => {
        var element = document.createElement("a");

        let file = new Blob([texto], { type: "text/plain;charset=utf-8" });
        element.href = URL.createObjectURL(file);
        element.download = nome;
        element.click();
    }

    //#endregion

    const getPontos = (c) => {
        let pontos = _.get(c, varPontos)
        if (pontos) {
            pontos = parseFloat(pontos)
        } else {
            pontos = getPontosType(c);
        }

        if (c.children) {
            c.children.forEach(c => {
                pontos = pontos + getPontos(c)
            });
        }
        return pontos;
    }

    useEffect(() => {

        const pushCompFilhos = (comp) => {
            let list = []

            if (comp.children) {
                comp.children.forEach(element => {
                    list = list.concat(pushCompFilhos(element, list))
                });
            } else {
                list.push({ typeComponente: comp.typeComponente, label: comp.label, postos: getPontos(comp), metaOkApp: comp.metaOkApp })
            }
            return list;

        }
        if (listOkForm) {
            let totalPontos = 0;
            let totalPrevisto = 0;
            let newForms = [];
            listOkForm.forEach(f => {
                console.log('f', f)
                let newForm = { uid: f.uid, name: f.name, description: f.description, }

                newForm.agrupador = _.get(f, prefixoModule + 'agrupador');
                newForm.pontosPrevistos = _.get(f, prefixoModule + 'pontosFuncaoPrevisto');
                newForm.pontosComponentes = getPontos(f);
                newForm.pontosAdicionais = _.get(f, prefixoModule + 'pontosFuncaoAdicional');
                newForm.pontosTotais = parseFloat(newForm.pontosComponentes) + (newForm.pontosAdicionais ? parseFloat(newForm.pontosAdicionais) : 0);
                newForm.components = pushCompFilhos(f)
                totalPontos = totalPontos + newForm.pontosTotais;
                if (newForm.pontosPrevistos) {
                    totalPrevisto = totalPrevisto + parseFloat(newForm.pontosPrevistos);
                }

                newForms.push(newForm)
            });
            setTotaisApp({ totalPontos, totalPrevisto })


            setFormsData(newForms)
        } else {
            setFormsData([])
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listOkForm])
    const CompsPontosPorTipo = () => {
        return <div>
            {DinamicComponentsList.map((c, i) => {
                return <TextField key={i} label={c.typeComponente} value={typePontos[c.typeComponente] || ""} onChange={(evt) => {
                    let newv = { ...typePontos }
                    newv[c.typeComponente] = evt.target.value
                    setTypePontos(newv)
                }} />

            })}
        </div>
    }



    const CompFilhos = ({ comp, prefixoTab }) => {
        if (comp.children) {
            let totalPontosFilhos = 0;
            comp.children.forEach(c => {
                totalPontosFilhos = totalPontosFilhos + getPontos(c)
            });


            return <div>

                {/* {comp.label && <h3> {prefixoTab + " Container Inicio: " + comp.label + " Pontos:" + totalPontosFilhos} </h3>} */}
                {comp.children.map((c, i) => <CompFilhos key={i} comp={c} prefixoTab={prefixoTab + "---"} />)}
                {/* {comp.label && <h3> {prefixoTab + " Container Fim   : " + comp.label} </h3>} */}

            </div>
        } else {
            // return <div>{prefixoTab + ' Tipo: ' + comp.typeComponente + " Label: " + comp.label + " => Pontos: " + getPontos(comp)}</div>
            return <div>{'Tipo: ' + comp.typeComponente + " | Label: " + comp.label + " => Pontos: " + getPontos(comp)}</div>
        }
    }


    // const CompForm = ({ form }) => {
    //     return <div>
    //         <h2>Formulário: {form.name} => Postos: {getPontos(form)}</h2>
    //         <CompFilhos comp={form} prefixoTab="" />
    //         <h2>------------------------------------------------------</h2>
    //     </div>
    // }
    // let totalPontos = 0;
    // (listOkForm || []).forEach(f => {
    //     totalPontos = totalPontos + getPontos(f)

    // })
    return (
        <div>

            <CompsPontosPorTipo />
            <Button onClick={gerarArquivoDados}>Gerar csv</Button>
            <h1>{okApp.name} | Pontos: {totalPontos} - Previstos: {totalPrevisto}</h1>

            <div>
                {(formsData || []).map((f, i) => <FomComp key={i} form={f} />)}
            </div>
        </div>
    )
}
