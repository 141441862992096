import * as Public from './public'
import * as Private from './private'
import * as Dinamic from './dinamic'
import * as Admin from './admin'

export const routes = (props) => {
    let r = []
    r = r.concat(Private.routes());
    r = r.concat(Public.routes());
    r = r.concat(Dinamic.routes());
    r = r.concat(Admin.routes(props));
    return r
}
export const menus = (props) => {

    let r = []
    r = r.concat(Private.menus(props));
    r = r.concat(Public.menus(props));
    r = r.concat(Dinamic.menus(props));
    r = r.concat(Admin.menus(props));
    return r
}




