import React from 'react'
import OkCompAdapter from 'OkApp/components/OkCompAdapter'
import CompView from './component/CompView'
import EditorView from './component/EditorView'
const typeComponente = 'AutoCompleteComp'

const AdapterOkComp = (props) => {
    let propsComp = {}
    return <OkCompAdapter {...props} propsComp={propsComp} component={CompView} />
}

const modelo = {
    typeComponente,
    field: 'cartegoria',
    label: 'Categoria',
    typeOpts: "fixo",
    exibePesquisa: true,
    options: ['Verde', 'Amarelo', 'Azul'],
}



const AutoCompleteComp = {
    typeComponente,
    configView: {
        grupo: 'Avançado',
        nome: 'Auto Complete',
        descricao: 'AutoCompleteComp',
        helpUrl: 'https://okapp.com.br/app/okappdocs/autocompletecomp',
        modelo
    },
    Component: AdapterOkComp,
    CompEditor: EditorView
}
export default AutoCompleteComp

