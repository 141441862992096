import React, { useState, useEffect } from 'react'

import { useOkAppContext, ajusteRoute } from 'OkApp/context'
import { useHistory } from 'react-router-dom'
import { Field } from 'redux-form'
import { Typography } from '@material-ui/core'
import { getComponetWith } from 'OkApp/functions'
import PesquisaDialog from 'components/PesquisaDialog'
import { Input, SwitchItem, Combobox } from 'components'

import OkFormAbaAdapter from 'OkApp/components/OkFormAbaAdapter'
import OkTypedComponent from 'OkApp/components/OkTypedComponent'
import { getFunctionCodeToFuncMap } from 'OkApp/CodeProcessor'
import { getOkCompCore } from 'OkApp'
import _ from 'lodash'


export function CompAddMetaGeral(props) {
    return <div>
        <Field name={"meta.pesquisa.showpesquisa"} label={"Exibir Pesquisa"} component={SwitchItem} />
        <Field name={"meta.pesquisa.titlepesquisa"} label={"Título da Pesquisa"} component={Input} />
    </div>
}

export function CrudInsertComp(props) {
    const { okform, pristine, submitting, addActions, removeActions } = props;
    // console.log('CrudInsertComp atualValues')

    const [openPesquisa, setOpenPesquisa] = useState(false)
    const [tituloUsar, setTituloUsar] = useState("Titulo")
    const [colunas, setColunas] = useState([])
    const [filtros, setFiltros] = useState([])

    const history = useHistory();
    // const uid = atualValues ? atualValues.uid : null;
    const { okApp, database } = useOkAppContext();

    let colectionFirebase = null;
    if (okApp && okform) {
        colectionFirebase = 'Project/' + okApp.uid + '/ValuesCustomForm/' + okform.uid + "/Values";
    }


    useEffect(() => {
        // console.log('useEffect okform')
        if (okform && okform.meta && okform.meta.pesquisa && okform.meta.pesquisa.showpesquisa) {
            addActions([{
                uid: 'crud.action.pesquisa', index: 30, text: "Pesquisar", icon: 'Search', action: () => setOpenPesquisa(true)
                // , props: { hidden: (!pristine) }// problemas com o comp filtro
                , props: {}
            }])
        }
        return () => {
            if (okform && okform.meta && okform.meta.pesquisa && okform.meta.pesquisa.showpesquisa)
                removeActions(['crud.action.pesquisa']);
        };
    }, [okform, pristine, submitting, addActions, removeActions])

    useEffect( () => {
        // console.log('useEffect okform')
        if (okform) {
            const componets =  getComponetWith(okform, { meta: { pesquisa: { coluna: true } } })
            if (componets) {
                let cols = []
                componets.forEach(comp => {
                    let funcToString = getFunctionCodeToFuncMap(comp.funcToString, "toStringPesquisa");

                    let newCol = { ...comp, ...comp.meta.pesquisa, funcToString };
                    cols.push(newCol)
                })
                if (!cols.length) {
                    cols.push({ label: "UID", field: "uid" })
                }
                setColunas(cols)
            }


            const componetsFiltros =  getComponetWith(okform, { meta: { pesquisa: { filter: true } } })

            if (componetsFiltros) {
                let fil = []
                componetsFiltros.forEach(comp => {                   
                    let funcToString = getFunctionCodeToFuncMap(comp.funcToString, "toStringPesquisa");                  
                    const filterC = { ...comp, ...comp.meta.pesquisa, okComp: comp, funcToString }
                    let OkCompCore = getOkCompCore(comp.typeComponente)
                    if (_.get(OkCompCore, "module.DefaultPesquisa.filterconf")) {
                        filterC.filterconf = { ...filterC.filterconf, ..._.get(OkCompCore, "module.DefaultPesquisa.filterconf") }
                    }
                    fil.push(filterC)
                })
                setFiltros(fil)
            }

            let tituloUsar = "Pesquisa de ";

            if (okform.meta && okform.meta.pesquisa && okform.meta.pesquisa.titlepesquisa) {
                tituloUsar = okform.meta.pesquisa.titlepesquisa
            } else {
                tituloUsar = tituloUsar + okform.name
            }

            setTituloUsar(tituloUsar)
        }
    }, [okform])
    const filtraNaTela = _.get(okform, 'meta.pesquisa.buscaDepoisFiltra')
    // console.log('colunas', colunas)
    // console.log('filtros', filtros)
    return (
        <>
            <PesquisaDialog open={openPesquisa} handleClose={() => setOpenPesquisa(false)}
                title={tituloUsar}
                database={database}
                filtraNaTela={filtraNaTela}
                colectionFirebase={colectionFirebase}
                onSelect={(selecionou) => {
                    setOpenPesquisa(false);
                    if (selecionou.length) {
                        history.push(ajusteRoute(okApp, okform) + "?uid=" + selecionou[0].uid)
                    }
                }}
                columns={colunas || []}
                filters={filtros || []}
            />
        </>
    )
}




export function CompAddCustomComponent(props) {

    const typeFilterToString = (v) => {
        if ("textfield" === v) {
            return "Campo de texto"
        }
        if ("okcomp" === v) {
            return "Proprio Componente"
        }

    }

    const filterCompareToString = (v) => {
        if ("equals" === v) {
            return "Se igual"
        }
        if ("include" === v) {
            return "Se inicia com"
        }

    }


    return <div style={{ display: 'flex', flexDirection: "column", height: "100%" }}>
        <Typography variant={'h6'}>Colunas:</Typography>
        <Field name={"meta.pesquisa.coluna"} label={"Usar como coluna"} component={SwitchItem} />
        <Field name="meta.pesquisa.sizecol" component={Input} label={"Tamanho Coluna(inteiro ou %)"} />


        <Typography variant={'h6'}>Filtros:</Typography>
        <Field name={"meta.pesquisa.filter"} label={"Usar como filtro"} component={SwitchItem} />
        <Field name={"meta.pesquisa.filterconf.type"} label={"Tipo de Filtro"} itens={['okcomp', 'textfield']} toStringItem={typeFilterToString} component={Combobox} />
        <Field name={"meta.pesquisa.filterconf.comparatype"} label={"Comparar"} itens={['include', 'equals']} toStringItem={filterCompareToString} component={Combobox} />
        <Typography variant={'h6'}>Avançado:</Typography>

        <OkTypedComponent typeComponente='FunctionsEditor' startFunc={"function toStringPesquisa(vField,obj,comp) {\n\treturn vField;\n}"} field='funcToString' label='Função toString' />


    </div>
}

export function OkFormMetaAba(props) {
    return <OkFormAbaAdapter keyTab={"Ações"} index={30} {...props}>
        <div style={{ display: 'flex' }}>
            <OkTypedComponent typeComponente='SwitchComp' field={"meta.pesquisa.showpesquisa"} label={"Exibir Pesquisa"} />
            <OkTypedComponent typeComponente='InputComp' field={"meta.pesquisa.titlepesquisa"} label="Título da Pesquisa" />
            <OkTypedComponent typeComponente='SwitchComp' field={"meta.pesquisa.buscaDepoisFiltra"} label={"Busca TODOS depois filtra"} />

        </div>
    </OkFormAbaAdapter>
}


export const data = { name: "PesquisaDefault", abaMetaGeral: "Pesquisa", abaMetaCustomComponent: "Pesquisa" }