import { createSnapCollectionProps } from 'dbApi';
import React, { useEffect, useState } from 'react'
import { ObjectInspector } from 'react-inspector';
import { Button } from '@material-ui/core';

const ItemHistorico = ({ item, open, setOpen, mapChange }) => {
    let data = new Date(item.lastChange)
    
    let changeChildren = null;
    if (mapChange && mapChange.changeChildren) {
        changeChildren = mapChange.changeChildren
    }

    return <div>
        <div onClick={() => setOpen(item.lastChange)}>
            Data: {data.toLocaleDateString('pt-BR', { timeZone: 'UTC' }) + " " + data.toLocaleTimeString()}
        </div>
        {open &&
            <div style={{ display: 'flex' }}>
                <div style={{ flex: 1 }}>
                    De:
                    <ObjectInspector data={item.de} expandLevel={1} sortObjectKeys={true} />

                </div>
                < div style={{ flex: 1 }}>
                    Para:
                    <ObjectInspector data={item.para} expandLevel={1} sortObjectKeys={true} />

                </div>
                {changeChildren &&
                    <div style={{ flex: 0 }}>
                        <Button variant='contained' color='primary' onClick={() => {
                            changeChildren(item.de.children);
                        }}>Restaurar "children" DE</Button>

                        <Button variant='contained' color='primary' onClick={() => {
                            changeChildren(item.para.children);
                        }}>Restaurar "children" PARA</Button>
                    </div>
                }
            </div>}
    </div >
}

export default function HistoricoRegistro({ colectionFirebase, uidItem, mapChange }) {
    const [expandido, setExpandido] = useState(null)
    const [colHistorico, setcolHistorico] = useState(null)
    const [listHistoricos, setListHistoricos] = useState(null)

    const limit = 5;

    console.log('HistoricoRegistro', colectionFirebase, uidItem)
    useEffect(() => {
        if (colectionFirebase && uidItem) {
            setcolHistorico(colectionFirebase + "/" + uidItem + "/Histórico")
        }
    }, [colectionFirebase, uidItem])



    useEffect(() => {
        let observer = null;
        console.log('colHistorico', colHistorico)
        if (colHistorico) {
            observer = createSnapCollectionProps(null, colHistorico, (r) => {
                console.log('r', r)

                setListHistoricos(r)
            }, (err) => {
                console.log('err', err)
                setListHistoricos([])
            }, {
                orderBy: 'lastChange',
                orderByDirection: 'desc',
                limite: limit
            }
            )
        }
        return () => {
            setListHistoricos([]);
            if (observer) observer()
        }

    }, [colHistorico])

    return (
        <div>
            <Button onClick={() => {
                let url = 'https://console.firebase.google.com/u/0/project/okappbeta/firestore/data/~2F'
                url += colHistorico.split("/").join('~2F')

                window.open(url);

            }}>Ver DB</Button>
            Histórico - Ultimos : {limit}
            {listHistoricos && listHistoricos.map((item, i) => <ItemHistorico key={i} item={item} open={expandido === item.lastChange} setOpen={setExpandido} mapChange={mapChange} />)}
        </div>
    )
}
