import React, { useState, useRef } from 'react'
import { Dialog, IconButton, makeStyles, Slide, Typography, Tooltip, Paper } from '@material-ui/core'

import AddIcon from '@material-ui/icons/Add';
import ListComponents from '../ListComponents';

import produce from "immer"
import { useOnKeyDownAlt, ajustNewOkComp } from 'OkApp/functions';
import { DialogAddEditComp } from 'OkApp';
const useStyles = makeStyles(theme => ({
    BackdropProps: {
        backgroundColor: "transparent"
    },
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        width: 250
    },
    title: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,

        display: 'flex',
        alignContent: 'center',
        padding: 5
    },

}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="right" ref={ref} {...props} />;
});


export default function MetaDialog({ input: { value, onChange }, openProps, okform, }) {
    const refAddCompDialog = useRef(null);

    const addComponet = (comp) => {
        setShow(false)
        let compAdd = ajustNewOkComp({ ...comp.configView.modelo }, okform);
        refAddCompDialog.current.showEditarComp(compAdd)
        // const nextState = produce(value || [], draftState => {

        //     let compAdd = ajustNewOkComp({ ...comp.configView.modelo }, okform);
        //     comp.uid = compAdd.uid
        //     draftState.push(compAdd);
        // })

        // onChange(nextState)
        // if (openProps)
        //     openProps(comp.uid);
    }

    const seveOnChange = (comp) => {
        const nextState = produce(value || [], draftState => {
            draftState.push(comp);
        })

        onChange(nextState)

    }
    const [show, setShow] = useState(false)
    const classes = useStyles();
    const handleShowAdd = () => setShow(b => !b);
    useOnKeyDownAlt('KeyA', handleShowAdd)

    return (
        <>
            <Tooltip title={"Adicionar Componente (Alt + A)"}>
                <IconButton size="small" style={{ width: "100%" }} onClick={() => { setShow(true) }}>
                    <AddIcon />
                </IconButton>
            </Tooltip>
            <Dialog open={show} onClose={() => { setShow(false) }}
                TransitionComponent={Transition}
                BackdropProps={{
                    classes: {
                        root: classes.BackdropProps
                    }
                }}
                PaperComponent={(props) => {
                    return <Paper style={{ position: 'absolute', left: 20, height: '100%', backgroundColor: "transparent", }} {...props} />
                }}

            >
                <div className={classes.title}>

                    <Typography variant="h6" className={classes.title}>Adicionar</Typography>
                </div>

                <div className={classes.root}>
                    <ListComponents addComponet={addComponet} />
                </div>
            </Dialog>
            <DialogAddEditComp ref={refAddCompDialog}
                okform={okform}
                updateComp={seveOnChange}
            // editComp={salvarEdicao}
            />
        </>
    )
}
