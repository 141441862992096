import React from 'react'
import OkCompAdapter from 'OkApp/components/OkCompAdapter'
import CompView from './components/CompView'
import EditorComp from './components/EditorComp'

const typeComponente = 'DiagramaDeEscopoComp'

const AdapterOkComp = (props) => {
    let propsComp = {}
    return <OkCompAdapter {...props} propsComp={propsComp} component={CompView} />
}

const modelo = {
    typeComponente,
    field: 'diagrama',
    label: 'Diagrama',   
    
}



const DiagramaComp = {
    typeComponente,
    configView: {
        grupo: 'OK Utils',
        nome: 'Diagrama de Escopo OkApp',
        descricao: 'Diagrama de Escopo OkApp',
        helpUrl: 'https://www.google.com.br/',
        modelo
    },
    Component: AdapterOkComp,
    CompEditor: EditorComp
   
}

export default DiagramaComp
