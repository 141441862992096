import React from 'react'
import { Field } from 'redux-form'
import { Input, IconSelector } from 'components';
import { useTranslation } from 'react-i18next';
import _ from 'lodash'
import OkFormAbaAdapter from 'OkApp/components/OkFormAbaAdapter';
import { ajusteRoute } from 'OkApp/context'


const metaVar = "meta.menu.";
const getValue = (chave, okForm) => {
    return _.get(okForm, metaVar + chave)
}



export function OkFormMetaAba(props) {
    const { t } = useTranslation();
    return <OkFormAbaAdapter keyTab={"Menu"} {...props}>
        <div style={{ display: 'flex' }}>
            <div style={{ flex: 1 }}>
                <Field name={metaVar + "icon"} label={t('project.menu.iconName')} component={IconSelector} />
            </div>
            <div style={{ flex: 1, padding: 5 }}>
                <Field name={metaVar + "text"} label={t('project.menu.text')} component={Input} />
            </div>
            <div style={{ flex: 1, padding: 5 }}>
                <Field name={metaVar + "index"} label={t('project.menu.index')} component={Input} />
            </div>
        </div>
        <div style={{ display: 'flex' }}>
            <div style={{ flex: 1 }}>
                <Field name={metaVar + "parentMenuIcon"} label={"Icone Pai"} component={IconSelector} />
            </div>
            <div style={{ flex: 1, padding: 5 }}>
                <Field name={metaVar + "parentMenu"} label={"Menu Pai"} component={Input} />
            </div>
            <div style={{ flex: 1, padding: 5 }}>
                <Field name={metaVar + "parentMenuIndex"} label={"Ordem Pai"} component={Input} />
            </div>
        </div>


    </OkFormAbaAdapter>
}

export function criaMenus(okForm, okApp, history) {
    let textMenu = getValue("text", okForm);
    let iconKey = getValue("icon", okForm);
    let index = getValue("index", okForm);
    let parentMenu = getValue("parentMenu", okForm);
    let parentMenuIcon = getValue("parentMenuIcon", okForm);
    let parentMenuIndex = getValue("parentMenuIndex", okForm);
    if (textMenu) {
        // let menuConfig = _.get(okForm, "meta.menu")
        return { text: textMenu, index:index||555555, parentMenu, parentMenuIcon, parentMenuIndex:parentMenuIndex||55555, action: () => history.push(ajusteRoute(okApp, okForm)), iconKey }
    }

}

export const data = { name: "MenuControl", }