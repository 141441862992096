import React, { useRef, useEffect, useCallback } from 'react'


import AceEditor from "react-ace";
import "ace-builds/webpack-resolver";
import "ace-builds/src-noconflict/mode-css";
import "ace-builds/src-noconflict/snippets/css";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-min-noconflict/ext-searchbox";
import "ace-builds/src-min-noconflict/ext-language_tools";
import {
    registerSnippets,
    createSnippets,
} from './ace-snippets-extension-simple'
import { InputLabel } from '@material-ui/core';

import _ from 'lodash'

import prettier from "prettier/standalone";
import babylon from "prettier/parser-babel";
// var beautify = require('js-beautify').js
export default function CodeEditor(props) {
    const refEditor = useRef(null)
    let theme = 'monokai';
    let mode = 'javascript'
    // let mode = 'jsx'
    let fontSize = 14

    const {
        input,
        label,
        startValue,
        useWorker,
        snippets,//ex: [ { name: 'build', code: 'console.log("build")' }]
        ...custom
    } = props

    useEffect(() => {
        if (refEditor && refEditor.current && refEditor.current.editor) {
            let editor = refEditor.current.editor;
            if (editor && snippets) {
                registerSnippets(//força snnipets javascript
                    editor,
                    editor.session,
                    mode,
                    createSnippets(snippets)
                )
            }

        }


    }, [mode, refEditor, snippets])
    let valueUsar = input.value
    if (startValue && !input.value) {
        valueUsar = startValue;
    }

    const { input: { value } } = props
    const formater = useCallback((v1, v2) => {

        let list = [];
        for (const key in value) {
            if (value.hasOwnProperty(key)) {
                const element = value[key];
                list.push(key + " : " + element)
            }
        }
        if (_.get(refEditor, "current.editor")) {
            let codeF = refEditor.current.editor.getValue()
            // let formated = beautify(codeF, { indent_size: 2, space_in_empty_paren: true, e4x: true, })
            const formated = prettier.format(codeF, {
                parser: "babel",
                plugins: [babylon]
            });
            input.onChange(formated)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [input])

    const commands = [{   // commands is array of key bindings.
        name: 'FormatCode', //name for the key binding
        bindKey: { win: 'Shift-Alt-f', mac: 'Shift-Alt-f' }, //key combination used for the command.
        exec: formater
    }]

    return (
        <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
            {label && <InputLabel>{label}</InputLabel>}
            <div style={{ flex: 1, position: 'relative' }}>

                <AceEditor
                    ref={refEditor}
                    theme={theme}
                    mode={mode}
                    fontSize={fontSize}
                    width={'100%'}
                    height={'100%'}
                    showGutter={true}
                    editorProps={{ $blockScrolling: true }}
                    setOptions={{
                        enableBasicAutocompletion: true,
                        enableLiveAutocompletion: true,
                        enableSnippets: true,
                        showLineNumbers: true,
                        useWorker: useWorker || true,
                        tabSize: 2,
                    }}
                    commands={commands}
                    {...input}
                    value={valueUsar}

                    {...custom}
                    onBlur={() => { if (input.onBlur) input.onBlur(props.input.value) }}
                />

            </div>
        </div>
    )
}
