import React from 'react'

import { getValueToPdfFunc2Comp } from '..'
import { Combobox, Input } from 'components'
import useCriaControlerField from '../../functionsUtil'

function PainelReportComp({ value, onChange, valueRef, removeLinha }) {
    const criaControler = useCriaControlerField(value, onChange)

    return (
        <div >
            <div style={{ flex: 1, display: 'flex' }}>

                <Combobox style={{ flex: 1, paddingRight: 5 }} {...criaControler("tipoView")} label="Tipo" itens={['painel', 'fieldset']} toStringItem={['Painel', 'Fieldset']} />
            </div>
            <div style={{ flex: 1, display: 'flex' }}>
                <div style={{ flex: 1 }}>
                    <Input label='Quebra em colunas. Quantidade: ' type='number' {...criaControler("maxColumns")} />
                </div>
                <Combobox style={{ flex: 1, paddingLeft: 5 }} {...criaControler("orientacaoPainel")} label="Orientação Visual dos Itens" itens={['vertical', 'horizontal']} toStringItem={['Vertical', 'Horizontal']} />


            </div>
        </div >

    )
}
async function convertToPdf(compConfig, json, meta) {
    const { lista, maxColumns } = compConfig
    let subListAux = lista;
    if (maxColumns) {

        subListAux = lista.length > maxColumns ? lista.slice(0, maxColumns) : lista
    }
    // let body = [compConfig.lista.map(async (c, i) => await getValueToPdfFunc2Comp(c, json))]




    let lbody = []
    for (let iii = 0; iii < compConfig.lista.length; iii++) {
        const c = compConfig.lista[iii];
        lbody.push(await getValueToPdfFunc2Comp(c, json, meta))
    }
    let body = [lbody];

    if (maxColumns && lista.length > maxColumns) {
        let nMax = parseInt(maxColumns)
        body = []
        let i = 0;
        let aux;
        while (i < lista.length) {
            aux = lista.slice(i, i + nMax);
            i = i + nMax;
            let linha = []
            for (let iii = 0; iii < aux.length; iii++) {
                const c = aux[iii];
                linha.push(await getValueToPdfFunc2Comp(c, json, meta))
            }
            // let linha = aux.map(async (c, i) => await getValueToPdfFunc2Comp(c, json))

            if (linha.length < nMax) {
                let falta = nMax - linha.length
                for (var i2 = 0; i2 < falta; i2++) {
                    linha.push({})
                }
            }
            body.push(linha)

        }

    }

    let defaultConfig = {
        style: 'tableDefault',       
        layout: 'noBorders',
        table: {
            body: body,
            widths: subListAux.map((c, i) => c.width ? isNaN(c.width) ? c.width : parseInt(c.width) : "*")
        }
    };

    if (compConfig.tipoView === 'fieldset') {
        return fieldset(compConfig.nome, defaultConfig)
    }
    return defaultConfig



}
export function fieldset(titulo, tabela) {

    return {
        style: 'tablefieldset',      
        table: {
            headerRows: 2,
            widths: [3, 250, '*'],
            heights: [5, 5, 'auto'],
            body: [
                [
                    {
                        text: '',
                        border: [false, false, false, false],
                    },
                    {
                        rowSpan: 2,
                        border: [true, true, true, true],
                        text: titulo,
                        style: 'fieldsetTitulo',
                    },
                    {

                        text: '',
                        border: [false, false, false, false],
                    }
                ],
                [
                    {
                        text: '',
                        border: [true, true, false, false],
                    },
                    {
                        text: '',
                        border: [false, false, false, false],
                    },
                    {
                        text: '',
                        border: [false, true, true, false],
                    }
                ],
                [
                    {
                        ...tabela,
                        colSpan: 3,
                        border: [true, false, true, true],

                        layout: 'noBorders'

                    }
                ]
                ,
            ]
        }
    }
}


const PainelReport = {
    typeComponent: "PainelReport",
    modelo: {
        nome: "Painel",
        typeComponent: "PainelReport",
        tipoView: 'painel',
        orientacaoPainel: 'horizontal',
        lista: []
    },
    Component: PainelReportComp,
    convertToPdfFunc: convertToPdf

}
export default PainelReport
