import produce from "immer"

export default function useOKControlerField(value, onChange) {
    const updateValue = (param, valueChange) => {
        let newValue = { ...value }
        newValue[param] = valueChange;
        const nextState = produce(value, draftState => {
            for (let key in newValue) {
                if (newValue.hasOwnProperty(key)) {
                    draftState[key] = newValue[key];
                }
            }
        })

        onChange(nextState)
    }
    const removeValue = (param) => {


        const nextState = produce(value, draftState => {

            if (value.hasOwnProperty(param)) {
                delete draftState[param]
            }

        })

        onChange(nextState)
    }
    const OKControler = (field) => {
        let valueVar = value[field]
        let onChange = (v) => {
            let realValue = ""
            if (v && (v.constructor.name === 'SyntheticEvent' || v.target)) {
                realValue = v.target.value
            } else {
                realValue = v
            }
            if (realValue === "") {
                removeValue(field)
            } else {
                updateValue(field, isNaN(realValue) ? realValue : parseFloat(realValue))
            }
        }

        return { value: valueVar, onChange }
    }
    return OKControler
}