import React, { useEffect, useState } from 'react'
import { useCrudContext } from 'crud'

import _ from 'lodash'
import './style.css'
import { useListOkApp } from 'OkApp/UseUtils';

import { ContentLoading } from 'components';

import ViewFiltroProjetos from 'routes/private/components/ViewFiltroProjetos';
import DialogCopiarProjeto from './components/DialogCopiarProjeto';


export default function ConsultaProjeto() {

    const [projetoCopiar, setProjetoCopiar] = useState(null)
    const { setTitle } = useCrudContext();
    const [projetosFilter, setProjetosFilter] = useState(null)

    const projetos = useListOkApp();
    // const okapp = useOkApp();
    useEffect(() => {
        setTitle("Copiar Projeto")
        return () => setTitle("")

    }, [setTitle])

    // let colectionFirebase = 'Project/' + okapp.uid + '/CustomForm';

    useEffect(() => {
        if (projetos) {
            setProjetosFilter(projetos.filter((p) => {
                let modelo = _.get(p, "modelo");
                if (!modelo) {
                    return false;
                }
                // return publicp;
                return true
            }))
        } else {
            setProjetosFilter(null)
        }

    }, [projetos])

    const handleClickOpen = (p) => {
        setProjetoCopiar(p);
    };

    const handleClose = () => {

        setProjetoCopiar(null)
    };

    return (
        <div className="projectsRootCopia">
            {!projetosFilter && <div className="projectContainer">
                <div style={{ margin: 10 }}>
                    <ContentLoading style={{ height: 100, width: 100 }} />
                </div>
                <div className="projectData">
                    <ContentLoading style={{ height: 18, width: 250 }} />
                    <div className="projectDescription">  <ContentLoading style={{ height: 12, width: 100 }} /></div>

                </div>
            </div>}
            {projetosFilter && <ViewFiltroProjetos projetos={projetosFilter} actionCopiar={handleClickOpen} />}
            <DialogCopiarProjeto open={projetoCopiar ? true : false}
                handleClose={handleClose} projeto={projetoCopiar} />
            {/* <Dialog
                open={projetoCopiar ? true : false}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Copiar projeto"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Deseja realmente copiar os formularios do projeto: {projetoCopiar ? projetoCopiar.name : ""}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">Não</Button>
                    <Button onClick={() => { handleClose(); copiarForms(projetoCopiar) }} variant='contained' color="primary" autoFocus>
                        Sim, copiar
                    </Button>
                </DialogActions>
            </Dialog> */}
            {(projetosFilter && !projetosFilter.length) && <div className="projectContainer" >

                <div className="projectData">
                    <strong className="projectName" style={{ textAlign: "center" }}>Nenhum projeto aqui.</strong>

                </div>
            </div>
            }
        </div >
    )
}
