import React from 'react'
import ReactDOM from 'react-dom'
import App from './app'
import '@fortawesome/fontawesome-free/js/all.js';
import './i18n';

const startApp = () => {
    console.log('startApp')
    ReactDOM.render(<App />, document.getElementById('root'));
};

if (window.cordova) {
    console.log('startApp cordova')
    document.addEventListener('deviceready', startApp, false);
} else {
    startApp();
}