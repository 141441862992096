import React from 'react'

import { Typography, Checkbox, FormControlLabel, InputLabel } from '@material-ui/core';

import produce from "immer"
import "./style.css"
import _ from 'lodash'


function RenderUser({ grupo, listPermissions, value, onChange }) {

    const updatePermissao = (strPermissao, bol) => {
        const nextState = produce(value || {}, draftState => {
            let novaPermicao = {}
            if (_.isObject(draftState[grupo])) {
                novaPermicao = draftState[grupo]
            }
            novaPermicao[strPermissao] = bol;

            draftState[grupo] = novaPermicao;
        })
        onChange(nextState)
    }
    let permiChaves = {}
    if (_.isObject(value[grupo])) {
        permiChaves = value[grupo];
    }
    let exibeListaPerm = (listPermissions && listPermissions.length) ? true : false
    return <div className={"RenderUser"}>

        <div style={{ flex: 1 }}>
            <Typography variant="h6">{grupo}</Typography>
        </div>
        {exibeListaPerm && <div style={{ display: "flex", flexDirection: 'column', flex: 1 }}>
            <InputLabel>Permissoes</InputLabel>
            {listPermissions.map((p, i) => {
                return <FormControlLabel
                    key={i}
                    control={<Checkbox
                        checked={permiChaves[p] || false}
                        onChange={evt => {
                            updatePermissao(p, evt.target.checked)
                        }}
                        name={p}
                        color="primary"
                    />}
                    label={p}
                />
            })}
        </div>
        }


    </div>

}

export default function SeletorPerfis({ label, listPermissions, gruposPermissao, input: { value, onChange } }) {


    let valueUsar = value || {};
    if (!value) {
        gruposPermissao.forEach(element => {
            valueUsar[element] = {};
        });
    }
    return (<div>
            <Typography>{label}</Typography>
        <div className={"UsersList"}>
            {gruposPermissao.map((g, index) => <RenderUser key={index} grupo={g} value={valueUsar} onChange={onChange} listPermissions={listPermissions} />)}
        </div>
    </div>

    )
}
