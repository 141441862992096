import React, { Component } from 'react'
import { Dialog, AppBar, Toolbar, IconButton, Typography, Slide, withStyles, Button, makeStyles } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import ListComponents from 'routes/dinamic/components/CustomFormDeveloper/componentes/ListComponents';
import EditorComp from 'routes/dinamic/components/CustomFormDeveloper/componentes/EditorComp';
import { ajustNewOkComp, getOkCompCore } from 'OkApp/functions';

import _ from 'lodash'




const styles = (theme => ({
    dialog: {
        position: 'absolute',
        right: 0,
        top: 0,
        margin: 0,

    },
    dialogv: {
        position: 'absolute',
        right: 0,
        top: 0,
        margin: 0,
        width: 300,
        height: '100%'

    },
    BackdropProps: {
        backgroundColor: "transparent"
    },
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        height: 400,

    },

    rootv: {
        // flexGrow: 1,
        // backgroundColor: theme.palette.background.paper,
        // display: 'flex',
        // height: 400,
        width: 300,

    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

// const propsStyleDialog = {fullScreen:true}
const propsStyleDialog = {

    sx: {
        "& .MuiDialog-container": {
            justifyContent: "flex-start",
            backgroundColor: "#F00"

        },
        "& .MuiPaper-root": {
            maxWidth: 300,

        }
    }

}

class DialogAddEditComp extends Component {
    constructor(props) {
        super(props)
        this.state = { compEditar: null, showDialogAdd: false, showDialogEdit: false, actions: [], soEditando: false }
        this.showAddComp = this.showAddComp.bind(this)
        this.hideAddComp = this.hideAddComp.bind(this)
        this.hideEditarComp = this.hideEditarComp.bind(this)
    }


    showAddComp() {
        this.setState({ showDialogAdd: true, soEditando: false })
    }
    hideAddComp() {
        this.setState({ showDialogAdd: false, soEditando: false })
    }

    showEditarComp(comp) {
        this.setState({ compEditar: comp, showDialogAdd: false, showDialogEdit: true, soEditando: true })
    }

    hideEditarComp() {
        this.setState({ showDialogEdit: false, soEditando: false })
    }
    render() {

        const { compEditar, showDialogAdd, showDialogEdit, soEditando, actions } = this.state
        const { okform, title, classes, updateComp, editComp, notInForm } = this.props



        const setActions = (acts) => {
            this.setState({ actions: acts })
        }
        const selecionouComp = (compSelCore) => {

            let compAdd = ajustNewOkComp({ ...compSelCore.configView.modelo }, okform);
            this.setState({ compEditar: compAdd, showDialogAdd: false, showDialogEdit: true })
        }

        const editouComp = (compPronto) => {

            if (!compPronto.uid) {
                compPronto.uid = Math.random().toString(36).substring(7)
            }
            if (editComp && soEditando) {
                editComp(compPronto);
            } else {
                updateComp(compPronto);
            }
            this.hideEditarComp()
        }


        let dialogPropsOver = {};
        let configsComp = {}

        if (compEditar) {
            configsComp = getOkCompCore(compEditar.typeComponente);

            if (_.get(configsComp, "editorProps.dialogProps")) {
                dialogPropsOver = _.get(configsComp, "editorProps.dialogProps")
            }

        }
        let fullScreenEditDialog = configsComp.editorFullScreen === undefined ? true : configsComp.editorFullScreen

        return (
            <>
                <Dialog fullScreen open={showDialogAdd} onClose={this.hideAddComp} TransitionComponent={Transition}>
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={this.hideAddComp} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" className={classes.title}>
                                {title || "Adicionar Componente"}
                            </Typography>

                        </Toolbar>
                    </AppBar>
                    <div>
                        <ListComponents addComponet={selecionouComp} />
                    </div>
                </Dialog>
                {compEditar &&
                    <Dialog fullScreen={fullScreenEditDialog} {...propsStyleDialog} open={showDialogEdit}
                        onClose={this.hideEditarComp}
                        TransitionComponent={Transition}
                        {...dialogPropsOver}
                        classes={{
                            paper: fullScreenEditDialog ? classes.dialog : classes.dialogv
                        }}

                        BackdropProps={{
                            classes: {
                                root: classes.BackdropProps
                            }
                        }}
                    >
                        <AppBar className={classes.appBar}>
                            {fullScreenEditDialog ?

                                <Toolbar>
                                    <IconButton edge="start" color="inherit" onClick={this.hideEditarComp} aria-label="close">
                                        <CloseIcon />
                                    </IconButton>
                                    <Typography variant="h6" className={classes.title}>
                                        Editando Componente : {configsComp.configView.nome} - {compEditar.label}
                                    </Typography>
                                    {actions.map((a, i) => <Button key={i} autoFocus color="inherit" onClick={a.action}>
                                        {a.text}
                                    </Button>)}
                                </Toolbar>
                                :

                                <Toolbar>
                                    <div>

                                        <Typography variant="button" className={classes.title}>
                                            Editando :
                                        </Typography>
                                        <Typography variant="button" className={classes.title}>
                                            {configsComp.configView.nome}
                                        </Typography>
                                        <div style={{ display: 'flex', padding: 5 }}>
                                            <Button style={{ flex: 1 }} color="inherit" onClick={this.hideEditarComp} aria-label="close">                                                
                                                Cancelar
                                            </Button>
                                            {actions.map((a, i) => <Button key={i} style={{ flex: 1 }} autoFocus color="secondary" variant='contained' onClick={a.action}>
                                                {a.text}
                                            </Button>)}
                                        </div>
                                    </div>
                                </Toolbar>
                            }
                        </AppBar>
                        <div >

                        </div>
                        <div className={fullScreenEditDialog ? classes.root : classes.rootv}>
                            <EditorComp form={"DialogEA" + compEditar.uid} fullScreenEditDialog={fullScreenEditDialog} okComp={compEditar} okform={okform} setactions={setActions} salvarEdicao={editouComp} notInForm={notInForm} configsComp={configsComp} />
                        </div>
                    </Dialog>


                }


            </>
        )
    }
}

export default withStyles(styles)(DialogAddEditComp);