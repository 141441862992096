import React, { useState } from 'react'
import { TextField, Fab } from '@material-ui/core'

import SearchIcon from '@material-ui/icons/Search';
import PesquisaOkForm from 'routes/dinamic/components/CustomFormDeveloper/componentes/PesquisaOkForm';


export default function SeletorUnicoPorPesquisa(props) {
    const { input, label, tituloPesquisa } = props
    const [showPesquisa, setShowPesquisa] = useState(false)


    const toStringValue = v => {
        return v ? v.name + " - " + v.uid : ""
    }

    const adapterSelectedValue = f => {
        
        return f ? { uid: f.uid, name: f.name, route: f.route, meta: f.meta, initialValues: f.initialValues, metaOkApp: f.metaOkApp } : null
    }

    return (
        <div style={{ display: 'flex', marginBottom: 15 }}>
            <TextField
                value={toStringValue(input.value)}
                label={label}
                fullWidth
                InputLabelProps={{
                    shrink: true,
                    readOnly: true,
                }}
            />
            <Fab size="small" color="primary" onClick={() => { setShowPesquisa(true) }}>
                <SearchIcon />
            </Fab>

            <PesquisaOkForm openPesquisa={showPesquisa} setOpenPesquisa={setShowPesquisa}
                titulo={tituloPesquisa}
                onSelect={(f) => {
                    input.onChange(adapterSelectedValue(f))
                }} />

        </div>
    )
}
