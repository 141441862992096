
import React, { useState } from 'react'
import {  Button, Dialog, Fab, IconButton, InputLabel, TextField,  Typography } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import OkStyleEditor from 'components/OkStyleEditor'
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';


let defaultStyle = {

    cellTitulo: {
        marginBottom: 1,
        marginLeft: 1,
        marginRight: 1,
        marginTop: 1,

        fontSize: 9,
        bold: true,
        fillColor: '#dcdcdc',
    },
    cellValor: {
        marginBottom: 1,
        marginLeft: 1,
        marginRight: 1,
        marginTop: 1,
        fontSize: 9,
        fillColor: '#f0f0f0',
    },
    cellLimpo: {
        marginBottom: 1,
        marginLeft: 1,
        marginRight: 1,
        marginTop: 1,
        fontSize: 9,
    },
    fieldsetTitulo: {

        marginBottom: 2,
        marginLeft: 2,
        marginRight: 2,
        marginTop: 2,
        fontSize: 11,
        bold: true,
        fillColor: '#285137',
        color: '#ffffff'
    },
    relatorioTitulo: {
        fontSize: 20,
        bold: true,
    },
    cabecalhoTexto1: {
        fontSize: 18,
        bold: true,
    },
    cabecalhoTexto2: {
        fontSize: 12,
        bold: false,

    },
}

const ShowStyle = ({ name, confStyle, value, onChange }) => {
    const [exib, setExib] = useState(false)
    const removePx = []

    const convetJssToPdfMakeStyle = (jss) => {
        let stylePdf = {}
        for (var key in jss) {
            var v = jss[key];


            if (key === 'backgroundColor') {
                stylePdf['fillColor'] = v


            } else if (key === 'fontStyle') {
                if (v === "italic") {
                    stylePdf.italics = true;

                } else {
                    stylePdf.italics = false;
                }

            } else if (key === 'fontWeight') {
                if (v) {
                    stylePdf.bold = true;

                }

            } else if (removePx.includes(key)) {
                stylePdf[key] = parseFloat(v.replace("px"))
            } else {
                stylePdf[key] = v
            }
        }
        return stylePdf
    }

    const convetPdfMakeStyleToJss = (jss) => {

        let stylePdf = {}
        for (var key in jss) {
            var v = jss[key];


            if (key === 'fillColor') {
                stylePdf['backgroundColor'] = v
            } else if (key === 'italics') {

                if (v) {
                    stylePdf.fontStyle = 'italic'
                } else {
                    stylePdf.fontStyle = 'normal'
                }
            } else if (key === 'bold') {

                if (v) {
                    stylePdf.fontWeight = '700'
                }
            } else if (removePx.includes(key)) {
                stylePdf[key] = v + "px"
            } else {
                stylePdf[key] = v
            }
        }

        return stylePdf
    }

    const removeThis = () => {
        let newV = { ...value }
        delete newV[name]
        onChange(newV)
    }

    return <div className='StyleRelatorioEditor-ShowStyle-container'>
        <div className='StyleRelatorioEditor-ShowStyle-containerTitle'>
            <Fab color='primary' size='small'
                style={{ marginRight: 10, }}
                onClick={() => {
                    setExib(!exib);
                }}>
                {!exib ? <ExpandMoreIcon /> : <ExpandLess />}
            </Fab>
            <Typography variant="h6" style={{ flex: 1 }}>{name}</Typography>
            <div className='StyleRelatorioEditor-ShowStyle-containerTitleHide'>
                <Fab color='primary' size='small' onClick={removeThis} ><DeleteIcon /></Fab>
            </div>

        </div>
        <div style={{ display: exib ? "block" : "none" }} className='StyleRelatorioEditor-ShowStyle-containerStyles'>
            <OkStyleEditor
                validComponents={[
                    'backgroundColor',
                    'margin',
                    // 'border',                   
                    'font',
                ]}
                value={convetPdfMakeStyleToJss(confStyle)}
                onChange={v => {
                    let newV = { ...value }
                    newV[name] = convetJssToPdfMakeStyle(v)
                    onChange(newV)
                }} />

            <div className='StyleRelatorioEditor-comp'>
                <InputLabel>Alignment</InputLabel>
                <div className='StyleRelatorioEditor-row'>
                    <button className={value[name].alignment === 'left' ? 'StyleRelatorioEditor-buttonSelected' : ''}
                        onClick={() => {
                            let newV = { ...value }
                            newV[name] = { ...newV[name], alignment: "left" }
                            onChange(newV)
                        }}
                    >left</button>
                    <button className={value[name].alignment === 'center' ? 'StyleRelatorioEditor-buttonSelected' : ''}
                        onClick={() => {
                            let newV = { ...value }
                            newV[name] = { ...newV[name], alignment: "center" }
                            onChange(newV)
                        }}
                    >center</button>
                    <button className={value[name].alignment === 'right' ? 'StyleRelatorioEditor-buttonSelected' : ''}
                        onClick={() => {
                            let newV = { ...value }
                            newV[name] = { ...newV[name], alignment: "right" }
                            onChange(newV)
                        }}
                    >right</button>
                    <button className={value[name].alignment === 'justify' ? 'StyleRelatorioEditor-buttonSelected' : ''}
                        onClick={() => {
                            let newV = { ...value }
                            newV[name] = { ...newV[name], alignment: "justify" }
                            onChange(newV)
                        }}
                    >justify</button>
                </div>

            </div>

        </div>

    </div >

}
export default function SlyleRelatorioEditor(props) {
    const { input: { value, onChange } } = props
    const [show, setShow2] = useState(false)
    const [textNew, setTextNew] = useState('')
    const [textNewError, setTextNewErrow] = useState('')
    let arrayStyle = []


    const setShow = (b) => {
        setTextNew("")
        setTextNewErrow("")
        setShow2(b);
    }

    if (value) {

        for (var key in value) {
            var v = value[key];
            arrayStyle.push(<ShowStyle key={key} name={key} confStyle={v} value={value} onChange={onChange} />)
        }
    }


    return (

        <div>
            <div style={{ display: 'flex',gap:5 }}>


                <Typography variant="h6" style={{ flex: 1 }}>Estilos</Typography>
                <Button variant='contained' color='primary' onClick={() => { onChange(defaultStyle) }}>Resetar</Button>
                <Button variant='contained' color='primary' onClick={() => {
                    setShow(true);
                    // let v = value ? { ...value } : {}

                    // v["Teste"] = {}
                    // console.log('v', v)
                    // onChange(v)
                }}>Novo</Button>
            </div>
            <Dialog open={show} onClose={() => { setShow(false) }}
                PaperProps={{ style: { height: 'auto', flex: 1 } }}>
                <div className="StyleRelatorioEditor-dialog-appBar" >
                    <IconButton edge="start" color="inherit" onClick={() => { setShow(false) }} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={"StyleRelatorioEditor-dialog-title"} >
                        Novo Estilo
                    </Typography>
                    <Button autoFocus color="inherit" onClick={() => {
                        let jaTem = false
                        for (const key in value) {
                            if (key.toLowerCase() === textNew.toLowerCase()) {
                                jaTem = true;
                                break;
                            }
                        }
                        if (!textNew) {
                            setTextNewErrow("Informe um nome")
                        } else if (jaTem) {
                            setTextNewErrow("Já existe estilo com este nome")
                        } else {
                            let v = value ? { ...value } : {}
                            v[textNew] = {}
                            onChange(v)
                            setShow(false);
                        }



                    }}>
                        OK
                    </Button>
                </div>
                <div className={"StyleRelatorioEditor-dialog-root"}>
                    <TextField
                        label="Nome"
                        fullWidth
                        error={(textNewError) ? true : false}
                        helperText={textNewError}
                        value={textNew}
                        onChange={etv => setTextNew(etv.target.value)}
                    />
                </div>
            </Dialog>


            {arrayStyle}


        </div>
    )
}
