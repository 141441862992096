import React from 'react'
import OkCompAdapter from 'OkApp/components/OkCompAdapter'
import CompView from './components/CompView'

import {  LabelInputAuto, OkTypedComponent } from 'OkApp'
const typeComponente = 'ChecklistComp'

const EditorOkComp = (props) => {

    return <>
        <OkTypedComponent typeComponente='InputComp' field='field' label='Nome Salvo' />
        <LabelInputAuto {...props} />
    </>
}

const AdapterOkComp = (props) => {
    let propsComp = {realProps: props}
    return <OkCompAdapter {...props} propsComp={propsComp} component={CompView} />
}

const modelo = {
    typeComponente,
    field: 'checklist',
    label: 'Checklist',
    children: [],
}


const ChecklistComp = {
    typeComponente,
    // status: 'Desenvolvimento',
    configView: {
        grupo: 'Avançado',
        nome: 'Checklist',
        descricao: 'ChecklistComp',
        helpUrl: 'https://okapp.com.br/app/okappdocs/checklist',
        modelo
    },
    Component: AdapterOkComp,
    CompEditor: EditorOkComp,
}


export default ChecklistComp;