import * as DinamicComponents from 'OkApp/DinamicComponents';
import { ModulesOkComps } from './modules';
export { default as PesquisaOkFormObj } from './modules/DefaultPesquisa/components/PesquisaOkFormObj';
export { default as OkCompAdapter } from './components/OkCompAdapter'
export { default as RenderOkForm } from './components/RenderOkForm'
export { default as OkTypedComponent } from './components/OkTypedComponent'
export { default as DialogAddEditComp } from './components/DialogAddEditComp'
export { default as LabelInputAuto } from './components/LabelInputAuto'
export { default as HistoricoRegistro } from './components/HistoricoRegistro'


export { default as OkModules } from './modules'
export { OkModulesConnect } from './modules'

export { componetIteratorOkForm, getOkCompCore } from './functions'

var AllDinamicComponents = {};
const AuxDinamicComponentsList = () => {
    AllDinamicComponents = {};
    let dd = []
    for (const key in DinamicComponents) {
        const c = DinamicComponents[key];
        dd.push(c)
        AllDinamicComponents[key] = c;
    }
    let moduleComps = ModulesOkComps();
    moduleComps.forEach(c => {
        AllDinamicComponents[c.typeComponente] = c;
    });
    
    dd = dd.concat(moduleComps)
    return dd
};

export const DinamicComponentsList = AuxDinamicComponentsList();

export function getDinamicComponent(type) {
    return AllDinamicComponents[type];
}