import { Typography } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import './style.css'

export default function AboutApp(props) {
    // const { location } = props;
    // const okapp = location.state;
    // console.log('props ', okapp);
    return (
        <div className={'container'}>
            <Typography variant={'h6'}>OkApp</Typography>
            {/* <div dangerouslySetInnerHTML={{ __html: okapp.descricaoDetalhada }} /> */}
            <div className={'links'}>
                <Link to={'/TermosUso'}><p>Termos de Uso</p></Link>
                <Link to={'/PoliticasPrivacidade'}><p>Políticas de Privacidade</p></Link>
            </div>
        </div>
    )
}