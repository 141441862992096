import * as React from "react";

import useAudio from "./useAudio";

import { Pause, PlayArrow } from "@material-ui/icons";
import { Fab } from "@material-ui/core";
import "./style.css"
function AudioPlayer({ url }) {
    const [audioElement, audioProps] = useAudio(url);
    function formatTime(seconds) {
        if (!seconds) {

            seconds = 0
        }
        return [Math.floor(seconds / 60), Math.floor(seconds % 60)]
            .map(x => x.toString())
            .map(x => (x.length === 1 ? `0${x}` : x))
            .join(":");
    }
    let disabled = !url || audioProps.isLoading
    return (
        <div className="audio-player">
            {audioElement}

            <div className="controls"  >
                <div onClick={disabled ? () => { } : audioProps.togglePlaybackStatus} className="playback-button">
                    {audioProps.playbackStatus === "play" ? <Fab size='small' color='secondary'><Pause /></Fab> : <Fab size='small' color='primary' disabled={disabled} ><PlayArrow /></Fab>}
                </div>
                <div>{formatTime(audioProps.currentTime)} / {formatTime(audioProps.duration)}</div>
            </div>

        </div>
    );
}

export default AudioPlayer;
