import React from 'react'
import OkTypedComponent from 'OkApp/components/OkTypedComponent'
import LabelInputAuto from 'OkApp/components/LabelInputAuto'

export default function EditorComp(props) {

    return <>
        <OkTypedComponent typeComponente='InputComp' field='field' label='Nome Salvo' />
        <LabelInputAuto {...props} />
        <OkTypedComponent typeComponente='ComboBoxComp' field='type' label='Tipo' disabled options={["poligon", "point"]} toStringItem={(v) => {
            switch (v) {
                case "poligon": return "Poligono"
                case "point": return "Ponto"
                default: return "poligon";
            }
        }} />
        <OkTypedComponent typeComponente='InputComp' field='fieldAreaCalc' label='Nome Salvo Área Calculada' />
        <OkTypedComponent typeComponente='InputComp' type='number' field='minHeight' label='minHeight' />

    </>
}

