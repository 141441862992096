import { Button, CircularProgress, Typography } from '@material-ui/core';
import firebaseUtil from 'firebaseUtil';
import { OkTypedComponent } from 'OkApp'
import { useAccountStripe, useProductsStripe } from 'OkApp/StripeUtils';
import { useAtualValues } from 'OkApp/UseUtils';
import React, { useEffect, useState } from 'react'
import _ from 'lodash'

const servcreateAccountStripe = firebaseUtil.funcServcreateAccountStripe();
const servCreateAccountLinkStripe = firebaseUtil.funcServCreateAccountLinkStripe();

export default function AssinaturasForm({ isAdmin }) {
    const [loadingStripe, setLoadingStripe] = useState(false)
    const [textWarnSripeAccont, setTextWarnSripeAccont] = useState("Configurar sua conta no Stripe")
    const okappUid = useAtualValues('uid');
    const gruposPermissao = useAtualValues('gruposPermissao');
    const stripeAccountId = useAtualValues('stripe.connectAccountId');

    const produtosStripe = useProductsStripe(stripeAccountId)
    const accountStripe = useAccountStripe(stripeAccountId);
    // console.log('accountStripe', accountStripe)
    // console.log('produtosStripe', produtosStripe)

    let temCompletarConta = _.get(accountStripe, 'requirements.currently_due.length') ? true : false
    useEffect(() => {
        if (accountStripe) {
            if (accountStripe.requirements) {
                // console.log('accountStripe.requirements', accountStripe.requirements)
                if (accountStripe.requirements.currently_due && accountStripe.requirements.currently_due.length) {
                    setTextWarnSripeAccont("Complete o cadastro de sua conta")
                }
            }
        }
    }, [accountStripe])

    const redirectToProduts = () => {
        const urlDash = "https://dashboard.stripe.com/products?active=true"
        window.open(urlDash, "_blank");
    }
    const criaConta = () => {
        let conta = {
            type: 'standard',
            metadata: {
                okappUid: okappUid
            }

        }
        console.log('conta', conta)
        setLoadingStripe(true)
        servcreateAccountStripe(conta).then(reConta => {

            console.log('reConta', reConta.data)
            criaContaLink(reConta.data.id)
            // reConta.data
        })
    }
    const criaContaLink = (idUsar) => {
        let url_atual = window.location.href;

        if (!url_atual.includes("&tab=planos")) {
            url_atual = url_atual + "&tab";
        }
        console.log('url_atual', url_atual)
        let contaLink = {
            account: idUsar || stripeAccountId,
            refresh_url: url_atual,
            return_url: url_atual,
            type: 'account_onboarding',


        }
        console.log('contaLink', contaLink)
        setLoadingStripe(true)
        servCreateAccountLinkStripe(contaLink).then(REcontaLink => {
            console.log('REcontaLink', REcontaLink)
            let url = REcontaLink.data.url;
            window.location.replace(url);
            // setLoadingStripe(false)
            // reConta.data
        })
    }
    if (!okappUid) {
        return <Typography variant='h6'>Salve o projeto primeiro</Typography>
    }

    return (
        <div>
            <Typography variant='h6'>Planos de Assinatura</Typography>
            <div style={{ display: 'flex' }}>
                <OkTypedComponent typeComponente='InputComp' field='stripe.connectAccountId' label={"ID da Conta Conectada"} disabled />

                <div>

                    {(!stripeAccountId || accountStripe === undefined) && <Button variant='contained' color='primary' disabled={loadingStripe} onClick={criaConta}>{loadingStripe ? <CircularProgress /> : "Criar Conta No Stripe"}</Button>}

                    {(accountStripe && (!accountStripe.email || temCompletarConta)) && <Button variant='contained' color='primary' disabled={loadingStripe} onClick={() => criaContaLink()}>{loadingStripe ? <CircularProgress /> : textWarnSripeAccont}</Button>}
                </div>
            </div>
            {(stripeAccountId && accountStripe === null) && <Typography variant='h6'>Carregando...</Typography>}
            {(!stripeAccountId || accountStripe === undefined) && <Typography variant='h6'>Cria uma conta Conectada no Stripe</Typography>}
            {(stripeAccountId && accountStripe === undefined) && <div >Conta Conectada não encontrada</div>}
            {(accountStripe) &&
                <>
                    {(!produtosStripe || !produtosStripe.length) ?
                        <div>
                            <Typography variant='h6'>Crie produtos recorrentes na sua conta Stripe para configuralos como assinatura</Typography>
                            <Button variant='contained' color='primary' onClick={redirectToProduts}>Criar Produtos</Button>
                        </div>
                        :
                        <>
                            <Button variant='contained' color='primary' onClick={redirectToProduts}>{!produtosStripe.length ? "Criar Produtos" : "Edite os Produtos no Stripe"}</Button>
                            <OkTypedComponent typeComponente='TabelaOkComp' field='stripe.assinaturas' label='Assinaturas'
                                exibeLabel={true}
                                permiteRemover={true}
                                permiteMover={true}
                                exibeAdd={true}
                                colunas={[
                                    { field: "product", label: "Produto Stripe", typeComponente: 'ComboBoxComp', options: produtosStripe, toStringItem: p => p.name },
                                    // { field: "descricao", label: "Descrição", typeComponente: 'InputComp', multiline: true },
                                    { field: "okGrupoPermissao", label: "Grupo de permissão", typeComponente: 'ComboBoxComp', options: gruposPermissao },
                                ]}
                            />
                        </>
                    }
                </>
            }

        </div>
    )
}
