import React, { useEffect, useState } from 'react'
import { useAtualValues, useOkForm, useOkObjListSnap } from 'OkApp/UseUtils'
import _ from 'lodash'

export default function CompView(props) {

    const { input, label, auxOkForm, developer, funcGetCompareMap, limitResultados } = props
    const [mapFilter, setMapFilter] = useState({})
    const atualValues = useAtualValues();
    if (funcGetCompareMap) {
        try {

            let newMap = funcGetCompareMap({ ...props.okfunctions, atualValues })
            if (!_.isEqual(mapFilter, newMap)) {
                setMapFilter(newMap)
            }
        } catch (error) {
            console.log('GetCompareMap erro', error)
            // setMapFilter(null)

        }
    }

    let auxOkFormFull = useOkForm(auxOkForm ? auxOkForm.uid : null)
    let limit = limitResultados ? parseInt(limitResultados) : 25;

    const lista = useOkObjListSnap(auxOkFormFull, mapFilter, limit, mapFilter != null);
    const updateField = () => {
        if (mapFilter != null) {
            input.onChange(lista)
        } else {
            // input.onChange(lista)
        }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(updateField, [lista])
    return (<>
        {developer && <div style={{ height: 40, width: '100%', backgroundColor: "#ff0", display: 'flex', justifyContent: "center", alignItems: "center" }}>FILTRO EM ELABORAÇÃO: {label}</div>}
        {(lista && limit === lista.length) && <div>Resultado limitado a {limit} registros. Filtre a consulta. </div>}

    </>
    )
}
