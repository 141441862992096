import React from 'react'
import FlexSessionEditor from './components/FlexSessionEditor';
import "./style.css"

export default function OkStyleEditor({ value, onChange, validComponents }) {

    const styleAtual = value;
    let isFlex = styleAtual.display ? styleAtual.display === "flex" : true
    return (
        <div>            
            {isFlex && <FlexSessionEditor styleAtual={styleAtual} value={styleAtual} onChange={onChange} validComponents={validComponents} />}
        </div>
    )
}
