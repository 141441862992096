
import React from 'react'

import OkTypedComponent from 'OkApp/components/OkTypedComponent';
import OkCompAdapter from 'OkApp/components/OkCompAdapter';
import LabelInputAuto from 'OkApp/components/LabelInputAuto';
import { Checkbox, FormControlLabel } from '@material-ui/core';
const typeComponente = "CheckboxComp";

const modelo = {
  typeComponente,
  field: "cancelado",
  label: "Cancelado",

}
const AdapterOkComp = (props) => {
  const {okComp} = props;
  let propsComp = {};
  return <OkCompAdapter {...props} propsComp={propsComp} component={(p) => <FormControlLabel
    control={<Checkbox disabled={okComp.disabled} checked={p.input.value ? true : false} onChange={p.input.onChange} />}
    label={p.label}
  />} />
}
const EditorOkComp = (props) => {
  return <>
    <OkTypedComponent typeComponente='InputComp' field='field' label='Nome Salvo' />
    <LabelInputAuto {...props} />
  </>
}

const CheckboxComp = {
  typeComponente,
  status: 'Concluído',
  configView: {
    grupo: "Básico",
    nome: "Checkbox",
    descricao: "Checkbox",
    helpUrl: "https://okapp.com.br/app/okappdocs/checkboxcomp",
    modelo
  },
  Component: AdapterOkComp,
  CompEditor: EditorOkComp
}

export default CheckboxComp;