import React, { useEffect } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import ArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { Menu } from "@material-ui/core";

function SubMenuItem(props) {
    const { parentAnchorEl } = props
    const [anchorEl, setAnchorEl] = React.useState(null);

    useEffect(() => {      
        if (!parentAnchorEl) {
            setAnchorEl(null)
        }
    }, [parentAnchorEl])

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleItemClick = (event) => {
        if (!anchorEl) {
            setAnchorEl(event.currentTarget);
        }
    }

    const { label, children } = props;
    return (
        <React.Fragment>
            <MenuItem
                onClick={handleItemClick}
                style={
                    {
                        display: "flex",
                        justifyContent: "space-between"
                    }
                }
            >
                {label}
                <ArrowRightIcon />
            </MenuItem>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >

                {children}
            </Menu>
        </React.Fragment>
    );
}


export default SubMenuItem;
