import React, { memo } from 'react'
import { Route } from 'react-router-dom'

function RoutesMap({ routes }) {

    return (
        <>
            {routes.map((route, index) => <Route key={"Route-" + index} {...route} />)}
        </>
    )
}

export default memo(RoutesMap)
