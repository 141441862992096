import React from 'react'
import OkFormAbaAdapter from 'OkApp/components/OkFormAbaAdapter';
import { OkTypedComponent } from 'OkApp';
import AnaliseApp from './components/AnaliseApp';
import { isAuthenticated, useIsAdmin } from 'useAuth';
import { permiteEditar } from '../PermissionMeta/functions';
import { useAtualValues, useOkApp } from 'OkApp/UseUtils';
import { Button } from '@material-ui/core';

const prefixoModule = "metaOkApp.gestao."
export function OkFormMetaAba(props) {
    const okApp = useOkApp();
    const uidForm = useAtualValues('uid');
    const isAdmin = useIsAdmin();
    if (isAdmin) {
        return <OkFormAbaAdapter keyTab={"Gestão"} {...props}>
            <div style={{ display: 'flex' }}>

                <OkTypedComponent typeComponente='InputComp' field={prefixoModule + 'pontosFuncao'} label='Pontos de Função Antigo' type="number" />
                <OkTypedComponent typeComponente='InputComp' field={prefixoModule + 'pontosFuncaoPrevisto'} label='Pontos de Função Previsto' type="number" />
                <OkTypedComponent typeComponente='InputComp' field={prefixoModule + 'pontosFuncaoAdicional'} label='Pontos de Função Adicional' type="number" />
            </div>
            <OkTypedComponent typeComponente='InputComp' field={prefixoModule + 'agrupador'} label='Agrupador' />
            <OkTypedComponent typeComponente='InputComp' field={prefixoModule + 'obs'} label='Obs' multiline rows={'3'} />
            <Button onClick={() => {
                let url = 'https://console.firebase.google.com/u/0/project/okappbeta/firestore/data/~2FProject~2F'
                url += okApp.uid
                console.log('okApp', okApp)
                console.log('uidForm', uidForm)
                url +='~2FCustomForm~2F'+uidForm
                window.open(url);

            }}>Ver DB</Button>

        </OkFormAbaAdapter>
    } else {
        return '';
    }
}

export function CompAddCustomComponent(props) {
    return <div>

        <OkTypedComponent typeComponente='InputComp' field={prefixoModule + 'pontosFuncao'} label='Pontos de Função' type="number" />
        <OkTypedComponent typeComponente='InputComp' field={prefixoModule + 'obs'} label='Obs' multiline rows={'3'} />
    </div>
}

export function ModulesForms({ historyPush, okApp }) {

    let m = [];
    if (okApp) {
        if (isAuthenticated()) {
            if (permiteEditar(okApp)) {
                m.push({
                    menu: { text: 'Gestão de projeto', type: 'okapp', index: "1", iconKey: "", parentMenu: "Gestão", parentMenuIndex: 999998, action: () => historyPush("GestaoProjeto") },
                    form: { path: "GestaoProjeto", component: (props) => <AnaliseApp {...props} okApp={okApp} /> }
                })
            }
        }
    }
    return m;
}


// export const data = { name: "GestaoProjetoModule", abaMetaCustomComponent: "Gestão" }// remove aba gestao do Comp para primeira versao
export const data = { name: "GestaoProjetoModule" }