import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react'

import { Field, reduxForm } from 'redux-form'
import { useTheme, withStyles } from '@material-ui/core/styles';
import { TextField, Button, Avatar, Box, Typography, CircularProgress, useMediaQuery } from "@material-ui/core";


import firebaseUtil from '../../../../../firebaseUtil'
import useAuth from '../../../../../useAuth';
import useNotification from '../../../../../notification';

import './style.css'
import Lock from "./lock.png"

import { getOkFormSnap } from 'OkApp/OkFunctions';

const renderTextField = ({ input, label, meta: { touched, error }, ...custom }) => (
    <TextField
        label={label}
        error={(touched && error) ? true : false}
        helperText={(touched && error) ? error : ""}
        fullWidth

        {...input}
        {...custom}
    />
)
function PasswordRecovery(props) {
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { t } = useTranslation();
    const { showSuccess, showWarning } = useNotification()
    const { handleSubmit, submitting, okApp, pagePosLogin, valid } = props
    const { notAuthenticatedOnly, redirectPosAuth, redirectLogin } = useAuth();
    const [pagePosLoginST, setPagePosLoginST] = useState(pagePosLogin)

    notAuthenticatedOnly();

    useEffect(() => {
        let observer = null
        if (okApp && pagePosLoginST === "/app/" + okApp.route) {
            if (okApp.formInicio) {
                observer = getOkFormSnap(okApp, okApp.formInicio.uid,
                    (r) => {
                        if (r) {
                            setPagePosLoginST("/app/" + okApp.route + "/" + r.route)
                        }
                    },
                    (err) => {
                    }
                );
            }
        }
        return () => {
            if (observer) observer()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [okApp])

    async function recoveryPassword(dados) {
        try {
            let emailAddress = dados.email;
            await firebaseUtil.passwordReset(emailAddress)
            showSuccess("E-mail para redefinição de senha enviado com sucesso!")
            redirectPosAuth(pagePosLoginST)
        } catch (error) {
            if (error && error.code === "auth/user-not-found") {
                showWarning("Usuário não cadastrado.")
            }
        }
    }

    return (
        <Box display="flex" alignItems="center" justifyContent="center" height="100vh" width="100%" style={{ background: "linear-gradient(to bottom, #575fff83,  #ef9c3283)" }}>
            <Box display="flex" maxWidth={mobile ? "95%" : 755} flexDirection={mobile ? "column" : "row"} borderRadius={15} style={{ background: mobile ? "#eeeeee95" : "#eee", boxShadow: "0px 0px 11px 0px rgba(0,0,0,0.54)" }} height="fit-content">
                <Box padding={3} display="flex" flexDirection="column" justifyContent="center" width={mobile ? "100%" : "60%"}>
                    <Typography style={{ marginBottom: 20, fontSize: 18, textAlign: 'center' }}>Insira seu email para redefinir a senha</Typography>
                    <Box marginBottom={2}>
                        <Field name="email" variant="outlined" valid={valid} handleSubmit={handleSubmit} component={renderTextField} label={t('user.email')} />
                    </Box>
                    <Box style={{ paddingTop: 15 }} >
                        <Button variant="contained" color="primary" fullWidth onClick={handleSubmit(recoveryPassword)} disabled={submitting}>
                            {!submitting && "Solicitar redefinição"}
                            {submitting && <CircularProgress size={25} />}
                        </Button>
                    </Box>
                    <Box style={{ paddingTop: 15 }} >
                        <Button variant="contained" color={theme.palette.primary.light} fullWidth onClick={() => redirectLogin(okApp)}>
                            Voltar para login
                        </Button>
                    </Box>
                </Box>
                {!mobile &&
                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" style={{ width: "40%", background: 'lightgray', borderBottomRightRadius: 15, borderTopRightRadius: 15 }} padding={5}>
                        <Avatar variant={'rounded'} alt={'logo'} style={{ width: "180px", height: "180px" }} src={Lock} />
                    </Box>
                }
            </Box>
        </Box>
    )
}

const validate = values => {
    const errors = {}

    if (!values.email) {
        errors.email = 'Requerido'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Email inválido'
    }
    return errors
}

export default reduxForm({
    validate,
    form: 'RegisterUser'
})(withStyles({})(PasswordRecovery))