
import React, { useState } from 'react'
import { useTheme, useMediaQuery, Dialog, Button, makeStyles, Typography, IconButton, CircularProgress } from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import { useOkForm } from 'OkApp/UseUtils'
import RenderOkForm from 'OkApp/components/RenderOkForm';

import "./style.css"

import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
    dialogPaper: {
        minHeight: '80vh',
        maxHeight: '80vh',
        minWidth: '80vw',
        maxWidth: '80vw',
        overflow: 'hidden',
        height: '80vh',
        width: '80vw',
    },

    title: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: 10,
        paddingRight: 10

    },
}));



export default function CompView(props) {
    const theme = useTheme();
    const classes = useStyles();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [openDialog, setOpenDialog] = useState(false);
    const { input: { value }, field, label, iconkey, title, auxOkForm } = props
    let developer = props.propscomp.developer
    let form = useOkForm(auxOkForm ? auxOkForm.uid : null);
    let startIcon = null;
    if (iconkey) {
        const RecIcon = Icons[iconkey]
        startIcon = <RecIcon />
    }
    const closeDialog = () => {
        setOpenDialog(false)
    }
    return (
        <div className='DialogFormCompEditorCont'>
            <Button variant="contained" color="primary"
                startIcon={startIcon}
                style={{ marginBottom: 15, marginTop: 4, whiteSpace: 'nowrap' }}
                onClick={() => {
                    setOpenDialog(true)
                }}>{label}</Button>
            <Dialog
                classes={{ paper: !fullScreen ? classes.dialogPaper : null }}
                className="DialogFormCompEditor-Dialog"
                fullScreen={fullScreen}
                open={openDialog}
                onClose={closeDialog}
            >

                <div className="DialogFormCompEditor-DialogRoot">
                    <div className={classes.title + " DialogFormCompEditor-DialogTitle"}>
                        <Typography variant="h6">
                            {title || "Dialog"}
                        </Typography>
                        <div style={{ display: 'flex' }}>


                            <IconButton edge="end" color="inherit" onClick={closeDialog} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </div>
                    <div className="DialogFormCompEditor-DialogCont">
                        {form ?
                            <RenderOkForm
                                okform={form}
                                developer={developer}
                                renderForm={true}
                                prefield={field}
                                value={value}
                                clearDevelorperNext={true} /> :
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <CircularProgress />
                            </div>}
                    </div>
                </div>
            </Dialog>

        </div>
    )
}
