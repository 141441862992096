import { LabelInputAuto } from 'OkApp'
import OkTypedComponent from 'OkApp/components/OkTypedComponent'
import { useAtualValues } from 'OkApp/UseUtils';
import React from 'react'
import { PlayerVideoTypes } from '..';

export default function EditorComp(props) {
    const type = useAtualValues("type");
    return <div style={{ display: 'flex', flexDirection: 'column', height: "100%" }}>
        <div>

            <OkTypedComponent typeComponente='InputComp' field='field' label='Nome Salvo' />
            <LabelInputAuto {...props} />
            <OkTypedComponent typeComponente='SwitchComp' field='autoPlay' label='AutoPlay' />
            <OkTypedComponent typeComponente='ComboBoxComp' field='type' label='Tipo' options={PlayerVideoTypes} toStringItem={(v) => {
                switch (v) {
                    case 'url': return "Endereço"
                    case 'anexo': return "Anexo"
                    default: return v;
                }
            }} />
            {type === 'anexo' && <>
                <OkTypedComponent typeComponente='SwitchComp' field='useCache' label='Manter video no dipositivo local(Somente para mobile)' />
            </>}
        </div>
        <OkTypedComponent typeComponente='FunctionsEditor' field='functionsmap' label='Funções' />

    </div>
}