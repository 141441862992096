import React from 'react'
import OkCompAdapter from 'OkApp/components/OkCompAdapter'
import { OkTypedComponent, LabelInputAuto } from 'OkApp'
import PainelEmitirRelatorio from 'OkReport/components/PainelEmitirRelatorio'
import { createDefaultFuncMapStr } from 'OkApp/CodeProcessor'
import { useAtualValues, useCreateFunctionFromFieldCodeScopeDestroy } from 'OkApp/UseUtils'

const typeComponente = 'EmitirRelatorioComp'

const AdapterOkComp = (props) => {

    const { okComp, okfunctions } = props;
    const atualValues = useAtualValues();
    const [getTextoCabecalho1] = useCreateFunctionFromFieldCodeScopeDestroy(okComp.functionsmap, 'getTextoCabecalho1', { ...okfunctions, atualValues })
    const [getTextoCabecalho2] = useCreateFunctionFromFieldCodeScopeDestroy(okComp.functionsmap, 'getTextoCabecalho2', { ...okfunctions, atualValues })
    const [getTituloRelatorio] = useCreateFunctionFromFieldCodeScopeDestroy(okComp.functionsmap, 'getTituloRelatorio', { ...okfunctions, atualValues })
    const [getNomeArquivo] = useCreateFunctionFromFieldCodeScopeDestroy(okComp.functionsmap, 'getNomeArquivo', { ...okfunctions, atualValues })
    const [getLogo] = useCreateFunctionFromFieldCodeScopeDestroy(okComp.functionsmap, 'getLogo', { ...okfunctions, atualValues })

    let propsComp = { getTextoCabecalho1, getTextoCabecalho2, getTituloRelatorio, getNomeArquivo, getLogo }

    return <OkCompAdapter {...props} propsComp={propsComp} component={PainelEmitirRelatorio} />
}

const EditorOkComp = (props) => {
    const startFunc = `"function getTextoCabecalho1(props) {\n\treturn '';\n}"`

    return <div style={{ display: 'flex', flexDirection: "column", height: "100%" }}><div>

        <OkTypedComponent typeComponente='InputComp' field='field' label='Nome Salvo' />
        <LabelInputAuto {...props} />
        <OkTypedComponent typeComponente='InputComp' field='chaveRelatorio' label='Filtro Chave de Relatórios' />
        <OkTypedComponent typeComponente='SwitchComp' field='somenteSalvo' label='Habilitar somente se o formulário estiver salvo' />
        <OkTypedComponent typeComponente='ComboBoxComp' field='type' label='Tipo' options={["dialogPainel"]} toStringItem={(v) => {
            switch (v) {
                case "dialogPainel": return "Botão abrir painel"
                default: return "ERRO";
            }
        }} />

    </div>


        <OkTypedComponent typeComponente='FunctionsEditor' field='functionsmap' label='Textos de Cabecalho'
            startFunc={startFunc}
        />





    </div>
}

const modelo = {
    typeComponente,
    field: '',
    label: 'Emitir Relatório',
    type: "dialogPainel",
    functionsmap: createDefaultFuncMapStr("getTextoCabecalho1", "function getTextoCabecalho1(props) {\n\treturn '';\n}"),
    metaOkApp: { style: { sizeWidth: "0" } }
}


const EmitirRelatorioComp = {
    typeComponente,
    configView: {
        grupo: 'OK Utils',
        nome: 'Emitir Relatório',
        descricao: 'Emitir Relatório',
        helpUrl: 'https://www.google.com.br/',
        modelo
    },
    Component: AdapterOkComp,
    CompEditor: EditorOkComp
}
export default EmitirRelatorioComp