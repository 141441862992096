import React, { useState, useEffect } from 'react'
import OkCompAdapter from 'OkApp/components/OkCompAdapter'
import { OkTypedComponent, LabelInputAuto } from 'OkApp'
import CompView from './components/CompView'
import { useOkApp, useAtualValues } from 'OkApp/UseUtils'
import { getOkFormSnap } from 'OkApp/OkFunctions'
import { componetIteratorOkForm } from 'OkApp/functions'
import { Field } from 'redux-form'
import { Button } from '@material-ui/core'
import Render from 'routes/dinamic/components/CustomFormDeveloper/componentes/form/Render'
const typeComponente = 'KanbanComp'

const AdapterOkComp = (props) => {
    let propsComp = {}
    return <OkCompAdapter {...props} propsComp={propsComp} component={CompView} />
}
const EditorOkComp = (props) => {
    const [camposFormulario, setCamposFormulario] = useState([])
    const atualValues = useAtualValues();
    const okApp = useOkApp();
    let uidAuxOkForm = null;
    let tipoView = "fields";
    if (atualValues.auxOkForm) {
        uidAuxOkForm = atualValues.auxOkForm.uid;
    }
    if (atualValues.tipoView) {
        tipoView = atualValues.tipoView
    }
    useEffect(() => {
        if (uidAuxOkForm) {
            getOkFormSnap(okApp, uidAuxOkForm, (r) => {
                let fields = []
                componetIteratorOkForm(r, (comp) => {
                    fields.push(comp.field)
                })
                setCamposFormulario(fields)
            }, (err) => {
                setCamposFormulario([])
            })
        }
    }, [uidAuxOkForm, okApp])
    return <>
        <OkTypedComponent typeComponente='InputComp' field='field' label='Nome Salvo' />
        <LabelInputAuto {...props} />
        <OkTypedComponent typeComponente='SeletorOkForm' field='auxOkForm' label='Formulário' />
        <OkTypedComponent typeComponente='InputComp' field='fieldKamban' label='Campo Kanban' />


        <OkTypedComponent typeComponente='SwitchComp' field='showQt' label='Exibe Quantidade' />
        <OkTypedComponent typeComponente='InputComp' field='colNull' label='Coluna para vazio' />

        <OkTypedComponent typeComponente='ListaItens' field='colunas' label='Colunas' adicionavel={true} removivel={true} permitemover={true} />
        <OkTypedComponent typeComponente='ComboBoxComp' field='tipoView' label='Tipo de Cards' options={['fields', 'okForm']} toStringItem={(v) => {
            switch (v) {
                case 'fields': return "Campos"
                case 'okForm': return "Criar Formulário [beta]"
                default: return v;
            }
        }} />
        {tipoView === "fields" ?
            <>
                <div style={{ display: 'flex' }}>
                    <OkTypedComponent typeComponente='SwitchComp' field='showBtVer' label='Exibir Ver' />
                    <OkTypedComponent typeComponente='SwitchComp' field='newTab' label='Nova aba' />
                </div>
                <OkTypedComponent typeComponente='ListaItens' field='fieldsShow' label='Campos Exibir' possiveis={camposFormulario} adicionavel={true} removivel={true} />
            </>
            :
            <>
                <Button variant='contained' color='primary' onClick={() => {
                    props.change('painelCard', [{ uid: Math.random().toString(36).substring(7), typeComponente: "PanelFlexComp", children: [{ uid: Math.random().toString(36).substring(7), field: "titulo", label: "Titulo", typeComponente: 'InputComp', multiline: true }] }])
                }}>Reiniciar Card</Button>
                <div style={{ padding: 25, backgroundColor: "#ccc", minHeight: 500 }}>

                    <div className="RegioesDnD-conteinerItemLista">
                        <Field name="painelCard" component={Render} prefield='cardValues' />
                    </div>
                </div>
            </>
        }



    </>
}

const modelo = {
    typeComponente,
    field: 'lista',
    label: 'Kanban',
}


const KanbanComp = {
    typeComponente,
    configView: {
        grupo: 'Container',
        nome: 'Kanban',
        descricao: 'Kanban',
        helpUrl: 'https://okapp.com.br/app/okappdocs/kanban',
        modelo
    },
    Component: AdapterOkComp,
    CompEditor: EditorOkComp
}
export default KanbanComp