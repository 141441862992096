import React from 'react'
import MapPoligons from './MapPoligons';
import { calcularAreaPolygonHA, poligonStr2List } from './MapPoligons/UtilCalcCordenadas';
import { useReduxFormProps } from 'OkApp/UseUtils';


export default function CompView(props) {
    const { input: { value, onChange }, meta: { touched, error }, label, type, minHeight, fieldAreaCalc } = props;
    const formProps = useReduxFormProps();
    const updateArea = () => {
        console.log('props',props)
        console.log('poligonoStr', value)
        if (value && fieldAreaCalc) {

            let novaArea = calcularAreaPolygonHA(poligonStr2List(value))
            if (novaArea !== null) {
                formProps.change(fieldAreaCalc, novaArea.toFixed("2"))
            }
        }
    }

    return (<MapPoligons
        poligonoStr={value}
        setPoligonoStr={onChange}
        // outrosPoligonos={outrosPoligonos} 
        updatePoligono={updateArea}
        minHeight={minHeight ? parseInt(minHeight) : ""}
        showCurrentLocation />)
}

