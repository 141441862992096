import { InputLabel } from '@material-ui/core';
import useOKControlerField from 'components/OkStyleEditor/functionsUtils';
import React from 'react'

export default function SizeComp({ styleAtual, value, onChange }) {
    const okControler = useOKControlerField(value, onChange);
    let ajustValue = (v) => {
        if (!v) {
            return v
        }
        let isPercent = v.includes('%');
        var res = v.replace(/\D/g, "");
        if (isPercent) {
            return v;
        } else {

            return res;
        }
    }
    let ajustOnValue = (evt) => {

        let v = evt.target.value
        let isPercent = v.includes('%');

        var res = v.replace(/\D/g, "");
        if (!res) {
            return res;
        }
        if (isPercent) {
            return (res + "%")
        } else {
            return (res + "px")
        }

    }


    return (
        <div className='OkStyleEditor-comp'>
            <InputLabel>Size</InputLabel>
            <div className='OkStyleEditor-row'>
                <div className='OkStyleEditor-cell50'>
                    <InputLabel>Width</InputLabel>

                    <input value={ajustValue(okControler('width').value)} onChange={evt => {
                        okControler('width').onChange(ajustOnValue(evt))
                    }} />
                </div>

                <div className='OkStyleEditor-cell50'>
                    <InputLabel>Height</InputLabel>
                    <input value={ajustValue(okControler('height').value)} onChange={evt => {
                        okControler('height').onChange(ajustOnValue(evt))
                    }} />
                </div>
            </div>

        </div>
    )
}
