
import React, { useState, useContext } from 'react'
import { Snackbar, SnackbarContent, IconButton, makeStyles } from '@material-ui/core';


import CloseIcon from '@material-ui/icons/Close'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';


import WarningIcon from '@material-ui/icons/Warning';


import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import classNames from 'classnames';

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
};

const useStyles = makeStyles(theme => ({
    buttonRoot: {
        color: 'white'
    },
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        backgroundColor: theme.palette.primary.dark,
    },
    warning: {
        backgroundColor: amber[700],
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
}));

const defaultDismissTime = 2500 // 2.5 seconds

export default function useNotification() {

    const { showSuccess, showWarning, showError, showNotification } = useCrudContext()
    return { showSuccess, showWarning, showError, showNotification }
}



export const NotificationContext = React.createContext({
    notifications: []
})
function CrudControl() {

    const [notifications, setNotifications] = useState([])
    const dismissNotification = (uid) => {
        let index = notifications.map(n => n.uid).indexOf(uid);
        if (index > -1) {
            let newArray = notifications.slice();
            newArray.splice(index, 1);
            setNotifications(newArray);
        }
    }

    const showNotification = (notif) => {
        if (!notif.uid) {
            notif.uid = Math.random().toString(36).substring(7)

        }


        setNotifications(notifications.concat([notif]))


        // alert(notif.message)
    }
    const showSuccess = (msg) => {
        showNotification({ type: 'success', message: msg })
    }
    const showWarning = (msg) => {
        showNotification({ type: 'warning', message: msg })
    }
    const showError = (msg) => {
        showNotification({ type: 'error', message: msg })
    }


    return { showSuccess, showWarning, showError, showNotification, notifications, setNotifications, dismissNotification }
}

export const NotificationProvider = ({ children }) => {
    const classes = useStyles();
    const v = CrudControl();
    const { notifications, dismissNotification } = v
    const className = null;//???
    return <>
        <NotificationContext.Provider value={v}>
            {children}
        </NotificationContext.Provider>
        {notifications.map((not, i) => {
            let variant = "info"
            let Icon = variantIcon[variant];
            if (not && not.type) {
                variant = not.type
                Icon = variantIcon[variant];
            }

            setTimeout(() => {
                dismissNotification(not.uid)
            }, not.dismissAfter || defaultDismissTime)
            return (
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    key={not.uid}
                    open
                >
                    <SnackbarContent
                        key={"sc" + not.uid}
                        className={classNames(classes[variant], className)}
                        aria-describedby="client-snackbar"
                        style={{ marginTop: 65 * i }}
                        message={
                            <span id="client-snackbar"
                                className={classNames(classes[variant], className)}
                            >
                                <Icon
                                    className={classNames(classes.icon, classes.iconVariant)}
                                />
                                {not.message}
                            </span>
                        }
                        action={
                            <IconButton
                                onClick={() => dismissNotification(not.uid)}
                                classes={{ root: classes.buttonRoot }}
                            >
                                <CloseIcon />
                            </IconButton>
                        }
                    />

                </Snackbar>
            )
        })}

    </>

}
export const useCrudContext = () => useContext(NotificationContext)