import React, { useState } from 'react'
import { Tab, Tabs, Typography, makeStyles, Box } from '@material-ui/core';
const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'relative',
    },
    rootVertical: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        height: 224,
    },
    rootHorizontal: {
        backgroundColor: theme.palette.background.paper,
        height: 224,
        display: 'flex',
        flexDirection: "column"
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
}));
function TabPanel(props) {
    const { children, atualTab, keyTab, tabStyle, typoStyle, ...other } = props;
    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={atualTab !== keyTab}
            style={{ width: "100%", ...typoStyle }}
            {...other}
        >
            {atualTab === keyTab && <Box p={1} style={tabStyle}>{children}</Box>}
        </Typography>
    );
}


export default function PainelAbas({ abaTitulos, children, vertical, style, typoStyle, tabStyle, ...outerProps }) {

    const [atualTab, setAtualTab] = useState(0)
    const classes = useStyles();
    let vazio = !children || !children.length


    let orientation = "vertical";
    if (!vertical) {
        orientation = 'horizontal'
    }

    return (
        <div className={vertical ? classes.rootVertical : classes.rootHorizontal} style={style}>


            <Tabs
                orientation={orientation}
                variant="scrollable"
                value={atualTab}
                onChange={(e, newValue) => {
                    setAtualTab(newValue)
                }}
                className={classes.tabs}
            >
                {vazio && <div className={classes.tabs} style={{ height: 55, width: "100%", padding: 15 }}>Painel sem abas :)</div>}
                {abaTitulos.map((aba, i) => {
                    return <Tab key={i} value={i} label={aba} />
                })}
            </Tabs>

            {/* <div style={{ width: "100%", display: 'flex', flex: 1, position: 'relative' }}> */}
            <>
                {children.map((filhoAba, i) => {
                    return <TabPanel key={i} atualTab={atualTab} keyTab={i} tabStyle={tabStyle} typoStyle={typoStyle} >
                        {filhoAba}
                        {/* < Aba key={i} {...outerProps} /> */}
                    </TabPanel>
                })}
            </>
            {/* </div> */}
        </div>
    )
}
