import firebaseUtil from './firebaseUtil'
import { useHistory } from "react-router-dom";
import { salvarPerfilRegistro } from 'dbApi';
import { useState, useEffect } from 'react';
import { useOkApp, useUserUid } from 'OkApp/UseUtils';
import { getPerfilUserSnap, getUserSnap } from 'OkApp/OkFunctions';
import _ from 'lodash'

export const isAuthenticated = () => {
    if (!firebaseUtil.getCurrentUser()) {
        return false;
    } else {
        return true;
    }

}

export const usePerfil = () => {
    const [perfil, setPerfil] = useState(null);
    const useruid = useUserUid();

    useEffect(() => {
        let observer
        if (useruid) {
            observer = getUserSnap(useruid,
                (r) => {
                    setPerfil((old) => {
                        if (!_.isEqual({ ...old, lastChange: null, lastLogin: null }, { ...r, lastChange: null, lastLogin: null })) {
                            return r
                        }
                        return old
                    })
                    // setPerfil(r)
                },
                (err) => {
                    setPerfil(null)
                }
            );
        } else {
            setPerfil(undefined)
        }
        return () => {
            if (observer) {
                observer();
            }
        }

    }, [useruid])

    return perfil;

}

export const usePerfilOkApp = () => {
    const [perfil, setPerfil] = useState(null);
    const useruid = useUserUid();
    const okApp = useOkApp()

    useEffect(() => {
        let observer
        if (useruid && okApp) {

            observer = getPerfilUserSnap(useruid, okApp, (p) => {
                // setPerfilUser((old) => {
                //     if (!_.isEqual({ ...old, lastChange: null, lastLogin: null }, { ...p, lastChange: null, lastLogin: null })) {
                //         return p
                //     }
                //     return old
                // })
                setPerfil((old) => {
                    if (!_.isEqual({ ...old, lastChange: null, lastLogin: null }, { ...p, lastChange: null, lastLogin: null })) {
                        return p
                    }
                    return old
                })

            }, (error) => console.log('error getPerfilUserSnap', error))

            // observer = getUserSnap(useruid,
            //     (r) => {
            //         setPerfil((old) => {
            //             if (!_.isEqual({ ...old, lastChange: null, lastLogin: null }, { ...r, lastChange: null, lastLogin: null })) {
            //                 return r
            //             }
            //             return old
            //         })
            //         // setPerfil(r)
            //     },
            //     (err) => {
            //         setPerfil(null)
            //     }
            // );
        } else {
            setPerfil(undefined)
        }
        return () => {
            if (observer) {
                observer();
            }
        }

    }, [useruid, okApp])

    return perfil;

}

export function useIsAdmin() {
    let perfil = usePerfil();

    let isAdmin = false;

    if (perfil) {
        if (perfil.tipoPerfil === "admin") {
            isAdmin = true;
        }
    }
    return isAdmin;
}
export default function useAuth() {
    const history = useHistory();

    const redirectPosAuth = (pagePosLogin) => {
        console.log('redirectPosAuth', pagePosLogin)
        history.push(pagePosLogin || '/')
    }
    const redirectLogin = (okApp) => {
        if (okApp) {
            history.push("/app/" + okApp.route + "/Login")
        } else {
            history.push('/Login')
        }
    }
    const redirectRegister = (okApp) => {
        if (okApp) {
            history.push("/app/" + okApp.route + "/Register")
        } else {
            history.push('/Register')
        }
    }

    const redirectPasswordRecovery = (okApp) => {
        if (okApp) {
            history.push("/app/" + okApp.route + "/password-recovery")
        } else {
            history.push('/password-recovery')
        }
    }

    const authenticatedOnly = () => {
        if (!firebaseUtil.getCurrentUser()) {
            redirectLogin()
        }

    }
    const notAuthenticatedOnly = () => {
        if (firebaseUtil.getCurrentUser()) {
            redirectPosAuth()
        }
    }



    const userName = () => {
        return firebaseUtil.getCurrentUsername()
    }

    const user = () => {
        return firebaseUtil.getCurrentUser()
    }

    const registerLogin = () => {
        // console.log('registerLogin', firebase.getCurrentUser())
        if (firebaseUtil.getCurrentUser()) {
            salvarPerfilRegistro(firebaseUtil.getCurrentUser());
        }
    }
    const registerSingIn = () => {
        // console.log('registerSingIn', firebase.getCurrentUser())
        if (firebaseUtil.getCurrentUser()) {
            salvarPerfilRegistro(firebaseUtil.getCurrentUser());
        }
    }


    return { authenticatedOnly, notAuthenticatedOnly, redirectPasswordRecovery, redirectLogin, redirectRegister, redirectPosAuth, isAuthenticated, userName, user, registerLogin, registerSingIn }
}
