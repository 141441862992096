import React from 'react'
import OkCompAdapter from 'OkApp/components/OkCompAdapter'
import CompView from './components/CompView'
import { LabelInputAuto, OkTypedComponent } from 'OkApp'

const typeComponente = 'RichTextComp' //deve ter o mesmo nome de exportação no index

const AdapterOkComp = (props) => {
    // const { okComp } = props
    let propsComp = {options_editor:{}}
    return <OkCompAdapter {...props} propsComp={propsComp} component={CompView} />
}

const EditorOkComp = (props) => {

    return <>
        <OkTypedComponent typeComponente='InputComp' field='field' label='Nome Salvo' />
        <LabelInputAuto {...props} />
       
        <OkTypedComponent typeComponente='InputComp' field='placeholder' label='Texto quando vazio' />

    </>
}

const modelo = {
    typeComponente,
    field: 'descricao',
    label: 'Descrição',
    placeholder:"Digite uma descrição aqui..."

}

const RichTextComp = {
    typeComponente,
    configView: {
        grupo: 'Básico',
        nome: 'Campo de Texto Formatavel',
        descricao: 'Campo de texto de multiplas linhas que pode ser formatado(Negrito, Fonte , etc)',
        helpUrl: 'https://okapp.com.br/app/okappdocs/richrext',
        modelo
    },
    Component: AdapterOkComp,
    CompEditor: EditorOkComp
}

export default RichTextComp;