import React from 'react'

import EditorPermissions from './components/EditorPermissions';
import OkFormAbaAdapter from 'OkApp/components/OkFormAbaAdapter';
import { Field } from 'redux-form';
import { Input, SwitchItem } from 'components';
import { useAtualValues } from 'OkApp/UseUtils';
import { componetIteratorOkForm } from 'OkApp/functions';
import _ from 'lodash'

import { useIsAdmin } from 'useAuth';
import GrupoSeletorComp from './components/GrupoSeletorComp';
import { temPermissaoChaveOkComp } from './functions';

export function OkFormMetaAba(props) {
    let atualValues = useAtualValues();
    const isAdmin = useIsAdmin();
    let listPermissions = [];
    componetIteratorOkForm(atualValues, (comp) => {

        let chaveVer = _.get(comp, 'metaOkApp.permission.chaveVer');
        let chaveEditar = _.get(comp, 'metaOkApp.permission.chaveEditar');
        if (chaveVer)
            if (!listPermissions.includes(chaveVer))
                listPermissions.push(chaveVer)
        if (chaveEditar)
            if (!listPermissions.includes(chaveEditar))
                listPermissions.push(chaveEditar)

    })


    return <OkFormAbaAdapter keyTab={"Permissões"} {...props}>

        <Field name='metaOkApp.permission.dadosPublicos' component={SwitchItem} label={"Dados Públicos (Dados cadastrados por um usuário pode ser visto por qualquer usuário)"} />
        <Field name='metaOkApp.permission.publicNoUser' component={SwitchItem} label={"Público sem usuário (Formulário pode ser aberto sem fazer login)"} />
        <EditorPermissions labelEditor="Editores do Formulário" labelUsers="Usuários do Formulário" labelPublico="Público a todos os Usuários (Qualquer usuário com acesso a aplicação pode ver o formulário)" listPermissions={listPermissions} grupoFixePermission={["Ver"]} />
    </OkFormAbaAdapter>
}

export function CompAddCustomComponent(props) {
    return <div>
        <Field name='metaOkApp.permission.chaveVer' component={Input} label={"Permissão Ver"} />
        <Field name="metaOkApp.permission.chaveEditar" component={Input} label={"Permissão Editar"} />
    </div>
}


export function OkCompModuleAdapterFunc(props) {
    let propsCopy = { ...props }
    let { okComp, okform, perfilUser } = propsCopy;
    if (okComp) {
        let okCompCopy = { ...okComp }

        let permiteEditarCompV = temPermissaoChaveOkComp(okform, _.get(okComp, 'metaOkApp.permission.chaveEditar'), perfilUser);
        if (!permiteEditarCompV) {
            okCompCopy.disabled = true;
        }

        let permiteVERCompV = temPermissaoChaveOkComp(okform, _.get(okComp, 'metaOkApp.permission.chaveVer'), perfilUser);
        if (!permiteVERCompV) {
            okCompCopy.hidden = true;
        }
        propsCopy.okComp = okCompCopy

    }
    return propsCopy;

}

export function ModuleFuncBeforeSave(obj, form) {
    let permissionForm = _.get(form, 'metaOkApp.permission');
    if (permissionForm && permissionForm.dadosPublicos) {
        _.set(obj, "metaOkApp.permission.public", true)
    }

    return obj;
}




export function ModuleOkComps() {
    return [GrupoSeletorComp];
}
export const data = { name: "OkCompControlView", abaMetaGeral: "View", abaMetaCustomComponent: "Permissão" }
