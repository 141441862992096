import React from 'react'
import "./style.css"
import DefaultLogo from 'resources/image/okapp-avatar.png'
export default function OKAppLoading() {
    return (
        <div className="OKAppLoadingRoot">
            <div className="OKAppLoadingContent">
                <img src={DefaultLogo} alt={"logoLoading"} />
                <div className="loading-container">                    
                    <div className="circle"></div>
                    <div className="circle"></div>
                    <div className="circle"></div>
                    <div className="circle"></div>
                </div>
            </div>
        </div>
    )
}


