
import React, { useState } from 'react'
import PopUpMenu from '../LeftComp/PopUpMenu'
import PesquisaOkForm from '../PesquisaOkForm'
import { Button } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { useReduxFormProps } from 'OkApp/UseUtils';
import _ from 'lodash'

export default function CopiarForm() {
    const [showCopiarForm, setShowCopiarForm] = useState(false);
    const reduxForm = useReduxFormProps();

    return (
        <div>
            <PopUpMenu text="Opções" Icon={Edit} >
                <div style={{ padding: 10 }}>
                    <Button variant='contained' color='primary' onClick={() => {
                        console.log('Copiar OkForm')
                        setShowCopiarForm(true)
                    }} >Copiar OkForm</Button>



                </div>
            </PopUpMenu>

            <PesquisaOkForm openPesquisa={showCopiarForm} setOpenPesquisa={setShowCopiarForm}
                titulo={"Selecione o formulário para copiar."}
                onSelect={(f) => {
                    console.log('CopiarForm', f)
                    const { children, initialValues } = f

                    let newForm = { children, initialValues };
                    if (_.get(f, "metaOkApp.style")) {
                        _.set(newForm, "metaOkApp.style", _.get(f, "metaOkApp.style"))
                    }
                    console.log('newForm', newForm)
                    console.log('reduxForm', reduxForm)

                    let newChildren = _.concat(reduxForm.atualValues().children === '' ? [] : reduxForm.atualValues().children, children)
                    let newInitialValues = _.merge(reduxForm.atualValues().initialValues ? {} : reduxForm.atualValues().initialValues, initialValues)
                    console.log('newClildren', newChildren)
                    console.log('newInitialValues', newInitialValues)
                    reduxForm.change('children', newChildren)
                    reduxForm.change('initialValues', newInitialValues)
                    if (_.get(f, "metaOkApp.style")) {
                        reduxForm.change('metaOkApp.style', _.get(f, "metaOkApp.style"))
                    }
                    
                }} />
        </div>
    )
}
