import React from 'react'
import OkCompAdapter from 'OkApp/components/OkCompAdapter'
import { Combobox } from 'components'
import { OkTypedComponent, LabelInputAuto } from 'OkApp'
import { useOkApp } from 'OkApp/UseUtils'
const typeComponente = 'GrupoPermissaoSelector'

const AdapterOkComp = (props) => {
    let okApp = useOkApp();
    return <OkCompAdapter {...props} propsComp={{ itens: okApp.gruposPermissao }} component={Combobox} />
}
const EditorOkComp = (props) => {

    return <>
        <OkTypedComponent typeComponente='InputComp' field='field' label='Nome Salvo' disabled />
        <LabelInputAuto {...props} />
    </>
}

const modelo = {
    typeComponente,
    field: 'okGrupoPermissao',
    label: 'Grupo de permissão',
    remover: true,
 
}

const GrupoSeletorComp = {
    typeComponente,
    //status: 'Desenvolvimento',
    configView: {
        grupo: 'OK Utils',
        nome: 'Grupo de permissao',
        helpUrl: 'https://www.google.com.br/',
        modelo
    },
    Component: AdapterOkComp,
    CompEditor: EditorOkComp
}

export default GrupoSeletorComp; 