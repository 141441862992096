import React, { Component } from 'react'
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';


class SwitchItem extends Component {
    render() {
        const {
            input: { value, onChange },
            meta: { touched, error },
            label,
            disabled
        } = this.props;
        let bool = false;
        if (value) {
            bool = value
        }
        return (<div>
            <div style={{ display: 'flex', justifyContent: "flex-start", alignItems: "center", flex: '1' }}>
                <Switch checked={bool} onChange={(b) => onChange(b)} disabled={disabled} />
                <Typography >{label}</Typography>
            </div>
            {touched && error &&

                <Typography style={{
                    color: '#ff5964',
                    fontSize: 12,
                }}>{error}</Typography>
            }
        </div>
        )
    }
}


export default SwitchItem 
