import React, { } from 'react'
import { RenderOkForm } from 'OkApp'
import OverComp from '../OverComp';
import produce from "immer"
import { ajustNewOkComp } from 'OkApp/functions';
function array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
        new_index = arr.length - 1;
    }
    if (new_index < 0) {
        new_index = 0;
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
};

export default function Render(props) {
    const { input: { value, onChange }, okform, openProps } = props;
    // console.log('value', value)
    const getChildren = (arrayComps, uid) => {
        if (!uid) {
            return null
        }
        for (var i = 0; i < arrayComps.length; i++) {
            let c = arrayComps[i];
            if (c.uid === uid) {
                return c;
            } else {
                if (c.children) {
                    let r = getChildren(c.children, uid)
                    if (r) {
                        return r;
                    }

                }

            }
        }

        return null
    }
    const getPai = (arrayComps, uid, paiAtual) => {
        if (!uid) {
            return null
        }
        for (var i = 0; i < arrayComps.length; i++) {
            let c = arrayComps[i];
            if (c.uid === uid) {
                return paiAtual

            } else {
                if (c.children) {
                    getPai(c.children, uid, c)

                }

            }
        }

        return null
    }

    const removeChildren = (arrayComps, uid) => {
        if (!uid) {
            return null
        }
        for (var i = 0; i < arrayComps.length; i++) {
            let c = arrayComps[i];
            if (c.uid === uid) {
                arrayComps.splice(i, 1);
                break;

            } else {
                if (c.children) {
                    removeChildren(c.children, uid)

                }

            }
        }

        return null
    }


    const editComponent = (c) => {
        const nextState = produce(value, draftState => {
            let r = getChildren(draftState, c.uid)
            if (r) {
                for (const key in c) {
                    if (c.hasOwnProperty(key)) {
                        const element = c[key];
                        r[key] = element;
                    }
                }
            } else {
                console.log("Nao encontrei o componente en edicao");
            }
        })

        onChange(nextState)
    }

    const deleteComponent = (c) => {
        const nextState = produce(value, draftState => {
            removeChildren(draftState, c.uid)
        })
        onChange(nextState)
    }
    const duplicarComponent = (c) => {
        console.log('duplicarComponent', c)
        const removeAllUid = (c) => {
            if (c.uid) {
                delete c.uid;
            }
            if(c.children){
                for (var i = 0; i < c.children.length; i++) {
                    c.children[i]=  removeAllUid(c.children[i])
                }

            }

            return c;
        }

        const nextState = produce(value, draftState => {
            let CompPai = getPai(draftState, c.uid, null)

            let compAdd = ajustNewOkComp(removeAllUid({ ...c }), okform);
            if(CompPai){ 
                CompPai.children.push(compAdd);
            }else{

                draftState.push(compAdd);
            }

        })
        onChange(nextState)
    }

    const extrairComponent = (c) => {

        const extrairChildren = (arrayComps, uid, parentArray) => {
            if (!uid) {
                return null
            }
            for (var i = 0; i < arrayComps.length; i++) {
                let c = arrayComps[i];
                if (c.uid === uid) {
                    if (parentArray) {
                        arrayComps.splice(i, 1);
                        parentArray.push(c)
                        return true;
                    }

                    break;

                } else {
                    if (c.children) {
                        if (extrairChildren(c.children, uid, arrayComps)) {
                            return true;
                        }
                    }

                }
            }

            return null
        }
        const nextState = produce(value, draftState => {
            extrairChildren(draftState, c.uid, draftState)
        })
        onChange(nextState)
    }

    const inserirEmComponent = (c, irmao) => {

        const inserirEmChildren = (arrayComps, uid, uidIrmao) => {
            if (!uid) {
                return null
            }
            let compInserir = null;
            let compInserirIndex = null;
            let compIrmao = null
            for (var i = 0; i < arrayComps.length; i++) {


                let c = arrayComps[i];

                if (c.uid === uidIrmao) {
                    compIrmao = c;
                }
                if (c.uid === uid) {

                    compInserir = c;
                    compInserirIndex = i;
                } else {
                    if (!compIrmao && !compInserir)
                        if (c.children) {
                            inserirEmChildren(c.children, uid, uidIrmao)
                        }

                }
                if (compInserir && compIrmao) {
                    arrayComps.splice(compInserirIndex, 1);
                    compIrmao.children.push(compInserir);
                    break;
                }
            }

            return null
        }
        const nextState = produce(value, draftState => {
            inserirEmChildren(draftState, c.uid, irmao.uid)
        })
        onChange(nextState)
    }

    const moveUp = (c) => {
        const moveChildren = (arrayComps, uid) => {
            if (!uid) {
                return null
            }
            for (var i = 0; i < arrayComps.length; i++) {
                let c = arrayComps[i];
                if (c.uid === uid) {


                    let new_index = i
                    if (i !== 0) {
                        new_index--;
                    }

                    array_move(arrayComps, i, new_index)
                    break;

                } else {
                    if (c.children) {
                        moveChildren(c.children, uid)
                    }
                }
            }

            return null
        }
        const nextState = produce(value, draftState => {
            moveChildren(draftState, c.uid)
        })
        onChange(nextState)
    }

    const moveDown = (c) => {
        const moveChildren = (arrayComps, uid) => {

            if (!uid) {
                return null
            }
            for (var i = 0; i < arrayComps.length; i++) {
                let c = arrayComps[i];
                if (c.uid === uid) {


                    let new_index = i

                    new_index++;


                    array_move(arrayComps, i, new_index)
                    break;

                } else {
                    if (c.children) {
                        moveChildren(c.children, uid)
                    }
                }
            }

            return null
        }
        const nextState = produce(value, draftState => {
            moveChildren(draftState, c.uid)
        })
        onChange(nextState)
    }

    let okformUsar = okform
    if (!okformUsar) {
        okformUsar = {}
    }
    okformUsar.children = value
    return (
        <RenderOkForm {...props}
            okComp={value}
            OverComp={OverComp}
            openProps={openProps}
            editComponent={editComponent}
            deleteComponent={deleteComponent}
            duplicarComponent={duplicarComponent}
            extrairComponent={extrairComponent}
            inserirEmComponent={inserirEmComponent}
            moveUp={moveUp}
            moveDown={moveDown}
            developer={true}
            okform={okformUsar}
            starter={true}


        />
    )
}
