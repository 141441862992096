
import * as RF from "redux-form";
import store from "reducer";

import { useContext } from 'react'

import { ReduxFormContext } from 'redux-form/es/ReduxFormContext'
import { useSelector } from 'react-redux'
import getFormValues from 'redux-form/es/getFormValues'

export default function useForm(form) {

    let atualValuesf = store.getState().form[form] ? store.getState().form[form].values : null
    return {
        change: (field, value) => {
            store.dispatch(RF.change(form, field, value));
        },
        changeA: (field) => (value) => {

            store.dispatch(RF.change(form, field, value));
            RF.change(form, field, value)

        },
        blur: (field, value) => {
            store.dispatch(RF.change(form, field, value));
        },
        reset: () => {
            store.dispatch(RF.reset(form));
        },
        atualValuesf,
        // handleSubmit: e => {
        //     e.preventDefault();
        //     onSubmit(formValues);
        // },
        // useField: name => {
        //     useEffect(() => {
        //         dispatch(RF.registerField(form, name, RF.Field));

        //         return () => {
        //             dispatch(RF.unregisterField(form, name));
        //         };
        //     }, []);

        //     return {
        //         input: {
        //             value: (formValues && formValues[name]) || null,
        //             onChange: e => dispatch(RF.change(form, name, e.target.value))
        //         }
        //     };
        // }
    };
}





export function useReduxForm() {
    return useContext(ReduxFormContext)
}
export function useFormValue(name) {
    return useSelector(state => {
        return getFormValues(name)(state)
    })
}