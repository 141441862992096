import React from 'react'

import useSpeechToText from 'react-hook-speech-to-text';

export default function CompView(props) {
   // const { input: { value, onChange }, } = props;

    // const {
    //     error,
    //     isRecording,
    //     results,
    //     startSpeechToText,
    //     stopSpeechToText,
    //     interimResult
    // } = useSpeechToText({
    //     continuous: true,
    //     timeout: 5000,
    //     speechRecognitionProperties: { interimResults: true }
    // });


    const {
        error,
        isRecording,
        results,
        startSpeechToText,
        stopSpeechToText,
        interimResult
    } = useSpeechToText({
        continuous: true,
        timeout: 5000,
        speechRecognitionProperties: { interimResults: true }
    });
    

    if (error) return <p> Web Speech API is not available in this browser</p>;

    return (
        <div>
            <h1>Recording: {isRecording.toString()}</h1>
            <button onClick={isRecording ? stopSpeechToText : startSpeechToText}>
                {isRecording ? 'Stop Recording' : 'Start Recording'}
            </button>
            <ul>
                {results.map((result, index) => (
                    <li key={index}>{result}</li>
                ))}
                {interimResult && <li>{interimResult}</li>}
            </ul>
        </div>
    );
}

