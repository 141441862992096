import Page from './components/Page'
import { getUserUid } from 'OkApp/OkFunctions'

export const routes = () => {
    return [
        { exact: true,path: "/", component: Page },
    ]
}
export const menus = ({ history, okApp }) => {

    let m = []
    if (!getUserUid() && !okApp) {
        m.push({ index: 0, text: "Início", iconKey: 'Home', action: () => { history.push("/") } })
    }
    return m


}

