import React, { useState } from 'react'
import {  useTheme, useMediaQuery, Dialog,  Fab } from '@material-ui/core'

import SearchIcon from '@material-ui/icons/Search';
import { SketchPicker } from 'react-color';
export default function ColorPiker({ label, value, onChange }) {
    
    const [dialogOpen, setDialogOpen] = useState(false)
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));


    return (
        <>
            <Fab size="small" color="primary" onClick={() => setDialogOpen(true)}>
                <SearchIcon />
            </Fab>
            <Dialog
                fullScreen={fullScreen}
                maxWidth={"lg"}
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                scroll={'body'}
            >
                <div>
                    <SketchPicker color={value} onChangeComplete={(v => onChange({ target: { value: v.hex } }))} />
                </div>
            </Dialog>
        </>
    )
}
