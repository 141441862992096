import React, { useState, useEffect } from 'react'

import { useAtualValues, useOkApp } from 'OkApp/UseUtils'
import { getOkFormSnap } from 'OkApp/OkFunctions'
import { componetIteratorOkForm, getOkCompCore } from 'OkApp/functions'
import { OkTypedComponent, LabelInputAuto } from 'OkApp'
import { sortedUniq } from 'lodash'

export default function Editor(props) {
    const [camposFormulario, setCamposFormulario] = useState([])
    const atualValues = useAtualValues();
    const okApp = useOkApp();
    const { okform } = props
    let uidAuxOkForm = null;
    if (atualValues.uidOkForm) {
        uidAuxOkForm = atualValues.uidOkForm.uid;
    }
    console.log('Editor props', props)
    console.log('atualValues', atualValues)
    useEffect(() => {
        if (okform) {

            let fields = []
            componetIteratorOkForm(okform, (comp) => {
                if (!['PanelFlexComp'].includes(comp.typeComponente)) {
                    if (!fields.includes(comp.field)) {
                        fields.push(comp.field)
                    }
                }
            })
            setCamposFormulario(fields)

        }

    }, [okform])
    return (
        <>
            <OkTypedComponent typeComponente='InputComp' field='field' label='Nome Salvo' />
            <LabelInputAuto {...props} />
            <OkTypedComponent typeComponente='InputComp' field='pronptOrientacao' label='Prompt de orientação adicional' />
            <OkTypedComponent typeComponente='ListaItens' field='camposPreencher' label='Campos Preencher' possiveis={camposFormulario} adicionavel={false} removivel={false} />
            <OkTypedComponent typeComponente='ComboBoxComp' field='model' label='Model' options={["gpt-4", "gpt-3.5-turbo"]} 
              />
            {/* <OkTypedComponent typeComponente='AutoCompleteComp' field='campoResultadoFiltros' label='Campo Resultado Para Filtros'
                options={camposFormulario}
                noOptionsText={"Nenhum item encontrado"}
                somenteOpt={true}
            /> */}
        </>
    )
}

