import React from 'react'

import { OkTypedComponent, LabelInputAuto } from 'OkApp'
import { Field } from 'redux-form'
import { LiveProvider, LiveEditor, LiveError } from 'react-live'
import { reactLiveHome } from './ReactRender'

export default function Editor(props) {


    return (<>
        <OkTypedComponent typeComponente='InputComp' field='field' label='Nome Salvo' />
        <LabelInputAuto {...props} />

        <OkTypedComponent typeComponente='SwitchComp' field='editinview' label='Editar na tela (apenas visualização)(o codigo deve se copiado)' />
        

        <Field name="code" component={({ input }) => {

            return <div>
                <LiveProvider code={input.value} scope={{ props }} noInline={true}
                    theme={reactLiveHome}
                >
                    <LiveEditor className={"StyledEditor"}
                        onChange={input.onChange}
                    />
                    <LiveError className={"StyledError"} />
                </LiveProvider>

            </div>
        }
        }
        />

    </>
    )
}
