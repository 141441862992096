import React, { useEffect, useState } from 'react'
import './styleTabela.css'
import { Typography, TablePagination } from '@material-ui/core'
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';



function Coluna({ coluna, linha, indexrow, data, RenderCell, index }) {
    const adapterString = (v) => {
        if (v) {
            return v;
        }
        return '';

    }

    return (<div className='celTableGrid' >
        <div className={'celTableTitleGrid' + (coluna.classHeaderAdd ? " " + coluna.classHeaderAdd : "")}>
            <Typography style={{
                color: "#929292",
                alignItems: 'center',
                fontSize: 12,
                paddingBottom: 3,
            }}
                component="div"
                noWrap
            >
                {coluna.label || 'Col_' + (index + 1)}
            </Typography>
        </div>
        <div className='celTableGridInner'>

            {RenderCell ?
                <RenderCell coluna={coluna} linha={linha} indexrow={indexrow} data={data} />
                :
                <Typography >{"" + adapterString(linha[coluna.field])}</Typography>
            }
        </div>
    </div>
    )
}

function Linha({ linha, indexrow, columns, data, onRowClick, RenderCell, sizeGridColuns }) {
    let onClick = null;
    if (onRowClick) {
        onClick = () => onRowClick(linha, indexrow);
    }
    // console.log('render linha')
    return (
        <div className='rowTableGrid' onClick={onClick} >
            <>
                {columns.map((c, i) => <Coluna key={i} coluna={c} index={i} linha={linha} data={data} indexrow={indexrow} RenderCell={RenderCell} />)}
            </>
        </div>
    )
}
function LinhaHeader({ columns, data, actionOnClickHeader, sortColumn, sortDirectionDown }) {

    const HeaderCol = ({ c, index }) => {
        let icon = sortColumn === c.label ? sortDirectionDown ? <ArrowDownward style={{
            color: "#929292",
            alignItems: 'center'
        }} /> : <ArrowUpward style={{
            color: "#929292",
            alignItems: 'center'
        }} /> : ""
        return <div className='celTableH' style={{ cursor: actionOnClickHeader ? 'pointer' : 'default' }} onClick={() => {

            if (actionOnClickHeader) {
                actionOnClickHeader(c)
            }
        }}>

            <Typography style={{
                color: "#929292",
                alignItems: 'center'
            }}
                component="div"
                noWrap
            >
                {c.label || 'Col_' + (index + 1)}
            </Typography>
            {icon}
        </div>
    }
    return (
        <div className='rowTableHeaderGrid'>
            {columns.map((c, i) => <HeaderCol key={i} c={c} index={i} />)}
        </div>
    )
}

export default function Tabela(props) {
    let LIMITE_TAM_TABELA = 15
    const { data, columns, onRowClick, rowsPer, msgSemRegistro, RenderCell, getValueSort, sortColuns, resetData } = props
    // let linhas = data || []
    const [linhas, setLinhas] = useState([])
    const pusar = rowsPer ? rowsPer : linhas.length > LIMITE_TAM_TABELA ? LIMITE_TAM_TABELA : null;
    const [rowsPerPage, setRowsPerPage] = React.useState(pusar);
    // eslint-disable-next-line no-unused-vars
    const [rowsPerPageOptions, setRowsPerPageOptions] = React.useState([15, 25, 50, 100])
    const [page, setPage] = React.useState(0);
    const [sortColumn, setSortColumn] = useState(null)
    const [sortDirectionDown, setSortDirectionDown] = useState(false)

    useEffect(() => {
        setRowsPerPage(pusar)
    }, [pusar])

    useEffect(() => {
        setLinhas(data || [])
    }, [data])
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    let adicionadorIndex = 0
    let linhasPagina = linhas
    if (rowsPerPage) {
        linhasPagina = linhasPagina.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        adicionadorIndex = page * rowsPerPage
    } else {
        if (linhasPagina.length > LIMITE_TAM_TABELA) {
            linhasPagina = linhasPagina.slice(0, LIMITE_TAM_TABELA)
        }
    }

    let semItens = !linhas.length && msgSemRegistro
    let sizeGridColuns = ''
    columns.forEach(coluna => {
        let sizecol = coluna.sizecol
        if (sizecol) {
            if (isNaN(sizecol)) {
                if (sizecol === "auto") {

                    sizeGridColuns += "1fr "
                } else {
                    sizeGridColuns += sizecol + " ";
                }

            } else {
                sizeGridColuns += sizecol + "px ";
            }

        } else {
            sizeGridColuns += "1fr "
        }
    });
    const actionOnClickHeader = (colunm) => {
        if (colunm) {
            let newSortColumn = sortColumn
            let newsortDirectionDown = sortDirectionDown
            if (colunm.label === newSortColumn) {
                newsortDirectionDown = !newsortDirectionDown;
            } else {
                newSortColumn = colunm.label
                newsortDirectionDown = false
            }
            setSortColumn(newSortColumn)
            setSortDirectionDown(newsortDirectionDown)
            setPage(0)

            let newArray = data ? data.slice() : [];
            function compare(a, b) {
                let ajust = newsortDirectionDown ? -1 : 1


                if (!getValueSort(a, colunm)) {
                    return 1 * ajust;
                }
                if (!getValueSort(b, colunm)) {
                    return -1 * ajust;
                }
                if (getValueSort(a, colunm) < getValueSort(b, colunm)) {
                    return -1 * ajust;
                }
                if (getValueSort(a, colunm) > getValueSort(b, colunm)) {
                    return 1 * ajust;
                }
                return 0;
            }
            newArray.sort(compare);
            if (resetData) {
                resetData(newArray)
            } else {

                setLinhas(newArray)
            }
            //do sort
        }
    }

    return (
        <div className='rootTableGridPag' >
            <div className='rootTableGridContant' >

                <div className='rootTableGrid' style={{ gridTemplateColumns: sizeGridColuns }}>
                    <LinhaHeader columns={columns} data={data} actionOnClickHeader={sortColuns ? actionOnClickHeader : null} sortColumn={sortColumn} sortDirectionDown={sortDirectionDown} />
                    {linhasPagina.map((l, i) => <Linha key={adicionadorIndex + i} sizeGridColuns={sizeGridColuns} indexrow={adicionadorIndex + i} linha={l} columns={columns} data={data} onRowClick={onRowClick} RenderCell={RenderCell} />)}

                </div>
            </div>
            {semItens && <Typography style={{ textAlign: 'center', gridColumn: "1/" + (columns.length || 1) }}>{msgSemRegistro}</Typography>}
            {rowsPerPage && <TablePagination
                style={{ gridColumn: "1/" + (columns.length || 1) }}
                labelRowsPerPage=""
                rowsPerPageOptions={rowsPerPageOptions}
                component="div"
                count={(data || []).length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />}
        </div>
    )
}
