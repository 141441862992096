import React, { useState, useEffect } from 'react'

import { useAtualValues, useOkApp } from 'OkApp/UseUtils'
import { getOkFormSnap } from 'OkApp/OkFunctions'
import { componetIteratorOkForm } from 'OkApp/functions'
import { OkTypedComponent, LabelInputAuto } from 'OkApp'

export default function Editor(props) {
    const [camposFormulario, setCamposFormulario] = useState([])
    const atualValues = useAtualValues();
    const okApp = useOkApp();
    let uidAuxOkForm = null;
    if (atualValues.uidOkForm) {
        uidAuxOkForm = atualValues.uidOkForm.uid;
    }
    useEffect(() => {
        if (uidAuxOkForm) {
            getOkFormSnap(okApp, uidAuxOkForm, (r) => {
                let fields = []
                componetIteratorOkForm(r, (comp) => {
                    if (!['PanelFlexComp'].includes(comp.typeComponente)) {
                        if (!fields.includes(comp.field))
                            fields.push(comp.field)
                    }
                })
                setCamposFormulario(fields)
            }, (err) => {
                setCamposFormulario([])
            })
        }

    }, [uidAuxOkForm, okApp])
    return (
        <>
            <OkTypedComponent typeComponente='InputComp' field='field' label='Nome Salvo' />
            <LabelInputAuto {...props} />
            <OkTypedComponent typeComponente='InputComp' field='tituloPesquisa' label='Titulo da Pesquisa' />
            <OkTypedComponent typeComponente='SeletorOkForm' field='uidOkForm' label='Formulário' />
            <div style={{ display: 'flex' }}>

                <OkTypedComponent typeComponente='SwitchComp' field='keepUpdate' label='Manter Valor Atualizado' />
                <OkTypedComponent typeComponente='SwitchComp' field='showNewTab' label='Exibir Botão Nova Aba' />
            </div>
            <OkTypedComponent typeComponente='ListaItens' field='saveFields' label='Campos Salvar' possiveis={camposFormulario} adicionavel={false} removivel={false} />
            <OkTypedComponent typeComponente='ListaItens' field='loadFields' label='Campos Carregar' possiveis={camposFormulario} adicionavel={false} removivel={false} />
            <OkTypedComponent typeComponente='ListaItens' field='toStringFields' label='Campos Exibir' possiveis={camposFormulario} adicionavel={false} removivel={false} />
            <OkTypedComponent typeComponente='FunctionsEditor' height={500} startFunc={"function filterList(list,props) {\n\treturn list;\n}"} field='filterListCode' label='Filtrar lista' />

        </>
    )
}

