import { Button } from '@material-ui/core'
import React from 'react'


export default function PageAppNaoExiste() {

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

            <h1>Nenhum aplicativo encontrado para esta rota</h1>
            <div>

                <Button variant='contained' color='primary' onClick={() => window.open('.')} >
                    Ir para Início
                </Button>
            </div>
        </div>
    )
}
