import React from 'react'

import { Field } from 'redux-form';

import { EditorCss } from 'components';
import _ from 'lodash'

import { OkTypedComponent } from 'OkApp';
import { transform } from './transform';
import OkFormAbaAdapter from 'OkApp/components/OkFormAbaAdapter';
export function OkCompModuleAdapterFunc(props) {
    let propsCopy = { ...props }
    let { okComp } = propsCopy;


    if (!propsCopy.developer && okComp.onlyindev) {
        return { ...propsCopy, okComp: { ...okComp, hidden: true } }
    }
    
    let styleCssOver = props.styleCssOver
    let sizeWidth = _.get(okComp, 'metaOkApp.style.sizeWidth');
    if (sizeWidth !== undefined && sizeWidth !== '') {
        if (!styleCssOver) styleCssOver = {};
        if (sizeWidth.includes("px")) {
            styleCssOver.minWidth = sizeWidth
            styleCssOver.flex = 0;
        } else {
            styleCssOver.flex = parseInt(sizeWidth)
        }
    }
    let overCss = _.get(okComp, 'metaOkApp.style.overCss');
    if (overCss) {
        let cssObj = transform(overCss);
        if (cssObj && cssObj.this) {
            if (!styleCssOver) styleCssOver = {};
            styleCssOver = { ...styleCssOver, ...cssObj.this }
        }
        propsCopy.overCssStyleComp = overCss
    }
    if (styleCssOver) {
        propsCopy.styleCssOver = styleCssOver
    }
    return propsCopy;
}

export function CompAddCustomComponent(props) {
    return <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>

        <div style={{ display: 'flex' }}>

            <OkTypedComponent typeComponente='SwitchComp' field='disabled' label='Desativado' />
            <OkTypedComponent typeComponente='SwitchComp' field='required' label='Requerido(*)' />
        </div>
        <div>

            <OkTypedComponent typeComponente='SwitchComp' field='onlyindev' label='Somente visivel em desenvolvimento' />
        </div>

        <div>
            <OkTypedComponent typeComponente='InputComp' field='metaOkApp.style.sizeWidth' label='Tamanho(flex ou px (5 ou 50px))' />
        </div>
        <div style={{ display: 'flex', flex: 1 }}>

            <Field name='metaOkApp.style.overCss' component={EditorCss} label={"CSS"} baseFixa=".this{}" noDialog />
        </div>
    </div>
}




export function OkFormMetaAba(props) {

    return <OkFormAbaAdapter keyTab={"Estilo"} {...props}>
        <div style={{ display: 'flex', flexDirection: "column", height: "100%" }}>

            <Field name='metaOkApp.style.overCss' component={EditorCss} label={"CSS"} noDialog />
        </div>
    </OkFormAbaAdapter>
}






export const data = { name: "StyleCompModule", abaMetaGeral: "Estilo", abaMetaCustomComponent: "Estilo" }