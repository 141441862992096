import React, { useEffect } from "react";
// import PDF from 'react-pdf-js-infinite';
import myPdf from "../../File/TERMO_DE_USO_OKAPP.pdf";
import { useCrudContext } from "crud";
const PDFViewer = () => {
  return <iframe src={myPdf} width="100%" height="100%" />;
};

export default function TermosUso(props) {
  const { setTitle } = useCrudContext();

  useEffect(() => {
    setTitle("Termos de uso");
    return () => setTitle("");
  }, [setTitle]);

  return (
    <div style={{ textAlign: "-webkit-center", flex: 1 }}>
      <iframe src={myPdf} width="100%" height="100%" />
      {/* <PDF style={{ width: 'min-content' }} file={myPdf} scale={1.6} /> */}
    </div>
  );
}
