import React from 'react'
import OkCompAdapter from 'OkApp/components/OkCompAdapter'
import { OkTypedComponent, LabelInputAuto } from 'OkApp'
import CompView from './components/CompView'
import { getFunctionCodeToFuncMap, createDefaultFuncMapStr } from 'OkApp/CodeProcessor'

const typeComponente = 'HtmlCodeEditor'

const AdapterOkComp = (props) => {
    const { okComp } = props

    let sn = []
    let funcAux = getFunctionCodeToFuncMap(okComp.functionsMap, "getSnippets");
    if (funcAux)
        sn = funcAux()
    let propsComp = { snProps: sn.concat(okComp.snippets || []) }
    return <OkCompAdapter {...props} propsComp={propsComp} component={CompView} />
}
const EditorOkComp = (props) => {

    return <div style={{ display: 'flex', flexDirection: "column", height: "100%" }}>
        <div>
            <OkTypedComponent typeComponente='InputComp' field='field' label='Nome Salvo' />
            <LabelInputAuto {...props} />
        </div>
        <OkTypedComponent typeComponente='FunctionsEditor' field='functionsmap' label='Snnipets' />
    </div>
}


// function getSnippets() {return [{ name: 'eita23', code: 'console.log("eita23")' }]}

const modelo = {
    typeComponente,
    field: 'html',
    label: 'Html Code Editor',
    functionsmap: createDefaultFuncMapStr('getSnippets', "function getSnippets() {\n\treturn [{ name: 'eita23', code: 'console.log(\"eita23\")' }]\n}"),
}


const FunctionsEditorComp = {
    typeComponente,
    configView: {
        grupo: 'Avançado',
        nome: 'HtmlCodeEditor',
        descricao: 'HtmlCodeEditor',
        helpUrl: 'https://www.google.com.br/',
        modelo
    },
    Component: AdapterOkComp,
    CompEditor: EditorOkComp
}
export default FunctionsEditorComp

// function criaFuncoes(code) {
//     var arrStr = code.split('function ');
//     let classe = {};
//     arrStr.forEach(m => {
//         if (m) {
//             let nome = m.split('(')[0].trim();
//             let func = " return function " + m;
//             let funJs = new Function(func)
//             funJs.bind(this)//////// aqui....
//             classe[nome] = funJs().bind(this);
//         }

//     })
//     return classe;
// }
