import useOKControlerField from 'components/OkStyleEditor/functionsUtils'
import React from 'react'
import AlignItems from './components/AlignItems';
import BackgroundColor from './components/BackgroundColor';
import JustifyContent from './components/JustifyContent';
import SizeComp from './components/SizeComp';
import Border from './components/Border';
import Overflow from './components/Overflow';
import Padding from './components/Padding';
import Margin from './components/Margin';
import Font from './components/Font';

export default function FlexSessionEditor({ validComponents, styleAtual, value, onChange }) {
    const okControler = useOKControlerField(value, onChange);
    return (
        <div className='OkStyleEditor-container'>
            {(!validComponents || validComponents.includes("size")) && <SizeComp styleAtual={styleAtual} value={value} onChange={onChange} />}
            {(!validComponents || validComponents.includes("backgroundColor")) && <BackgroundColor {...okControler("backgroundColor")} styleAtual={styleAtual} />}
            {(!validComponents || validComponents.includes("padding")) && <Padding styleAtual={styleAtual} value={value} onChange={onChange} />}
            {(!validComponents || validComponents.includes("margin")) && <Margin styleAtual={styleAtual} value={value} onChange={onChange} />}
            {(!validComponents || validComponents.includes("justifyContent")) && <JustifyContent {...okControler("justifyContent")} styleAtual={styleAtual} />}
            {(!validComponents || validComponents.includes("alignItems")) && <AlignItems {...okControler("alignItems")} styleAtual={styleAtual} />}
            {(!validComponents || validComponents.includes("border")) && <Border styleAtual={styleAtual} value={value} onChange={onChange} />}
            {(!validComponents || validComponents.includes("overflow")) && <Overflow {...okControler("overflow")} styleAtual={styleAtual} />}
            {(!validComponents || validComponents.includes("font")) && <Font styleAtual={styleAtual} value={value} onChange={onChange} />}
        </div>
    )
}
