import React, { useState } from 'react'
import { TextField, Fab } from '@material-ui/core'
import { PesquisaDialog } from 'components';
import SearchIcon from '@material-ui/icons/Search';
import { Delete } from '@material-ui/icons';
import { useAtualValues } from 'OkApp/UseUtils';

export default function OkFormSel(props) {
    const { input: { value, onChange }, label, tituloPesquisa, permiteRemover } = props
    const [showPesquisa, setShowPesquisa] = useState(false)

    let uidOkApp = useAtualValues("uid");



    let colectionFirebase = null;
    if (uidOkApp) {
        colectionFirebase = 'Project/' + uidOkApp + '/CustomForm';
    }


    const toStringValue = v => {
        return v ? v.name + " - " + v.uid : ""
    }

    const adapterSelectedValue = f => {
        return f ? { uid: f.uid, name: f.name } : null
    }
    return (
        <div style={{ display: 'flex', flex: 1, padding: 5, marginBottom: 15 }}>
            <TextField
                value={toStringValue(value)}
                label={label}
                fullWidth
                InputLabelProps={{
                    shrink: true,
                    readOnly: true,
                }}
            />
            {permiteRemover &&
                <Fab size="small" color="primary" style={{marginRight:10}} onClick={() => { onChange(null) }}>
                    <Delete />
                </Fab>
            }
            <Fab size="small" color="primary" onClick={() => { setShowPesquisa(true) }}>
                <SearchIcon />
            </Fab>
            {colectionFirebase && <PesquisaDialog open={showPesquisa} handleClose={() => setShowPesquisa(false)}
                filterEditar
                title={tituloPesquisa || "Pesquisa de OkForm"}
                colectionFirebase={colectionFirebase}
                onSelect={(selecionou) => {
                    setShowPesquisa(false);
                    if (selecionou.length) {
                        onChange(adapterSelectedValue(selecionou[0]))
                    }
                }}
                columns={[
                    { label: "Nome", field: "name", options: { filter: true, sort: true, } },
                    { label: "Rota", field: "route", options: { filter: true, sort: true, } },
                    { label: "Descrição", field: "description", options: { filter: true, sort: true, } },
                ]} />
            }

        </div>
    )
}
