import React, { useRef, useEffect, useCallback } from 'react'
import { Field } from 'redux-form'
import { Input } from 'components'


export default function LabalInput({ actionSaveComp }) {
    const refInput = useRef(null)

    const onKeyPress = useCallback((e) => {
        if (e.key === 'Enter') {
            if (actionSaveComp) {
                actionSaveComp();
            }
        }

    }, [actionSaveComp])
    useEffect(() => {
        const timeout = setTimeout(() => {
            refInput.current.focus();
            refInput.current.select();
        }, 100);

        return () => {
            clearTimeout(timeout);
        };


    }, [])
    return (
        <div style={{ paddingLeft: 5, paddingRight: 5, flex: 1 }} >
            <Field name="label" label='Label' onKeyPress={onKeyPress} inputRef={refInput} component={Input} />
        </div>
    )
}
