import React from 'react'

import { Tooltip } from '@material-ui/core';
import _ from 'lodash'

import { Combobox, Input, SeletorDePath } from 'components';
import useCriaControlerField from '../../functionsUtil';
import { useAtualValues } from 'OkApp/UseUtils';

function QrCodeReportComp({ value, onChange, valueRef, removeCelula }) {
    const criaControler = useCriaControlerField(value, onChange)
    let atualOkReport = useAtualValues()
    let listStyle = [
        { name: "Titulo", value: "cellTitulo" },
        { name: "Texto", value: "cellValor" },
    ]
    if (atualOkReport.styles) {
        listStyle = []
        for (const key in atualOkReport.styles) {
            if (Object.hasOwnProperty.call(atualOkReport.styles, key)) {
                listStyle.push({ name: key, value: key })

            }
        }

    }

    return (
        <div>
            <div style={{ paddingBottom: 10 }}>
                <SeletorDePath {...criaControler("chave")} label='Chave/Valor/Caminho' data={valueRef} />
            </div>
            <div style={{ flex: 1, display: 'flex' }}>
                <Combobox style={{ flex: 1 }} {...criaControler("style")} label="Estilo" itens={listStyle.map(v => v.value)} toStringItem={listStyle.map(v => v.name)} />
            </div>

            <div style={{ flex: 1, display: 'flex' }}>

                <Combobox style={{ flex: 1, paddingLeft: 5, paddingRight: 5 }} {...criaControler("alignment")} label="Alinhamento" itens={['left', 'center', 'right']} toStringItem={['Esquerda', 'Centro', 'Direita']} />
                <div style={{ flex: 1, }}>
                    <Input label='Tamanho' {...criaControler("sizeExibir")} />
                </div>
                <div style={{ flex: 1, }}>
                    <Tooltip title={"* - Maior possivel, auto - Menor possivel, 'numero' exato "}>
                        <Input label='Tamanho Celula(*,auto ou numero)' {...criaControler("width")} />
                    </Tooltip>
                </div>
            </div>
        </div>
    )
}





async function convertToPdf(compConfig, json) {

    const { nome, chave, sizeExibir, tipoValor, formato, typeComponent, width, ...custom } = compConfig
    let r = chave
    if (tipoValor === 'get') {
        r = _.get(json, chave)
    }


    let retorno = await toDataUrl(r, function (myBase64) {
        console.log("myBase64", myBase64); // myBase64 is the base64 string
    });
    return { ...custom, image: retorno, width: sizeExibir };

}
async function toDataUrl(url) {
    return await new Promise((resolve, reject) => {
        var xhr = new XMLHttpRequest();
        xhr.onload = function () {
            var reader = new FileReader();
            reader.onloadend = function () {
                resolve(reader.result);

            }
            reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    }).catch((error) => {
        console.log(`Something happened: ${error}`);

    });
}

const QrCodeReport = {
    typeComponent: "AnexoImagemReport",
    modelo: {
        typeComponent: "AnexoImagemReport",
        nome: "Anexo Imagem",
        style: "cellValor",
        tipoValor: "get",
        width: "*",
        chave: "",
        alignment: "center",
        sizeExibir: 240
    },
    Component: QrCodeReportComp,
    convertToPdfFunc: convertToPdf

}
export default QrCodeReport
