import React from 'react'

import { OkTypedComponent, LabelInputAuto } from 'OkApp'
import { useAtualValues } from 'OkApp/UseUtils'
const startFunc = `function collectionQuerry(c,filtros) {
	//https://cloud.google.com/firestore/docs/query-data/queries#in_and_array-contains-any
	
	//if(filtros.CDMATRIZ) c=c.where('CDMATRIZ', '==', parseInt(filtros.CDMATRIZ));
	
	return c;
}

function ajustFilterList(list) {
    return list
}

`
export default function Editor(props) {
    let type = useAtualValues("type")

    //https://cloud.google.com/firestore/docs/query-data/queries#in_and_array-contains-any
    return (
        <>
            <OkTypedComponent typeComponente='InputComp' field='field' label='Nome Salvo' />
            <LabelInputAuto {...props} />
            <OkTypedComponent typeComponente='SeletorOkForm' field='auxOkForm' label='Formulário' />

            <OkTypedComponent typeComponente='SwitchComp' field='salvarDados' label='Salvar Dados' />
            <OkTypedComponent typeComponente='ComboBoxComp' field='type' label='Tipo' options={['getAll', 'onChange', 'onClick']} toStringItem={(v) => {
                switch (v) {
                    case 'getAll': return "Buscar Todos"
                    case 'onChange': return "Ao alterar filtros"
                    case 'onClick': return "Clicar no botão"
                    default: return "Ao alterar filtros";
                }
            }} />
            {type !== 'getAll' && <>
                <div style={{ display: 'flex' }}>
                    <OkTypedComponent typeComponente='InputComp' field='limitResultados' label='Limite de Resultado' type="number" />
                    <OkTypedComponent typeComponente='InputComp' field='msgLimite' label='Mensagem limite de Resultados' />
                </div>
                {type === 'onClick' ?
                    <>
                        <OkTypedComponent typeComponente='InputComp' field='textBotao' label='Texto do Botão' />
                    </>
                    :
                    <>
                        <OkTypedComponent typeComponente='ListaItens' field='fieldsFilter' label='Fields Filtros' adicionavel={true} removivel={true} />
                    </>}
            </>

            }
            {type !== 'getAll' &&
                <OkTypedComponent typeComponente='FunctionsEditor' field='collectionQuery' label='Collection Query' height={1000}
                    startFunc={startFunc}
                />}
        </>
    )
}
