

import logo64 from '../logo';

const stylesTemplate = {
    template: {

        rodapeTexto: {
            fontSize: 10,
        }
    },
    relatorioTitulo: {
        fontSize: 20,
        bold: true,
    },
    cabecalhoTexto1: {
        fontSize: 18,
        bold: true,
    },
    cabecalhoTexto2: {
        fontSize: 12,
        bold: false,

    },
    cellTitulo: {
        marginBottom: 1,
        marginLeft: 1,
        marginRight: 1,
        marginTop: 1,

        fontSize: 9,
        bold: true,
        fillColor: '#dcdcdc',
    },

    cellValor: {
        marginBottom: 1,
        marginLeft: 1,
        marginRight: 1,
        marginTop: 1,
        fontSize: 9,
        fillColor: '#f0f0f0',
    },
    cellLimpo: {
        marginBottom: 1,
        marginLeft: 1,
        marginRight: 1,
        marginTop: 1,
        fontSize: 9,
    },
    fieldsetTitulo: {

        marginBottom: 2,
        marginLeft: 2,
        marginRight: 2,
        marginTop: 2,
        fontSize: 11,
        bold: true,
        fillColor: '#285137',
        color: 'white'
    }
}

export function gerarDocumento(okReport, conteudo, meta) {
    let tituloRelatorio = "Titulo Relatorio"
    let textoCabecalho1 = ""
    let textoCabecalho2 = ""
    let textoRodape1 = ""
    let textoRodape2 = ""

    let stylesUsar = stylesTemplate;

    const { styles, nome } = okReport
    if (styles) {
        stylesUsar = { ...stylesUsar, ...styles }
    }
    if (nome)
        tituloRelatorio = nome;

    let imageLogo = logo64;
    if (meta) {
        if (meta.logo) {
            imageLogo = `data:image/png;base64,${meta.logo}`;
        }
        if (meta.tituloRelatorio) {
            tituloRelatorio = meta.tituloRelatorio
        }
        textoCabecalho1 = meta.textoCabecalho1
        textoCabecalho2 = meta.textoCabecalho2
        textoRodape1 = meta.textoRodape1
        textoRodape2 = meta.textoRodape2
    }

    const documentImprimir = templatePadrao(conteudo,
        {
            icone: imageLogo,
            tituloRelatorio,
            textoCabecalho1,
            textoCabecalho2,
            textoRodape1,
            textoRodape2,

        }, stylesUsar, meta, okReport
    );
    return documentImprimir
}



export function templatePadrao(conteudo, templateDados, styles, meta, okReport) {

    const documentDefinition = {
        pageMargins: okReport.pageMargins || [25, 25, 25, 25],
        pageSize: okReport.pageSize || 'A4',
        pageOrientation: okReport.pageOrientation || 'portrait',
        content: conteudo,
        styles: styles,

    };

    return documentDefinition;
}