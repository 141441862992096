import React from 'react';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import produce from "immer"
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Tooltip } from '@material-ui/core';
import SubMenuItem from './components/SubMenu';

function array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
};
export default function SimpleMenu(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { okComp, strComp, okCompsIrmaos, extrairComponent, inserirEmComponent, deleteComponent, duplicarComponent } = props
    const { input: { value, onChange } } = props; //input do Children do Elaborar pra redirecionar comps



    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const moveChildren = (arrayComps, uid, funcIndex) => {
        if (!uid) {
            return null
        }
        for (var i = 0; i < arrayComps.length; i++) {
            let c = arrayComps[i];
            if (c.uid === uid) {

                array_move(arrayComps, i, funcIndex(i, arrayComps.length))
                break;

            } else {
                if (c.children) {
                    moveChildren(c.children, uid, funcIndex)
                }
            }
        }

        return null
    }

    const moveUp = (c) => {
        const nextState = produce(value, draftState => {
            moveChildren(draftState, c.uid, (i, l) => {
                let new_index = i
                if (i !== 0) {
                    new_index--;
                }
                return new_index;
            })
        })
        onChange(nextState)
    }
    const moveDown = (c) => {
        const nextState = produce(value, draftState => {
            moveChildren(draftState, c.uid, (i, l) => {
                let new_index = i
                if (i < l - 1) {
                    new_index++;
                }
                return new_index;
            })
        })
        onChange(nextState)
    }

    const moveInicio = (c) => {
        const nextState = produce(value, draftState => {
            moveChildren(draftState, c.uid, () => 0)
        })
        onChange(nextState)
    }
    const moveFim = (c) => {
        const nextState = produce(value, draftState => {
            moveChildren(draftState, c.uid, (i, l) => l - 1)
        })
        onChange(nextState)
    }


    return (
        <>
            <Tooltip title={<div>Mais opções...<br />{strComp}</div>}>
                <MoreVertIcon className="actionIconOver" onClick={handleClick} />
            </Tooltip >
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={() => {
                    deleteComponent(okComp)
                    handleClose()
                }}>Excluir</MenuItem>
                <MenuItem onClick={() => {
                    duplicarComponent(okComp)
                    handleClose()
                }}>Duplicar</MenuItem>
                <MenuItem onClick={() => {
                    extrairComponent(okComp)
                    handleClose()
                }}>Extrair do Painel</MenuItem>
                <SubMenuItem label={"Mover..."} parentAnchorEl={anchorEl}>

                    <MenuItem onClick={() => {
                        moveInicio(okComp)
                        handleClose()
                    }}>Para inicio</MenuItem>

                    <MenuItem onClick={() => {
                        moveUp(okComp)
                        handleClose()
                    }}>Sobe</MenuItem>
                    <MenuItem onClick={() => {
                        moveDown(okComp)
                        handleClose()
                    }}>Desce</MenuItem>

                    <MenuItem onClick={() => {
                        moveFim(okComp)
                        handleClose()
                    }}>Para fim</MenuItem>


                </SubMenuItem>
                <SubMenuItem label={"Inserir em..."} parentAnchorEl={anchorEl}>
                    {okCompsIrmaos && okCompsIrmaos.map((c, i) => {
                        if (c.children) {
                            return <MenuItem key={i} onClick={() => {
                                inserirEmComponent(okComp, c)
                                handleClose()
                            }}>{c.label}</MenuItem>
                        } else {
                            return ''
                        }
                    }
                    )}

                </SubMenuItem>




            </Menu>
        </>
    );
}