import Page from './components/Page'



export const routes = () => {
    return [
        { exact: true, path: "/TermosUso", component: Page },
    ]
}
export const menus = ({ history, okApp }) => {


    let m = []


    m.push({index: 999999, text: "Termos de Uso", iconKey: 'Assignment', action: () => { history.push("/TermosUso") } })

    return m;
}

