import React, { useEffect, useState } from 'react'
import { Field, reduxForm } from 'redux-form'
import { withStyles } from '@material-ui/core/styles';
import { TextField, Button, CircularProgress, Avatar, Typography, Box, useTheme, useMediaQuery } from "@material-ui/core";
import './style.css'
import firebaseUtil from '../../../../../firebaseUtil'
import useNotification from '../../../../../notification';

import { useTranslation } from 'react-i18next';
import useAuth from '../../../../../useAuth';
import LoginIntegrado from 'routes/public/components/LoginIntegrado';
import { getOkFormSnap } from 'OkApp/OkFunctions';

import loginPage2 from './func2.png'

const renderTextField = ({ input, label, meta: { touched, error }, valid, loginUsuario, handleSubmit, ...custom }) => (
    <TextField
        label={label}
        error={(touched && error) ? true : false}
        helperText={(touched && error) ? error : ""}
        onKeyDown={(e) => {
            if (e.keyCode === 13 && valid) {
                handleSubmit(loginUsuario)();
            }
        }}
        fullWidth

        {...input}
        {...custom}
    />
)
function Login(props) {
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { t } = useTranslation();
    const { showWarning } = useNotification()

    const { handleSubmit, submitting, valid, pagePosLogin, okApp } = props
    const [pagePosLoginST, setPagePosLoginST] = useState(pagePosLogin)
    const { notAuthenticatedOnly, redirectPosAuth, redirectRegister, registerLogin, redirectPasswordRecovery } = useAuth();

    notAuthenticatedOnly();

    useEffect(() => {
        let observer = null
        if (okApp && pagePosLoginST === "/app/" + okApp.route) {
            if (okApp.formInicio) {
                observer = getOkFormSnap(okApp, okApp.formInicio.uid,
                    (r) => {
                        if (r) {
                            setPagePosLoginST("/app/" + okApp.route + "/" + r.route)
                        }
                    },
                    (err) => {
                    }
                );
            }
        }
        return () => {
            if (observer) observer()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [okApp])

    async function loginUsuario(dados) {
        try {
            await firebaseUtil.login(dados.email, dados.password)
            registerLogin()
            redirectPosAuth(pagePosLoginST)
        } catch (error) {
            console.log("error.message", error.message);
            showWarning(t(error.code))
        }
    }

    return (
        <Box display="flex" alignItems="center" justifyContent="center" height="100vh" width="100%" style={{ background: "linear-gradient(to bottom, #575fff83,  #ef9c3283)" }}>
            <Box display="flex" maxWidth={mobile ? "95%" : 755} flexDirection={mobile ? "column" : "row"} borderRadius={15} style={{ background: mobile ? "#eeeeee95" : "#eee", boxShadow: "0px 0px 11px 0px rgba(0,0,0,0.54)" }} height="fit-content">
                <Box padding={3} display="flex" flexDirection="column" width={mobile ? "100%" : "50%"}>
                    <Typography style={{ textAlign: 'center', color: "rgb(82, 82, 82)", marginBottom: 20, fontWeight: 'bold', fontSize: 18, fontStyle: 'italic' }}>
                        Faça login na plataforma
                    </Typography>
                    <Box marginBottom={2}>
                        <Field name="email" variant="outlined" valid={valid} handleSubmit={handleSubmit} loginUsuario={loginUsuario} component={renderTextField} label={t('user.email')} />
                    </Box>
                    <Box>
                        <Field name="password" variant="outlined" valid={valid} handleSubmit={handleSubmit} loginUsuario={loginUsuario} component={renderTextField} label={t('user.password')} type="password" />
                    </Box>
                    <Typography style={{ cursor: "pointer", textDecoration: "underline", fontSize: 12 }} onClick={() => redirectPasswordRecovery(okApp)}>{t('user.forgotPassword')}</Typography>
                    <Box style={{ paddingTop: 15 }} >
                        <Button variant="contained" color="primary" fullWidth onClick={handleSubmit(loginUsuario)} disabled={submitting}>
                            {!submitting && t('auth.login')}
                            {submitting && <CircularProgress size={25} />}
                        </Button>
                    </Box>
                    <Box display="flex" alignItems='center' justifyContent='center' style={{ paddingTop: 10, paddingBottom: 10 }}>
                        <Typography style={{ fontStyle: "italic", color: "rgb(82, 82, 82)" }}>ou</Typography>
                    </Box>
                    <LoginIntegrado pagePosLogin={pagePosLoginST} />

                    <Typography style={{ fontSize: 12, cursor: "pointer", textDecoration: "underline", marginTop: 15, textAlign: 'center', color: theme.palette.primary.light }} onClick={() => redirectRegister(okApp)}>Não tenho uma conta</Typography>
                </Box>
                {!mobile &&
                    <Box display="flex" flexDirection="column" style={{ width: "50%", background: 'lightgray', borderBottomRightRadius: 15, borderTopRightRadius: 15 }} padding={5}>
                        <Avatar variant={'rounded'} alt={'logo'} style={{ width: "300px", height: "300px" }} src={loginPage2} />
                    </Box>
                }
            </Box>
        </Box>
    )
}

const validate = values => {
    const errors = {}

    if (!values.email) {
        errors.email = 'Requerido'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Email inválido'
    }
    if (!values.password) {
        errors.password = 'Requerido'
    }
    return errors
}

export default reduxForm({
    validate,
    form: 'LoginUser'
})(withStyles({})(Login))