import { useEffect, useState, useContext, useCallback, useMemo, useRef } from 'react'
import { getOkFormSnap, getOkObjSnap, getOkAppListSnap, getOkFormListSnap, getUsersListSnap, getStrCollectonsListSnap, getUserSnap, getOkFormListRoutesSnap, getOkObjListSnapDatabase, getUsersListSnapAdmin, getOkAppListSnapAdmin } from './OkFunctions';
import { useOkAppContext } from './context';
import _ from 'lodash'

import firebaseUtil from '../firebaseUtil'
import { ReduxFormContext } from 'redux-form/es/ReduxFormContext'
import { getFunctionCodeToFuncMap, getClassCodeToFuncMap } from './CodeProcessor';
import { useCrudContext } from 'crud';




//#region - Geral
export function usePerfilUser() {
    const { perfilUser } = useOkAppContext();
    return perfilUser
}


export function useUserUid() {
    if (!firebaseUtil.getCurrentUser()) {
        return null;
    }
    return firebaseUtil.getCurrentUser().uid
}

export function useUserSnap(uiduser) {
    const [user, setUser] = useState(null)


    useEffect(() => {
        let observer
        if (uiduser)
            observer = getUserSnap(uiduser,
                (r) => {
                    setUser(r)
                },
                (err) => {
                    setUser(undefined)
                }
            );
        return () => {
            if (observer) {
                observer();
            }
        }

    }, [uiduser])


    return user
}

export const useUsersListSnap = (strFilter) => {
    console.log("strFilter ", strFilter);
    const [list, setList] = useState(null);
    useEffect(() => {
        let observer = null
        observer = getUsersListSnap(strFilter,
            (r) => {
                setList(r);
            }, (err) => {
                console.log('err', err)
                setList([]);

            }
        )

        return () => {
            if (observer) observer()
        }
    }, [strFilter])

    return list
}
export const useUsersListSnapAdmin = () => {
    const [list, setList] = useState(null);
    useEffect(() => {
        let observer = null
        observer = getUsersListSnapAdmin(
            (r) => {
                setList(r);
            }, (err) => {
                console.log('err', err)
                setList([]);

            }
        )

        return () => {
            if (observer) observer()
        }
    }, [])

    return list
}

export function useAtualValues(fieldStr) {
    const useReduxForm = useContext(ReduxFormContext);
    let atualValues = useReduxForm.getValues();
    let retorno = atualValues;
    if (fieldStr) {
        retorno = _.get(atualValues, fieldStr)
    }
    return useMemo(() => retorno, [retorno])
}

export function useReduxFormProps() {
    const { actions } = useCrudContext();
    const form = useContext(ReduxFormContext);
    const { title, dispatch, change, getValues, initialize } = form;

    const actionsCrud = [];
    if (actions) {
        actions.forEach(a => {
            let key = a.uid.split('.').join("-")
            actionsCrud[key] = a.action
        });
    }

    const formPropsi = {
        title: title,
        change: (f, v) => {
            dispatch(change(f, v))
        },
        initialize: initialize,
        actions: actionsCrud,

        atualValues: () => getValues()
    }

    return formPropsi
}

export function useGetAtualValues() {

    const form = useContext(ReduxFormContext);

    return () => form.getValues();
}

//#endregion

//#region - okapp
export function useOkApp() {
    const { okApp } = useOkAppContext();
    return okApp
}


export const useListOkApp = (filtersCompare) => {
    const [list, setList] = useState(null);

    useEffect(() => {
        let observer = getOkAppListSnap(filtersCompare,
            (r) => {
                setList(r)
            }, (err) => {
                console.log('err', err)
                setList([])
            },
        )

        return () => {
            setList([]);
            if (observer) observer()
        }
    }, [filtersCompare])

    return list
}
export const useListOkAppAdmin = (filtersCompare) => {
    const [list, setList] = useState(null);

    useEffect(() => {
        let observer = getOkAppListSnapAdmin(filtersCompare,
            (r) => {
                setList(r)
            }, (err) => {
                console.log('err', err)
                setList([])
            },
        )

        return () => {
            setList([]);
            if (observer) observer()
        }
    }, [filtersCompare])

    return list
}
export const useListMeusOkApp = () => {
    const [list, setList] = useState(null);
    let userUid = useUserUid();
    useEffect(() => {
        let filter = { 'metaOkApp.permission.owner': userUid }
        let observer = getOkAppListSnap(filter,
            (r) => {
                setList(r)
            }, (err) => {
                console.log('err', err)
                setList([])
            },
        )

        return () => {
            setList([]);
            if (observer) observer()
        }
    }, [userUid])

    return list
}

//#endregion

//#region - okForm
export function useOkForm(uidOkForm) {
    const [okFormConsulta, setOkFormConsulta] = useState(null)
    const { okApp } = useOkAppContext();

    useEffect(() => {
        if (uidOkForm && okApp) {
            let observer = getOkFormSnap(okApp, uidOkForm,
                (r) => {
                    setOkFormConsulta(r)
                },
                (err) => {
                    setOkFormConsulta(null)
                }
            );
            return () => {
                if (observer) {
                    observer();
                }
            }
        }
    }, [uidOkForm, okApp])


    return okFormConsulta
}
export function useAtualOkForm() {

    const { atualOkForm } = useOkAppContext();



    return atualOkForm
}



export const useOkFormListSnap = (okApp, filtersCompare) => {
    const [list, setList] = useState(null);


    useEffect(() => {
        let observer = null
        if (okApp && okApp.uid) {
            observer = getOkFormListSnap(okApp, filtersCompare,
                (r) => {
                    setList(r)
                }, (err) => {
                    console.log('err', err)
                    setList([])
                },
            )
        }
        return () => {

            if (observer) {
                observer()
                setList([]);
            }
        }
    }, [okApp, filtersCompare])
    return list
}

export const useOkFormListRoutesSnap = (okApp, setLoadingRoutes, filtersCompare) => {
    const [list, setList] = useState(null);


    useEffect(() => {
        let observer = null
        if (okApp && okApp.uid) {
            if (setLoadingRoutes) setLoadingRoutes(true);
            observer = getOkFormListRoutesSnap(okApp, filtersCompare,
                (r) => {

                    setList(r)
                    if (setLoadingRoutes) setLoadingRoutes(false);
                }, (err) => {
                    console.log('err', err)
                    setList([])
                },
            )
        }
        return () => {

            if (observer) {
                observer()
                setList([]);
            }
        }
    }, [okApp, filtersCompare, setLoadingRoutes])
    return list
}
//#endregion

//#region - okObj
export const useOkObj = (okForm, uidObj) => {
    const [obj, setObj] = useState(null);
    const okApp = useOkApp();
    useEffect(() => {
        let observer = getOkObjSnap(okApp, okForm.uid, uidObj,
            (r) => {
                setObj(r)
            }, () => {
                setObj(null);
            }
        )
        return () => {
            if (observer) observer()
        }
    }, [uidObj, okForm, okApp])

    return obj
}


export const useOkObjListSnap = (okForm, filtersCompare, limite, start) => {
    const [list, setList] = useState(null);
    const okApp = useOkApp();
    const { database } = useOkAppContext();

    useEffect(() => {
        let observer = null

        if (okForm && okForm.uid && start) {

            observer = getOkObjListSnapDatabase(okApp, database, okForm.uid, filtersCompare,
                (r) => {
                    setList(r)
                }, (err) => {
                    console.log('err', err)
                    setList([])
                }, limite
            )
        }
        return () => {
            setList([]);
            if (observer) observer()
        }
    }, [okForm, okApp, filtersCompare, limite, start, database])

    return list
}
export const useOkObjListSnapFilter = (uidOkForm, funcFilter, limite) => {
    const [list, setList] = useState(null);
    const okApp = useOkApp();
    const { database } = useOkAppContext();

    useEffect(() => {
        let observer = null
        console.log('useEffect useOkObjListSnapFilter')
        if (uidOkForm && funcFilter) {
            observer = getOkObjListSnapDatabase(okApp, database, uidOkForm, funcFilter,
                (r) => {
                    setList(r)
                }, (err) => {
                    console.log('err', err)
                    setList([])
                }, limite
            )
        }
        return () => {
            setList([]);
            if (observer) observer()
        }
    }, [database, limite, okApp, uidOkForm, funcFilter])

    return list
}
//#endregion




export const useColectionListSnap = (strCollection, filtersCompare, limite, startAfter) => {
    const [list, setList] = useState(null);
    useEffect(() => {
        let observer = getStrCollectonsListSnap(strCollection, filtersCompare,
            (r) => {
                setList(r)
            }, (err) => {
                console.log('err', err)
                setList([])
            }, limite, startAfter
        )

        return () => {
            setList([]);
            if (observer) observer()
        }
    }, [filtersCompare, limite, startAfter, strCollection])

    return list
}

export const useCreateFunctionFromFieldCode = (valueFieldCode, nameFunction) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useCallback(
        getFunctionCodeToFuncMap(valueFieldCode, nameFunction),
        [valueFieldCode, nameFunction])
}

export const useCreateFunctionFromFieldCodeScope = (valueFieldCode, nameFunction, scope) => {
    const [classCode, setClassCode] = useState(null)
    useEffect(() => {
        setClassCode(getClassCodeToFuncMap(valueFieldCode, nameFunction));
        return () => {
            setClassCode(null)
        }
    }, [nameFunction, valueFieldCode])
    if (classCode && scope) {
        for (const key in scope) {
            if (scope.hasOwnProperty(key)) {
                classCode[key] = scope[key];

            }
        }
    }
    return classCode ? classCode[nameFunction] : null
}

export const criaFuncPraExecutar = (valueFieldCode, nameFunction, scope) => {
    const classCode = getClassCodeToFuncMap(valueFieldCode, nameFunction);

    if (classCode && scope) {
        for (const key in scope) {
            if (scope.hasOwnProperty(key)) {
                classCode[key] = scope[key];

            }
        }
    }
    return classCode ? classCode[nameFunction] : null
}


export const useCreateFunctionFromFieldCodeScopeDestroy = (valueFieldCode, nameFunction, scope) => {
    const [classCode, setClassCode] = useState(null)

    useEffect(() => {
        let clSet = getClassCodeToFuncMap(valueFieldCode, nameFunction);
        setClassCode(clSet);
        return () => {
            setClassCode(null)
        }
    }, [nameFunction, valueFieldCode,])
    // }, [nameFunction, valueFieldCode,okApp,setListDestroy])
    if (classCode && scope) {
        for (const key in scope) {
            if (scope.hasOwnProperty(key)) {

                classCode[key] = scope[key];


            }
        }
    }



    return [classCode ? classCode[nameFunction] : null,]
}

export function useTraceUpdate(props, name) {
    const prev = useRef(props);
    useEffect(() => {
        const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
            if (prev.current[k] !== v) {
                ps[k] = [prev.current[k], v];
            }
            return ps;
        }, {});
        if (Object.keys(changedProps).length > 0) {
            console.log((name || "") + ' Changed props:', changedProps);
        }
        prev.current = props;
    });
}
