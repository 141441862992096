import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flex: 1,
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
}));

const data = [
    {
        titulo: "O que é o OKApp",
        titulo2: "",
        descricao: "Uma plataforma de desenvolvimento de soluções, nossa proposta é acompanhar nossos parceiros em todas as etapas de criação de sua solução, senda elas ideação, validação, desenvolvimento, operação, marketing e vendas."
    },
    {
        titulo: "Como o OKApp vai aumentar a minha produtividade?",
        titulo2: null,
        descricao: "O OKApp foi desenvolvido com programadores com mais de 10 anos de experiência e tem componentes especialmente pensados para suas necessidades. A plataforma foi desenvolvida de maneira modular e conta com inclusões de novos componentes frequentemente"
    },

    {
        titulo: "Tem um plano Gratuito para testar?",
        titulo2: null,
        descricao: `Não, no entanto, se você for estudante de qualquer curso médio ou superior pode solicitar uma licença estudantil.`
    },

    {
        titulo: "Terei que começar o meu software do zero?",
        titulo2: null,
        descricao: `Não, a plataforma OKApp além dos componentes prontos, também disponibiliza módulos prontos para que você copie para seu projeto.`
    },
    {
        titulo: "Qual o suporte oferecido pela plataforma?",
        titulo2: null,
        descricao: `Temos o suporte via e-mail, chat, live tira dúvidas semanais, showcase dos componentes e nosso grupo no facebook e servidor no Discord onde você pode além de ter suporte pode interagir com os demais usuários da plataforma.`
    },
    {
        titulo: "Posso ganhar dinheiro com o OKApp?",
        titulo2: null,
        descricao: `Sim, você poderá desenvolver sistemas para terceiros. Inclusive simplesmente copiando os modelos existentes e licenciados para seus clientes.`
    },
]



export default function Fac() {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <div className={classes.root}>
            {data.map(d =>
                <Accordion key={d.titulo} expanded={expanded === d.titulo} onChange={handleChange(d.titulo)}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={d.titulo + "bh-content"}
                        id={d.titulo + "bh-header"}
                    >
                        <Typography className={classes.heading}>{d.titulo}</Typography>
                        {d.titulo2 && <Typography className={classes.secondaryHeading}>{d.titulo2}</Typography>}
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            {d.descricao}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            )}


        </div>
    );
}
