import React from 'react'
import OkCompAdapter from 'OkApp/components/OkCompAdapter'
import { OkTypedComponent, LabelInputAuto } from 'OkApp'
import CortarImagem from './components/CortarImagem'
import { Box } from '@material-ui/core'
const typeComponente = 'ImageSelComp'

const AdapterOkComp = (props) => {
    let propsComp = {}
    return <OkCompAdapter {...props} propsComp={propsComp} component={CortarImagem} />
}
const EditorOkComp = (props) => {

    return <>
        <OkTypedComponent typeComponente='InputComp' field='field' label='Nome Salvo' />
        <LabelInputAuto {...props} />
        <OkTypedComponent typeComponente='InputComp' field='textoBotao' label='Texto Selecione' />
        <OkTypedComponent typeComponente='ComboBoxComp' field='aspectType' label='Tipo' options={['circle', 'square', '16/9', 'custom']} toStringItem={(v) => {
            switch (v) {
                case 'circle': return "Circular"
                case 'square': return "Quadrado"
                case '16/9': return "16 / 9"
                case 'custom': return "Personalizado"
                default: return v;
            }
        }} />
        {props.atualValues?.aspectType === 'custom' &&
            <Box display="flex">
                <OkTypedComponent style={{ color: "red" }} typeComponente='InputComp' field='customWidth' label='Largura' type="number" required />
                <OkTypedComponent typeComponente='InputComp' field='customHeight' label='Altura' type="number" required />
            </Box>
        }
        <OkTypedComponent typeComponente='SwitchComp' field='criaLista' label='Criar Lista' />
        <OkTypedComponent typeComponente='SwitchComp' field='cortar' label='Cortar' />

    </>
}

const modelo = {
    typeComponente,
    field: 'logo',
    label: 'Logo',
    textoBotao: "Selecione a logo",
    aspectType: 'circle',
    cortar: true,
    criaLista: false,
}


const ImageSelComp = {
    typeComponente,
    configView: {
        grupo: 'Básico',
        nome: 'Seletor de Imagem',
        descricao: 'Seletor de Imagem',
        helpUrl: 'https://okapp.com.br/app/okappdocs/imageselcomp',
        modelo
    },
    Component: AdapterOkComp,
    CompEditor: EditorOkComp
}
export default ImageSelComp