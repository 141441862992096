
import { RenderOkForm } from 'OkApp'
import { useOkApp, useOkForm, usePerfilUser } from 'OkApp/UseUtils'
import React, { useEffect, useState } from 'react'
import _ from 'lodash'

export default function MeuPerfil(props) {
    const [formAjust, setFormAjust] = useState(null)
    const okapp = useOkApp();


    const form = useOkForm((okapp && okapp.formPerfil) ? okapp.formPerfil.uid : null);
    const perfil = usePerfilUser();

    useEffect(() => {
        if (form && perfil) {
            let au = { ...form, initialValues: perfil, posSaveUrl: "/app/" + okapp.route + "/meuperfil" }
            _.set(au, 'meta.pesquisa.showpesquisa', false)
            setFormAjust(au)
        }
    }, [form, okapp.route, perfil])

    return (
        <div>
            {formAjust &&
                <RenderOkForm okform={formAjust} starter={true} />
            }
        </div>
    )
}
