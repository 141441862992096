import React, { memo } from 'react'
import RotaRender from 'OkApp/components/RotaRender';
import { Route } from 'react-router-dom';


function RouterAdaper(props) {

    const { customForm } = props;
    // console.log('RouterAdaper', props)
    if (customForm) {

        return <Route {...props} component={() => <RotaRender customForm={customForm} />} />
    }

    return <Route {...props} />

} export default memo(RouterAdaper)

