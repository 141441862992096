import React from 'react'


export function CrudInsertComp({ okform, addActions, removeActions }) {
    // const okApp = useOkApp()
    // const history = useHistory();  

    // const actionElaborar = useCallback(() => {
    //     history.push("CustomForm?uid=" + okform.uid)
    // }, [history, okform])




    // useEffect(() => {
    //     let add = [];
    //     if (permiteEditar(okApp)) {
    //         add.push({ uid: 'crud.actions.dev', text: "Editar OkForm", icon: null, action: actionElaborar, props: { style: { marginRight: 35, backgroundColor: "#ae1ebb" } } })
    //     }
    //     if (add.length)
    //         addActions(add)
    //     return () => { removeActions(['crud.actions.dev',]); };
    // }, [actionElaborar, addActions, okApp, removeActions])


    return <></>
}


export const data = { name: "DevAux", }