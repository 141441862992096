import React from 'react'
import { RenderOkForm } from 'OkApp'
import { Field } from 'redux-form'
import Input from 'components/Input'
const ItemFiltro = ({ okCompFilter, index, filtrar }) => {
    if (okCompFilter.filterconf) {
        if (okCompFilter.filterconf.type) {
            if (okCompFilter.filterconf.type === "okcomp") {

                return <RenderOkForm key={index} okComp={{ ...okCompFilter.okComp, inFilter: true, required: false }} propsComp={{
                    onKeyDown: (e) => { if (e.keyCode === 13) filtrar(); }
                }} />
            }
        }
    }

    return <div style={{ paddingLeft: 5, paddingRight: 5 }}><Field name={okCompFilter.field} label={okCompFilter.label} component={Input} onKeyDown={(e) => { if (e.keyCode === 13) filtrar(); }} /></div>
}
export default function Filtros({ filters, filtrar }) {

    return (
        <div style={{ paddingLeft: -5, paddingRight: -5 }}>
            {filters.map((f, i) => <ItemFiltro key={i} index={i} okCompFilter={f} filtrar={filtrar} />)}
        </div>
    )
}
