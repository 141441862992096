import React, { useState } from 'react'
import { getComp2CompViewType } from '../../DinamicReportComps'
import produce from "immer"
import { Fab, Typography } from '@material-ui/core';
import MenuAddNewComp from '../MenuAddNewComp';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import useCriaControlerField from '../../functionsUtil';
import { Input } from 'components';
import UpIcon from '@material-ui/icons/ArrowDropUp';
import DownIcon from '@material-ui/icons/ArrowDropDown';

export default function ContainerDinamicReportComp({ value, onChange, valueRef, removeThis, moveUp, moveDown }) {
    //const [exibeItens, setExibeItens] = useState(false)
    const [showActions, setShowActions] = useState(false)
    const CompConfig = getComp2CompViewType(value);
    const criaControler = useCriaControlerField(value, onChange)

    const addItem = (comp) => {
        let newArray = value.lista ? value.lista.slice() : []
        newArray.push(comp)
        onChange({ ...value, lista: newArray, exibeItens: true })
    }

    const isExibeItens = () => {
        if (value.exibeItens !== undefined) {
            return value.exibeItens
        }
        return true
    }

    const setExibeItens = (bool) => {
        onChange({ ...value, exibeItens: bool })
    }

    const updateItem = (i, newValue) => {
        const nextState = produce(value, draftState => {
            draftState.lista[i] = newValue
        })
        onChange(nextState)
    }

    function array_move(arr, old_index, new_index) {
        if (new_index >= arr.length) {
            new_index = arr.length - 1;
        }
        if (new_index < 0) {
            new_index = 0;
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        return arr; // for testing
    };
    const moveUpFilho = (i) => {
        const nextState = produce(value, draftState => {
            array_move(draftState.lista, i, i - 1)

        })
        onChange(nextState)
    }
    const moveDownFilho = (i) => {
        const nextState = produce(value, draftState => {
            array_move(draftState.lista, i, i + 1)

        })
        onChange(nextState)
    }
    const removeItem = (index) => {
        const nextState = produce(value, draftState => {
            if (index > -1) {
                draftState.lista.splice(index, 1);
            }
        })
        onChange(nextState)
    }

    if (!CompConfig) {
        console.log('CompConfig nao', CompConfig, value)
        return <div>Componente nao encontrado!!!</div>
    }
    let orientacaoPainel = value.orientacaoPainel ? value.orientacaoPainel : 'horizontal'
    let maxColumns = value.maxColumns

    let ajustRef = valueRef

    if (CompConfig.ajustJsonRef) {
        ajustRef = CompConfig.ajustJsonRef(valueRef, value)
    }

    let styleFilhos = {};
    if (orientacaoPainel === "horizontal") {
        styleFilhos = { display: 'flex' }
    }

    if (maxColumns) {

        styleFilhos = {
            display: 'grid', gridTemplateColumns: Array(parseInt(maxColumns)).fill('auto').join(' ')
        }
    }
    return (
        <div style={{
            boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
            transition: '0.3s',
            borderRadius: 5,
            padding: 10,
            margin: 5,
            background: '#fff',
            flex: 1,

        }}
            onMouseEnter={() => setShowActions(true)} onMouseLeave={() => setShowActions(false)}
        >
            <div style={{ display: "flex", alignItems: "center", marginBottom: 5 }}>
                <div>

                    <Fab color='primary' size='small' style={{ marginRight: 10, }} onClick={() => setExibeItens(!isExibeItens())}>
                        {!isExibeItens() ? <ExpandMoreIcon /> : <ExpandLess />}
                    </Fab>
                </div>
                {isExibeItens() ? <>

                    <Input style={{ paddingRight: 10 }} label='Nome' {...criaControler("nome")} />
                </> :
                    <>

                        <Typography style={{ flex: 1 }} >{criaControler("nome").input.value} {value.chave ? "- " + value.chave : ""}  {value.lista ? " - Itens: " + value.lista.length : ""}</Typography>
                    </>}
                {showActions &&
                    <div style={{ display: "flex" }}>

                        <Fab color='primary' size='small' style={{ marginRight: 5 }} onClick={moveUp} ><UpIcon /></Fab>
                        <Fab color='primary' size='small' style={{ marginRight: 5 }} onClick={moveDown} ><DownIcon /></Fab>
                        <Fab color='primary' size='small' onClick={removeThis} ><DeleteIcon /></Fab>
                    </div>}
            </div>
            {isExibeItens() &&
                <>
                    <div>
                        <CompConfig.Component value={value} onChange={onChange} valueRef={valueRef} />
                    </div>
                    {value.lista &&
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Typography variant="h6" style={{ paddingLeft: 10, flex: 1 }}>Itens: {value.lista.length} </Typography>
                            {value.lista &&
                                <MenuAddNewComp addNewComp={addItem} />
                            }
                        </div>
                    }
                    {(value.lista && !value.lista.length) && <div>Sem Itens</div>}


                    <div style={styleFilhos}>
                        {value.lista && value.lista.map((c, i) => <ContainerDinamicReportComp key={i} value={c} onChange={(v) => updateItem(i, v)}
                            removeThis={() => { removeItem(i) }}
                            moveUp={() => { moveUpFilho(i) }}
                            moveDown={() => { moveDownFilho(i) }}
                            valueRef={ajustRef} />)}
                    </div>
                </>
            }
        </div>
    )
}
