function lerArquivoLocal(fileEntry, fileType, resolve) {
    fileEntry.file(function (file) {
        var reader = new FileReader();
        reader.onloadend = function () {
            console.log("Successful file load: " + this.result);
            var blob = new Blob([new Uint8Array(this.result)], { type: fileType });
            let blobUrl = window.URL.createObjectURL(blob)
            resolve(blobUrl)
        };
        reader.readAsArrayBuffer(file);

    }, error => console.log('error lerArquivoLocal', error));
}

function doGetFile(subDirEntry, fileName, fileType, downloadURL, resolve, updateNote) {
    console.log('subDirEntry', subDirEntry)
    subDirEntry.getFile(fileName, { exclusive: false }, function (fileEntry) {
        console.log("Loading Local File" + fileEntry.toURL());
        lerArquivoLocal(fileEntry, fileType, resolve)
    }, (error) => {
        if (error.code === 1) {//arquivo nao existe
            console.log('arquivo nao existe')
            updateNote({})
            subDirEntry.getFile(fileName, { create: true, exclusive: false }, function (fileEntry) {
                console.log('fileEntry', fileEntry)
                downloadFile(downloadURL).then(blob => {
                    updateNote({ downloadURLBlob: window.URL.createObjectURL(blob) })
                    fileEntry.createWriter(function (fileWriter) {
                        fileWriter.onwriteend = function () {
                            console.log("Successful DOWNLOAD file write...");
                            let blobUrl = window.URL.createObjectURL(blob)
                            resolve(blobUrl)

                        };

                        fileWriter.onerror = function (e) {
                            console.log("Failed file write: " + e.toString());
                        };

                        fileWriter.write(blob);
                    });


                })


            }, (error) => console.log('error3', error));
        } else {
            console.log('error1', error)
        }
    }
    );
}
function getDir(DirEntr, pasta, action) {
    if (pasta.includes("/")) {
        let index = pasta.indexOf("/")
        let p1 = pasta.substring(0, index)
        let p2 = pasta.substring(index + 1)
        DirEntr.getDirectory(p1, { create: true, exclusive: false }, function (subDirEntry) {
            getDir(subDirEntry, p2, action)
        }, (error) => console.log('error getDir', error));
    } else {
        DirEntr.getDirectory(pasta, { create: true, exclusive: false }, function (subDirEntry) {
            action(subDirEntry)
        }, (error) => console.log('error getDir do', error));
        // DirEntr.getDirectory(pasta, { create: true, exclusive: false }, action, (error) => console.log('error getDir', error));
    }
}
export function getBlobOkFileControl(filePath, fileType, downloadURL, updateNote) {
    return new Promise(function (resolve, reject) {

        console.log('Promise filePath', filePath)

        window.requestFileSystem(window.PERSISTENT, 0, function (fs) {
            let reverse = filePath.split("").reverse().join("")
            let indexOf = reverse.indexOf("/")
            let pasta = reverse.substring(indexOf).split("").reverse().join("")
            let fileName = reverse.substring(0, indexOf).split("").reverse().join("")
            console.log("pasta", pasta)
            console.log("fileName", fileName)
            if (pasta[pasta.length - 1] === "/") {
                pasta = pasta.substring(0, pasta.length - 1);
            }

            getDir(fs.root, pasta, (subDirEntry) => {
                doGetFile(subDirEntry, fileName, fileType, downloadURL, resolve, updateNote)
            })




        }, (error) => console.log('error2', error))


    });

}


function downloadFile(url) {
    return new Promise(function (resolve, reject) {
        var xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.open('GET', url);



        xhr.onload = function () {
            if (this.status >= 200 && this.status < 300) {
                resolve(xhr.response);
            } else {
                reject({
                    status: this.status,
                    statusText: xhr.statusText
                });
            }
        };
        xhr.onerror = function () {
            reject({
                status: this.status,
                statusText: xhr.statusText
            });
        };
        xhr.send();
    });
}