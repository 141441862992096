import React from 'react'

import { Tooltip } from '@material-ui/core';
import _ from 'lodash'

import { Combobox, Input, SeletorDePath } from 'components';
import useCriaControlerField from '../../functionsUtil';
import htmlToPdfmake from 'html-to-pdfmake';
import { useAtualValues } from 'OkApp/UseUtils';

import { QRCodeSVG } from '@cheprasov/qrcode';

function QrCodeReportComp({ value, onChange, valueRef, removeCelula }) {
    const criaControler = useCriaControlerField(value, onChange)
    let atualOkReport = useAtualValues()
    let listStyle = [
        { name: "Titulo", value: "cellTitulo" },
        { name: "Texto", value: "cellValor" },
    ]
    if (atualOkReport.styles) {
        listStyle = []
        for (const key in atualOkReport.styles) {
            if (Object.hasOwnProperty.call(atualOkReport.styles, key)) {
                listStyle.push({ name: key, value: key })

            }
        }

    }

    return (
        <div>
            <div style={{ paddingBottom: 10 }}>
                <SeletorDePath {...criaControler("chave")} label='Chave/Valor/Caminho' data={valueRef} />
            </div>
            <div style={{ flex: 1, display: 'flex' }}>
                <Combobox style={{ flex: 1 }} {...criaControler("style")} label="Estilo" itens={listStyle.map(v => v.value)} toStringItem={listStyle.map(v => v.name)} />
                <Combobox style={{ flex: 1, paddingLeft: 5, paddingRight: 5 }} {...criaControler("tipoValor")} label="Tipo Valor" itens={['fixo', 'get']} toStringItem={['Fixo', 'Valor do objeto']} />            </div>
            <div style={{ flex: 1, display: 'flex' }}>

                <Combobox style={{ flex: 1, paddingLeft: 5, paddingRight: 5 }} {...criaControler("alignment")} label="Alinhamento" itens={['left', 'center', 'right']} toStringItem={['Esquerda', 'Centro', 'Direita']} />
                <div style={{ flex: 1, }}>
                    <Input label='Tamanho Qrcode' {...criaControler("sizeQr")} />
                </div>
                <div style={{ flex: 1, }}>
                    <Tooltip title={"* - Maior possivel, auto - Menor possivel, 'numero' exato "}>
                        <Input label='Tamanho Celula(*,auto ou numero)' {...criaControler("width")} />
                    </Tooltip>
                </div>
            </div>
        </div>
    )
}





async function convertToPdf(compConfig, json) {

    const { nome, chave, sizeQr, tipoValor, formato, typeComponent, width, ...custom } = compConfig
    let r = chave
    if (tipoValor === 'get') {
        r = _.get(json, chave)
    }


    const qrSVG = new QRCodeSVG(r);
    console.log('qrSVG', qrSVG)
    htmlToPdfmake(qrSVG.toString())

    return {
        ...custom,
        svg: qrSVG.qrCodeSVG,
        width: sizeQr,
        height: sizeQr
    }




}

const QrCodeReport = {
    typeComponent: "QrCodeReport",
    modelo: {
        typeComponent: "QrCodeReport",
        nome: "QrCode",
        style: "cellValor",
        tipoValor: "get",
        formato: 'texto',
        width: "*",
        chave: "",
        alignment: "left",
        sizeQr: 120
    },
    Component: QrCodeReportComp,
    convertToPdfFunc: convertToPdf

}
export default QrCodeReport
