// import { getStripePayments } from "@stripe/firestore-stripe-payments";

import { initializeApp } from "firebase/app";
import { getStorage, ref } from "firebase/storage";
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, GoogleAuthProvider, signInWithPopup, GithubAuthProvider, FacebookAuthProvider, updateProfile, sendPasswordResetEmail } from "firebase/auth";
import { initializeFirestore, CACHE_SIZE_UNLIMITED, persistentLocalCache, persistentMultipleTabManager } from "firebase/firestore";

import { getFunctions, httpsCallable } from 'firebase/functions';
const mapDataBases = {}

export const config = {
	apiKey: "AIzaSyA8AERZRE86OeLOAi3XI5BlQdxGEyVLUtk",
	//authDomain: "okappbeta.firebaseapp.com",
	authDomain: "okapp.com.br",
	databaseURL: "https://okappbeta.firebaseio.com",
	projectId: "okappbeta",
	storageBucket: "okappbeta.appspot.com",
	messagingSenderId: "133480358469",
	appId: "1:133480358469:web:b5590aec72f6c2269f792c",
	measurementId: "G-KD39HKPQ5P"
};

export const configPig = {
	apiKey: "AIzaSyD-3_15rhG-NeMv4blAZrV1pZ0_LS6xSrM",
	authDomain: "pigclouddash.firebaseapp.com",
	databaseURL: "https://pigclouddash.firebaseio.com",
	projectId: "pigclouddash",
	storageBucket: "pigclouddash.appspot.com",
	messagingSenderId: "804101848909",
	appId: "1:804101848909:web:d68b80a01ba3635ce40104",
	measurementId: "G-GY62TH5N9V"
};
const app = initializeApp(config);

class FirebaseUtil {
	constructor() {
		// app.initializeApp(config)
		this.currentUser = null;
		this.perfilUser = null;
		if (window.cordova && window.cordova.plugins && window.cordova.plugins.firebase && window.cordova.plugins.firebase.auth) {
			console.log('auth cordova')
			this.auth = window.cordova.plugins.firebase.auth
		} else {

			// this.auth = app.auth()
			this.auth = getAuth(app);
		}
		this.storageF = getStorage(app);
		// this.db = app.firestore()
		this.db = initializeFirestore(app, {
			localCache: persistentLocalCache(/*settings*/{ cacheSizeBytes: CACHE_SIZE_UNLIMITED, tabManager: persistentMultipleTabManager() })

		});

		// this.db.enablePersistentCacheIndexAutoCreation()
		// enablePersistentCacheIndexAutoCreation()

		this.functions = getFunctions(app);

		// this.db.settings({
		// 	cacheSizeBytes: app.firestore.CACHE_SIZE_UNLIMITED
		// });
		// this.db.enablePersistence({ synchronizeTabs: true }).catch(function (err) {
		// 	console.log("Erro ao ativar persistencia offline: ", err); 

		// 	if (err.code === 'failed-precondition') {
		// 		// Multiple tabs open, persistence can only be enabled
		// 		// in one tab at a a time.
		// 		// ...
		// 	} else if (err.code === 'unimplemented') {
		// 		// The current browser does not support all of the
		// 		// features required to enable persistence
		// 		// ...
		// 	}
		// });

		// this.db.disableNetwork()
		// 	.then(() => {
		// 		console.log('disableNetwork Do offline actions')
		// 		// Do offline actions
		// 		// ...
		// 	});
		// this.paymentsStripe = getStripePayments(app, {
		// 	productsCollection: "stripe-products",
		// 	customersCollection: "stripe-customers",
		// });

	}
	firestore() {
		return app.firestore
	}



	// storageFunc() {
	// 	return this.
	// }

	storage() {
		return this.storageF
	}

	getStorageRef(pathOrUrlStorage) {
		return ref(this.storageF, pathOrUrlStorage)
	}


	login(email, password) {
		return signInWithEmailAndPassword(this.auth, email, password)
		// return this.auth.signInWithEmailAndPassword(email, password)
	}

	loginGoogle() {
		const provider = new GoogleAuthProvider();
		// var provider = new app.auth.GoogleAuthProvider();
		return signInWithPopup(this.auth, provider)
	}

	loginGoogleCordova(call) {

		window.plugins.googleplus.login({
			'offline': true
		}, (res) => {
			console.log('res', res)
			// signin into Firebase
			this.auth.signInWithGoogle(res.idToken, res.accessToken).then(() => {
				console.log("Firebase logged in with Google");
				call()
			});


		}, function (err) {
			console.error("login failed", err);
		});

	}
	loginFacebookCordova(call) {
		var provider = new app.auth.FacebookAuthProvider();
		console.log('FacebookAuthProvider', provider)
		// this.auth.signInWithFacebook (res.idToken).then(() => {				
		// 	console.log("Firebase logged in with Facebook");
		// 	call()
		// });
	}
	loginFacebook() {
		var provider = new FacebookAuthProvider();
		return signInWithPopup(this.auth, provider)
	}
	loginGithubCordova(call) {

	}
	loginGithub() {
		var provider = new GithubAuthProvider();
		return signInWithPopup(this.auth, provider)
	}
	loginTwitch() {

	}

	async passwordReset(email) {
		return sendPasswordResetEmail(this.auth, email)
	}

	logout() {
		this.currentUser = null;
		return this.auth.signOut()
	}

	async register(name, email, password) {

		await createUserWithEmailAndPassword(this.auth, email, password)
		// return this.getCurrentUser().updateProfile({
		// 	displayName: name
		// })

		return updateProfile(this.getCurrentUser(), {
			displayName: name
		})
	}

	isInitialized() {
		return new Promise(resolve => {
			this.auth.onAuthStateChanged((r) => {
				this.currentUser = r;
				resolve(r);
			})
		})
	}

	getCurrentUsername() {
		return this.getCurrentUser() ? this.getCurrentUser().displayName : "Usuário"
	}
	getCurrentUser() {
		if (this.currentUser) {
			return this.currentUser;
		}
		return this.auth.currentUser
	}
	setPerfilUser(perfilUser) {
		this.perfilUser = perfilUser;
	}

	getPerfilUser() {
		if (this.perfilUser) {
			return this.perfilUser;
		}

	}


	initGetDataBase(config, uidApp) {
		if (config) {
			// app.initializeApp(config, uidApp);
			let dbUsar = mapDataBases[uidApp]
			if (dbUsar) {
				return dbUsar;
			}

			const appUsar = initializeApp(config, uidApp);
			dbUsar = initializeFirestore(appUsar, {
				localCache: persistentLocalCache(/*settings*/{ cacheSizeBytes: CACHE_SIZE_UNLIMITED, tabManager: persistentMultipleTabManager() })
			});
			mapDataBases[uidApp] = dbUsar
			return dbUsar;
		} else {
			return this.db;
		}


	}
	getDataBaseOkApp(uidOkAPP) {
		let dbUsar = mapDataBases[uidOkAPP]
		if (dbUsar) {
			return dbUsar;
		} else {
			return this.db;
		}
	}



	funcServCreatePaymentIntentStripe() {
		let createPaymentIntentStripe = httpsCallable(getFunctions(app), 'createPaymentIntentStripe');

		return createPaymentIntentStripe;
	}
	funcServCreateCustomerStripe() {
		return httpsCallable(getFunctions(app), 'createCustomerStripe');
	}
	funcServGptChat() {
		return httpsCallable(getFunctions(app), 'gptChat');
	}



	funcServcreateAccountStripe() {
		return httpsCallable(getFunctions(app), 'createAccountStripe');
	}
	funcServCreateAccountLinkStripe() {
		return httpsCallable(getFunctions(app), 'createAccountLinkStripe');
	}
	funcServCancelStripeSubscription() {
		return httpsCallable(getFunctions(app), 'cancelStripeSubscription');
	}

	// getPaymentsStripe() {
	// 	return this.paymentsStripe
	// }
}

export default new FirebaseUtil()