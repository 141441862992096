/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react'
import "./style.css"
import { useCrudContext } from 'crud';
import Fac from './Fac'
import Video from './Video';
import Okapplogo from './okapp-logo.png';
import ImgProdutividade from './produtividade.png';
import ImgProgramming from './programming.png';
import ImgReutilizar from './reutilizar.png';
import Discordlogo from './discordia.png';
import Instagramlogo from './instagram.png';
import Facebooklogo from './facebook.png';
import Youtubelogo from './youtube.png';
import useAuth, { isAuthenticated } from 'useAuth';
import { CircularProgress } from '@material-ui/core';


export default function PageHome(props) {


    const { addActions, removeActions } = useCrudContext();

    const ToLogin = () => {
        props.history.push("/login")
    }

    const ToRegister = () => {
        props.history.push("/Register")
    }

    const ToDemo = () => {
        props.history.push("/app/utils/solicitacao-de-licenca-beta")
    }

    // useEffect(() => {
    //     let add = [];

    //     add.push({ uid: 'home.demo', text: "Solicitar Demo", icon: null, action: ToDemo })
    //     add.push({ uid: 'home.login', text: "Login", icon: null, action: ToLogin })
    //     addActions(add)

    //     // add = []

    //     addActions(add);
    //     return () => { removeActions(['home.login', 'home.demo']); };

    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [])



    useEffect(() => {
        console.log('to site')

        setTimeout(() => {
            if (!isAuthenticated()) {
                window.open('Login', '_self')
                // window.open('https://www.okapp.com.br/', '_self')
            }
        }, 1000);

    }, []);

    return <><CircularProgress /></>

    // return (
    //     <div className="root">
    //         <div className="main">

    //             <div className="main limiteWight">
    //                 <span className="beta">BETA Test</span>
    //                 <div className="mainCont">

    //                     <div className="mainImage">
    //                         <img alt="mainImage" className="responsiveImage" src={Okapplogo} />
    //                     </div>
    //                     <h1>Construa soluções digitais personalizadas.</h1>

    //                     <div className="mainDesc">Ganhe tempo, padronize seus processos, atenda seus clientes em tempo record.</div>
    //                     <div style={{
    //                         display: 'flex',
    //                         justifyContent: 'space-between',
    //                         width: "100%",
    //                         alignItems: "center"
    //                     }}>

    //                         <div className="mainBtTeste" onClick={ToRegister}>Cadastre-se</div>
    //                         {/* <div className="mainBtMais">Conheça mais...</div> */}
    //                     </div>
    //                 </div>

    //                 <div className="mainSpace">

    //                 </div>

    //             </div>
    //         </div>
    //         <div className="container1">
    //             <div className="containerCont limiteWight">
    //                 <div className="cardQuem">
    //                     <img src={ImgProdutividade} alt="img" style={{ width: "100%" }} />
    //                     <div className="cardQuemCont">

    //                         <h3>Ganhe horas de produtividade</h3>
    //                         <p>Reutilização de componentes e formularios altamente customizável</p>
    //                     </div>
    //                 </div>
    //                 <div className="cardQuem">
    //                     <img src={ImgProgramming} alt="img" style={{ width: "100%" }} />
    //                     <div className="cardQuemCont">   <h3>Desenvolvimento ágil</h3>
    //                         <p>Com templates e modelos pré-configurados o desenvolvimento é acelerado e a curva de aprendizado reduzido</p>
    //                     </div>
    //                 </div>
    //                 <div className="cardQuem">
    //                     <img src={ImgReutilizar} alt="img" style={{ width: "100%" }} />
    //                     <div className="cardQuemCont">   <h3>Componentes prontos</h3>
    //                         <p>Desenvolva aplicações usando o conceito de reutilização de componentes LowCode ou HighCode</p>
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>
    //         <div className="container3">
    //             <div className="containerCont2  limiteWight">
    //                 <h2>Em ação</h2>
    //                 <Video />
    //             </div>

    //         </div>
    //         <div className="container2">
    //             <div className="containerCont  limiteWight">
    //                 <h2>Perguntas Frequentes</h2>
    //                 <Fac />
    //             </div>

    //         </div>
    //         <div className="rodape">
    //             <div className="containerCont  limiteWight">
    //                 <p>Desenvolvido por OKDS</p>

    //                 <div className="socialLinks">
    //                     <a className='socialButton' href='https://www.instagram.com/okapp.ofc/' target='_blank' rel="noopener noreferrer"><img alt="instagram" src={Instagramlogo} width={30} height={30} /></a>
    //                     <a className='socialButton' href='https://www.facebook.com/okapp.ofc/' target='_blank' rel="noopener noreferrer"><img alt="facebook" src={Facebooklogo} width={30} height={30} /></a>
    //                     <a className='socialButton' href='https://discord.com/invite/HCdXyVnJPS' target='_blank' rel="noopener noreferrer"><img alt="discord" src={Discordlogo} width={30} height={30} /></a>
    //                     <a className='socialButton' href='https://www.youtube.com/channel/UCS4bqVEFnRMAmkrtr3JZTuQ' target='_blank' rel="noopener noreferrer"><img alt="youtube" src={Youtubelogo} width={30} height={30} /></a>
    //                 </div>
    //             </div>
    //         </div>


    //         <a target="_blank"
    //             rel="noopener noreferrer"
    //             href="https://api.whatsapp.com/send/?phone=556798463002&text=Olá, gostaria de saber mais sobre o OkApp"
    //             className='whatsFloatButton'
    //         >
    //             <i class="fab fa-whatsapp" />
    //         </a>

    //     </div>
    // )
}
