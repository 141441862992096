import { InputLabel } from '@material-ui/core';
import useOKControlerField from 'components/OkStyleEditor/functionsUtils';
import React from 'react'

export default function Border({ styleAtual, value, onChange }) {
    const okControler = useOKControlerField(value, onChange);

    // let split = value ? value.split(" ") : ["1px", "solid", "#f00"]
    const valueB = okControler("border").value;
    let ajustGet = (index) => {

        if (!valueB) {
            return "";
        }
        let split = valueB ? valueB.split(" ") : ["1px", "solid", "#000"]
        if (index === 0) {
            return split[0].replace("px", "").trim()
        }

        return split[index];
    }
    let ajustChange = (index) => evt => {
        let v = evt.target.value
        if (index === 0) {
            v = v + 'px'
        }
        let split = valueB ? valueB.split(" ") : ["1px", "solid", "#000"]
        split[index] = v
        console.log('split', split)
        okControler("border").onChange(split.join(" "))
    }

    return (
        <div className='OkStyleEditor-comp'>
            <InputLabel>Border</InputLabel>

            <div className='OkStyleEditor-row'>
                <div className='OkStyleEditor-cell50'>
                    <InputLabel>Size</InputLabel>
                    <input type='number' value={ajustGet(0)} onChange={ajustChange(0)} />
                </div>
                <input type='color' value={ajustGet(2)} onChange={ajustChange(2)} />
                <div className='OkStyleEditor-cell50'>
                    <InputLabel>Radius</InputLabel>

                    <input type='number' value={okControler("borderRadius").value} onChange={evt => {
                        let v = evt.target.value;
                        if (v !== undefined || v !== "") {
                            v = parseFloat(v)
                        }
                        okControler("borderRadius").onChange(v)
                    }} />
                </div>
            </div>

        </div>
    )
}
