import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';

import { Field, reduxForm } from 'redux-form'

import InputMask from 'react-input-mask'

import { TextField, Button, CircularProgress, Checkbox, Box, FormControl, Typography, useMediaQuery, Tabs, Tab } from "@material-ui/core";
import { useTheme, withStyles } from '@material-ui/core/styles';

import firebaseUtil from '../../../../../firebaseUtil'
import useNotification from '../../../../../notification';
import { useTranslation } from 'react-i18next';
import useAuth, { usePerfil } from '../../../../../useAuth';

import { getOkFormSnap } from 'OkApp/OkFunctions';

import './style.css'
import { size, trim } from 'lodash';
import { Autocomplete } from '@material-ui/lab';

import { updatePerfil } from 'dbApi';
import { useFormValue } from 'crud/useForm';
import { LogTracker } from 'OkApp/OkTracker';

const renderTextField = ({ input, label, maskField, mask, showMask, setMask, multiline = false, meta: { touched, error }, ...custom }) => {
    if (showMask) {
        return (
            <InputMask
                mask={mask}
                maskChar=""
                onBlur={e => {
                    if (size(trim(e.target.value, '_')) === 14) {
                        setMask('99 9999 9999')
                    }
                }}
                onFocus={e => {
                    if (size(trim(e.target.value, '_')) === 14) {
                        setMask('99 99999 9999')
                    }
                }}
                value={input.value}
                onChange={input.onChange} >
                {(inputProps) =>
                    <TextField {...inputProps} type="text"
                        label={label}
                        error={(touched && error) ? true : false}
                        helperText={(touched && error) ? error : ""}
                        fullWidth
                        InputLabelProps={{
                            // shrink: true,
                        }}
                        style={{ marginBottom: 15 }}
                        {...custom}

                    />
                }
            </InputMask>
        )
    }

    return (
        <TextField
            label={label}
            error={(touched && error) ? true : false}
            helperText={(touched && error) ? error : ""}
            fullWidth
            multiline={multiline}
            {...input}
            {...custom}
        />
    )
}

const SelectStateComp = ({ input, estados, handleEstadoChange, change }) => {
    return (
        <FormControl fullWidth>
            <Autocomplete
                id='estado-autocomplete'
                options={estados}
                getOptionLabel={(estado) => estado.nome}
                value={estados.find((estado) => estado.id === input.value) || null}
                onChange={(event, newValue) => {
                    handleEstadoChange(newValue ? newValue.id : '')
                    input.onChange(newValue ? newValue.id : '')
                    change("estado", newValue.nome)
                }}
                renderInput={(params) => (
                    <TextField
                        name='estado'
                        label='Estado'
                        InputLabelProps={{
                            shrink: true,
                        }}
                        // {...input}
                        {...params}
                    />
                )}
            />
        </FormControl>
    )
}

const SelectCityComp = ({ input, cidades, selectedEstado, change }) => {
    return (
        <FormControl fullWidth>
            <Autocomplete
                id='cidade-autocomplete'
                options={cidades}
                getOptionLabel={(cidade) => cidade.nome}
                value={cidades.find((cidade) => cidade.id === input.value) || null}
                onChange={(event, newValue) => {
                    input.onChange(newValue ? newValue.id : '')
                    change("cidade", newValue.nome)
                }}
                disabled={selectedEstado === ''}
                renderInput={(params) => (
                    <TextField
                        name='cidade'
                        label='Cidade'
                        InputLabelProps={{
                            shrink: true,
                        }}
                        {...params}
                    />
                )}
            />
        </FormControl>
    )
}

function Register(props) {
    const { handleSubmit, submitting, okApp, pagePosLogin, change } = props

    const estado = useFormValue('state')
    // console.log("estado ", estado);

    const { t } = useTranslation();
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [accept, setAccept] = useState(false);

    const [tab, setTab] = useState(0)
    const [mask, setMask] = useState('99 99999 9999');
    const [estados, setEstados] = useState([]);
    const [cidades, setCidades] = useState([]);
    const [pagePosLoginST, setPagePosLoginST] = useState(pagePosLogin)

    const { redirectPosAuth, registerSingIn } = useAuth();
    const { showSuccess } = useNotification()

    const perfil = usePerfil()

    const handleChange = (event, newValue) => {
        setTab(newValue);
    };

    useEffect(() => {
        fetch('https://servicodados.ibge.gov.br/api/v1/localidades/estados')
            .then((response) => response.json())
            .then((data) => {
                setEstados(data);
            })
            .catch((error) => console.error(error));
    }, []);

    useEffect(() => {
        let observer = null
        if (okApp && pagePosLoginST === "/app/" + okApp.route) {
            if (okApp.formInicio) {
                observer = getOkFormSnap(okApp, okApp.formInicio.uid,
                    (r) => {
                        if (r) {
                            setPagePosLoginST("/app/" + okApp.route + "/" + r.route)
                        }
                    },
                    (err) => {
                    }
                );
            }
        }
        return () => {
            if (observer) observer()
        }
    }, [okApp, pagePosLoginST])

    async function registrarUsuario(dados) {
        try {
            await firebaseUtil.register(dados.name, dados.email, dados.password)
            registerSingIn();
            setTab(1)

            // showSuccess(t('sucessRegister'))
            // redirectPosAuth(pagePosLoginST)
        } catch (error) {
            console.log("error.message", error.message);
        }
    }

    async function updateUsuarioPosRegistro(dados) {

        const today = new Date()
        today.setDate(today.getDate() + 90);

        try {
            let dataToUpdate = {
                ...dados,
                created_at: new Date(),
                nomePlano: "Demonstração",
                numProject: 1,
                numUser: 1,
                limiteArmazenamento: 500,
                limiteOperacoes: 50000,
                dataFimPlano: today.toISOString().split('T')[0]
            }

            delete dataToUpdate.password
            delete dataToUpdate.passwordRepeat

            updatePerfil(perfil, dataToUpdate)

            showSuccess(t('sucessRegister'))
            LogTracker("OkApp - Formulário de demonstração", dataToUpdate, perfil)
            redirectPosAuth(pagePosLoginST)

        } catch (error) {
            console.log("error ", error);
        }
    }

    const handleEstadoChange = (stateId) => {
        // Buscar a lista de cidades do estado selecionado da API do IBGE
        if (stateId) {
            fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${stateId}/municipios`)
                .then((response) => response.json())
                .then((data) => {
                    setCidades(data);
                })
                .catch((error) => console.error(error));
        } else {
            setCidades([]);
        }
    };

    useEffect(() => {
        if (perfil) {
            setTab(1)
        }
    }, [perfil])

    return (
        <Box display="flex" alignItems="center" justifyContent="center" height="100vh" width="100%" style={{ background: "linear-gradient(to bottom, #575fff83,  #ef9c3283)" }}>
            <Box
                display="flex"
                width={755}
                height="fit-content"
                maxWidth={450}
                borderRadius={15}
                style={{
                    background: mobile ? "#eeeeee95" : "#eee",
                    boxShadow: "0px 0px 11px 0px rgba(0,0,0,0.54)",
                    margin: mobile ? "0 15px 0 15px" : "0"
                }}
            >
                <Box padding={3} display="flex" flexDirection="column" width={"100%"}>
                    <Typography style={{ textAlign: 'center', color: "rgb(82, 82, 82)", marginBottom: 20, fontWeight: 'bold', fontSize: 18, fontStyle: 'italic' }}>
                        {perfil ?
                            "Complete seus dados para solicitar sua licença!"
                            :
                            "Crie a sua conta e comece a testar gratuitamente!"
                        }
                    </Typography>
                    <Tabs
                        value={tab}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        style={{ width: 'fit-content', display: 'flex', margin: '0 auto' }}
                    >
                        <Tab label="Dados de Login" disabled={perfil} />
                        <Tab label="Informações adicionais" disabled={!perfil} />
                    </Tabs>

                    {tab === 0 &&
                        <>
                            <Box>
                                <Field name="name" component={renderTextField} label={t('user.fullname')} required />
                            </Box>
                            <Box>
                                <Field name="email" component={renderTextField} label={t('user.email')} required />
                            </Box>
                            <Box>
                                <Field name="password" component={renderTextField} label={t('user.password')} type='password' />
                            </Box>
                            <Box>
                                <Field name="passwordRepeat" component={renderTextField} label={t('user.passwordRepeat')} type="password" />
                            </Box>

                            <Box marginTop={2}>
                                <Button fullWidth variant="contained" color="primary" onClick={handleSubmit(registrarUsuario)} disabled={submitting}>
                                    {!submitting && "Avançar"}
                                    {submitting && <CircularProgress size={24} />}
                                </Button>
                            </Box>
                        </>
                    }

                    {tab === 1 &&
                        <>
                            <Box>
                                <Field name="phone" component={renderTextField} label="Telefone/celular" showMask={true} mask={mask} setMask={setMask} />
                            </Box>
                            <Box>
                                <Field name="stateId" change={change} component={SelectStateComp} estados={estados} handleEstadoChange={handleEstadoChange} required />
                            </Box>
                            <Box>
                                <Field name="cityId" change={change} component={SelectCityComp} cidades={cidades} selectedEstado={estado} required />
                            </Box>
                            <Box>
                                <Field name="ocupation" component={renderTextField} label={t('user.ocupation')} />
                            </Box>

                            <Box>
                                <Field
                                    name="problemToSolve"
                                    component={renderTextField}
                                    label="Qual problema você quer resolver com o OkApp?"
                                    placeholder="Ex: Melhorar o fluxo de atendimento da minha equipe"
                                />
                            </Box>
                            <Box>
                                <Field name="description" component={renderTextField} label="Como conheceu o OkApp?" multiline={true} />
                            </Box>
                            <Box display="flex" alignItems={"center"}>
                                <Checkbox
                                    name="accept"
                                    id='checkbox'
                                    onChange={(p, value) => {
                                        setAccept(value)
                                    }}
                                    style={{ padding: "5px 5px 5px 0" }}
                                />
                                <Typography component="label" htmlFor="checkbox">
                                    Li e Aceito os
                                </Typography>
                                &nbsp;
                                <Link target="_blank" to={"/TermosUso"}>Termos de Uso</Link>
                            </Box>
                            <Box marginTop={2}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSubmit(updateUsuarioPosRegistro)}
                                    disabled={submitting || !accept}
                                >
                                    {!submitting && "Enviar informações"}
                                    {submitting && <CircularProgress size={24} />}
                                </Button>
                            </Box>
                        </>
                    }
                </Box>
            </Box>
        </Box>
    )
}

const validate = values => {
    const errors = {}
    if (!values.name) {
        errors.name = 'Requerido'
    }
    if (!values.email) {
        errors.email = 'Requerido'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Email inválido'
    }
    if (!values.password) {
        errors.password = 'Requerido'
    } else if (values.password !== values.passwordRepeat) {
        errors.passwordRepeat = 'Senhas não conferem'
    }

    if (!values.ocupation) {
        errors.ocupation = "Requerido"
    }

    if (!values.phone) {
        errors.phone = "Requerido"
    } else if (!/^\s*(\d{2}|\d{0})[-. ]?(\d{5}|\d{4})[-. ]?(\d{4})[-. ]?\s*$/i.test(values.phone)) {
        errors.phone = 'Celular inválido'
    }

    return errors
}

export default reduxForm({
    validate,
    form: 'DemonstrationRequestForm'
})(withStyles({})(Register))