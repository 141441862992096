import { Button, Menu, MenuItem } from '@material-ui/core'
import React from 'react'
import { getComponents } from '../../DinamicReportComps';

import AddIcon from '@material-ui/icons/Add';

const CompsRelatorio = getComponents();
export default function MenuAddNewComp({ addNewComp, label }) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const ajustComponenteAdd = (comp) => {

        addNewComp(comp.modelo)
        handleClose()
    }


    return (
        <>
            <Button aria-controls="simple-menu" aria-haspopup="true" variant='contained' color='primary'
                startIcon={<AddIcon />} onClick={handleClick}>{label}</Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {CompsRelatorio.map((comp, i) => {
                    return <MenuItem key={i} onClick={() => {
                        ajustComponenteAdd(comp)
                    }}>{comp.modelo ? comp.modelo.nome : comp.typeComponent}</MenuItem>
                })}

            </Menu>
        </>
    )
}
