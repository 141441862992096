import React from 'react'

import EditorPermissions from 'OkApp/modules/PermissionMeta/components/EditorPermissions'
import { LabelInputAuto } from 'OkApp';
const typeComponente = 'EditorPermissaoComp'

const AdapterOkComp = (props) => {

    return <EditorPermissions hideEditor={true} hideGrupos={true} labelUsers="Usuários Permitidos" labelPublico="Público" />
}

const EditorOkComp = (props) => {


    return <div style={{ display: 'flex', flexDirection: "column", height: "100%" }}>
        <LabelInputAuto {...props} />
    </div>
}

const modelo = {
    typeComponente,
    field: 'metaOkApp',
    label: 'Editor de Permissão do Objeto',
}


const EditorPermissaoComp = {
    typeComponente,
    configView: {
        grupo: 'OK Utils',
        nome: 'Editor de Permissão do Objeto',
        descricao: 'Editor de Permissão do Objeto',
        helpUrl: 'https://www.google.com.br/',
        modelo
    },
    Component: AdapterOkComp,
    CompEditor: EditorOkComp
}
export default EditorPermissaoComp