import React, { useState } from 'react'
import { Dialog, Toolbar, IconButton, makeStyles, Slide, AppBar, Button, Typography, Tab, Tabs, Box, Tooltip } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import { Field } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { Input, SwitchItem } from 'components';
import { OkModulesConnect } from 'OkApp'

import ConfigIcon from '@material-ui/icons/Settings';
import { useOnKeyDownAlt } from 'OkApp/functions';
import { useReduxFormProps } from 'OkApp/UseUtils';
const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'relative',
    },
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        height: 224,
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
        minWidth: 200
    },

    active_tabStyle: {
        backgroundColor: theme.palette.secondary.dark,
    },
    default_tabStyle: {

    },

}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function TabPanel(props) {
    const { children, atualTab, keyTab, styleProps, ...other } = props;
    let st = { flex: 1 }
    if (styleProps) {
        st = { flex: 1, ...styleProps }
    }
    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={atualTab !== keyTab}
            style={st}
            {...other}
        >
            {atualTab === keyTab && <Box style={{ height: "100%", position: 'relative' }} p={1}>{children}</Box>}
        </Typography >
    );
}


export default function MetaDialog(props) {
    
    const formProps = useReduxFormProps();
    const [custonAbas, setCustonAbas] = useState([])
    const { t } = useTranslation();
    const [show, setShow] = useState(false)
    const classes = useStyles();
    const [atualTab, setAtualTab] = useState(0)
    const addAba = (aba) => {
        setCustonAbas(antAbas => {
            if (antAbas.includes(aba)) {
                return antAbas;
            } else {
                let newArray = antAbas.slice();
                newArray.push(aba);
                return newArray;
            }
        })
    }

    useOnKeyDownAlt('KeyC', () => setShow(b => !b))



    return (
        <>
            <Tooltip title={"Configurações (Alt + C)"}>
                <IconButton size="small" style={{ width: "100%" }} onClick={() => { setShow(true) }}>
                    <ConfigIcon />
                </IconButton>
            </Tooltip>
            <Dialog fullScreen open={show} onClose={() => { setShow(false) }} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={() => { setShow(false) }} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Configurações do Formulario
                        </Typography>
                        <Button autoFocus color="inherit" onClick={() => { setShow(false) }}>
                            OK
                        </Button>
                    </Toolbar>
                </AppBar>
                <div className={classes.root}>
                    <Tabs
                        orientation="vertical"
                        variant="scrollable"
                        value={atualTab}
                        onChange={(e, newValue) => {
                            setAtualTab(newValue)
                        }}
                        aria-label="Vertical tabs"
                        className={classes.tabs}

                    >
                        <Tab label="Dados Gerais" className={atualTab === 0 ? classes.active_tabStyle : classes.default_tabStyle} />
                        {custonAbas.map((aba, i) => {
                            return <Tab key={i} value={aba} label={aba} className={atualTab === aba ? classes.active_tabStyle : classes.default_tabStyle} />
                        })}
                    </Tabs>

                    <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                        <TabPanel atualTab={atualTab} keyTab={0}>
                            <Field name="uid" component={Input} label={"UID"} disabled />
                            <Field name="name" component={Input} label={t('project.name')} required={(v) => v ? undefined : 'Requerido'} parseInputs={(v) => {
                                let auxRota = v.trim().toLowerCase().split(' ').join('-')
                                formProps.change("route", auxRota)

                                return v;
                            }} />
                            <Field name="route" component={Input} label={t('project.route')} required={(v) => v ? undefined : 'Requerido'}
                                parseInputs={(v) => {
                                    return v.split('/').join('');
                                }} />
                            <Field name="description" component={Input} label={t('project.description')} />
                            <div style={{ display: "flex",gap:5 }}>
                                <Field name="type" component={Input} label={t('type')} />
                                <Field name="version" component={Input} label={t('Version')} />

                            </div>
                            <Field name="guardarValorComoInicial" component={SwitchItem} label={'Guardar valores atuais como dados inicial no novo preenchimento.'} />
                        </TabPanel>
                        <OkModulesConnect.OkFormMetaAba {...props} addTab={addAba} atualTab={atualTab} TabPanel={TabPanel} />
                    </div>


                </div>
            </Dialog>
        </>
    )
}
