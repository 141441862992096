import React, { useMemo, useCallback, useState, useEffect } from 'react'
import { Field } from 'redux-form'
import { useOkApp } from 'OkApp/UseUtils'
import FieldAdapter from '../FieldAdapter'
import { useHistory, useLocation } from 'react-router-dom'
import { observerOkObjList, saveOkObj } from 'OkApp/OkFunctions'

import Style from 'style-it';
import useNotification from 'notification'
import html2canvas from 'html2canvas'
class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error, info) {
        this.setState({ hasError: true });
        console.log('Capiturou error:', error)
        console.log('Capiturou error:', info)
    }

    render() {
        if (this.state.hasError) {
            // Você pode renderizar qualquer alternativa de UI
            return <div style={{ height: 40, width: '100%', backgroundColor: "#f00", display: 'flex', justifyContent: "center", alignItems: "center" }}>Erro no component. Veja o log.</div>;
        }
        return this.props.children;
    }
}
function OkCompAdapter(props) {

    const [required, setRequired] = useState([])
    const [labelOver, setLabelOver] = useState("")
    const { refField, component, okComp, propsComp, permiteEditar, okform, prefield, styleCssOver, itemList, noStyleStart, overCssStyleComp } = props
    const { typeComponente, metaOkApp, meta, ...okCompSemType } = okComp

    let disabled = okComp.disabled || (propsComp ? propsComp.disabled : false);

    let hystory = useHistory();
    let location = useLocation();
    const okApp = useOkApp();
    const { showError, showSuccess } = useNotification();

    const salvaOkObj = useCallback((uidOkForm, obj, resolve, reject) => {
        return saveOkObj(okApp, uidOkForm, obj, resolve, reject)
    }, [okApp])

    const observerOkObjs = useCallback(
        (keyOkForm, collectionFilter, resolve, reject, limit) => {
            const reTry = r => {
                try {
                    resolve(r);
                } catch (error) {
                    console.log('observerOkObjs erro no resolve', error)
                }
            }


            return observerOkObjList(okApp, keyOkForm, collectionFilter, reTry, reject, limit)
        },
        [okApp],
    )

    const okfunctions = useMemo(() => {
        const generateComponentImage = async (component) => {
            const canvas = await html2canvas(component);
            const image = canvas.toDataURL("image/png", 1.0);
            return image;
        }
        const redirectTo = (url, state) => {
            hystory.push(url, state);
        }
        const newTab = (url, state) => {
            window.open(url, state);
        }

        const dateToString = (data) => {
            let str = new Date(
                data.getTime() - data.getTimezoneOffset() * 60000
            ).toISOString();
            str = str.split(".")[0];

            return str.split("T")[0];

        }
        const stringToDate = (strDate) => {
            let dateAux = new Date(strDate);
            dateAux = new Date(dateAux.getTime() + dateAux.getTimezoneOffset() * 60000);
            return dateAux;
        }
        let r = {

            okform, okComp,
            // formProps,
            location,
            redirectTo,
            newTab,
            observerOkObjs,
            salvaOkObj,
            dataUtil: { dateToString, stringToDate },
            showError, showSuccess,
            generateComponentImage,
        }


        if (itemList) {
            r.itemList = itemList
        }

        return r

    }, [okform, okComp, location, observerOkObjs, salvaOkObj, itemList, hystory, showError, showSuccess]);

    if (permiteEditar === false) {//vem o okform
        disabled = true;
    }
    let labelComp = okComp.label;

    let requiredComp = okComp.required;
    useEffect(() => {
        if (requiredComp) {
            setRequired([(value) => value ? undefined : "Requerido"])
            setLabelOver(labelComp + "*")
        } else {
            setRequired([])
            setLabelOver(labelComp)
        }
    }, [requiredComp, setRequired, labelComp])
    if (okComp.hidden) {
        return ""
    }

    let fieldUsar = okComp.field;
    if (prefield) {
        fieldUsar = prefield + fieldUsar;
    }
    let styleUsar = noStyleStart ? {} : { paddingLeft: 5, paddingRight: 5, flex: 1 }



    if (styleCssOver) {
        styleUsar = { ...styleUsar, ...styleCssOver }
    }

    return (<div style={styleUsar} className={typeComponente}>
        <Style>
            {overCssStyleComp ? overCssStyleComp : ".nada{}"}
            <ErrorBoundary>
                <Field ref={refField} {...propsComp} propscomp={propsComp} {...okCompSemType} field={fieldUsar} okfunctions={okfunctions} label={labelOver} styleCssOver={styleCssOver} name={fieldUsar} disabled={disabled} ComponentView={component} component={FieldAdapter} validate={required} />
            </ErrorBoundary>
        </Style>
    </div>)
}

export default OkCompAdapter
