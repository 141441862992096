import React, { useCallback, useEffect } from 'react'
import { useCrudContext } from 'crud';
import useNotification from 'notification';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { deleteObj, salvarObj } from 'dbApi';
import { LogTracker } from 'OkApp/OkTracker';
import { usePerfil } from 'useAuth';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@material-ui/core';
import { useState } from 'react';

export default function CrudControlForm({ pristine, initialize, handleSubmit, submitting, initialValues, colectionFirebase, atualValues, setOpenPesquisa }) {

    const uid = atualValues ? atualValues.uid : null;
    const perfil = usePerfil()

    const { addActions, removeActions } = useCrudContext();
    const { showError, showSuccess } = useNotification();
    const { t } = useTranslation();
    const history = useHistory();

    const [open, setOpen] = useState(false);
    const [textoConfirm, setTextoConfirm] = useState("")
    const txtConfimDelete = "Deletar";

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);


    const handleClose = () => {
        setOpen(false);
        setTextoConfirm("")
    };

    const acaoDeletar = () => {

        let objDeletar = atualValues;

        setTextoConfirm("")

        if (colectionFirebase) {
            let objFormPermission = { uid: objDeletar.uid }

            let strCollectionRota = colectionFirebase + 'Permission'

            deleteObj(objDeletar, colectionFirebase,
                (r) => {
                    console.log("CRUD - Deletado com sucesso", r.msg);
                    showSuccess(r.msg)
                    history.push(history.location.pathname);
                    initialize(initialValues);
                },
                (err) => {
                    console.log("CRUD - Deletado error", err);
                    showError(err.message)
                }
                , [{
                    obj: objFormPermission, strCollection: strCollectionRota
                }]
            )
        } else {
            showError('Sem collection para excluir')
        }
    }

    const actionNovo = useCallback(() => {
        history.push(history.location.pathname);
        initialize(initialValues);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history, initialValues])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const actionSave = useCallback(handleSubmit((obj) => {
        if (colectionFirebase) {

            salvarObj(obj, colectionFirebase,
                (r) => {
                    if (!queryParams.has('uid')) {
                        LogTracker("OkApp - criou novo relatório", { reportUid: obj.uid, reportName: obj.nome }, perfil)
                    }
                    console.log("CRUD - Sucesso Salvou: ", r.msg);
                    showSuccess(r.msg)

                    if (obj.uid) {
                        history.push("OkReport?uid=" + obj.uid)
                    }
                },
                (err) => {
                    console.log("CRUD - error", err);
                    // showError(err.message)
                }
            )
        } else {
            showError('Sem collection para salvar')
        }
    }), [colectionFirebase, handleSubmit, showError, showSuccess])

    useEffect(() => {
        let add = [];
        add.push({ uid: 'crud.actions.delete', text: t('crud.actions.delete'), icon: null, action: () => setOpen(true), props: { style: { backgroundColor: "#f00" }, hidden: (pristine ? !uid : false) }, disabled: submitting })
        add.push({ uid: 'crud.actions.new', text: t('crud.actions.new'), icon: null, action: actionNovo, props: { hidden: (pristine ? !uid : false) }, disabled: submitting })
        add.push({ uid: 'crud.actions.save', text: t('crud.actions.save'), icon: null, action: actionSave, props: { disabled: (pristine || submitting) } })
        add.push({ uid: 'crud.action.pesquisa', index: 30, text: "Pesquisar", icon: null, action: () => setOpenPesquisa(true) })

        addActions(add)
        return () => { removeActions(['crud.actions.new', 'crud.actions.save', 'crud.action.pesquisa']); };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pristine, submitting, t, uid])
    return (
        <>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Excluir Registros</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Esta ação excluirá permanentemente esse registro. O registro removido NÃO PODE ser restaurado! Tem certeza ABSOLUTA?
                        <br />
                        <br />
                        Esta ação pode levar à perda de dados. Para evitar ações acidentais, solicitamos que você confirme sua intenção.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        value={textoConfirm}
                        label={'Digite "' + txtConfimDelete + '" para confirmar a exclusão'}

                        onChange={(event) => {
                            setTextoConfirm(event.target.value);
                        }}

                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant='contained' color='secondary'>
                        Cancelar
                    </Button>
                    <Button variant='contained'
                        onClick={() => {
                            handleClose()
                            acaoDeletar();
                        }}
                        color="primary"
                        disabled={txtConfimDelete.toUpperCase() !== textoConfirm.toUpperCase()}
                    >
                        Excluir Registro
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
