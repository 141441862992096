import React from 'react'
import { Field } from 'redux-form'
import './style.css'
import { makeStyles } from '@material-ui/core';
import { listTypeComponents } from '../../functions';

import MetaDialog from '../MetaDialog';
import SliderAddComp from '../SliderAddComp';
import SliderTreeComp from '../SliderTreeComp';
import { EditorCss } from 'components';
import CopiarForm from '../CopiarForm';


const useStyles = makeStyles(theme => ({

    leftContainerClass: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.contrastText,
        height: '100%'
    },
}));
export default function LeftComp({ okform, openProps }) {
    // const { t } = useTranslation();



    const classes = useStyles();

    const criaSnippetCss = (t) => {
        return {
            name: "OkCss-" + t,
            // eslint-disable-next-line no-template-curly-in-string
            content: '.' + t + "{${1:css}}",
        }
    }

    const types = listTypeComponents(okform);
    let snippets = types.map(t => criaSnippetCss(t))
    snippets.push({
        name: 'OkCss-CustomFormContainer',
        // eslint-disable-next-line no-template-curly-in-string
        content: '.CustomFormContainer{${1:css}}',
    });


    return (
        <div className={classes.leftContainerClass + " leftContainer"}>
            <div style={{ paddingBottom: 5 }}>
                <Field name="children" openProps={openProps} okform={okform} component={SliderAddComp} />
            </div>
            <div style={{ paddingBottom: 5 }}>
                <Field name="children" openProps={openProps} okform={okform} component={SliderTreeComp} />
            </div>
            <div style={{ paddingBottom: 5 }}>
                <MetaDialog okform={okform} />
            </div>
            {/* somente pra criar os snippets */}
            <div style={{ paddingBottom: 5 }}>
                <Field name="globalFormCss" notShow component={EditorCss} snippets={snippets} label="CSS" onlyDialog button />
            </div>


            <div style={{ paddingBottom: 5 }}>
                <CopiarForm/>
                
            </div>


        </div >
    )
}
