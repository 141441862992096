import React from 'react'
import { Button } from '@material-ui/core';

import { gerarExibir } from 'OkReport/OkReporGerador';
import { useAtualValues, useOkApp } from 'OkApp/UseUtils';
import _ from 'lodash'

export default function CodeEView({ okreport, jsonValue, label,metaPre }) {
    // const [docPdf, setDocPdf] = useState(null)
    let okApp = useOkApp();
    let metaUsar = okApp ? { logo: okApp.logo } : {}
    if (metaPre) {
        metaUsar = { ...metaUsar, ...metaPre };
    }
    let atualAux = useAtualValues()
    if (!okreport) {
        okreport = atualAux;// esta no cadastro de formularios
    }

    const gerar = () => {
        gerarExibir(okreport, jsonValue, metaUsar,okreport.nome)
    }
    let disabled = !_.isEmpty(jsonValue) ? false : true

    return (
        <div>
            <Button variant="contained" color="primary" onClick={gerar} disabled={disabled}>{disabled ? "Sem objetos para testes de relatório":label || 'Gerar'  }</Button>

        </div>
    )
}
