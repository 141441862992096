import React from 'react'


import { OkTypedComponent, LabelInputAuto } from 'OkApp'
import Componente from './components';

const EditorOkComp = (props) => {

  return <>
    <OkTypedComponent typeComponente='InputComp' field='field' label='Nome Salvo' />
    <LabelInputAuto {...props} />


  </>
}
const modelo = {
  field: "verdadeiro",
  label: "Verdadeiro",
  typeComponente: "SwitchComp"
}

const comp = {
  typeComponente: "SwitchComp",
  configView: {
    nome: "Verdadeiro/Falso",
    grupo: "Básico",
    helpUrl: "https://okapp.com.br/app/okappdocs/FalsoVerdadeiro",
    modelo,
  },

  Component: Componente,
  CompEditor: EditorOkComp
}

export default comp;
