import React, { useEffect, useState } from 'react'
import { reduxForm, getFormValues } from 'redux-form';

import { connect, } from 'react-redux'

import { OkModules } from 'OkApp'

import { makeStyles, Typography, Tab, Tabs, Box, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { createOkComponet, getOkCompCore } from 'OkApp/functions';


import _ from 'lodash'

const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'relative',
    },
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        height: 224,
    },
    rootv: {
        // flexGrow: 1,
        // backgroundColor: theme.palette.background.paper,
        // display: 'flex',
        // height: 224,
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
    },
    tabsV: {
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    active_tabStyle: {

        backgroundColor: theme.palette.secondary.dark,
    },
    default_tabStyle: {

    },

    active_tabStylev: {
        minWidth: 'auto',
        backgroundColor: theme.palette.secondary.dark,
    },
    default_tabStylev: {
        minWidth: 'auto',
    },
}));
function TabPanel(props) {
    const { children, atualTab, keyTab, fullScreenEditDialog, ...other } = props;
    if (!fullScreenEditDialog) {
        return (<Accordion>
            <AccordionSummary
                // expandIcon={<ExpandMoreIcon />}
                // aria-controls="panel1a-content"
                // id="panel1a-header"
            >
                <Typography>Accordion 1</Typography>
            </AccordionSummary>
            <AccordionDetails>
                {children}
            </AccordionDetails>
        </Accordion>)
    }
    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={atualTab !== keyTab}
            style={{ flex: 1 }}
            {...other}
        >
            {atualTab === keyTab && <Box p={1} style={{ height: "100%", position: 'relative' }}>{children}</Box>}
        </Typography>
    );
}


var EditorComp = function (props) {
    const { okComp, handleSubmit, initialize, setactions, salvarEdicao, configsComp, fullScreenEditDialog } = props
    const [atualTab, setAtualTab] = useState(0)
    let okCompCore = null
    let fields = []

    const classes = useStyles();


    useEffect(() => {
        initialize(okComp)
        return () => {

        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [okComp])
    const actionSaveComp = () => {
        handleSubmit((salvarDados) => {
            salvarEdicao(salvarDados)

        })()
    }
    useEffect(() => {
        setactions([{
            text: "Salvar", action: actionSaveComp
        }])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    if (okComp) {
        okCompCore = getOkCompCore(okComp.typeComponente);
        if (okCompCore && okCompCore.configView) {
            if (!okCompCore.CompEditor) {
                if (okCompCore.configView.modelo) {
                    for (const key in okCompCore.configView.modelo) {
                        if (key !== "typeComponente" && key !== "uid" && key !== "children" && okCompCore.configView.modelo.hasOwnProperty(key)) {
                            let value = okCompCore.configView.modelo[key];
                            let editor = null;
                            if (typeof value === 'number') {
                                editor = createOkComponet(key, "InputComp")
                            } else if (typeof value === 'string') {
                                editor = createOkComponet(key, "InputComp")
                            } else if (typeof value === 'boolean') {
                                editor = createOkComponet(key, "SwitchComp")
                            }

                            if (editor) {
                                fields.push(editor);
                            } else {
                                console.log('okComp.typeComponente', okComp.typeComponente)
                                console.log("nao tem editor para: ", key);
                                console.log("value", value);
                            }
                        }
                    }
                }
                //add editors

                if (okCompCore.configView.editors) {
                    let editors = okCompCore.configView.editors;
                    for (const field in editors) {
                        let editorConf = editors[field];
                        let label = editorConf.label || field;
                        let editor = createOkComponet(field, editorConf.typeComponente, label, editorConf.props)
                        fields.push(editor);
                    }
                }

            }
        }
    }
    let filterModules = OkModules;
    let hideModules = [];
    if (configsComp) {

        if (_.get(configsComp, "editorProps.hideModules")) {
            hideModules = _.get(configsComp, "editorProps.hideModules")
        }

        filterModules = OkModules.filter(m => {
            if (_.get(m, "data.abaMetaCustomComponent")) {
                if (hideModules.includes(_.get(m, "data.abaMetaCustomComponent"))) {
                    return false;
                }
            }
            return true;
        })
    }


    return (
        <>
            <Tabs
                orientation={fullScreenEditDialog ? "vertical" : "horizontal"}
                variant="scrollable"
                value={atualTab}
                onChange={(e, newValue) => {
                    setAtualTab(newValue)
                }}
                aria-label="Vertical tabs"
                className={fullScreenEditDialog ? classes.tabs : classes.tabsv}
            >
                <Tab label="Geral" className={atualTab === 0 ? fullScreenEditDialog ? classes.active_tabStyle : classes.active_tabStylev : fullScreenEditDialog ? classes.default_tabStyle : classes.default_tabStylev} />
                {filterModules.map((M, i) => {
                    if (M.data.abaMetaCustomComponent) {
                        //notInForm
                        return <Tab key={M.data.abaMetaCustomComponent} value={M.data.abaMetaCustomComponent} label={M.data.abaMetaCustomComponent} className={atualTab === M.data.abaMetaCustomComponent ? fullScreenEditDialog ? classes.active_tabStyle : classes.active_tabStylev : fullScreenEditDialog ? classes.default_tabStyle : classes.default_tabStylev} />
                    }
                    return ''
                })}

            </Tabs>

            <TabPanel atualTab={atualTab} keyTab={0} fullScreenEditDialog={fullScreenEditDialog}>
                {fields}
                {(okCompCore && okCompCore.CompEditor) && <okCompCore.CompEditor {...props} actionSaveComp={actionSaveComp} />}
            </TabPanel>
            {filterModules.map((M, i) => {
                if (M.data.abaMetaCustomComponent) {
                    return <TabPanel key={M.data.abaMetaCustomComponent} atualTab={atualTab} keyTab={M.data.abaMetaCustomComponent} fullScreenEditDialog={fullScreenEditDialog}>
                        <M.CompAddCustomComponent {...props} />
                    </TabPanel>
                }
                return ''
            })}
        </>
    )
}
EditorComp = connect((state, props) => {
    let atualValues = getFormValues(props.form)(state);
    return { atualValues }
})(EditorComp)
EditorComp = reduxForm({})(EditorComp)


export default (EditorComp)


