import React, { useEffect, useState } from 'react'
import { Button, CircularProgress, Typography } from '@material-ui/core'
import { createAssinatura, getPaymentIntentPendent, useAssinaturasStripeProdutos, usePriceProductsStripe, useProductForRefStripe } from 'OkApp/StripeUtils'



import PaymentIntentComp from './PaymentIntentComp'
function LinhaAssinatura({ stripeAccount, consumerStripe, assinatura, geralLoadding, setGeralLoadding }) {
    const return_url = window.location.href
    const [paymentIntent, setPaymentIntent] = useState(null)

    useEffect(() => {
        let observer = null
        if (assinatura && assinatura.status === "incomplete") {
            if (!paymentIntent) {
                let idLastInvoise = assinatura.latest_invoice
                getPaymentIntentPendent(stripeAccount, consumerStripe.stripeId, idLastInvoise, (listPaymentIntent) => {
                    if (!paymentIntent) {
                        let listPaymentIntentIncompletas = listPaymentIntent.filter(a => a.status === "requires_payment_method")
                        if (listPaymentIntentIncompletas && listPaymentIntentIncompletas.length) {
                            setPaymentIntent(listPaymentIntentIncompletas[0])
                        }
                    }
                })
            }
        } else {
            setPaymentIntent(null)
        }
        return () => {
            if (observer) observer()
        }
    }, [stripeAccount, assinatura, consumerStripe?.stripeId, paymentIntent])

    if (!assinatura.status) {
        return <div>Carregando...</div>
    }
    const getStatus = (a) => {
        if (a.status === "active") return "Ativa"
        if (a.status === "canceled") return "Cancelada"
        if (a.status === "incomplete_expired") return "Pagamento Expirado"
        if (a.status === "incomplete") return "Pendente"
        return a.status
    }
    const getColor = (a) => {
        if (a.status === "active") return "#96ff96"
        if (a.status === "canceled") return "#f55b5b"
        if (a.status === "incomplete_expired") return "#f55b5b"
        if (a.status === "incomplete") return "#e5f911"
        console.log('a', a)
        return "unset"
    }

    return <div style={{ padding: 5 }}>
        <div style={{ display: "flex", flexWrap: "wrap", justifyContent: 'space-between', gap: 5, padding: 5, backgroundColor: getColor(assinatura) }}>
            <div style={{ flex: "1 1 250px" }}>
                <b>Status: </b> {getStatus(assinatura)}
            </div>
            <div style={{ flex: "1 1 250px" }}>
                <b>Criação: </b> {new Date(assinatura.created * 1000).toLocaleDateString()}
            </div>
            <div style={{ flex: "1 1 250px" }}>
                <b>Valor:</b> R$ {(assinatura.plan.amount / 100).toFixed(2)}
            </div>
            <div style={{ flex: "1 1 250px" }}>
                <b>Período: </b> {new Date(assinatura.current_period_start * 1000).toLocaleDateString()} a {new Date(assinatura.current_period_end * 1000).toLocaleDateString()}
            </div>
        </div>
        {paymentIntent && <PaymentIntentComp stripeAccount={stripeAccount} paymentIntent={paymentIntent} setGeralLoadding={setGeralLoadding} return_url={return_url} />}
    </div>
}

function PriceComp({ price, permiteAssinarNova, stripeAccount, consumerStripe, stripeOkappConfig, geralLoadding, setGeralLoadding }) {
    let valor = (price.unit_amount / 100).toLocaleString(undefined, { minimumFractionDigits: 2 })


    let perido = ""
    if (price.recurring) {

        switch (price.recurring.interval) {
            case "month":
                perido = "/ por mês"
                break;
            case "year":
                perido = "/ por ano"
                break;
            case "day":
                perido = "/ por dia"
                break;

            default:
                perido = "/ " + price.recurring.interval
                break;
        }

    }
    let currencyS = price.currency === "brl" ? "R$" : price.currency

    let txtButton = price.type === "one_time" ? "Comprar" : "Assinar"

    const criarAssinatora = async () => {
        // console.log('consumerStripe', consumerStripe)
        let assinaturaObj = {
            customer: consumerStripe.stripeId,
            items: [{ price: price.id }],
            // transfer_data: { destination: stripeAccount },
            stripeAccount: stripeAccount,
            application_fee_percent: stripeOkappConfig.application_fee_percent || 10,
            payment_behavior: 'default_incomplete',
            payment_settings: {
                save_default_payment_method: 'on_subscription',
                payment_method_types: ["card"]
            },
            expand: ['latest_invoice.payment_intent'],
        }
        // console.log('assinaturaObj', assinaturaObj)
        setGeralLoadding("Criando Assinatura...")
        createAssinatura(stripeAccount, consumerStripe.stripeId, assinaturaObj, assCreate => {
            console.log('assCreate', assCreate)
            setGeralLoadding("Sincronizando dados...")
        })
    }
    return <div style={{
        boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
        transition: '0.3s',
        borderradius: 5,
        padding: 15,
        margin: 10,
        gap: 5,
        width: 250,
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',

    }}>

        <Typography style={{ flex: 1 }} variant="h6">{currencyS} {valor}</Typography>
        <Typography style={{ flex: 1 }} variant="caption">{perido}</Typography>
        <Typography style={{ flex: 1, marginTop: 10, marginBottom: 10 }} variant="subtitle2">{price.nickname}</Typography>
        <Button variant='contained' color='primary' disabled={(price.type === "one_time" || !permiteAssinarNova || geralLoadding) ? true : false} onClick={criarAssinatora}>{txtButton}</Button>

    </div>
}
export default function ProductView({ stripeAccount, consumerStripe, productRef, stripeOkappConfig }) {
    const [geralLoadding, setGeralLoadding] = useState("")

    let permiteAssinarNova = true
    const product = useProductForRefStripe(stripeAccount, productRef)
    const prices = usePriceProductsStripe(stripeAccount, productRef.id)
    const assinaturasAtual = useAssinaturasStripeProdutos(stripeAccount, consumerStripe.stripeId, product.id);
    // console.log('product', product)
    useEffect(() => {
        if (assinaturasAtual && geralLoadding === "Sincronizando dados...") {
            let assPn = assinaturasAtual.filter(a => a.status === 'incomplete')
            if (assPn.length) {
                setGeralLoadding("")
            }
        }

        return () => {

        }
    }, [geralLoadding, assinaturasAtual])

    permiteAssinarNova = (assinaturasAtual || []).filter(a => a.status === 'active' || a.status === 'incomplete').length ? false : true
    return (
        <div style={{
            boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
            transition: '0.3s',
            borderradius: 5,
            padding: 10

        }}>
            <div style={{ display: "flex" }}>
                {(product.images && product.images.length) && <img style={{ maxHeight: 75, margin: 15 }} src={product.images[0]} alt="imgProduct" />}
                <div>
                    <Typography style={{ flex: 1 }} variant="h6">{product.name}</Typography>
                    <p>{product.description}</p>
                </div>
            </div>
            {geralLoadding && <div style={{
                display: "flex", justifyContent: 'center',
                alignItems: 'center',
                gap: 10
            }}>
                <CircularProgress /> {geralLoadding}
            </div>}
            {permiteAssinarNova && <>
                <Typography style={{ flex: 1 }} variant="h6">Valor{(prices && prices.length > 1) ? "es" : ""}</Typography>
                <div style={{ display: "flex", justifyContent: 'center', flexWrap: "wrap" }}>
                    {(prices || []).map((p, i) => <PriceComp key={i} price={p}
                        permiteAssinarNova={permiteAssinarNova} stripeAccount={stripeAccount}
                        consumerStripe={consumerStripe} stripeOkappConfig={stripeOkappConfig} geralLoadding={geralLoadding} setGeralLoadding={setGeralLoadding} />)}
                </div>
            </>}
            <div>
                {(assinaturasAtual || []).map((a, i) => <LinhaAssinatura key={i} stripeAccount={stripeAccount} consumerStripe={consumerStripe} assinatura={a} geralLoadding={geralLoadding} setGeralLoadding={setGeralLoadding} />)}
            </div>
        </div>
    )
}
