import React from 'react'
import OkTypedComponent from 'OkApp/components/OkTypedComponent'
import LabelInputAuto from 'OkApp/components/LabelInputAuto'
import { useAtualValues } from 'OkApp/UseUtils'
import {  useIsAdmin } from 'useAuth';
export default function EditorComp(props) {
    const isAdmin = useIsAdmin();
    
    // const typeFile = useAtualValues('type'); // redimencionar se imagem
    let typeQt = useAtualValues('typeQt')
    if (!typeQt) {
        typeQt = "multiplo"
    }
    return <>
        <OkTypedComponent typeComponente='InputComp' field='field' label='Nome Salvo' />
        <LabelInputAuto {...props} />
        <OkTypedComponent typeComponente='ComboBoxComp' field='viewType' label='Visualização' options={["listagem", "botao"]} toStringItem={(v) => {
            switch (v) {
                case "listagem": return "Listagem de Anexos com botão"
                case "botao": return "Botão"
                default: return "listagem";
            }
        }} />
        <OkTypedComponent typeComponente='ComboBoxComp' field='typeQt' label='Lista ou anexo único' options={["multiplo", "unico"]} toStringItem={(v) => {
            switch (v) {
                case "multiplo": return "Lista de Anexos"
                case "unico": return "Anexo Único"
                default: return "multiplo";
            }
        }} />

        <OkTypedComponent typeComponente='ComboBoxComp' field='type' label='Tipo' options={["image/*","video/*", ".pdf", "*"]} toStringItem={(v) => {
            switch (v) {
                case "*": return "Qualquer"
                case "image/*": return "Imagem"
                case "video/*": return "Video"
                case ".pdf": return "PDF"
                default: return "ERRO";
            }
        }} />

        
        {typeQt === "multiplo" && <>
            <OkTypedComponent typeComponente='InputComp' disabled={!isAdmin} field='limit' label='Quantidade máxima de arquivos' />
            <OkTypedComponent typeComponente='SwitchComp' field='multiple' label='Upload multiplo' />
        </>}
    </>
}

