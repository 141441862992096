import React, { Component } from 'react'
import './style.css'
import Menu from '../menu'
import { Route } from 'react-router-dom'
class OkAppTheme extends Component {

    render() {
        const { menus, routes } = this.props;
        return (
            <div className="ContainerMenuRoot">
                <div className="ContainerMenuItens">
                    <Menu menus={menus} />
                </div>
                <div className="ContainerMenuForm">
                    {routes.map((route, index) => <Route key={"Route-" + index} {...route} />)}
                </div>
            </div>
        )
    }


}

export default OkAppTheme