import React from 'react'
import OkCompAdapter from 'OkApp/components/OkCompAdapter'
import EditorComp from './components/EditorComp'
import CompView from './components/CompView'
const typeComponente = 'AssinaturaStripeComp'

const AdapterOkComp = (props) => {
    let propsComp = {};
    return <OkCompAdapter {...props} propsComp={propsComp} component={CompView} />
}

const modelo = {
    typeComponente,
    field: 'AssinaturaStripe',
    label: 'AssinaturaStripe(Teste)', 
}


const AssinaturaStripeComp = {
    typeComponente,
    configView: {
        grupo: 'Financeiro',
        nome: 'Assinatura Stripe',
        descricao: 'Assinatura Stripe',
        helpUrl: 'https://www.google.com.br/',
        modelo
    },
    Component: AdapterOkComp,
    CompEditor: EditorComp
}
export default AssinaturaStripeComp
