import React, { useState, useContext, useEffect, useCallback } from 'react'
import CustomFormDeveloper from 'routes/dinamic/components/CustomFormDeveloper/componentes/form'
import CopiarProjetos from 'routes/dinamic/CopiarProjetos'
import { Redirect, useHistory } from 'react-router-dom'
import * as ModuleMenuControl from './modules/MenuControl'
import { useOkFormListRoutesSnap, useUserSnap } from './UseUtils'
import { getUserUid, getPerfilUserSnap } from './OkFunctions'
import { isAuthenticated, usePerfil } from 'useAuth'
import _ from 'lodash'
import { ModulesForms } from './modules'
import { permiteVer } from './modules/PermissionMeta/functions'
import packageJson from '../../package.json';

import firebaseUtil from 'firebaseUtil'
import OkReportElaboracao from 'OkReport/components/OkReportElaboracao'
import { snapVerificaAssinaturas, snapVerificaAssinaturasCallback } from './StripeUtils'
import { updatePerfilUid } from 'dbApi'
export const OkAppContext = React.createContext({})

const sortMenu = (menus) => {


    return menus.sort((a, b) => {
        let ac = a.index || 0;
        let bc = b.index || 0;

        if (ac < bc)
            return -1;
        if (ac > bc)
            return 1;

        ac = a.text;
        bc = b.text;
        if (ac < bc)
            return -1;
        if (ac > bc)
            return 1;

        return 0;
    });
}
function OkAppControl() {
    const [okApp, setOkAppFinal] = useState(null)
    const [loadingOkApp, setLoadingOkApp] = useState(false)
    const [loadingRoutes, setLoadingRoutes] = useState(true)
    const [routes, setRoutes] = useState(null)
    const [menus, setMenus] = useState([])
    const perfil = usePerfil();
    const [perfilUser, setPerfilUser] = useState(null)

    const [atualOkForm, setAtualOkForm] = useState(null)
    const [database, setDatabase] = useState(null)

    const history = useHistory()
    const listOkForm = useOkFormListRoutesSnap(okApp, setLoadingRoutes);
    let userUid = getUserUid();
    const userPerfilOkapp = useUserSnap(userUid)



    //verifica muda perfil por assinatura
    useEffect(() => {
        const stripeOkappConfig = okApp?.stripe
        if (stripeOkappConfig && perfilUser) {
            snapVerificaAssinaturas(okApp, stripeOkappConfig, perfilUser)
        }

        return () => { }
    }, [okApp, perfilUser])

    //verifica muda perfil por assinatura
    useEffect(() => {
        //ass geral
        console.log('Verifica assinatura perfil', perfil)
        if (perfil) {
            snapVerificaAssinaturasCallback(null, ["prod_NuCNx91Zrt4Xxd"], perfil.uid, (assinatura) => {
                if (perfil.ignoreAssinaturas) {
                    console.log('ignora busca stripe')
                    return;
                }

                console.log('Verificou assinatura', assinatura)
                if (assinatura) {
                    const { id } = assinatura
                    // if (perfil?.plano?.id !== id) {
                    let validade = new Date(assinatura.current_period_end * 1000).toISOString().split('T')[0]
                    let plano = { id, nomePlano: "Plano Ultimate", validade, origem: "stripe", status: assinatura.status, limiteArmazenamento: 500, limiteOperacoes: 50000 }
                    let p = { uid: perfil.uid, nomePlano: "Plano Ultimate", dataFimPlano: validade, numProject: 3, numUser: 2, plano }
                    updatePerfilUid(perfil.uid, p)
                    // }
                } else {
                    if (perfil?.plano && perfil.plano?.status === "inactive") {
                        let p = {
                            uid: perfil.uid, nomePlano: ""
                        }
                        updatePerfilUid(perfil.uid, p)
                    }

                    if (perfil?.plano && perfil.plano.status) {
                        let p = {
                            uid: perfil.uid, plano: { status: 'inactive' }
                        }
                        updatePerfilUid(perfil.uid, p)
                    }
                    console.log('sem assinatura stripe')
                    if (new Date(perfil.dataFimPlano) > new Date()) {
                        console.log('ainda vigente plano manual')
                    } else {

                    }
                }
            })
        }
        return () => { }
    }, [perfil])

    //perfilUser
    useEffect(() => {
        let observer = null;
        if (okApp && userUid) {
            observer = getPerfilUserSnap(userUid, okApp, (p) => {
                setPerfilUser((old) => {
                    if (!_.isEqual({ ...old, lastChange: null, lastLogin: null }, { ...p, lastChange: null, lastLogin: null })) {
                        return p
                    }
                    return old
                })

            }, (error) => console.log('error getPerfilUserSnap', error))
        } else {
            setPerfilUser(null)

        }
        return () => {
            setPerfilUser(null)
            if (observer) observer()
        }
    }, [okApp, userUid])

    //dataBase
    useEffect(() => {
        if (okApp) {
            // console.log('use effect okApp mudou', okApp)
            const { firebaseProjectId } = okApp;
            if (firebaseProjectId) {
                setDatabase(firebaseUtil.initGetDataBase({ projectId: firebaseProjectId }, okApp.uid))
            }
        } else {

            let fixedRoute = ''
            if (packageJson && packageJson.fixedAppRoute) {
                fixedRoute = packageJson.fixedAppRoute
                console.log('fixedRoute', fixedRoute)
            }

            if (fixedRoute) {
                history.push("/app/" + fixedRoute)
            } else {
                if (userPerfilOkapp) {
                    let defaultAppRouteUser = userPerfilOkapp.defaultRoute;
                    if (defaultAppRouteUser) {
                        history.push("/app/" + defaultAppRouteUser)
                    }
                }

            }
        }

        return () => {
            setDatabase(null)
        }
    }, [history, okApp, userPerfilOkapp])

    useEffect(() => {
        window.addEventListener('popstate', handleChangeMenus);
        return () => {
            window.removeEventListener('popstate', handleChangeMenus);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChangeMenus = (e) => {
        if (!okApp && history && /^\/+$/.test(history.location.pathname)) {
            setOkAppFinal(null)
        }
    };

    // verifica se tem algum okapp e seta o nome da aba com o projeto atual
    useEffect(() => {
        if (okApp) {
            document.title = okApp.name
        } else {
            document.title = 'Ok App'
        }
    }, [okApp])

    //all Rotas e Menus
    useEffect(() => {
        if (listOkForm) {
            let rd = []
            let md = []
            let parentMenus = {};
            //#region Funcoes
            const criaRoute = (customForm) => {
                return { path: "/app/:appRoute/" + customForm.route, customForm };
            }

            const criaMenu = (customForm) => {
                if (customForm) {
                    return ModuleMenuControl.criaMenus(customForm, okApp, history);
                }
            };



            const pushMenu = (menuCriado) => {
                if (menuCriado) {
                    if (menuCriado.parentMenu) {
                        let me = parentMenus[menuCriado.parentMenu];
                        if (!me) {
                            me = { text: menuCriado.parentMenu, iconKey: menuCriado.parentMenuIcon, index: menuCriado.parentMenuIndex, menus: [], type: menuCriado.type }
                            parentMenus[menuCriado.parentMenu] = me;
                            md.push(me)
                        }
                        if (menuCriado.parentMenuIcon) {
                            me.iconKey = menuCriado.parentMenuIcon;
                        }
                        me.menus.push(menuCriado)
                        sortMenu(me.menus)
                    } else {

                        md.push(menuCriado)
                    }

                }
            }
            const historyPush = (route) => {
                history.push("/app/" + okApp.route + "/" + route)
            }
            const CompRedirectTo = ({ route }) => {
                return <Redirect to={"/app/" + okApp.route + "/" + route} />
            }

            //#endregion
            rd.push({ path: "/app/:appRoute/CustomForm", component: CustomFormDeveloper })
            rd.push({ path: "/app/:appRoute/Copy", component: CopiarProjetos })
            rd.push({ path: "/app/:appRoute/OkReport", component: OkReportElaboracao })

            let criouInicioSemLogin = false;



            let authenticated = isAuthenticated();

            listOkForm.forEach(customForm => {
                let criaDeste = true;

                if (!authenticated) {
                    if (!_.get(customForm, "metaOkApp.permission.publicNoUser")) {
                        criaDeste = false
                    }
                    if (okApp && okApp.formInicioNotAuth) {

                        if (customForm.uid === okApp.formInicioNotAuth.uid) {
                            criouInicioSemLogin = true
                            // let rotaInicio = criaRoute(customForm);
                            let rotaInicio = { component: () => <CompRedirectTo route={customForm.route} /> }
                            rotaInicio.path = "/app/:appRoute/"
                            rotaInicio.exact = true;
                            rd.push(rotaInicio)
                        }

                    }
                } else {
                    criaDeste = permiteVer(customForm, perfilUser);
                    if (okApp && okApp.formInicio) {
                        if (customForm.uid === okApp.formInicio.uid) {
                            // let rotaInicio = criaRoute(customForm);
                            let rotaInicio = { component: () => <CompRedirectTo route={customForm.route} /> }
                            rotaInicio.path = "/app/:appRoute/"
                            rotaInicio.exact = true;
                            rd.push(rotaInicio)
                        }
                    }
                }


                if (criaDeste) {
                    const rotaCriado = criaRoute(customForm);
                    const menuCriado = criaMenu(customForm);
                    rd.push(rotaCriado)
                    pushMenu(menuCriado)

                }

            });


            if (!criouInicioSemLogin) {

                //criar rota para login
            }


            let formModules = ModulesForms({ okApp, historyPush });
            formModules.forEach(f => {
                if (f.menu) {
                    pushMenu(f.menu)
                }
                if (f.form) {
                    rd.push({ ...f.form, path: "/app/:appRoute/" + f.form.path })
                }
            })
            setRoutes(old => {
                let newArr = [...old];
                if (newArr.length && newArr.length === rd.length) {

                    newArr = newArr.map(item => {
                        const item2 = rd.find(i2 => i2.path === item.path);
                        if (_.isEqual(item, item2)) {
                            return item
                        } else {
                            return item2
                        }
                    });

                    return newArr
                }

                return rd
            })
            setMenus(md)
            setLoadingOkApp(false)

        } else {
            setRoutes([])
            setMenus([])
        }

        return () => {
            // setRoutes([])
            // setMenus([])
        }
    }, [history, listOkForm, okApp, perfilUser])

    useEffect(() => {
        return () => {
            setRoutes([])
            setMenus([])
        }
    }, [okApp])

    const setOkApp = useCallback((okapp1) => {
        //  console.log('setOkApp real', okapp1)
        if (okapp1 && okapp1.firebaseProjectId) {
            const database = firebaseUtil.initGetDataBase({ projectId: okapp1.firebaseProjectId }, okapp1.uid)
            setOkAppFinal({ ...okapp1, database });
        } else {
            setOkAppFinal(okapp1);
        }

    },
        [setOkAppFinal],
    )
    return { okApp, setOkApp, routes, menus, loadingOkApp, setLoadingOkApp, perfilUser, loadingRoutes, database, atualOkForm, setAtualOkForm }
}

export const OkAppProvider = ({ children }) => {
    const v = OkAppControl();

    return <OkAppContext.Provider value={v}>
        {children}
    </OkAppContext.Provider>

}
export const useOkAppContext = () => useContext(OkAppContext)


export function useDinamicRoutes() {
    return useContext(OkAppContext).routes;
}

export function useDinamicMenus() {
    return useContext(OkAppContext).menus;
}

export function ajusteRoute(okApp, okForm) {
    return "/app/" + okApp.route + "/" + okForm.route;
}