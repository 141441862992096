import React, { useState } from 'react'
import RenderOkForm from 'OkApp/components/RenderOkForm'
import { Tab, Tabs, Typography, makeStyles, Box } from '@material-ui/core';
import { permiteVerOkComp } from 'OkApp/modules/PermissionMeta/functions';
import { usePerfilUser } from 'OkApp/UseUtils';


const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'relative',
    },
    rootVertical: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        height: 'auto',
    },
    rootHorizontal: {
        backgroundColor: theme.palette.background.paper,
        height: 'auto',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
}));
function TabPanel(props) {
    const { children, atualTab, keyTab, ...other } = props;
    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={atualTab !== keyTab}
            style={{ width: "100%" }}
            {...other}
        >
            {atualTab === keyTab && <Box p={0} >{children}</Box>}
        </Typography>
    );
}

export default function CompView({ okComp, ...rest }) {
    const [atualTab, setAtualTab] = useState(0)
    const classes = useStyles();
    const { children, vertical } = okComp
    const perfilUser = usePerfilUser();

    let filtrarAbas = children.filter(comp => {
        let permiteVerVar=false;
        if(!rest.developer && comp.onlyindev){
            
            return false
        }
        permiteVerVar = permiteVerOkComp(rest.okform, comp, perfilUser)        
        return permiteVerVar;
    })

    let vazio = !filtrarAbas || !filtrarAbas.length

    let orientation = "vertical";
    if (!vertical) {
        orientation = 'horizontal'
    }
    let exibeAbas = true;
    if (okComp.ocultarAbasSeUnica && filtrarAbas.length === 1) {
        exibeAbas = false
    }

    return <div className={vertical ? classes.rootVertical : classes.rootHorizontal}>
        {exibeAbas && <>
            <Tabs
                orientation={orientation}
                variant="scrollable"
                value={atualTab}
                onChange={(e, newValue) => {
                    setAtualTab(newValue)
                }}
                className={classes.tabs}
            >
                {vazio && <div className={classes.tabs} style={{ height: 55, width: "100%", padding: 15 }}>Painel sem abas :)</div>}

                {filtrarAbas.map((aba, i) => {
                    return <Tab key={i} value={i} label={aba.label} />
                })}

            </Tabs>
        </>}
        <div style={{ width: "100%" }}>

            {filtrarAbas.map((aba, i) => {

                return <TabPanel key={i} atualTab={atualTab} keyTab={i} >
                    < RenderOkForm   {...rest} okComp={aba} />
                </TabPanel>
            })}
        </div>
    </div>

}
