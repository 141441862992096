import { IconSelector } from 'components'
import { LabelInputAuto } from 'OkApp'
import OkTypedComponent from 'OkApp/components/OkTypedComponent'
import React from 'react'
import { Field } from 'redux-form'

export default function EditorComp(props) {
    return <>
        <OkTypedComponent typeComponente='InputComp' field='field' label='Nome Salvo' />
        <LabelInputAuto {...props} />
        <OkTypedComponent typeComponente='InputComp' field='title' label='Título do Dialog' />
        <div style={{
            display: 'flex',

        }}>

            <OkTypedComponent typeComponente='ComboBoxComp' field='color' label='Cor' options={['primary', 'secondary']} toStringItem={(v) => {
                switch (v) {
                    case 'primary': return "Cor Primária"
                    case 'secondary': return "Cor Secundária"

                    default: return v;
                }
            }} />
            <Field name={"iconkey"} label={"Icone"} component={IconSelector} />


        </div>
        <OkTypedComponent typeComponente='SeletorOkForm' field='auxOkForm' label='Formulário' />

    </>
}