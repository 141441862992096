export function listTypeComponents(okform) {

    let r = []
    if (okform)
        percorreComponentes(okform, (comp) => {
            if (comp.typeComponente) {
                if (r.indexOf(comp.typeComponente) === -1)
                    r.push(comp.typeComponente);
            }
        })
    return r
}

function percorreComponentes(okform, acao) {
    if (okform.children) {
        for (let index = 0; index < okform.children.length; index++) {
            const c = okform.children[index];
            acao(c)
            percorreComponentes(c, acao)

        }
    }
}

