import React from 'react'
import OkCompAdapter from 'OkApp/components/OkCompAdapter'
import { OkTypedComponent, LabelInputAuto } from 'OkApp'
import CompView from './components/CompView'
import Render from 'routes/dinamic/components/CustomFormDeveloper/componentes/form/Render'
import { Field } from 'redux-form'
import { Button } from '@material-ui/core'

import "./style.css"
const typeComponente = 'RegioesDnd'

const AdapterOkComp = (props) => {
    let propsComp = {}
    return <OkCompAdapter {...props} propsComp={propsComp} component={CompView} />
}
const EditorOkComp = (props) => {

    return <>
        <OkTypedComponent typeComponente='InputComp' field='field' label='Nome Salvo' />
        <LabelInputAuto {...props} />
        {/* <OkTypedComponent typeComponente='SeletorOkForm' field='auxOkForm' label='Formulário' /> */}
        <div style={{ display: 'flex' }}>
            <OkTypedComponent typeComponente='SwitchComp' field='exibeAdd' label='Exibir Adicionar' />
        </div>
        <div style={{ display: 'flex' }}>

            <OkTypedComponent typeComponente='InputComp' field='fieldRegion' label='Campo para Regiões' />
            <OkTypedComponent typeComponente='InputComp' field='numColunas' label='Nº Colunas' type="number" />
            <OkTypedComponent typeComponente='InputComp' field='gap' label='Espaçamento' type="number" />
        </div>
        <OkTypedComponent typeComponente='TabelaOkComp' field='regioes' label='Regiões'
            exibeLabel={true}
            permiteRemover={true}
            permiteMover={true}
            exibeAdd={true}
            colunas={[

                { field: "nome", label: "Nome", typeComponente: 'InputComp' },
                { field: "rowspan", label: "Qt Linhas(rowspan)", typeComponente: 'InputComp', type: "number" },
                { field: "colspan", label: "Qt Colunas(colspan)", typeComponente: 'InputComp', type: "number" },
            ]}
        />
        <Button variant='contained' color='primary' onClick={() => {
            props.change('painelCard', [{ uid: Math.random().toString(36).substring(7), typeComponente: "PanelFlexComp", children: [{ uid: Math.random().toString(36).substring(7), field: "descricao", label: "Descrição", typeComponente: 'InputComp', multiline: true }] }])
        }}>Reiniciar Card com Painel</Button>
        <div style={{ padding: 25, backgroundColor: "#ccc", minHeight: 250 }}>

            <div className="RegioesDnD-conteinerItemLista">
                <Field name="painelCard" component={Render} prefield='cardValues' />
            </div>
        </div>

    </>
}

const modelo = {
    typeComponente,
    field: 'regioes',
    label: 'Regiões',
    fieldRegion: "regiao",
    numColunas: '3',
    gap: '10',
    painelCard: [{ uid: Math.random().toString(36).substring(7), typeComponente: "PanelFlexComp", children: [{ uid: Math.random().toString(36).substring(7), field: "descricao", label: "Descrição", typeComponente: 'InputComp', multiline: true }] }],

    regioes: [
        { nome: "Região 1", rowspan: '2' },
        { nome: "Região 2" },
        { nome: "Região 3", rowspan: '2' },
        { nome: "Região 4" }
    ]
}


const RegioesDnd = {
    typeComponente,
    configView: {
        grupo: 'Container',
        nome: 'Regiões',
        descricao: 'Painéis organizados em regiões no formato de tabela com cards personalizáveis e arrastáveis entre regiões',
        helpUrl: 'https://okapp.com.br/app/okappdocs/regioes',
        modelo
    },
    Component: AdapterOkComp,
    CompEditor: EditorOkComp
}
export default RegioesDnd