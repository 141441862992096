import React from 'react'

export default function FomComp({ form }) {

    // console.log('form', form)
    return (
        <div>
            <h2>{form.name} => Pontos: {form.pontosTotais} - Pontos Previstos: {form.pontosPrevistos}</h2>
            <h3>{form.description}</h3>

            <div>
                {form.components.map((c, i) => <div key={i}>
                    {'Tipo: ' + c.typeComponente + " | Label: " + c.label + " => Pontos: " + c.postos}
                </div>
                )}
            </div>
        </div>
    )
}
