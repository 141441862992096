import React, { useEffect, useState } from 'react'
import { useCrudContext } from 'crud'
import { Button, Avatar } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import EditIcon from '@material-ui/icons/Edit';
import NavigationIcon from '@material-ui/icons/Navigation';
import _ from 'lodash'
import './style.css'
import { useListOkApp, useUserUid } from 'OkApp/UseUtils';
import { permiteEditar } from 'OkApp/modules/PermissionMeta/functions';
import { ContentLoading } from 'components';

const route = "/Projeto";

export default function ConsultaProjeto() {
    const userUid = useUserUid();
    const { setTitle, addAction, removeActions } = useCrudContext();
    const [projetosFilter, setProjetosFilter] = useState(null)

    const projetos = useListOkApp();
    const history = useHistory();
    const { t } = useTranslation();

    useEffect(() => {
        setTitle("Meus projetos")
        return () => setTitle("")

    }, [setTitle])


    useEffect(() => {
        addAction({ uid: 'crud.actions.new_project', text: t('crud.actions.new_project'), icon: null, action: () => history.push(route), props: {}, })
        return () => { removeActions(['crud.actions.new_project']) }

    }, [addAction, history, removeActions, t])

    const editProject = (project) => {
        history.push(route + "?uid=" + project.uid)
    }
    const inicioProject = (project) => {
        history.push("/app/" + project.route)
    }

    useEffect(() => {

        if (projetos && userUid) {
            setProjetosFilter(projetos.filter((p) => {
                let b = false;
                let owner = _.get(p, "metaOkApp.permission.owner");
                if (owner && owner === userUid) {
                    return true;
                }
                return b;
            }))
        } else {
            setProjetosFilter(null)
        }

    }, [projetos, userUid])

    return (
        <div className="projectsRoot">


            {!projetosFilter && <div className="projectContainer">
                <div style={{ margin: 10 }}>
                    <ContentLoading style={{ height: 100, width: 100 }} />
                </div>
                <div className="projectData">
                    <ContentLoading style={{ height: 18, width: 250 }} />
                    <div className="projectDescription">  <ContentLoading style={{ height: 12, width: 100 }} /></div>

                </div>
            </div>}
            {projetosFilter && projetosFilter.map((p, i) => {
                let scr = "https://www.gstatic.com/mobilesdk/160505_mobilesdk/zerostate/2x/database.png";

                if (p.logo) {
                    scr = `data:image/png;base64,${p.logo}`;
                }
                
                return (<div className="projectContainer" key={"Project" + p.uid}>


                    <Avatar variant={'rounded'} alt={p.name} style={{ margin: 10, height: 140, width: 140 }} src={scr} />

                    <div className="projectData">
                        <strong className="projectName">{p.name}</strong>
                        <p className="projectDescription">{p.description}</p>
                        <div className="projectActions">
                            {permiteEditar(p) &&
                                <Button onClick={() => editProject(p)} >
                                    <EditIcon />
                                </Button>}
                            <Button onClick={() => inicioProject(p)} >
                                <NavigationIcon />
                            </Button>
                        </div>
                    </div>
                </div>)
            })}
            {(projetosFilter && !projetosFilter.length) && <div className="projectContainer" >

                <div className="projectData">
                    <strong className="projectName" style={{ textAlign: "center" }}>Você não possui nenhum projeto. Cadastre um clicando no botão "NOVO" acima.</strong>
                    {/* <div style={{ margin: "0 auto", paddingTop: 10 }}>
                        <Button variant="contained" color="primary" onClick={novoProject} >Novo Projeto</Button>
                    </div> */}
                </div>
            </div>
            }

        </div >
    )
}
