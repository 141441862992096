import React, { memo } from 'react'
import RouterAdapter from './RouterAdapter'

const MemoRoute = React.memo((props) => {    
    // console.log('MemoRoute', props)
    return (
        <RouterAdapter  {...props} />
    );
});

function RoutesMapDinamic(props) {
    const { routes } = props

    // const testItems = routes.map((route, index) =>
    //     <TestItem key={"Route-" + index} route={route} />
    // );
    return (<>
        {/* {testItems} */}
        {routes.map((route, index) => <MemoRoute key={"Route-" + index} {...route} />)}
    </>
    )
}

export default memo(RoutesMapDinamic)