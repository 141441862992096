import React, { useCallback, useEffect, useRef, useState } from 'react'

import _ from 'lodash'



import AceEditor from "react-ace";
import "ace-builds/webpack-resolver";
import "ace-builds/src-noconflict/mode-css";
import "ace-builds/src-noconflict/snippets/css";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-min-noconflict/ext-searchbox";
import "ace-builds/src-min-noconflict/ext-language_tools";
import {
    registerSnippets,
    createSnippets,
} from './ace-snippets-extension-simple'



import { html_beautify } from 'js-beautify';
import './style.css'
import NewWindow from 'react-new-window';
import { InputLabel } from '@material-ui/core';

function CodeEditor(props) {
    const [showDialogCode, setShowDialogCode] = useState(false)
    const refEditor = useRef(null)
    let theme = 'monokai';
    let mode = 'html'
    // let mode = 'jsx'
    let fontSize = 14

    const {
        input,
        label,
        startValue,
        useWorker,
        snippets,//ex: [ { name: 'build', code: 'console.log("build")' }]
        ...custom
    } = props

    useEffect(() => {
        if (refEditor && refEditor.current && refEditor.current.editor) {
            let editor = refEditor.current.editor;
            if (editor && snippets) {
                registerSnippets(//força snnipets javascript
                    editor,
                    editor.session,
                    mode,
                    createSnippets(snippets)
                )
            }
        }
    }, [mode, refEditor, snippets])
    let valueUsar = input.value
    if (startValue && !input.value) {
        valueUsar = startValue;
    }

    const { input: { value } } = props
    const formater = useCallback((v1, v2) => {
        let list = [];
        for (const key in value) {
            if (value.hasOwnProperty(key)) {
                const element = value[key];
                list.push(key + " : " + element)
            }
        }
        if (_.get(refEditor, "current.editor")) {
            let codeF = refEditor.current.editor.getValue()
            let formated = html_beautify(codeF)
            console.log('formated', formated)
            // const formated = prettier.format(codeF, {
            //     parser: "babel",
            //     plugins: [babylon]
            // });
            input.onChange(formated)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [input])

    const commands = [{   // commands is array of key bindings.
        name: 'FormatCode', //name for the key binding
        bindKey: { win: 'Shift-Alt-f', mac: 'Shift-Alt-f' }, //key combination used for the command.
        exec: formater
    }]
  
    return (
        <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
            {label && <InputLabel style={{
                fontSize: 12,
                paddingBottom: 3,
            }}>{label}</InputLabel>}
            {/* <Button onClick={() => setShowDialogCode(true)}>ShowDialog</Button> */}
            <div style={{ flex: 1, position: 'relative', marginBottom: 15, maxHeight: 600 }}>
                <AceEditor
                    // onLoad={onLoad}
                    ref={refEditor}
                    theme={theme}
                    mode={mode}
                    fontSize={fontSize}
                    height={450}
                    width={"100%"}

                    showGutter={true}
                    editorProps={{ $blockScrolling: true }}
                    setOptions={{
                        enableBasicAutocompletion: true,
                        enableLiveAutocompletion: true,
                        enableSnippets: true,
                        showLineNumbers: true,
                        useWorker: useWorker || true,
                        tabSize: 2,
                    }}
                    commands={commands}
                    {...input}
                    value={valueUsar}

                    {...custom}
                    onBlur={() => { if (input.onBlur) input.onBlur(props.input.value) }}
                />

            </div>
            {showDialogCode &&
                <NewWindow onUnload={() => setShowDialogCode(false)} closeOnUnmount={true}>
                    {/* <div style={{ flex: 1, position: 'relative', backgroundColor: "#272822" }}> */}
                    <AceEditor
                        // onLoad={onLoad}
                        // ref={refEditor}
                        theme={theme}
                        mode={mode}
                        fontSize={fontSize}
                        width={"100%"}


                        showGutter={true}
                       // editorProps={{ $blockScrolling: true }}
                        setOptions={{
                            enableBasicAutocompletion: true,
                            enableLiveAutocompletion: true,
                            enableSnippets: true,
                            showLineNumbers: true,
                            useWorker: useWorker || true,
                            tabSize: 2,
                        }}
                        commands={commands}
                        {...input}
                        value={valueUsar}

                        {...custom}
                        onBlur={() => { if (input.onBlur) input.onBlur(props.input.value) }}
                    />


                    {/* </div> */}
                </NewWindow >
            }
        </div >
    )
}





export default function CompView(props) {
    const { input, label } = props


    return (<div style={{ display: 'flex', flex: 1, position: "relative", }}>
        <CodeEditor input={input} label={label} />
    </div>)
}
