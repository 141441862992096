import React from 'react'

import { useConsumerStripe } from 'OkApp/StripeUtils';

import { Typography } from '@material-ui/core';

import { useOkApp } from 'OkApp/UseUtils';

import SemCustomerComp from './SemCustomerComp';
import SemPlanosAssinatura from './SemPlanosAssinatura';
import ProductView from './ProductView';

function PossivelAssinatura({ consumerStripe, connectAccountId, confAssinatura, stripeOkappConfig }) {
    const { product } = confAssinatura;

    return <ProductView stripeAccount={connectAccountId}
        consumerStripe={consumerStripe} productRef={product}
        stripeOkappConfig={stripeOkappConfig}
    />
}


export default function CompView({ label }) {

    const okapp = useOkApp()
    const stripeOkappConfig = okapp?.stripe
    const connectAccountId = stripeOkappConfig.connectAccountId
    const consumerStripe = useConsumerStripe();

    if (!stripeOkappConfig || !stripeOkappConfig) {
        return (<SemPlanosAssinatura />)
    }
    if (!consumerStripe) {
        return (<SemCustomerComp />)
    }
    return (
        <div>
            <Typography style={{ flex: 1 }} variant="h6">{label}</Typography>
            <div>
                <div>
                    {(stripeOkappConfig.assinaturas || []).map((a, i) => <PossivelAssinatura key={i}
                        confAssinatura={a}
                        consumerStripe={consumerStripe}
                        connectAccountId={connectAccountId}
                        stripeOkappConfig={stripeOkappConfig} />)}
                </div>
            </div>
        </div>
    )
}
