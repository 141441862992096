import { useTranslation } from 'react-i18next';
import React from 'react'

import { Button, Avatar, Box } from '@material-ui/core'

import useAuth from 'useAuth';
import firebaseUtil from 'firebaseUtil'
import useNotification from 'notification';

import LogoGoogle from './google.png'
import LogoGithub from './git.png'

export default function LoginIntegrado({ pagePosLogin }) {
    const { t } = useTranslation();
    const { showWarning } = useNotification()
    const { redirectPosAuth, registerLogin } = useAuth();

    async function loginG() {
        console.log('loginG')
        try {
            if (window.cordova) {
                console.log('loginGoogleRedirect')
                firebaseUtil.loginGoogleCordova(() => {
                    console.log('loginGoogle')
                    registerLogin()
                    redirectPosAuth(pagePosLogin)
                })
            } else {

                await firebaseUtil.loginGoogle()
                console.log('loginGoogle')
                registerLogin()
                redirectPosAuth(pagePosLogin)
            }
        } catch (error) {
            console.log("error.message", error.message);
            showWarning(t(error.code))
        }
    }

    // eslint-disable-next-line no-unused-vars
    async function loginF() {
        console.log('loginF')
        try {
            if (window.cordova) {
                firebaseUtil.loginFacebookCordova(() => {
                    console.log('loginFacebook')
                    registerLogin()
                    redirectPosAuth(pagePosLogin)
                })
            } else {
                await firebaseUtil.loginFacebook()
                console.log('loginFacebook')
                registerLogin()
                redirectPosAuth(pagePosLogin)
            }
        } catch (error) {
            console.log("error.message", error.message);
            showWarning(t(error.code))
        }
    }

    async function loginGit() {
        console.log('loginGit')
        try {
            if (window.cordova) {

            } else {
                await firebaseUtil.loginGithub()
                console.log('loginGithub')
                registerLogin()
                redirectPosAuth(pagePosLogin)
            }
        } catch (error) {
            console.log("error.message", error.message);
            showWarning(t(error.code))
        }
    }

    return (
        <Box style={{}}>
            {/* <Typography >Acesso rápido</Typography> */}
            <Box style={{ display: 'flex', flexDirection: "", alignItems: 'center', minWidth: 200, gap: 5 }}>
                <Button style={{
                    marginBottom: 5,
                    justifyContent: 'flex-start',
                    paddingLeft: 25,
                    flex: 1,
                    background: "white",
                    color: 'gray'
                }} variant="contained" color="primary" onClick={loginG}
                    startIcon={
                        <Avatar src={LogoGoogle} style={{ width: "25px", height: "25px" }} />
                    }
                >
                    <Box style={{ width: '100%' }}>
                        Google
                    </Box>
                </Button>
                {!window.cordova && <Button style={{
                    marginBottom: 5,
                    justifyContent: 'flex-start',
                    paddingLeft: 25,
                    flex: 1,
                    background: "white",
                    color: 'gray'
                }} variant="contained" color="primary" onClick={loginGit}
                    startIcon={
                        <Avatar src={LogoGithub} style={{ width: "25px", height: "25px" }} />
                    }
                ><Box style={{ width: '100%' }}>GitHub</Box></Button>}
            </Box>
        </Box>

    )
}
