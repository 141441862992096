import React, { useState, useContext, useCallback } from 'react'

export const CrudContext = React.createContext({
    titleCrud: "Title2Context"
})
function CrudControl() {

    const [title, setTitle] = useState("OkApp")
    const [logoForm, setLogoForm] = useState(null)
    const [actions, setActions] = useState([])

    const [visibilityTopBar, setVisibilityTopBar] = useState(true)


    const addAction = useCallback((action) => {
        setActions((antActions) => {
            let newArray = antActions.slice();
            let index = newArray.map(a => a.uid).indexOf(action.uid);
            if (index !== -1) {
                newArray[index] = action;
            } else {

                newArray.push(action);
            }

            return newArray
        });
    },
        []
    )

    const addActions = useCallback((actionsAdd) => {
        setActions((antActions) => {
            let newArray = antActions.slice();
            actionsAdd.forEach((action, idx) => {
                if (!action.uid)
                    action.uid = Math.random().toString(36).substring(7)
                let index = newArray.map(a => a.uid).indexOf(action.uid);

                if (index !== -1) {
                    newArray[index] = action;
                } else {
                    newArray.push(action);
                }
            });


            return newArray
        });
    },
        [],
    )
    const removeActions = useCallback((arrayUid) => {

        setActions((antActions) => {
            let newArray = antActions.slice();
            arrayUid.forEach((uidRemove => {
                let index = newArray.map(a => a.uid).indexOf(uidRemove);
                if (index !== -1)
                    newArray.splice(index, 1);
            }))
            return newArray
        });
    },
        [],
    )



    return { title, setTitle, actions, removeActions, addActions, addAction, visibilityTopBar, setVisibilityTopBar, logoForm, setLogoForm }
}

export const CrudProvider = ({ children }) => {

    const v = CrudControl();

    return <CrudContext.Provider value={v}>
        {children}
    </CrudContext.Provider>

}
export const useCrudContext = () => useContext(CrudContext)