import DinamicConfig from './components/DinamicRouterCreate'
import { isAuthenticated } from '../../useAuth';

import firebaseUtil from '../../firebaseUtil'
import { permiteEditar } from 'OkApp/modules/PermissionMeta/functions';
import PageAppNaoExiste from './components/DinamicRouterCreate/components/PageAppNaoExiste';
import PageSemPermissaoApp from './components/DinamicRouterCreate/components/PageSemPermissaoApp';
import MeuPerfil from './components/MeuPerfil';
export const routes = () => {
    return [
        { path: "/app/:appRoute/", component: DinamicConfig },
        { path: "/app/:appRoute/meuperfil", component: MeuPerfil },
        { path: "/AppNotFound", component: PageAppNaoExiste },
        { path: "/NotAuthorized", component: PageSemPermissaoApp },
    ]
}
export const menus = (props) => {
    const { history, okApp } = props

    let r = [];
    if (okApp) {
        if (isAuthenticated()) {

            if (okApp.formInicio) {
                r.push({ text: "Início", iconKey: 'Home', action: () => { history.push("/app/" + okApp.route + "/") } })
            }
            if (okApp.formPerfil) {
                r.push({ index: 888888, text: "Meu Perfil", iconKey: 'Person', action: () => { history.push("/app/" + okApp.route + "/meuperfil") } })
            }

            if (permiteEditar(okApp)) {
                r.push({
                    index: 888888,
                    type: 'okapp',
                    text: "Desenvolvimento", iconKey: 'DeveloperBoard', menus: [
                        {
                            text: "Elaborar Formulario", iconKey: 'Ballot', action: () => {
                                console.log('props menus', props); history.push("/app/" + okApp.route + "/CustomForm");
                            }
                        },
                        { text: "Elaborar Relátorio", iconKey: 'PictureAsPdf', action: () => { history.push("/app/" + okApp.route + "/OkReport") } },
                        { text: "Copiar Projeto", iconKey: 'FileCopy', action: () => { history.push("/app/" + okApp.route + "/Copy") } },
                        { text: "Editar Projeto", iconKey: 'Edit', action: () => { history.push("/Projeto?uid=" + okApp.uid) } },

                    ]
                })
            }
            r.push({ index: 999999, text: "Sair", iconKey: 'ExitToApp', confirmText: "Deseja realmente sair?", action: () => { firebaseUtil.logout().then(() => { history.push("/app/" + okApp.route + "/Login") }); } })
        } else {
            r.push({ text: "Início", iconKey: 'Home', action: () => { history.push("/app/" + okApp.route + "/") } })

            if (!okApp.ocultarLoginMenu)
                r.push({ text: "Login", iconKey: 'VpnKey', action: () => { history.push("/app/" + okApp.route + "/Login") } })
        }

    }
    return r
}

