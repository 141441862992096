import React from 'react'
import OkCompAdapter from 'OkApp/components/OkCompAdapter'
import CompView from './components/CompView'
import EditorComp from './components/EditorComp'

import _ from 'lodash'
const typeComponente = 'AnexosComp' 

const AdapterOkComp = (props) => {
    let propsComp = {}
    return <OkCompAdapter {...props} propsComp={propsComp} component={CompView} />
}

const modelo = {
    typeComponente,
    field: 'anexos',
    label: 'Anexos',
    typeQt:'multiplo',
    viewType:"listagem",
    limit: 5,
    type: "image/*",
    multiple: false,
}


const AnexosComp = {
    typeComponente,
   // status: 'Desenvolvimento',
    configView: {
        grupo: 'Avançado',
        nome: 'Anexos',
        descricao: 'AnexosComp',
        helpUrl: 'https://okapp.com.br/app/okappdocs/anexoscomp',
        modelo
    },
    Component: AdapterOkComp,
    CompEditor: EditorComp,
    beforeSave: (obj, okComp) => {



        let arrayAnexo = _.get(obj, okComp.field);
        if (arrayAnexo) {
            for (let index = 0; index < arrayAnexo.length; index++) {
                const anexo = arrayAnexo[index];

                console.log('anexo salvao', anexo)


            }

        }


        return obj;
    }
}


export default AnexosComp