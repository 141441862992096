import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { usePerfil } from 'useAuth';
import { salvarObj } from 'dbApi';

import { ContentLoading } from 'components';
import DefaultLogo from 'resources/image/okapp-avatar.png'
import useNotification from 'notification';
import { useUserSnap } from 'OkApp/UseUtils';
import { permiteEditar } from 'OkApp/modules/PermissionMeta/functions';

import { Button, Avatar, Dialog, DialogTitle, DialogContent, Typography, IconButton, DialogActions, Tabs, Tab, Box, Card, CardContent, CardActions, makeStyles, Chip, Tooltip, useTheme, useMediaQuery } from '@material-ui/core';
import { Favorite, Close as CloseIcon, Info, CheckCircleOutlined, InfoOutlined, BuildOutlined, BlockOutlined } from '@material-ui/icons';
import EditIcon from '@material-ui/icons/Edit';
import NavigationIcon from '@material-ui/icons/Navigation';
import FileCopyIcon from '@material-ui/icons/FileCopy';

import './style.css'

export default function CardProjeto({ projeto, actionCopiar, actionClick, updateItem, admin }) {
    const [openDialog, setOpenDialog] = useState(false);

    const { t } = useTranslation();
    const { showError, showSuccess } = useNotification();
    const [tab, setTab] = useState(0);
    const history = useHistory();
    const user = useUserSnap(projeto.metaOkApp.permission.owner);
    const perfil = usePerfil()

    const theme = useTheme()
    const xs = useMediaQuery(theme.breakpoints.down('xs'));
    const sm = useMediaQuery(theme.breakpoints.down('sm'));
    const md = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        if (user) {
            if (updateItem)
                updateItem({ ...projeto, autor: user })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])

    const editProject = (project) => {
        history.push("/Projeto?uid=" + project.uid)
    }
    const inicioProject = (project) => {
        history.push("/app/" + project.route)
    }

    const handleCloseDialog = () => {
        setOpenDialog(false);
    }

    const handleOpenDialog = () => {
        if (!openDialog) {
            setOpenDialog(true);
        }
    }

    const abrirProjeto = useCallback(() => {
        history.push("app/" + projeto.route)

    }, [history, projeto.route])

    let scr = DefaultLogo
    if (projeto.logo) {
        scr = `data:image/png;base64,${projeto.logo}`;
    }

    const revertFavorito = (e) => {
        e.stopPropagation()

        let updatePerfil = {
            uid: perfil.uid,
            projetosFavoritos: perfil.projetosFavoritos || {}
        }

        updatePerfil.projetosFavoritos[projeto.uid] = !updatePerfil.projetosFavoritos[projeto.uid]

        salvarObj(updatePerfil, 'Users',
            (r) => {
                console.log(updatePerfil.projetosFavoritos[projeto.uid] ? "Marcado como favorito!" : "Removido dos favoritos!");
                showSuccess(updatePerfil.projetosFavoritos[projeto.uid] ? "Marcado como favorito!" : "Removido dos favoritos!")
            },
            (err) => {
                console.log("Erro ao adicionar/remover favorito", err);
                showError(err.message)
            }
        )
    }

    const MAX_CHARACTERS = 45;
    const nomeProjeto = projeto.name.length > MAX_CHARACTERS
        ? `${projeto.name.slice(0, MAX_CHARACTERS)}...`
        : projeto.name;

    const useStyles = makeStyles({
        root: {
            maxWidth: sm ? '100%' : md ? '' : 650,
            margin: 15,
            minHeight: 172
        },
        media: {
            height: 140,
        },
    });

    const classes = useStyles()

    const getColor = (a) => {
        if (a.status === "ready") return "#96ff96"
        if (a.status === "under-developmen") return "#FFD700"
        if (a.status === "under-maintenance") return "#FFA07A"
        if (a.status === "discontinued") return "#D3D3D3"
        return "unset"
    }

    const getIcon = (a) => {
        if (a.status === "ready") return <CheckCircleOutlined />
        if (a.status === "under-developmen") return <InfoOutlined />
        if (a.status === "under-maintenance") return <BuildOutlined />
        if (a.status === "discontinued") return <BlockOutlined />
        return "unset"
    }

    return (
        <>
            <Card className={classes.root} key={"Project" + projeto.uid}>
                <Box style={{ display: 'flex', flexDirection: xs ? 'column' : 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                    <Box width={xs ? "100%" : 'fit-content'}>
                        <Box display="flex" justifyContent='space-between'>
                            <Chip icon={getIcon(projeto)} style={{ borderRadius: '0 0 8px 0', background: getColor(projeto) }} size="small" label={t("project.status." + projeto.status)} />
                            {xs &&
                                <IconButton onClick={revertFavorito} style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', padding: 5 }}>
                                    {(perfil && perfil.projetosFavoritos && perfil.projetosFavoritos[projeto.uid]) ?
                                        <Favorite style={{ color: '#e80c0c' }} />
                                        :
                                        <Favorite />
                                    }
                                </IconButton>
                            }
                        </Box>
                        <Box display="flex" alignItems="center" justifyContent="center">
                            <Avatar variant={'rounded'} alt={projeto.name} style={{ margin: 10, height: 100, width: 100 }} src={scr} />
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection="column" justifyContent="space-between" flex={1}>
                        <CardContent style={{ paddingBottom: 0 }}>
                            <Box display="flex" justifyContent="space-between" width="100%" >
                                <Tooltip title={projeto.name}>
                                    <Typography gutterBottom style={{ fontSize: 16, fontWeight: 'bold', }}>{nomeProjeto}</Typography>
                                </Tooltip>
                                {!xs &&
                                    <IconButton onClick={revertFavorito} style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', padding: 0 }}>
                                        {(perfil && perfil.projetosFavoritos && perfil.projetosFavoritos[projeto.uid]) ?
                                            <Favorite style={{ color: '#e80c0c' }} />
                                            :
                                            <Favorite />
                                        }
                                    </IconButton>
                                }
                            </Box>
                            <Box>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    {projeto.description}
                                </Typography>
                            </Box>
                            <Box display="flex" flexDirection='column' style={{ gap: 5, marginTop: 10 }}>
                                {user ? <Typography className="projectTextColor" variant="caption">Autor: {user.name}</Typography> : <ContentLoading />}
                                <Typography className="projectTextColor" variant="caption">{projeto.modelo ? "Projeto Modelo " : ""}Tipo: {t("project.type." + projeto.type)}</Typography>
                            </Box>
                        </CardContent>
                    </Box>
                </Box>
                <Box>
                    <CardActions style={{ display: "flex", alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                        {!actionCopiar && <>
                            <IconButton onClick={handleOpenDialog} style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', padding: 5 }}>
                                <Info style={{ color: "gray" }} />
                            </IconButton>
                            {(admin || permiteEditar(projeto)) &&
                                <IconButton onClick={() => editProject(projeto)} style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', padding: 5 }}>
                                    <EditIcon style={{ color: "gray" }} />
                                </IconButton>
                            }
                            <IconButton onClick={() => inicioProject(projeto)} style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', padding: 5 }}>
                                <NavigationIcon style={{ color: "gray" }} />
                            </IconButton>
                        </>
                        }
                        {actionCopiar &&
                            <Button onClick={() => {

                                actionCopiar(projeto)
                            }} >
                                <FileCopyIcon />
                            </Button>
                        }
                    </CardActions>
                </Box>
            </Card>
            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="max-width-dialog-title"
                maxWidth={'sm'}
                fullWidth={true}
                className={"dialogContainer"}
            >
                <DialogTitle disableTypography className={'dialogTitle'}>
                    <Box style={{ display: 'flex' }}>
                        <Avatar variant={'rounded'} alt={projeto.name} style={{ margin: 10, height: 50, width: 50 }} src={scr} />
                        <Typography variant="h6">{projeto.name}</Typography>
                    </Box>

                    <IconButton aria-label="close" className={'closeButton'} onClick={handleCloseDialog} color="primary">
                        <CloseIcon />
                    </IconButton>

                </DialogTitle>
                <Tabs
                    className={"tabsContainer"}
                    value={tab}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={(evt, newTab) => {
                        setTab(newTab);
                    }}>
                    <Tab label="Descrição" />
                    <Tab label="Termos de Uso" />
                </Tabs>
                <DialogContent className={'dialogContent'}>
                    {tab === 0 && <div id="divContent" dangerouslySetInnerHTML={{ __html: projeto.descricaoDetalhada }} />}
                    {tab === 1 && <div id="divContent" dangerouslySetInnerHTML={{ __html: projeto.termosDeUso }} />}
                </DialogContent>
                <DialogActions>
                    {actionCopiar ?
                        <Button variant='contained' color="primary" onClick={() => {
                            actionCopiar(projeto)
                        }} >
                            <FileCopyIcon /> Copiar
                        </Button>
                        :
                        <Button onClick={abrirProjeto} variant='contained' color="primary">
                            ACESSAR
                        </Button>
                    }
                </DialogActions>
            </Dialog>
        </>
    )
}
