import React from 'react'


import AnexoItem from './AnexoItem';
import { useOkApp } from 'OkApp/UseUtils';
import { Typography, Card } from '@material-ui/core';
import UploadButtonControl from './UploadButtonControl';
import "./style.css"
import firebaseUtil from 'firebaseUtil';
import useNotification from 'notification';
import { deleteObject, ref } from "firebase/storage";

export default function CompView(props) {
    const { input: { value, onChange }, meta: { touched, error }, label, msgSemRegistro, type, typeQt, viewType, multiple, limit, disabled } = props;
    const { showError } = useNotification()
    const okApp = useOkApp();
    const { okform } = props.okfunctions
    let tmpFolder = (okApp ? okApp.uid + "/" : "") + (okform ? okform.uid + "/" : "") + "anexos/";
    let typeAccept = type;
    let tipoUnico = false;
    if (!typeQt || typeQt === 'multiplo') {
        tipoUnico = false;
    } else {
        tipoUnico = true;
    }

    const addAnexo = (upFile) => {
        if (tipoUnico) {
            onChange(upFile)
        } else {
            onChange(arrayAtual => {
                let newArray = arrayAtual ? arrayAtual.slice() : []
                newArray = newArray.concat([upFile])
                newArray.sort((a, b) => new Date(b.dateCreate) - new Date(a.dateCreate))
                return newArray
            })
        }
    }

    const deleteAnexo = (anexo, index) => {
        if (window.confirm("Deseja realmente deletar este anexo?")) {
            console.log('deleteAnexo', anexo)

            if (anexo && anexo.storagePath) {

                let arquivo = firebaseUtil.getStorageRef(anexo.storagePath)

                deleteObject(arquivo).then(function () {
                    // File deleted successfully
                    console.log('deletado')
                    if (tipoUnico) {
                        onChange(null)
                    } else {
                        onChange(arrayAtual => {
                            let newArray = arrayAtual ? arrayAtual.slice() : []
                            newArray.splice(index, 1);
                            return newArray
                        })
                    }
                }).catch(function (error) {
                    // Uh-oh, an error occurred!
                    if (error.code && error.code === "storage/object-not-found") {

                        showError("Arquivo já removido")
                        if (tipoUnico) {
                            onChange(null)
                        } else {
                            onChange(arrayAtual => {
                                let newArray = arrayAtual ? arrayAtual.slice() : []
                                newArray.splice(index, 1);
                                return newArray
                            })
                        }

                    } else {
                        console.log('error', JSON.stringify(error))

                        showError(error._baseMessage)
                    }

                });

            }
        }
    }
    let listSort = value || []

    let disableUpload = tipoUnico ? value ? true : false : listSort.length >= limit
    if (!viewType || viewType === "listagem") {
        return (
            <Card style={{ padding: 5, marginBottom: 15 }}>
                <div style={{ display: 'flex', justifyContent: "space-between", paddingBottom: 10 }}>
                    <div style={{ display: 'flex' }}>
                        <Typography variant="h6">{label}</Typography>
                        {touched && error && <span style={{ fontSize: 12, alignSelf: 'center', color: '#ff5964', height: 35 }}>{error}</span>}
                    </div>
                    {/* <UploadFile style={{ margin: 10 }} uploadSuccess={addAnexo} tmpFolder={tmpFolder} typeAccept={typeAccept} multiple={multiple} disableUpload={listSort.length === limit} disabled={disabled} /> */}
                    <UploadButtonControl
                        tmpFolder={tmpFolder}
                        uploadSuccess={addAnexo}
                        typeAccept={typeAccept}
                        multiple={typeQt !== 'unico' && multiple}
                        limitFiles={limit}
                        disabled={disabled || disableUpload} />
                </div>

                {tipoUnico ?
                    <>
                        {value && <AnexoItem anexo={value} deleteAnexo={deleteAnexo} type={type} disabled={disabled} />}
                    </>
                    : <>
                        {listSort.map((a, i) => <AnexoItem key={i} anexo={a} index={i} deleteAnexo={deleteAnexo} type={type} disabled={disabled} />)}
                        {!listSort.length && <Typography style={{ textAlign: 'center' }}>{msgSemRegistro || "Sem anexos"}</Typography>}
                    </>
                }
            </Card>
        )
    } else {
        return (<div className='AnexoContButtonOnly'>
            <UploadButtonControl
                tmpFolder={tmpFolder}
                uploadSuccess={addAnexo}
                typeAccept={typeAccept}
                multiple={typeQt !== 'unico' && multiple}
                limitFiles={limit}
                disabled={disabled || disableUpload} />
        </div>
        )
    }
}

