
import firebaseUtil from "firebaseUtil";
import { useEffect } from "react";
import { useState } from "react";
import { usePerfil } from "useAuth";
import { useOkAppContext } from "./context";
import { updatePerfilOkAppProject } from "./OkFunctions";
import { useOkApp } from "./UseUtils";
import QueryFactory from "./QueryFactory";


const servCreateCustomerStripe = firebaseUtil.funcServCreateCustomerStripe();
export function useConsumerStripe() {
    const okapp = useOkApp()
    const perfil = usePerfil()
    let stripeAccount = null;
    if (okapp && okapp.stripe) {
        stripeAccount = okapp.stripe.connectAccountId;
    }

    const [consumerStripe, setConsumerStripe] = useState()
    useEffect(() => {
        let observer = null
        if (perfil) {
            const refDocAccont = new QueryFactory(null, "StripeConnectAccount").doc(stripeAccount || 'a-okappCol');
            observer = refDocAccont.collection('customers').doc(perfil.uid).onSnapshot(doc => {
                if (!doc.data()) {
                    const { uid, name, email, phone } = perfil
                    console.log('cria CustomerStripe', perfil)
                    servCreateCustomerStripe({ id: uid,uid: uid, name, email, phone, stripeAccount })
                } else {
                    setConsumerStripe(doc.data())
                }
            })
        }

        return () => {
            if (observer) observer()
        }
    }, [perfil, stripeAccount])


    return consumerStripe
}

// export const getConsumerStripeSnap = (stripeAccount, perfilUser, resolve, reject) => {

//     const refDocAccont = new QueryFactory(null, "StripeConnectAccount").doc(stripeAccount);

//     doc.onSnapshot(doc2 => {
//         resolve(doc2.data())
//     })
//     const refDocCustomer = refDocAccont.collection("customers").where("firebaseUID", "==", perfilUser.uid).get().then(querySnapshot => {

//         doc.onSnapshot(doc2 => {
//             resolve(doc2.data())
//         })
//         console.log('querySnapshot', querySnapshot)
//         if (querySnapshot.empty) {
//             resolve(null)
//             const { uid, name, email, phone } = perfilUser
//             servCreateCustomerStripe({ id: uid, name, email, phone, stripeAccount })
//         } else {

//             querySnapshot.forEach(doc => {
//                 resolve(doc.data())
//                 console.log('doc', doc)

//                 doc.onSnapshot(doc2 => {
//                     resolve(doc2.data())
//                 })
//             });


//         }


//     })

//     // let colCustomForm = 'stripe-customers';

//     // return crateSnapDocument(uidUser, colCustomForm,
//     //     (r) => {
//     //         resolve(r)
//     //     },
//     //     (err) => {
//     //         // console.log('getUserSnap erro', err)
//     //         reject(err)
//     //     }
//     // )
// }
export function useAccountStripe(stripeAccount) {

    const [accountStripe, setAccountStripe] = useState(null)
    useEffect(() => {
        let observer = null
        if (stripeAccount) {
            const refDocAccont = new QueryFactory(null, "StripeConnectAccount").doc(stripeAccount || 'a-okappCol');
            observer = refDocAccont.onSnapshot(doc => {
                setAccountStripe(doc.data())
            })
        }

        return () => {
            if (observer) observer()
        }
    }, [stripeAccount])


    return accountStripe
}
export function usePriceProductsStripe(stripeAccount, productId) {

    const [prices, setPrices] = useState(null)
    useEffect(() => {
        let observer = null
        if (stripeAccount) {
            const refDocAccont = new QueryFactory(null, "StripeConnectAccount").doc(stripeAccount || 'a-okappCol');
            refDocAccont.collection('product').doc(productId).collection("price")
                .where("active", "==", true).onSnapshot(querySnapshot => {
                    if (querySnapshot.empty) {
                        setPrices([])

                    } else {
                        let r = []
                        querySnapshot.forEach(doc => {
                            r.push(doc.data());
                        });
                        r.sort((a, b) => a.unit_amount - b.unit_amount)
                        setPrices(r)
                    }
                })
        }

        return () => {
            if (observer) observer()
        }
    }, [stripeAccount, productId])


    return prices
}
export function useProductsStripe(stripeAccount) {

    const [produtos, setProdutos] = useState(null)
    useEffect(() => {
        let observer = null
        if (stripeAccount) {
            const refDocAccont = new QueryFactory(null, "StripeConnectAccount").doc(stripeAccount || 'a-okappCol');
            observer = refDocAccont.collection('product').where("active", "==", true).onSnapshot(querySnapshot => {
                if (querySnapshot.empty) {
                    setProdutos([])

                } else {
                    let r = []
                    querySnapshot.forEach(doc => {
                        r.push(doc.data());
                    });
                    r.sort((a, b) => b.name - a.name)
                    setProdutos(r)
                }
            })
        }

        return () => {
            if (observer) observer()
        }
    }, [stripeAccount])


    return produtos
}

export function useProductForRefStripe(stripeAccount, productRef) {

    const [produto, setProduto] = useState(productRef)
    useEffect(() => {
        const refDocAccont = new QueryFactory(null, "StripeConnectAccount").doc(stripeAccount || 'a-okappCol');
        let observer = null
        if (stripeAccount) {
            observer = refDocAccont.collection('product').doc(productRef.id).onSnapshot(doc => {
                setProduto(doc.data())
            })
        }

        return () => {
            if (observer) observer()
        }
    }, [stripeAccount, productRef])


    return produto
}
export function useAssinaturasStripeProdutos(stripeAccount, consumerId, productId) {

    const [assinaturas, setAssinaturas] = useState(null)
    useEffect(() => {
        let observer = null
        if (consumerId && productId) {
            const refDocAccont = new QueryFactory(null, "StripeConnectAccount").doc(stripeAccount || 'a-okappCol');
            const refDocCustomer = refDocAccont.collection("customers").doc(consumerId);
            let colection = refDocCustomer.collection("subscription")
            colection = colection.where("plan.product", "==", productId)
            observer = colection.onSnapshot(querySnapshot => {
                if (querySnapshot.empty) {
                    setAssinaturas([])

                } else {
                    let r = []
                    querySnapshot.forEach(doc => {
                        r.push(doc.data());

                    });
                    r.sort((a, b) => b.created - a.created)
                    setAssinaturas(r)
                }
            })
        }

        return () => {
            if (observer) observer()
        }
    }, [consumerId, productId, stripeAccount])


    return assinaturas
}

export function createAssinatura(stripeAccount, consumerId, assinaturaObj, callbackUpdate) {
    const refDocAccont = new QueryFactory(null, "StripeConnectAccount").doc(stripeAccount || 'a-okappCol');
    const refDocCustomer = refDocAccont.collection("customers").doc(consumerId);
    let colection = refDocCustomer.collection("subscription")
    const docSalvar = colection.doc();
    let realObj = {
        metadata: { uidOkapp: docSalvar.id(), okAppCustomerUid: consumerId },
        ...assinaturaObj
    }
    docSalvar.set(realObj, { merge: true }).then((docRef) => {
        docSalvar.onSnapshot((snap) => {
            callbackUpdate(snap.data())
        });
    })
}

export function getPaymentIntentPendent(stripeAccount, consumerId, invoiceId, callbackUpdate) {
    const refDocAccont = new QueryFactory(null, "StripeConnectAccount").doc(stripeAccount || 'a-okappCol');
    const refDocCustomer = refDocAccont.collection("customers").doc(consumerId);
    let colection = refDocCustomer.collection("paymentintent")
    colection = colection.where("invoice", "==", invoiceId)
    console.log('stripeAccount', stripeAccount)
    console.log('consumerId', consumerId)
    console.log('invoiceId', invoiceId)
    colection.onSnapshot((querySnapshot) => {
        if (querySnapshot.empty) {
            callbackUpdate([])

        } else {
            let r = []
            querySnapshot.forEach(doc => {
                r.push(doc.data());
            });
            callbackUpdate(r)
        }
    });
}

//#region SyncAssinatura com Permissão

export function snapVerificaAssinaturasCallback(connectAccountId, products, userUid, callback) {
    (products || []).forEach(prod => {
        onAssinaturaAtiva(connectAccountId, userUid, prod, (ativa) => {
            callback(ativa)
        })
    })
}

export function snapVerificaAssinaturas(okApp, okappStripeConfig, perfil) {

    // if (perfil.ignoreAssinaturas) {
    //     return;
    // }
    if (okappStripeConfig.assinaturas) {

        okappStripeConfig.assinaturas.forEach(ass => {
            // console.log('ass', ass)
            if (ass.okGrupoPermissao)
                onAssinaturaAtiva(okappStripeConfig.connectAccountId, perfil.uid, ass.product, (ativa) => {
                    // console.log('onAssinaturaAtiva', ass.nome, ativa)
                    if (ativa) {
                        if (perfil.okGrupoPermissao !== ass.okGrupoPermissao) {
                            //set perfil com grupo da assinatura

                            // updatePerfilUid(perfil.uid, { okGrupoPermissao: ass.okGrupoPermissao })
                            updatePerfilOkAppProject(okApp, perfil.uid, { okGrupoPermissao: ass.okGrupoPermissao })
                        }
                    } else {
                        if (perfil.okGrupoPermissao === ass.okGrupoPermissao) {
                            //set perfil sem permissão
                            updatePerfilOkAppProject(okApp, perfil.uid, { okGrupoPermissao: null })

                        }
                    }
                })
        })
    }

}

export function onAssinaturaAtiva(stripeAccount, consumerId, productId, callback) {
    // const refDocAccont = new QueryFactory(null, "StripeConnectAccount").doc(stripeAccount);

    const refDocAccont = new QueryFactory(null, "StripeConnectAccount").doc(stripeAccount || 'a-okappCol');
    const refDocCustomer = refDocAccont.collection("customers").doc(consumerId);
    let colection = refDocCustomer.collection("subscription")
    // let colection = new QueryFactory(null, ["StripeConnectAccount", stripeAccount, "customers", consumerId, "subscription"])
    colection = colection.where("plan.product", "==", productId)
    colection = colection.where("status", "==", "active")
    colection.onSnapshot(querySnapshot => {
        if (querySnapshot.empty) {
            //NADA
            callback(null)
        } else {
            //TEM ATIVA   
            // console.log('querySnapshot', querySnapshot.docs[0].data())
            callback(querySnapshot.docs[0].data())
        }
    })
}
//#endregion

//////////////////////////////////////////////////////////////////////////////////////////
export function useConsumerStripeold() {
    const { consumerStripe } = useOkAppContext();
    return consumerStripe
}


export function novaFaturaStripeold(props) {
    // cria a fatura no sistema e envia o email

    let CurrentUser = firebaseUtil.getCurrentUser();
    let userUid = CurrentUser.uid;
    console.log('createConsumerStripe CurrentUser', CurrentUser)
    console.log('userUid', userUid)

    let fatura = {
        ...props,
        uid: userUid,
        application_fee_amount: 250,
        transfer_data: {
            destination: 'acct_1KLwlJKIxzCLbS85',
        },
    }
    console.log('fatura', fatura)

    let colection = new QueryFactory(null, 'stripe-invoices');
    colection.add(fatura)
        .then((docRef) => {
            console.log("Document written with ID: ", docRef.id);
        })
        .catch((error) => {
            console.error("Error adding document: ", error);
        });

}

export function getAtiveAssinaold(consumer, props) {
    const { userUid } = consumer
    let colection = new QueryFactory(null, 'stripe-customers')
        .doc(userUid)
        .collection("subscriptions")
        .onSnapshot(async (snapshot) => {
            if (snapshot.empty) {
                // Show products
                //   document.querySelector("#subscribe").style.display = "block";
                return;
            }
            console.log('subscriptions', snapshot.docs)
            // document.querySelector("#subscribe").style.display = "none";
            // document.querySelector("#my-subscription").style.display = "block";
            // // In this implementation we only expect one Subscription to exist
            // const subscription = snapshot.docs[0].data();
            // const priceData = (await subscription.price.get()).data();
            // document.querySelector(
            //   "#my-subscription p"
            // ).textContent = `You are paying ${new Intl.NumberFormat("en-US", {
            //   style: "currency",
            //   currency: priceData.currency
            // }).format((priceData.unit_amount / 100).toFixed(2))} per ${
            //   priceData.interval
            // }`;
        });
}

export function createAssinaturaOLDold(consumer, assinaturaObj, callback) {

    const { userUid } = consumer
    console.log('userUid', userUid)
    let colection = new QueryFactory(null, 'stripe-customers')
        .doc(userUid)
        .collection("checkout_sessions")

    colection.add(assinaturaObj).then((docRef) => {
        console.log('docRef', docRef)
        docRef.onSnapshot((snap) => {
            const { sessionId } = snap.data();
            if (sessionId) {
                // We have a session, let's redirect to Checkout
                // const stripe = Stripe(STRIPE_PUBLISHABLE_KEY);
                // stripe.redirectToCheckout({ sessionId });
                callback({ sessionId, data: snap.data() })
            } else {

                console.log(' sem sessionId snap.data()', snap.data())
                if (snap.data().error) {
                    console.log("error", snap.data().error.message)
                }

            }

        });


    });


}
//new

export function createPaymentIntentold(consumerId, paymentIntentObj, callbackUpdate) {

    let colection = new QueryFactory(null, "StripeConnectCustomers/" + consumerId + "/paymentintent/")
    const docSalvar = colection.doc();
    let realObj = {
        metadata: { uidOkapp: docSalvar.id },
        ...paymentIntentObj
    }
    docSalvar.set(realObj, { merge: true }).then((docRef) => {
        console.log('criou PaymentIntent', docRef)

        docSalvar.onSnapshot((snap) => {
            callbackUpdate(snap.data())
        });

    })
}


