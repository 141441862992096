import React from 'react'
import ReactDOM from 'react-dom';
import {
    LineChart, Line, XAxis, YAxis, CartesianGrid,  
    BarChart, Bar, 
    PieChart, Pie,
} from 'recharts';
import { getFunctionCodeToFuncMap } from 'OkApp/CodeProcessor';


export function createImports(codeRel, jsonDados, meta) {

    const funcGerarGraficos = getFunctionCodeToFuncMap(codeRel, "getGraficos")
    if (funcGerarGraficos) {
        // let arrayCharts = funcGerarGraficos(jsonDados, meta)

        // Promise.all(arrayCharts.map((c, index) => criaGrafico(c, c.name))).then(function (values) {

        //     let imports = {};
        //     values.forEach(element => {
        //         imports = { ...imports, ...element };
        //     })
        //     retorno(imports)
        // })
        //     .catch(function (err) {
        //         console.log('err', err)
        //     })

    }

}

//graficos
const svgToPng = (svg, width, height) => {

    return new Promise((resolve, reject) => {

        let canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        let ctx = canvas.getContext('2d');

        // Set background to white
        ctx.fillStyle = '#ffffff';
        ctx.fillRect(0, 0, width, height);

        let xml = new XMLSerializer().serializeToString(svg);
        let dataUrl = 'data:image/svg+xml;utf8,' + encodeURIComponent(xml);
        let img = new Image(width, height);

        img.onload = () => {
            ctx.drawImage(img, 0, 0);
            let imageData = canvas.toDataURL('image/png', 1.0);
            resolve(imageData)
        }

        img.onerror = () => reject();

        img.src = dataUrl;
    })
}
const convertChart1 = async (ref, WIDTH, HEIGHT, retorno) => {

    if (ref && ref.container) {
        let svg = ref.container.children[0];
        let pngData = await svgToPng(svg, WIDTH, HEIGHT);
        retorno(pngData)
    }
}
function getLineChart(grafico) {
    let dataNovo = [];
    grafico.columns.forEach((col, index) => {
        let dado = { name: col };
        grafico.series && grafico.series.forEach(serie => {
            if (serie.values[index])
                dado[serie.name] = serie.values[index];
        })
        dataNovo.push(dado)
    })

    return (<LineChart
        name={"lc"}
        width={500}
        height={250}
        data={dataNovo}
        isAnimationActive={false}
    >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        {grafico.series.map((serie, index) => {
            return <Line isAnimationActive={false} dataKey={serie.name} {...serie.props} />
        })}
    </LineChart>)
}
function getBarChart(grafico) {
    let dataNovo = [];
    console.log('Grafico ', grafico);
    grafico.columns.forEach((col, index) => {
        let dado = { name: col };
        grafico.series && grafico.series.forEach(serie => {
            if (serie.values[index])
                dado[serie.name] = serie.values[index];
        })
        dataNovo.push(dado)
    })


    return (<BarChart
        name={"bc"}
        width={500}
        height={300}
        data={dataNovo}
        isAnimationActive={false}
    >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        {grafico.series.map((serie, index) => {
            return <Bar isAnimationActive={false} dataKey={serie.name} {...serie.props} />
        })}
    </BarChart>)
}

function getPieChart(grafico) {
    return (<PieChart width={400} height={400}>
        <Pie dataKey="value" isAnimationActive={false} data={grafico.values} {...grafico.props} />
    </PieChart>)
}
// eslint-disable-next-line no-unused-vars
function criaGrafico(chart, name) {

    let CompChart = null;

    if (chart.type === "BarChart") {
        CompChart = getBarChart(chart)
    }

    if (chart.type === "LineChart") {
        CompChart = getLineChart(chart)
    }

    if (chart.type === "PieChart") {
        CompChart = getPieChart(chart)
    }



    return new Promise((resolve, reject) => {
        if (CompChart) {
            const WIDTH = CompChart.props.width;
            const HEIGHT = CompChart.props.height;

            let compComRef = React.cloneElement(CompChart, {
                ref: ref => convertChart1(ref, WIDTH, HEIGHT, (r) => {
                    let rr = {}
                    rr[name] = r
                    resolve(rr);
                }),
                isAnimationActive: false
            })
            const helperDiv = document.createElement('tmp');
            ReactDOM.render(compComRef, helperDiv);
        } else {
            resolve(null);
        }
    })


}