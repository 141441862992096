import React from 'react'
import OkCompAdapter from 'OkApp/components/OkCompAdapter'

import CompView from './components/CompView'
import Editor from './components/Editor'
const typeComponente = 'ChatGptComp'

const AdapterOkComp = (props) => {
    let propsComp = {}
    return <OkCompAdapter {...props} propsComp={propsComp} component={CompView} />
}


const modelo = {
    typeComponente,
    field: 'chatgpt',
    label: 'ChatGpt',
}


const ChatGptComp = {
    typeComponente,
    configView: {
        grupo: 'Avançado',
        nome: 'ChatGpt(BETA)',
        descricao: 'ChatGptComp',
        helpUrl: 'https://www.google.com.br/',
        modelo
    },
    Component: AdapterOkComp,
    CompEditor: Editor
}

export default ChatGptComp