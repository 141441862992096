import React, { useEffect, useState } from 'react'


import _ from 'lodash'


import { CodeEditor, Input } from 'components';
import useCriaControlerField from '../../functionsUtil';

import { painel, fieldset } from './../../../../OkReportUtil'

import htmlToPdfmake from 'html-to-pdfmake'
// or:

import { createFunctionMap, convertFunctionMapToCode, defaultSnippets, getFunctionCodeToFuncMap, createDefaultFuncMapStr } from 'OkApp/CodeProcessor'
import { Tooltip } from '@material-ui/core';

function FunctionReportComp({ value, onChange, valueRef, removeCelula }) {
    const criaControler = useCriaControlerField(value, onChange)
    const codeInput = criaControler("code");

    const [code, setCode] = useState("")
    const startFunc = "function getCompPdf(json) {return {text: 'testes'}}"
    const inputCode = {
        value: startFunc ? code || startFunc : code, onChange: setCode, onBlur: (code) => {
            setCode(code)
            let newV = createFunctionMap(code);
            if (!_.isEqual(newV, codeInput.input.value))
                codeInput.input.onChange(newV)
        }
    };

    const upCode = () => {
        let newV = createFunctionMap(code);
        if (!_.isEqual(newV, codeInput.input.value)) {
            let newCode = convertFunctionMapToCode(codeInput.input.value)
            setCode(newCode)
        }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(upCode, [codeInput.input.value])


    return (
        <div>

            <Tooltip title={"* - Maior possivel, auto - Menor possivel, 'numero' exato "}>
                <Input style={{ whiteSpace: 'nowrap', overflow: 'hidden' }} label='Tamanho (*,auto ou numero)' {...criaControler("width")} />
            </Tooltip>
            <div style={{ display: 'flex', height: 500, flex: 1, position: "relative", }}>
                <CodeEditor input={inputCode} snippets={defaultSnippets.concat([{ name: 'startFunc', code: "function getCompPdf(json) {return {text: 'testes'}}" }])} label={"Função"} />
            </div>
        </div>

    )
}





async function convertToPdf(compConfig, json, meta) {
    const getBase64ImageFromURL = (url) => {
        return new Promise((resolve, reject) => {
            var img = new Image();
            img.setAttribute("crossOrigin", "anonymous");
            img.onload = () => {
                var canvas = document.createElement("canvas");
                canvas.width = img.width;
                canvas.height = img.height;
                var ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0);
                var dataURL = canvas.toDataURL("image/png");
                resolve(dataURL);
            };
            img.onerror = error => {
                reject(error);
            };
            img.src = url;
        });
    }


    let scope = { painel, fieldset, htmlToPdfmake, getBase64ImageFromURL };
    let funcAux = getFunctionCodeToFuncMap(compConfig.code, "getCompPdf");
    if (funcAux) {
        return funcAux(json, meta, scope)
    }
    return null



}


const FunctionReport = {
    typeComponent: "FunctionReport",
    modelo: {
        typeComponent: "FunctionReport",
        nome: "Função",

        code: createDefaultFuncMapStr('getCompPdf', "function getCompPdf(json) {return {text: 'testes'}}"),

    },
    Component: FunctionReportComp,
    convertToPdfFunc: convertToPdf

}
export default FunctionReport
