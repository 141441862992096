import React from 'react'
import { OkTypedComponent, LabelInputAuto } from 'OkApp'


import ReCAPTCHA from "react-google-recaptcha";
import OkCompAdapter from 'OkApp/components/OkCompAdapter';

const typeComponente = 'RecaptchGoogle'


const AdapterOkComp = (props) => {

    const { okComp } = props;
    const propsComp = { required: true };

    return <OkCompAdapter
        {...props}
        propsComp={propsComp}
        okComp={{ ...okComp, required: true }}
        component={(props) => {
            const { input, meta } = props
            // console.log('input', input)
            // console.log('meta', meta)

            return <div style={{ display:"flex",justifyContent:'center'}}>
                <ReCAPTCHA
                style={{ border: (meta.touched && meta.error) ? '1px solid red' : '' }}
                    sitekey="6Ld_Q5obAAAAAIW_e-Um7IYEv80qAcis0y_6WIxQ"
                    onChange={input.onChange}
                />
            </div>
        }} />
}


const EditorOkComp = (props) => {

    return <>
        <OkTypedComponent typeComponente='InputComp' field='field' label='Nome Salvo' />
        <LabelInputAuto {...props} />
    </>
}

const modelo = {
    typeComponente,
    field: 'recaptcha',
    label: '(ReCAPTCHA) Não sou um robô',


}

const RecaptchGoogle = {
    typeComponente,
    configView: {
        grupo: 'Básico',
        nome: 'Não sou um robô (ReCAPTCHA)',
        descricao: 'recaptcha',
        modelo
    },
    Component: AdapterOkComp,
    CompEditor: EditorOkComp
}


export default RecaptchGoogle