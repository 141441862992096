import React from 'react'

import { OkTypedComponent, LabelInputAuto } from 'OkApp'

export default function Editor(props) {
    //https://cloud.google.com/firestore/docs/query-data/queries#in_and_array-contains-any
    return (
        <div style={{ display: 'flex', flexDirection: "column", height: "100%" }}>
            <div>
                <OkTypedComponent typeComponente='InputComp' field='field' label='Nome Salvo' />
                <LabelInputAuto {...props} />
                <OkTypedComponent typeComponente='SeletorOkForm' field='auxOkForm' label='Formulário' />
                <OkTypedComponent typeComponente='InputComp' field='limitResultados' label='Limite de Resultado' type="number" />
                <OkTypedComponent typeComponente='SwitchComp' field='salvarDados' label='Salvar Dados' />
            </div>
            <OkTypedComponent typeComponente='FunctionsEditor' field='functionsmap' label='GetCompareMap' />
            <OkTypedComponent typeComponente='FunctionsEditor' field='collectionQuery' label='Collection Query'
                startFunc={"function collectionQuerry(collection,props) {\n\treturn collection;\n}"}
            />
        </div>
    )
}
