export { default as InputComp } from './basic/InputComp'
export { default as SwitchComp } from './basic/SwitchComp'
export { default as ComboBoxComp } from './basic/ComboBoxComp'
export { default as RichTextComp } from './basic/RichTextComp'
export { default as ListaItens } from './basic/ListaItens'
export { default as TabelaOkComp } from './basic/TabelaOkComp'
export { default as ButtonComp } from './basic/ButtonComp'
export { default as TextComp } from './basic/TextComp'
export { default as CheckboxComp } from './basic/CheckboxComp'
export { default as SequencialIndexComp } from './basic/SequencialIndexComp'
export { default as ImageSelComp } from './basic/ImageSelComp'
export { default as HtmlEditor } from './basic/HtmlEditor'
export { default as RecaptchGoogle } from './basic/RecaptchGoogle'
export { default as QrCodeComp } from './basic/QrCodeComp'
export { default as PlayerVideoComp } from './basic/PlayerVideoComp'

export { default as CalcComp } from './basic/CalcComp'

export { default as SeletorOkObjUnico } from './consulta/SeletorOkObjUnico'
export { default as SeletorOkForm } from './consulta/SeletorOkForm'
export { default as FilterOkObj } from './consulta/FilterOkObj'
export { default as FiltroPainelComp } from './consulta/FiltroPainelComp'

export { default as PanelFlexComp } from './container/PanelFlexComp'
export { default as PainelAbaComp } from './container/PainelAbaComp'
export { default as KanbanComp } from './container/KanbanComp'
export { default as RegioesDnd } from './container/RegioesDnd'

export { default as AutoCompleteComp } from './avancado/AutoCompleteComp'
export { default as AnexosComp } from './avancado/AnexosComp'
export { default as ComentariosComp } from './avancado/ComentariosComp'
export { default as ReactRenderComp } from './avancado/ReactRenderComp'
export { default as ReactCssRender } from './avancado/ReactCssRender'
export { default as FunctionsEditor } from './avancado/FunctionsEditor'
export { default as ObserverFunction } from './avancado/ObserverFunction'
export { default as RenderOkFormComp } from './avancado/RenderOkFormComp'
export { default as ChecklistComp } from './avancado/ChecklistComp'
// export { default as DiagramaComp } from './avancado/DiagramaComp'
export { default as DiagramaDeEscopoComp } from './avancado/DiagramaDeEscopoComp'
export { default as AtualUserLoadComp } from './avancado/AtualUserLoadComp'
export { default as ControleOutroOkObjComp } from './avancado/ControleOutroOkObjComp'
export { default as AudioRecordButtonComp } from './avancado/AudioRecordButtonComp'
export { default as HtmlCodeEditor } from './avancado/HtmlCodeEditor'
export { default as DialogFormCompEditor } from './avancado/DialogFormCompEditor'
export { default as MapsComp } from './avancado/MapsComp'

export { default as SpeechText } from './avancado/SpeechText'
export { default as ChatGptComp } from './avancado/ChatGptComp'


// export { default as TransacaoStripeComp } from './avancado/TransacaoStripeComp'
export { default as AssinaturaStripeComp } from './financial/AssinaturaStripe'
//  export { default as TransacaoZoopComp } from './avancado/TransacaoZoopComp'



export { default as EmitirRelatorioComp } from './okutil/EmitirRelatorioComp'
export { default as ImportarNfe } from './okutil/ImportarNfe'
export { default as EditorPermissaoComp } from './okutil/EditorPermissaoComp'



// export { default as HistoricoComp } from './okutil/HistoricoComp'


export * from '../OldComponents'
