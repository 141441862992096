
import React from 'react'

import ContainerDinamicReportComp from './componentes/ContainerDinamicReportComp'
import MenuAddNewComp from './componentes/MenuAddNewComp'
import produce from "immer"
import { Typography } from '@material-ui/core'

export default function ElaboradorVisual({ input: { value, onChange }, jsonValue }) {
   
    const addNewComp = (comp) => {
        let containerInicial = value ? value : { nome: 'root', lista: [] }


        let newArray = containerInicial.lista ? containerInicial.lista.slice() : []
        newArray.push(comp)
        onChange({ ...value, lista: newArray })

    }

    let arrayShow = value ? value.lista : []


    const updateItem = (i, newValue) => {
        const nextState = produce(value, draftState => {
            draftState.lista[i] = newValue
        })
        onChange(nextState)
    }

    const removeItem = (index) => {
        const nextState = produce(value, draftState => {
            if (index > -1) {
                draftState.lista.splice(index, 1);
            }
        })
        onChange(nextState)
    }

    function array_move(arr, old_index, new_index) {
        if (new_index >= arr.length) {
            new_index = arr.length - 1;
        }
        if (new_index < 0) {
            new_index = 0;
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        return arr; // for testing
    };
    const moveUpFilho = (i) => {
        const nextState = produce(value, draftState => {
            array_move(draftState.lista, i, i - 1)

        })
        onChange(nextState)
    }
    const moveDownFilho = (i) => {
        const nextState = produce(value, draftState => {
            array_move(draftState.lista, i, i + 1)

        })
        onChange(nextState)
    }
    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="h6">
                    Elaborador de Relatório
                </Typography>
                <MenuAddNewComp addNewComp={addNewComp} label="Adicionar Componente" />
            </div>

            <div style={{ paddingTop: 10 }}>
                {arrayShow.map((c, i) => <ContainerDinamicReportComp key={i} value={c} onChange={(v) => updateItem(i, v)}
                    removeThis={() => { removeItem(i) }}
                    moveUp={() => { moveUpFilho(i) }}
                    moveDown={() => { moveDownFilho(i) }}
                    valueRef={jsonValue} />)}
            </div>


        </div>
    )
}
