import React, { useEffect } from 'react'
import './styleTabela.css'
import { Typography, TablePagination } from '@material-ui/core'

const styleCell = (coluna) => {
    let sizecol = coluna.sizecol
    let r = {};

    // r.flex = 1;

    if (sizecol) {
        if (isNaN(sizecol)) {
            if (sizecol === "auto") {
                r.flex = 1
            } else {
                r.width = sizecol;
            }

        } else {
            r.width = sizecol + "px";
        }

    } else {
        r.flex = 1
    }

    return r
}


function Coluna({ coluna, linha, indexrow, data, RenderCell }) {
    const adapterString = (v) => {
        if (v) {
            return v;
        }
        return '';

    }

    return (<div className='celTable' style={{ ...styleCell(coluna) }} >
        {RenderCell ?
            <RenderCell coluna={coluna} linha={linha} indexrow={indexrow} data={data} />
            :
            <Typography >{"" + adapterString(linha[coluna.field])}</Typography>
        }
    </div>
    )
}

function Linha({ linha, indexrow, columns, data, onRowClick, RenderCell }) {
    let onClick = null;
    if (onRowClick) {
        onClick = () => onRowClick(linha, indexrow);
    }
    // console.log('render linha')
    return (
        <div className='rowTable' onClick={onClick}>
            {columns.map((c, i) => <Coluna key={i} coluna={c} index={i} linha={linha} data={data} indexrow={indexrow} RenderCell={RenderCell} />)}
        </div>
    )
}
function LinhaHeader({ columns, data }) {

    const HeaderCol = ({ c, index }) => <div className='celTable' style={{ ...styleCell(c), cursor: 'default' }}>

        <Typography style={{
            color: "#929292",
            alignItems: 'center'
        }}
            component="div"
            noWrap
        >
            {c.label || 'Col_' + (index + 1)}
        </Typography>
    </div>
    return (
        <div className='rowTable'>
            {columns.map((c, i) => <HeaderCol key={i} c={c} index={i} />)}
        </div>
    )
}

export default function Tabela(props) {
    let LIMITE_TAM_TABELA = 15
    const { data, columns, onRowClick, rowsPer, msgSemRegistro, RenderCell } = props
    let linhas = data || []
    const pusar = rowsPer ? rowsPer : linhas.length > LIMITE_TAM_TABELA ? LIMITE_TAM_TABELA : null;

    const [rowsPerPage, setRowsPerPage] = React.useState(pusar);
    // eslint-disable-next-line no-unused-vars
    const [rowsPerPageOptions, setRowsPerPageOptions] = React.useState([15, 25, 50, 100])
    const [page, setPage] = React.useState(0);

    useEffect(() => {
        setRowsPerPage(pusar)
    }, [pusar])
 
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    let adicionadorIndex = 0
    if (rowsPerPage) {
        linhas = linhas.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        adicionadorIndex = page * rowsPerPage
    } else {
        if (linhas.length > LIMITE_TAM_TABELA) {
            linhas = linhas.slice(0, LIMITE_TAM_TABELA)
        }
    }

    let semItens = !linhas.length && msgSemRegistro

    return (
        <div className='rootTable'>
            <LinhaHeader columns={columns} data={data} />
            {semItens && <Typography style={{ textAlign: 'center' }}>{msgSemRegistro}</Typography>}
            {linhas.map((l, i) => <Linha key={adicionadorIndex + i} indexrow={adicionadorIndex + i} linha={l} columns={columns} data={data} onRowClick={onRowClick} RenderCell={RenderCell} />)}
            {rowsPerPage && <TablePagination
                labelRowsPerPage="Registros por página"
                rowsPerPageOptions={rowsPerPageOptions}
                component="div"
                count={(data || []).length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                
            />}
        </div>
    )
}
