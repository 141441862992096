import { gerarDocumento as gerarPadraoOkApp } from "./template/TemplatePadrao";
import { gerarDocumento as gerarPadraoLimpo } from "./template/TemplateLimpo";
import pdfMake from "pdfmake/build/pdfmake";
import { getFunctionCodeToFuncMap } from "OkApp/CodeProcessor";
import { painel, fieldset } from "./OkReportUtil";
import { createImports } from "./OkReportsImports";
import htmlToPdfmake from "html-to-pdfmake";
import { geraRelatorioDinamicReport } from "./components/ElaboradorVisual/DinamicReportComps";
var pdfFonts = require("pdfmake/build/vfs_fonts.js");

export async function gerarExibir(okReport, jsonDados, meta, nomeArquivo) {
  const docRel = await getPdfRelatorio(okReport, jsonDados, meta);
  if (docRel) {
    try {
      if (nomeArquivo) {
        docRel.download(nomeArquivo + ".pdf");
      } else {
        docRel.open();
      }
      return docRel;
    } catch (error) {
      console.log("error abrir docRel", error);
    }
  }
}

export async function getPdfRelatorio(okReport, jsonDados, meta) {
  let f = { painel, fieldset, htmlToPdfmake };
  let funcGerarRelatorio = getFunctionCodeToFuncMap(
    okReport ? okReport.code : null,
    "gerarRelatorio"
  );
  let conteudo = [];
  if (funcGerarRelatorio) {
    let imports = createImports(
      okReport ? okReport.code : null,
      jsonDados,
      meta
    );
    // console.log('imports gerar', imports)
    conteudo = funcGerarRelatorio(f, jsonDados, meta, imports);
  } else {
    // console.log('func gerarRelatorio nao encontrada')
  }
  let conteudo2 = await geraRelatorioDinamicReport(
    jsonDados,
    okReport ? okReport.componentes : null,
    meta
  );
  conteudo = conteudo.concat(conteudo2);
  let rel = geraERetornaRelatorio(okReport, conteudo, meta);
  return rel;
}
function geraERetornaRelatorio(okReport, conteudo, meta) {
  // console.log('geraERetornaRelatorio AAAAAAAAAAAA', conteudo)

  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  try {
    let gerarDoc = gerarPadraoOkApp;
    if (okReport.template === "padraoLimpo") {
      gerarDoc = gerarPadraoLimpo;
    }
    return pdfMake.createPdf(gerarDoc(okReport, conteudo, meta));
  } catch (error) {
    console.log("error", error);
  }
}
