
import { createGetCollectionSemPermissao, createSnapCollectionSemPermissao } from "dbApi";
import { useState, useEffect } from "react";
import { useOkApp } from "OkApp/UseUtils";
import { permiteVer } from "OkApp/modules/PermissionMeta/functions";
import { usePerfilOkApp } from "useAuth";

export function useRelatorios(chave) {
    const [relatorios, setRelatorios] = useState([])
    const okApp = useOkApp();
    let strCollection = 'Project/' + okApp.uid + '/OkReport';
    const perfil = usePerfilOkApp()
    let ajustR = relatorios.filter(r => permiteVer(r, perfil))

    useEffect(() => {
        let observer = createSnapCollectionSemPermissao(strCollection, setRelatorios, (e) => console.log('erro useRelatorios', e), { chaveRelatorio: chave })
        return () => {
            observer()
        }
    }, [chave, strCollection])
    return ajustR
}

export function getAllReports(uidOkapp) {

    return new Promise(resolve => {
        createGetCollectionSemPermissao('Project/' + uidOkapp + '/OkReport').get().then((reports) => {
            let rep = []

            reports.forEach(report => {
                rep.push(report.data())
            })

            resolve(rep)
        })
    })
}
