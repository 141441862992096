import React from "react";
// import { Editor } from '@tinymce/tinymce-react';

import { InputLabel } from "@material-ui/core";

import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import "./style.css";
const styles = {
  labelForm: {
    fontSize: 12,
    paddingBottom: 3,
  },
};
export default function CompView(props) {
  const {
    input: { value, onChange },
    disabled,
    placeholder,
    options_editor,
    label,
  } = props;
  const handleEditorChange = (content, editor) => {
    onChange(content);
  };
  let placeholder1 = disabled
    ? ""
    : placeholder
    ? placeholder
    : "Digite algo aqui...";

  let inlineUsar = false;
  let disabledUsar = disabled;

  return (
    <div style={{ paddingBottom: 10 }}>
      {label && !inlineUsar ? (
        <InputLabel style={styles.labelForm}>{label}</InputLabel>
      ) : (
        ""
      )}
      <SunEditor
        setContents={value}
        disabled={disabledUsar}
        onChange={handleEditorChange}
        setOptions={{
          mode: inlineUsar ? "inline" : "classic",
          rtl: false,
          katex: "window.katex",
          minHeight: "300",
          videoFileInput: false,
          tabDisable: false,
          placeholder:placeholder1,
          fontSizeUnit: "pt",
          font: [
            "Roboto",
            "Helvetica",
            "Arial",
            "Georgia",
            "Tahoma",
            "Trebuchet MS",
            "Verdana",
          ],
          buttonList: [
            [
              "undo",
              "redo",
              "font",
              "fontSize",
              "formatBlock",
              "paragraphStyle",
              "blockquote",
              "bold",
              "underline",
              "italic",
              "strike",
              "subscript",
              "superscript",
              "fontColor",
              "hiliteColor",
              "textStyle",
              "removeFormat",
              "outdent",
              "indent",
              "align",
              "horizontalRule",
              "list",
              "lineHeight",
              "table",
              "link",
              "image",
              "video",
              "audio",
              "math",
              "fullScreen",
              "showBlocks",
              "codeView",
              "preview",
              "print",
            ],
          ],
          "lang(In nodejs)": "pt_br",
          ...options_editor,
        }}
      />
      {/* <Editor
                    value={value}
                    disabled={disabledUsar}
                    
                    apiKey="q2yj9emgr387nl1n4859h3mnw64hqct6bejt4f4nabjvghuu"
                    init={{
                        placeholder,
                        height: 300,
                        inline: inlineUsar,
                        language: 'pt_BR',
                        menubar: 'edit insert view format table'  ,
                        plugins: 'paste searchreplace autolink directionality visualblocks visualchars fullscreen image link media table charmap hr nonbreaking anchor toc insertdatetime advlist lists imagetools textpattern noneditable charmap quickbars emoticons',
                        toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat |  charmap emoticons | fullscreen | insertfile image media link anchor | ltr rtl',

                    }}

                    onEditorChange={handleEditorChange}
                /> */}
    </div>
  );
}
