import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next';

import useAuth, { usePerfil } from 'useAuth';

import { OkTypedComponent } from 'OkApp';
import { CrudForm } from 'crud'
import CrudControlForm from './CrudControlForm';
import ListProjetosPerfil from './ListProjetosPerfil';

import { Tabs, Tab, Box, useTheme, useMediaQuery } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { usePerfilUser } from 'OkApp/UseUtils';
import { getAllOkFormList } from 'OkApp/OkFunctions';

const colectionFirebase = "Users";

function ProjetoForm(props) {
    const theme = useTheme();
    const sm = useMediaQuery(theme.breakpoints.down('sm'));
    const { authenticatedOnly } = useAuth();
    const { t } = useTranslation();
    const [tab, setTab] = useState(0)
    let uidPerfil = null;

    if (props.location && props.location.search) {
        uidPerfil = props.location.search.slice(5, props.location.search.length)
    }

    const handleChange = (event, newValue) => {
        setTab(newValue);
    };

    useEffect(() => {
        authenticatedOnly()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div style={{ padding: 15 }}>
            <CrudForm
                title={"Editar perfil"}
                form={"EditPerfilForm"}
                initialValues={{ globalStyle: '.CustomFormContainer{padding:10px; padding-bottom: 45px;}' }}
                colectionFirebase={colectionFirebase}
                salvar novo
                noModules
                InsertComp={CrudControlForm}
            >
                <Tabs
                    value={tab}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    style={{ width: 'fit-content', display: 'flex', margin: '0 auto' }}
                >
                    <Tab label="Dados" />
                    {/* <Tab label="Style" />
                    <Tab label="Permissoes" /> */}

                </Tabs>
                {tab === 0 && <Box style={{}}>
                    <Box style={{ display: 'flex' }} sx={{ flexDirection: sm ? "column" : "row" }}>
                        <Box style={{ flex: 0 }}>
                            <OkTypedComponent typeComponente='ImageSelComp' field='avatar' label='Avatar' textoBotao="Selecione uma imagem" />
                        </Box>
                        <Box style={{ flex: 1 }}>
                            <OkTypedComponent typeComponente='InputComp' field='uid' label={'UID'} disabled />
                            <Box style={{ display: 'flex' }} sx={{ flexDirection: sm ? "column" : "row" }}>
                                <OkTypedComponent typeComponente='InputComp' field='name' label={t('project.name')} />
                                <OkTypedComponent typeComponente='InputComp' field='email' label={"Email"} disabled={true} />
                            </Box>
                            <Box display="flex" sx={{ flexDirection: sm ? "column" : "row" }}>
                                <OkTypedComponent typeComponente='InputComp' field='cidade' label={'Cidade'} />
                                <OkTypedComponent typeComponente='InputComp' field='estado' label={'Estado'} />
                            </Box>
                            <Box display="flex" sx={{ flexDirection: sm ? "column" : "row" }}>
                                <OkTypedComponent typeComponente='InputComp' field='phone' label={"Contato"} />
                                <OkTypedComponent typeComponente='InputComp' field='ocupation' label={"Ocupação"} />
                            </Box>
                            <OkTypedComponent typeComponente='InputComp' field='defaultRoute' label={"Rota App Padrão"} />
                            <Box style={{ display: 'flex' }} sx={{ flexDirection: sm ? "column" : "row" }}>
                                <OkTypedComponent typeComponente='ComboBoxComp' field='tipoPerfil' label='Tipo Perfil' options={['admin', "empresa", 'usuario']} toStringItem={(v) => {
                                    switch (v) {
                                        case 'admin': return "Admin"
                                        case 'empresa': return "Empresa"
                                        case 'usuario': return "usuario"
                                        default: return "Usuário";
                                    }
                                }} />
                                <OkTypedComponent typeComponente='InputComp' field='numProject' label={"Número de Projetos"} type={"numeric"} />
                                <OkTypedComponent typeComponente='InputComp' field='numUser' label={"Número de Usuários"} type={"numeric"} />
                            </Box>
                            <Box style={{ display: 'flex' }} sx={{ flexDirection: sm ? "column" : "row" }}>
                                <OkTypedComponent typeComponente='InputComp' field='nomePlano' label={"Nome Plano"} />
                                <OkTypedComponent typeComponente='InputComp' field='campanha' label={"Campanha"} />
                                <OkTypedComponent typeComponente='InputComp' field='dataFimPlano' label={"Data fim Plano"} type={"date"} />
                            </Box>
                            <OkTypedComponent typeComponente='CheckboxComp' field='ignoreAssinaturas' label={"Não busca assinatura Stripe"} />
                        </Box>
                    </Box>

                </Box>}
                {uidPerfil && <Button onClick={() => {
                    let url = "https://console.firebase.google.com/u/0/project/okappbeta/firestore/data~2FUsers~2F" + uidPerfil
                    window.open(url, "_blank")

                }}>Ver no console</Button>

                }
                {uidPerfil && <ListProjetosPerfil uidPerfil={uidPerfil} />}
            </CrudForm>
        </div>
    )
}

export default ProjetoForm