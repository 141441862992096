import React, { useState } from 'react'
import { Button, CircularProgress } from '@material-ui/core'
import { gerarExibir } from 'OkReport/OkReporGerador'
import { useOkApp } from 'OkApp/UseUtils'

export default function BotaoEmitir({ okreport, jsonValue, auxAction, metaPre }) {
    const [gerando, setGerando] = useState(false)
    let okApp = useOkApp();
    let metaUsar = okApp ? { logo: okApp.logo ? `data:image/png;base64,${okApp.logo}` : null } : {}
    if (metaPre) {
        metaUsar = { ...metaUsar, ...metaPre };
    }
    const gerar = () => {
        setGerando(true)
        gerarExibir(okreport, jsonValue, metaUsar, metaUsar.nomeArquivo || okreport.nome).then(r => {

            setGerando(false)
            if (auxAction) auxAction();
        }).catch(error => {
            console.log('error', error)
            if (auxAction) auxAction();
        })

    }
    return (
        <div>
            {gerando ? <CircularProgress /> :
                <Button variant="contained" color="primary" onClick={gerar}>Emitir</Button>
            }
        </div>
    )
}
