import React from 'react'
import OkCompAdapter from 'OkApp/components/OkCompAdapter'
import { OkTypedComponent, LabelInputAuto } from 'OkApp'
import CompView from './components/CompView'
const typeComponente = 'HtmlEditor'

const AdapterOkComp = (props) => {

    let propsComp = { developer: props.developer }
    return <OkCompAdapter {...props} propsComp={propsComp} component={CompView} />
}
const EditorOkComp = (props) => {

    return <>
        <OkTypedComponent typeComponente='InputComp' field='field' label='Nome Salvo' />
        <LabelInputAuto {...props} />
        <  div style={{ display: "flex" }}>
            <OkTypedComponent typeComponente='SwitchComp' field='inline' label='Menus em PopUp' />
            <OkTypedComponent typeComponente='SwitchComp' field='visualizador' label='Visualizador' />
        </div>
        <OkTypedComponent typeComponente='InputComp' field='textoVazio' label='Texto quando vazio' />

    </>
}

const modelo = {
    typeComponente,
    field: 'html',
    label: 'Editor de HTML',
    textoVazio: 'Digite algo aqui...',
    inline: true,
    visualizador: true
}

const HtmlEditor = {
    typeComponente,
    configView: {
        grupo: 'Básico',
        nome: 'Editor de HTML',
        descricao: 'Editor de HTML',
        helpUrl: 'https://okapp.com.br/app/okappdocs/htmleditor',
        modelo
    },
    Component: AdapterOkComp,
    CompEditor: EditorOkComp
}

export default HtmlEditor