import { collection, getDocs, doc, setDoc, getDoc, onSnapshot, where, query, limit, serverTimestamp, orderBy as orderByF } from "firebase/firestore";
import firebaseUtil from "firebaseUtil";

export default class QueryFactory {
    constructor(collections, strArrCol) {
        //teste
        if (collections) {

            this.collections = collections
            this.refQuery = collections
        } else {


            if (strArrCol.split) {//String

                this.collections = collection(firebaseUtil.db, strArrCol)
            } else {

                this.collections = collection(firebaseUtil.db, ...strArrCol)
            }
            this.refQuery = this.collections
        }
    }
    doc(str) {
        if (str) {

            this.refQuery = doc(this.refQuery, str)
        } else {

            this.refQuery = doc(this.refQuery)
        }
        return this;
    }
    collection(str) {
        this.refQuery = collection(this.refQuery, str)
        return this;
    }
    where(...args) {

        this.refQuery = query(this.refQuery, where(...args));
        return this;
    }
    id() {
        return this.refQuery.id
    }

    set(obj, parans) {
        return setDoc(this.refQuery, obj, parans)
    }

    get() {
        if (this.refQuery.type === "collection") {

            return getDocs(this.refQuery)
        } else {
            console.log('get this.refQuery.type', this.refQuery.type)
            return getDoc(this.refQuery)

        }
    }


    orderBy(field, type = 'cres') {
        this.refQuery = query(this.refQuery, orderByF(field, type));
        return this;
    }
    limit(...args) {
        this.refQuery = query(this.refQuery, limit(...args));
        return this;
    }
    onSnapshot(callback, callError) {
        onSnapshot(this.refQuery, callback, callError);
    }


    getResultCol() {
        return this.refQuery;
    }

}