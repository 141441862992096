import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, ListItemSecondaryAction, IconButton, InputLabel, Tooltip } from '@material-ui/core';

import UpIcon from '@material-ui/icons/ArrowDropUp';
import DownIcon from '@material-ui/icons/ArrowDropDown';

import DeleteIcon from '@material-ui/icons/Delete';
function array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
        new_index = arr.length - 1;
    }
    if (new_index < 0) {
        new_index = 0;
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
};
const useStyles = makeStyles(theme => ({
    root: {
        margin: 'auto',
    },
    paper: {
        width: 350,
        height: 230,
        overflow: 'auto',
    },
    button: {
        margin: theme.spacing(0.5, 0),
    },
}));

function not(a, b) {
    return a.filter(value => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter(value => b.indexOf(value) !== -1);
}
function isFunction(f) {
    if (typeof f === "function") {
        return true;
    }
}

export default function TransferList(props) {
    const { label, input, meta: { touched, error }, possiveis, adicionavel, removivel, permitemover } = props;
    const { onChange } = input;
    let valueUsar = input.value || []
    const classes = useStyles();
    const [checked, setChecked] = React.useState([]);
    const [left, setLeft] = React.useState([]);

    const updatePossiveis = () => {
        if (possiveis) {
            let possiveisUsar = possiveis;
            if (isFunction(possiveis)) {
                possiveisUsar = possiveis(props)
            }
            setLeft(not(possiveisUsar, input.value || []))
        }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(updatePossiveis, [possiveis])

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, valueUsar);

    const handleToggle = value => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };


    const addItem = (item) => {
        if (item) {
            let index = valueUsar.concat(left).indexOf(item);
            if (index === -1) {
                input.onChange(valueUsar.concat([item]));
            }
        }

    };


    const removeItem = (item) => {
        if (item) {
            let index = valueUsar.indexOf(item);
            if (index !== -1) {
                let newArray = valueUsar.slice();
                newArray.splice(index, 1)
                input.onChange(newArray);
            }
        }
    };

    const removeItemLeft = (item) => {
        if (item) {
            let index = left.indexOf(item);
            if (index !== -1) {
                let newArray = left.slice();
                newArray.splice(index, 1)
                setLeft(newArray);
            }
        }
    };

    const handleAllRight = () => {
        input.onChange(valueUsar.concat(left));
        setLeft([]);
    };

    const handleCheckedRight = () => {
        input.onChange(valueUsar.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        input.onChange(not(valueUsar, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const handleAllLeft = () => {
        setLeft(left.concat(valueUsar));
        input.onChange([]);
    };

    const customList = (items, left) => (
        <Paper className={classes.paper}>
            <List dense component="div" role="list">
                {!items.length && <InputLabel shrink  >
                    Sem Itens
                </InputLabel >}
                {items.map((value, indexrow) => {
                    const labelId = `transfer-list-item-${value}-label`;

                    return (
                        <ListItem key={value} role="listitem" button onClick={handleToggle(value)}>
                            {possiveis && <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': labelId }}
                                />
                            </ListItemIcon>}
                            <ListItemText id={labelId} primary={value} />
                            {removivel &&
                                <ListItemSecondaryAction>
                                    <IconButton edge="end" aria-label="delete"
                                        onClick={() => {
                                            if (left) {

                                                removeItemLeft(value)
                                            } else {
                                                removeItem(value)
                                            }
                                        }}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                    {permitemover &&
                                        <>

                                            <Tooltip title="Mover para cima">
                                                <IconButton edge="end" size='small' onClick={() => moveUp(indexrow)}><UpIcon /></IconButton>
                                            </Tooltip>
                                            <Tooltip title="Mover para baixo">
                                                <IconButton edge="end" size='small' onClick={() => moveDown(indexrow)}><DownIcon /></IconButton>
                                            </Tooltip>
                                        </>
                                    }
                                </ListItemSecondaryAction>
                            }
                            {(!removivel && permitemover) &&
                                <ListItemSecondaryAction>

                                    <Tooltip title="Mover para cima">
                                        <IconButton edge="end" size='small' onClick={() => moveUp(indexrow)}><UpIcon /></IconButton>
                                    </Tooltip>
                                    <Tooltip title="Mover para baixo">
                                        <IconButton edge="end" size='small' onClick={() => moveDown(indexrow)}><DownIcon /></IconButton>
                                    </Tooltip>
                                </ListItemSecondaryAction>
                            }
                        </ListItem>
                    );
                })}
                <ListItem />
            </List>
        </Paper>
    );
    const [open, setOpen] = React.useState(false);
    const [novoItem, setNovoItem] = useState("")

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setNovoItem("");
    };

    const moveUp = useCallback((indexrow, data) => {
        onChange(value => {
            let newArray = value ? value.slice() : [];
            array_move(newArray, indexrow, indexrow - 1)
            return newArray;
        })
    }, [onChange])

    const moveDown = useCallback((indexrow, data) => {
        onChange(value => {
            let newArray = value ? value.slice() : [];
            array_move(newArray, indexrow, indexrow + 1)
            return newArray;
        })
    }, [onChange])

    return (
        <div>
            <InputLabel shrink  >
                {label}<span style={{ color: '#ff5964' }}>{touched && error ? error : ""}</span>
            </InputLabel >
            <Grid container spacing={2} justify="center" alignItems="center" className={classes.root}>
                {possiveis && <>
                    <Grid item>{customList(left, true)}</Grid>
                    <Grid item>
                        <Grid container direction="column" alignItems="center">
                            <Button
                                variant="outlined"
                                size="small"
                                className={classes.button}
                                onClick={handleAllRight}
                                disabled={left.length === 0}
                                aria-label="move all right"
                            >
                                ≫
                            </Button>
                            <Button
                                variant="outlined"
                                size="small"
                                className={classes.button}
                                onClick={handleCheckedRight}
                                disabled={leftChecked.length === 0}
                                aria-label="move selected right"
                            >
                                &gt;
                            </Button>
                            <Button
                                variant="outlined"
                                size="small"
                                className={classes.button}
                                onClick={handleCheckedLeft}
                                disabled={rightChecked.length === 0}
                                aria-label="move selected left"
                            >
                                &lt;
                            </Button>
                            <Button
                                variant="outlined"
                                size="small"
                                className={classes.button}
                                onClick={handleAllLeft}
                                disabled={valueUsar.length === 0}
                                aria-label="move all left"
                            >
                                ≪
                            </Button>
                        </Grid>
                    </Grid>
                </>}
                <Grid item>
                    {customList(valueUsar, false)}

                </Grid>
            </Grid>
            {adicionavel &&
                <Grid container spacing={2} justify="center" alignItems="center" className={classes.root}>

                    <Button variant="contained" color="primary" disabled={props.disabled} onClick={handleClickOpen}>
                        Adicionar Item
                    </Button>
                </Grid>}
            <Dialog
                PaperProps={{ style: { height: 'auto', flex: 1 } }}
                open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Adicionar um Item</DialogTitle>
                <DialogContent>

                    <TextField
                        value={novoItem}
                        onChange={(v) => setNovoItem(v.target.value)}
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Item"

                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={() => {
                        addItem(novoItem)

                        handleClose()
                    }} color="primary">
                        Adicionar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}