import produce from "immer"

export default function useCriaControlerField(value, onChange) {

    const updateValue = (param, valueChange) => {
        if (param === "maxColumns") {
            if (valueChange >= 0) {
                let newValue = { ...value }
                newValue[param] = valueChange;
                const nextState = produce(value, draftState => {
                    for (let key in newValue) {
                        if (newValue.hasOwnProperty(key)) {
                            draftState[key] = newValue[key];
                        }
                    }
                })
                onChange(nextState)
            }
        } else {
            let newValue = { ...value }
            newValue[param] = valueChange;
            const nextState = produce(value, draftState => {
                for (let key in newValue) {
                    if (newValue.hasOwnProperty(key)) {
                        draftState[key] = newValue[key];
                    }
                }
            })
            onChange(nextState)
        }
    }

    const criador = (field) => {
        let r = {};
        r.value = value[field]
        r.onChange = (v) => {
            if (v && (v.constructor.name === 'SyntheticEvent' || v.target)) {
                updateValue(field, v.target.value)
            } else {
                updateValue(field, v)
            }
        }
        // r.onChange = { event => { updateValue('tipoView', event.target.value) }

        return { input: { ...r }, meta: {} }
    }
    return criador

}