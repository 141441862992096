import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';

import EditorPainel from './EditorPainel';

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export default function EditorPlayground(props) {
    const { scopeAux, code, css, updateCodeECss, mouseOver } = props;
    const classes = useStyles();
    const [showDialog, setShowDialog] = useState(false)


    const [codeAux, setCodeAux] = useState("")

    const [cssAux, setCssAux] = useState("")
    useEffect(() => {
        if (code) {
            setCodeAux(code)
        }
        return () => {
            setCodeAux("")
        }
    }, [code])
    useEffect(() => {
        if (css) {
            setCssAux(css)
        }
        return () => {
            setCssAux("")
        }
    }, [css])
    const handleClickOpen = () => {
        console.log('scope', scopeAux)

        setShowDialog(true);
    };

    const handleClose = () => {

        setShowDialog(false);
    };
    const saveClose = () => {
        updateCodeECss(codeAux, cssAux)
        setShowDialog(false);
    };

    return (
        <div className={'ButtonEditor'}>
            <Button variant="contained" color="primary" style={{ position: 'absolute', top: 28, right: 5, width: 200, display: !mouseOver && "none", opacity: "90%",zIndex: 50 }} onClick={handleClickOpen}>
                Editar Componente
            </Button>
            <Dialog fullScreen open={showDialog} onClose={handleClose} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Editor React/CSS
                        </Typography>
                        <Button autoFocus color="inherit" onClick={saveClose}>
                            Salvar
                        </Button>
                    </Toolbar>
                </AppBar>
                <EditorPainel codeAux={codeAux} setCodeAux={setCodeAux} cssAux={cssAux} setCssAux={setCssAux} scopeAux={scopeAux} />
            </Dialog>
        </div>
    )
}
