import React, { useEffect } from "react";

import myPdf from "../../File/POLITICA_DE_PRIVACIDADE_OKAPP.pdf";
import { useCrudContext } from "crud";

export default function PoliticasPrivacidade(props) {
  const { setTitle } = useCrudContext();

  useEffect(() => {
    setTitle("Políticas de privacidade");
    return () => setTitle("");
  }, [setTitle]);

  return (
    <div style={{ textAlign: "-webkit-center", flex: 1  }}>
      <iframe src={myPdf} width="100%" height="100%" />
      {/* <PDF style={{ width: 'min-content' }} file={myPdf} scale={1.6} /> */}
    </div>
  );
}
