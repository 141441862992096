import _ from 'lodash'


export const defaultSnippets = [
    { name: 'sout', code: 'console.log("$1",$1)' }
]

function replaceInternalCall(nameFunction, stringFunction) {
    // return stringFunction.replace(nameFunction + "(", "this." + nameFunction + "(")
    return stringFunction;

}
class CodeClass {
    constructor(auxf, strFunc, mapFuncs) {

        let strAddTry = ''
        try {

            let index = auxf.indexOf("{")
            strAddTry = auxf.substring(0, index + 1) + "\ntry {" + auxf.substring(index + 1, auxf.length)
            index = strAddTry.lastIndexOf("}")
            strAddTry = strAddTry.substring(0, index) +
                "\n} catch (error) {\nconsole.log('Erro em Código: ',error)\n}\n" +
                strAddTry.substring(index, strAddTry.length)


            for (const nameFun in mapFuncs) {
                if (nameFun !== strFunc && mapFuncs.hasOwnProperty(nameFun)) {
                    const strAuxFun = mapFuncs[nameFun];
                    // eslint-disable-next-line no-new-func
                    this[nameFun] = new Function(strAuxFun)().bind(this);
                    strAddTry = replaceInternalCall(nameFun, strAddTry);

                }
            }
            // eslint-disable-next-line no-new-func            
            let funJs = new Function(strAddTry)
            this[strFunc] = funJs().bind(this);

        } catch (error) {
            console.log('Erro ao criar function:' + strFunc + " - Error: ", error)
            console.log('Code: ', auxf)
            console.log('strAddTry: ', strAddTry)
        }
    }
}


export function getFunctionCodeToFuncMap(obj, strFunc) {
    let auxf = _.get(obj, strFunc);
    if (auxf) {
        try {
            return new CodeClass(auxf, strFunc, obj)[strFunc];
        } catch (error) {
            console.log('error', error)
        }

    }
    return null
}

export function getClassCodeToFuncMap(obj, strFunc) {
    let auxf = _.get(obj, strFunc);
    if (auxf) {
        try {
            return new CodeClass(auxf, strFunc, obj);
        } catch (error) {
            console.log('error', error)
        }

    }
    return null
}

export function createDefaultFuncMap2(func) {
    var anyString = 'return ';
    var functionToText = anyString + func;
    let deva = {}
    deva[func.name] = functionToText

    return deva;

}
export function createDefaultFuncMapStr(nome, funcStr) {
    var anyString = 'return ';
    var functionToText = anyString + funcStr;
    let deva = {}
    deva[nome] = functionToText

    return deva;

}
export function createDefaultFuncMapStr2(nome, funcStr, nome2, funcStr2) {
    var anyString = 'return ';
    var functionToText = anyString + funcStr;
    var functionToText2 = anyString + funcStr2;
    let deva = {}
    deva[nome] = functionToText
    deva[nome2] = functionToText2

    return deva;

}

export function createFunctionMap(code) {
    if (!code || !code.includes('function ')) {
        return {}
    }

    var arrStr = code.split('function ');
    let classe = {};
    arrStr.forEach(m => {

        if (m && m.trim()) {
            let nome = m.split('(')[0].trim();
            if (nome) {

                let func = "return function " + m;
                classe[nome] = func;
            }
        }

    })
    return classe;
}

export function convertFunctionMapToCode(functions) {
    let code = "";
    for (const key in functions) {
        if (functions.hasOwnProperty(key)) {
            const element = functions[key];
            let fStr = element.substring(7).trim();
            code = code.concat(fStr).concat("\n\n")
        }
    }

    return code;
}