import React from 'react'
import { useTranslation } from 'react-i18next';
export const languages = [
    { sigla: 'en', nome: 'Ingles' },
    { sigla: 'pt', nome: 'Português' }
]

export default function CompI18n() {
    const { i18n } = useTranslation();
    const changeLanguage = lng => {
        i18n.changeLanguage(lng);
    };
    return (
        <div style={{ display: "none" }}>
            {languages.map((l) => <button key={l.sigla} onClick={() => changeLanguage(l.sigla)}>{l.nome}</button>)}
        </div>
    )
}

