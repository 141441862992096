import React from 'react'
import OkCompAdapter from 'OkApp/components/OkCompAdapter'
import SeletorUnicoPorPesquisa from './component/SeletorUnicoPorPesquisa'

const typeComponente = 'SeletorOkForm' //deve ter o mesmo nome de exportação no index

const AdapterOkComp = (props) => {
    // const { okComp } = props
    let propsComp = {}



    return <OkCompAdapter {...props} propsComp={propsComp} component={SeletorUnicoPorPesquisa} />
}



const modelo = {
    typeComponente,
    field: 'okForm',
    label: 'Formulário',
    tituloPesquisa: '',
}

const SeletorOkForm = {
    typeComponente,
    configView: {
        grupo: 'Consulta',
        nome: 'Seletor de Formulário',
        descricao: 'Seleciona um formulario do aplicativo',
        helpUrl: 'https://www.google.com.br/',
        modelo,       
    },
    Component: AdapterOkComp
}

export default SeletorOkForm;