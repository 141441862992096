
import * as Perfil from './Perfil'
import * as Home from './Home'
import * as Favoritos from './Favoritos'
import * as Projeto from './Projeto'
import ProjetosPermitidos from './ProjetosPermitidos'
import ProjetosPublicos from './ProjetosPublicos'
import Planos from './Planos'
import { isAuthenticated } from '../../useAuth';

export const routes = () => {
    let r = [];

    if (isAuthenticated()) {
        r = r.concat(Perfil.routes());
        r = r.concat(Home.routes());
        r = r.concat(Favoritos.routes());
        r = r.concat(Projeto.routes());
        r = r.concat([{ exact: true, path: "/Projetos", component: ProjetosPermitidos }]);
        r = r.concat([{ exact: true, path: "/ProjetosPublicos", component: ProjetosPublicos }]);
        r = r.concat([{ exact: true, path: "/Planos", component: Planos }]);
    }

    return r
}
export const menus = (props) => {
    const { okApp, history } = props
    let r = [];
    if (!okApp) {//não esta dentro de um OkApp
        if (isAuthenticated()) {
            r = r.concat(Perfil.menus(props));
            r = r.concat(Home.menus(props));
            r = r.concat(Projeto.menus(props));
            r = r.concat(Favoritos.menus(props));

            r = r.concat([{ text: "Meus Projetos", iconKey: "FolderShared", action: () => { history.push("/Projetos") }, index: '3' }]);
            r = r.concat([{ text: "Aplicativos Públicos", iconKey: "Apps", action: () => { history.push("/ProjetosPublicos") }, index: '4' }]);
            r = r.concat([{ text: "Planos", action: () => { history.push("/Planos") }, iconKey: "ShoppingCart", index: '5' }]);
            r = r.concat([{
                index: "999998",
                text: "Suporte",
                iconKey: "HeadsetMic",
                action: () => {
                    window.open(
                        "https://okapp.com.br/app/okappdocs/suporte-okapp",
                        "_blank"
                    );
                },
            }]);
        }
    }
    return r
}

