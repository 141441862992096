import React, { useCallback, useEffect, useState } from 'react'
import { CircularProgress, Typography } from '@material-ui/core';

import { getPdfRelatorio } from 'OkReport/OkReporGerador';
import { useAtualValues, useOkApp } from 'OkApp/UseUtils';
// import PDF from 'react-pdf-js-infinite';
import _ from 'lodash'
import './style.css'
export default function VisualizadorPdf({ okreport, jsonValue, metaPre }) {
    const [docPdf, setDocPdf] = useState(null)
    const [force, setForce] = useState(true)
    const [lastTime, setLastTime] = useState(null)
    const [timer, setTimer] = useState(null)
    let okApp = useOkApp();
    
    let metaUsar = okApp ? { logo: okApp.logo ? `data:image/png;base64,${okApp.logo}` : null } : {}
    if (metaPre) {
        metaUsar = { ...metaUsar, ...metaPre };
    }
    let atualAux = useAtualValues()
    if (!okreport) {
        okreport = atualAux;// esta no cadastro de formularios
    }
    const gerar = useCallback(
        async () => {
            if (!timer) {
                let tempo = 8000;
                let now = new Date().getTime();
                if (lastTime)
                    tempo = now - lastTime;

                if (tempo > 1000) {
                    setForce(false)
                    setLastTime(now)
                    let docrel = await getPdfRelatorio(okreport, jsonValue, metaUsar)
                    if (docrel) {
                        docrel.getBase64((data) => {
                            setDocPdf(data)
                            setForce(true)
                        });
                    }
                } else {


                    setTimer("new timer")
                    setTimeout(() => {
                        gerar();
                        setTimer(null)
                    }, 500)
                }
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [okreport, jsonValue],
    )

    useEffect(() => {
        gerar()
    }, [gerar])

    return (
        <div className='VisualizadorPdf-container'>
            {!force && <div style={{ backgroundColor: "#fff", width: 500, paddingTop: 25 }}><CircularProgress /></div>}
            {!_.isEmpty(jsonValue) ?
                <>
                    {/* {docPdf && <>
                        {force &&
                            <PDF style={{ width: 'min-content' }}
                                file={`data:application/pdf;base64,${docPdf}`}
                                scale={1.6} />}</>} */}
                    {docPdf && <>
                        {force &&
                            <iframe src={`data:application/pdf;base64,${docPdf}`} width="100%" height="100%" />}</>}
                </>
                :
                <div >
                    <Typography>Sem dados JSON para emitir o PDF</Typography>
                </div>

            }
        </div>

    )
}
