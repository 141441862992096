import { Button } from '@material-ui/core'
import React from 'react'
import xml2js from 'xml2js';
import _ from 'lodash'
import { useReduxFormProps } from 'OkApp/UseUtils';
import useNotification from 'notification';
export default function CompView(props) {
    const { input:{onChange}, label, exibe_aux_console, produts_fields, map_propriedades, map_propriedades_produtos, ...outer } = props
    const formProps = useReduxFormProps();
    const { showWarning } = useNotification();
    const readFile = async (e) => {
        e.preventDefault()
        const reader = new FileReader()
        reader.onload = async (e) => {
            const text = (e.target.result)
            processaTexto(text)

        };
        if (!e.target.files[0].name.toUpperCase().endsWith(".XML")) {
            showWarning("Arquivo Invalido")
            return;
        }

        reader.readAsText(e.target.files[0])
    }
    const processaTexto = (text) => {

        xml2js.parseString(text, { mergeAttrs: true, trim: true, explicitArray: false }, function (err, result) {
            let retorno = {};

            let produtosVar = result.nfeProc.NFe.infNFe.det
            if (exibe_aux_console) {
                console.log('json xml', result)
            }

            map_propriedades.forEach(prop => {
                _.set(retorno, prop.propVar, _.get(result, prop.propStr))
            });
            if (!Array.isArray(produtosVar)) {

                produtosVar = [produtosVar]
            }

            if (map_propriedades_produtos && map_propriedades_produtos.length) {
                let retornoProdutos = []
                produtosVar.forEach(produto => {
                    if (exibe_aux_console) {
                        console.log('json xml produto', produto)
                    }

                    let novoProduto = {}
                    map_propriedades_produtos.forEach(prop => {
                        _.set(novoProduto, prop.propVar, _.get(produto, prop.propStr))
                    });
                    retornoProdutos.push(novoProduto)
                });

                retorno[produts_fields] = retornoProdutos;

            }
            if (exibe_aux_console) {
                console.log('retorno', retorno)
            }
            for (const key in retorno) {
                if (Object.hasOwnProperty.call(retorno, key)) {
                    const element = retorno[key];
                    formProps.change(key, element)

                }
            }
            onChange(text)


        });

    }


    return (
        <div>
            <Button
                variant="contained"
                color="primary"
                style={{ marginBottom: 15, marginTop: 4 }}
                component="label"
                {...outer}
            >
                {label}
                <input hidden
                    type="file"
                    accept=".XML"
                    onChange={(e) => readFile(e)} />
            </Button>
        </div>
    )
}
