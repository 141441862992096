import React, { useEffect } from 'react'
import OkCompAdapter from 'OkApp/components/OkCompAdapter'
import { OkTypedComponent, LabelInputAuto } from 'OkApp'
import { useAtualValues, usePerfilUser } from 'OkApp/UseUtils'
const typeComponente = 'AtualUserLoadComp'


const AtualUserLoad = (props) => {

    const { developer, label, forceLoad, input: { value, onChange } } = props
    const uidAtual = useAtualValues('uid');

    const perfilUser = usePerfilUser()


    useEffect(() => {
        if (forceLoad || (!uidAtual && perfilUser && !value)) {
            onChange(perfilUser)
            setTimeout(() => {
                onChange(perfilUser)
            }, 300);
        }

    }, [value, perfilUser, uidAtual, onChange, forceLoad])
    return <>
        {developer &&
            <div style={{ height: 40, width: '100%', backgroundColor: "#f0f", display: 'flex', justifyContent: "center", alignItems: "center" }}>
                AtualUserLoad: {label}
            </div>
        }
    </>
}


const AdapterOkComp = (props) => {
    let propsComp = { developer: props.developer }

    return <OkCompAdapter {...props} propsComp={propsComp} component={AtualUserLoad} />
}



const EditorOkComp = (props) => {

    return <div style={{ display: 'flex', flexDirection: 'column', height: "100%" }}>
        <div style={{ display: 'flex' }}>

            <OkTypedComponent typeComponente='InputComp' field='field' label='Nome Salvo' />
            <LabelInputAuto {...props} />
        </div>
        <OkTypedComponent typeComponente='SwitchComp' field='forceLoad' label='Recarregar sempre que abrir' />


    </div>
}

const modelo = {
    typeComponente,
    field: 'user',
    label: 'Carregar Usuário Atual',
}


const AtualUserLoadComp = {
    typeComponente,
    configView: {
        grupo: 'Avançado',
        nome: 'Carregar Usuário Atual',
        descricao: 'Carrega o usuário logado para os dados da tela. Somente carrega um usuário caso não exista um já carregado e em estado "Novo", (ajustar reload)',
        helpUrl: 'https://www.google.com.br/',
        notView: true,
        modelo
    },
    Component: AdapterOkComp,
    CompEditor: EditorOkComp
}
export default AtualUserLoadComp
