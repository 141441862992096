import React, { useState } from 'react'
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';

import Help from '@material-ui/icons/Help';

import { makeStyles, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';

import * as Icons from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    menuItem: {
        color: theme.palette.secondary.contrastText,
    },
    menuItemInterno: {
        color: theme.palette.primary.contrastText,
    },
    collapse: {
        backgroundColor: theme.palette.primary.dark,
    },
}));

export default function ItemMenu({ menu, handleDrawerClose, colorInvert }) {
    const [open, setOpen] = useState(false);
    const [expanded, setExpanded] = useState(false)
    const classes = useStyles();

    let classesNameUsar = colorInvert ? classes.menuItemInterno : classes.menuItem
    let iconeAux = ""
    let iconeUsar = <Help className={classesNameUsar} />;
    if (menu.icone) {
        let Teste = menu.icone
        iconeUsar = <Teste className={classesNameUsar} />;
    }

    if (menu.iconKey) {
        const RecIcon = Icons[menu.iconKey]
        iconeUsar = <RecIcon className={classesNameUsar} />;
    }

    const expandAction = () => {
        setExpanded(!expanded)
    }


    const handleClose = () => {
        setOpen(false);
    };


    return (<>
        <ListItem button key={menu.text} onClick={() => {
            if (menu.menus) {
                expandAction()
            } else {
                if (menu.confirmText) {
                    setOpen(true);
                } else {
                    if (menu.action) {
                        menu.action();
                        if (handleDrawerClose) handleDrawerClose()
                    }
                }
            }
        }}>
            < ListItemIcon >
                {iconeUsar}
            </ListItemIcon>
            <ListItemText
                disableTypography
                primary={<Typography className={classesNameUsar} ><span>{menu.text} {iconeAux}</span></Typography>}
            />

            {menu.menus && <>{expanded ? <ExpandLess className={classesNameUsar} /> : <ExpandMore className={classesNameUsar} />}</>}
        </ListItem >
        {menu.menus &&
            <Collapse className={classes.collapse} in={expanded} timeout="auto" unmountOnExit>
                {menu.menus.map((m, i) => <ItemMenu key={"submenu" + m.text + i} menu={m} colorInvert={!colorInvert} handleDrawerClose={handleDrawerClose} />)}
            </Collapse>
        }
        {menu.confirmText && <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{ style: { height: 'auto', } }}
        >
            <DialogTitle id="alert-dialog-title">{menu.confirmText}</DialogTitle>
            <DialogContent>
                {menu.confirmTextObs &&
                    <DialogContentText id="alert-dialog-description">
                        {menu.confirmTextObs}
                    </DialogContentText>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    handleClose()
                    if (menu.action) {
                        menu.action();
                        if (handleDrawerClose) handleDrawerClose()
                    }
                }} color="primary">
                    Sim
                </Button>
                <Button onClick={handleClose} color="primary" autoFocus>
                    Não
                </Button>
            </DialogActions>
        </Dialog>}
    </>)


}
