import React, { useState, useEffect } from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { useUsersListSnap, useUserSnap } from 'OkApp/UseUtils';
import { Typography, IconButton, Checkbox, FormControlLabel, InputLabel } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import produce from "immer"
import "./style.css"
import _ from 'lodash'
import { ContentLoading } from 'components';

function RenderUser({ uidUser, removeUsuario, listPermissions, labelListaPermi, value, onChange }) {
    let user = useUserSnap(uidUser);

    const updatePermissao = (strPermissao, bol) => {
        const nextState = produce(value || {}, draftState => {
            let novaPermicao = {}
            if (_.isObject(draftState[uidUser])) {
                novaPermicao = draftState[uidUser]
            }
            novaPermicao[strPermissao] = bol;

            draftState[uidUser] = novaPermicao;
        })
        onChange(nextState)
    }
    let permiChaves = {}
    if (_.isObject(value[uidUser])) {
        permiChaves = value[uidUser];
    }
    let exibeListaPerm = (listPermissions && listPermissions.length) ? true : false
    let loading = user === null
    if (user === undefined) {
        user = {}
        user.name = "Usuários Excluido"
        user.email = "Remova o desta utilização."
    }
    
    return <div className={"RenderUser"}>
        {!loading ?
            <>
                <div>
                    <Typography variant="h6">{user.name}</Typography>
                    <Typography variant="subtitle2">{user.email}</Typography>
                </div>
                {exibeListaPerm && <div style={{ display: "flex", flexDirection: 'column' }}>
                    <InputLabel>{labelListaPermi || "Permissoes"}</InputLabel>
                    {listPermissions.map((p, i) => {
                        return <FormControlLabel
                            key={i}
                            control={<Checkbox
                                checked={permiChaves[p] || false}
                                onChange={evt => {
                                    updatePermissao(p, evt.target.checked)
                                }}
                                name={p}
                                color="primary"
                            />}
                            label={p}
                        />
                    })}
                </div>
                }

                <IconButton onClick={() => removeUsuario(uidUser)}><DeleteIcon /></IconButton>
            </> :
            <div>
                <ContentLoading style={{ height: 18, width: 250 }} />
                <ContentLoading style={{ height: 14, width: 200 }} />
            </div>

        }
    </div>

}

export default function SeletorPerfis({ label, listPermissions, labelListaPermi, input: { value, onChange }, disabled }) {

    const [perfisExibir, setPerfisExibir] = useState([])
    const [texto, setTexto] = useState("")
    const users = useUsersListSnap(texto);

    useEffect(() => {
        if (texto.length >= 3) {
            setPerfisExibir(users)
        } else {
            setPerfisExibir([])
        }
        return () => { setPerfisExibir([]) }
    }, [texto, users])

    const RenderItemSelecionavel = ({ user }) => (<div>
        <Typography variant="h6">{user.name}</Typography>
        <Typography variant="subtitle2">{user.email}</Typography>
    </div>)

    const addUsuario = (user) => {
        setTexto("")
        const nextState = produce(value || {}, draftState => {
            draftState[user.uid] = true;
        })
        onChange(nextState)
    }

    const removeUsuario = (userUid) => {
        setTexto("")
        const nextState = produce(value || {}, draftState => {
            draftState[userUid] = false;
        })
        onChange(nextState)
    }


    let list = [];
    for (const key in value) {
        if (value.hasOwnProperty(key)) {
            let p = value[key];
            if (p)
                list.push(key)
        }
    }

    return (<div>

        <Autocomplete
            id="combo-box-demo"
            disabled={disabled}
            options={perfisExibir}
            value={null}
            noOptionsText={"Busque pelo email. Infome 3 caracteres"}
            getOptionLabel={option => <RenderItemSelecionavel user={option} />}
            filterOptions={(options, params) => {
                return options.filter(u => {
                    if (u.name.toUpperCase().includes(texto.toUpperCase())) {
                        return true;
                    }
                    if (u.email.toUpperCase().includes(texto.toUpperCase())) {
                        return true;
                    }
                    return false;
                })
            }}

            onChange={(e, v) => {
                addUsuario(v)
            }}
            renderInput={params => <TextField
                {...params}
                label={label || "Usuários"}
                value={texto}
                onChange={(e) => setTexto(e.target.value)}
                fullWidth
                InputLabelProps={{
                    shrink: true,
                }}

            />}
        />
        <div className={"UsersList"}>
            {list.map((uidUser, index) => <RenderUser key={index} uidUser={uidUser} removeUsuario={removeUsuario} value={value} onChange={onChange} listPermissions={listPermissions} labelListaPermi={labelListaPermi} />)}
        </div>
    </div>

    )
}
