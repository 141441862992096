import React, { useEffect } from 'react'

export default function OkFormAbaAdapter({ keyTab, atualTab, TabPanel, children, addTab, styleProps }) {
    useEffect(() => {
        addTab(keyTab)
    }, [addTab, keyTab])
    return <TabPanel atualTab={atualTab} keyTab={keyTab} styleProps={styleProps}>
        {children}
    </TabPanel>
}
