import React, { memo, useEffect, useState } from 'react'
import { reduxForm } from 'redux-form'
import { useLocation } from 'react-router-dom';

import { useCrudContext } from 'crud/context';
import { crateSnapDocumentDatabase } from 'dbApi';
import { OkModulesConnect } from 'OkApp';
import { useOkAppContext } from 'OkApp/context';
import { connect } from 'react-redux';
import getFormValues from 'redux-form/es/getFormValues';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function CrudFormChildrensC({ children }) {

    // console.log('CrudFormChildrens')
    return <>
        {children}
    </>;
}

const CrudFormChildrens = memo(CrudFormChildrensC)

var CrudForm = function (props) {
    const [loadingUid, setLoadingUid] = useState(false)
    const { initialize, atualValues } = props

    const { children, title, colectionFirebase, onChange, noModules, InsertComp, noUseDB, okform } = props

    const query = useQuery();
    const uid = query.get('uid')
    const { setTitle } = useCrudContext()
    const { database, setAtualOkForm } = useOkAppContext();

    // React.useEffect(() => { // problema para alguns redirecionamentos
    //     const unblock = history.block((location, action) => {
    //         if (!pristine) {
    //             if (location.pathname !== history.location.pathname) {
    //                 if (location.search !== history.location.search) {


    //                     return window.confirm("Deseja continuar e perder os dados não salvos?");
    //                 }
    //             }
    //         }
    //         return true;
    //     });

    //     return () => {
    //         unblock();
    //     };
    // }, [history, pristine]);

    useEffect(() => {
        // console.log('useEffect setAtualOkForm')
        if (!noModules) {

            setAtualOkForm(okform)
        } else {
            setAtualOkForm(null)
        }

        return () => {
            setAtualOkForm(null)
        }

    }, [okform, setAtualOkForm, noModules])

    useEffect(() => {

        // console.log('useEffect title', title)
        setTitle(title)
        return () => { setTitle("OkApp") }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (onChange) {
            onChange(atualValues);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [atualValues, onChange])

    useEffect(() => {
        let observer = null;
        if (uid) {
            if (colectionFirebase) {
                setLoadingUid(true)
                crateSnapDocumentDatabase(noUseDB ? null : database, uid, colectionFirebase,
                    (r) => {
                        initialize(r);
                        setLoadingUid(false)
                    },
                    (err) => {

                        initialize(props.initialValues);
                        setLoadingUid(false)

                    })
            }
        } else {
            initialize(props.initialValues);
        }

        return () => { if (observer) observer() }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uid])

    return (<>

        <CrudFormChildrens children={children} />

        <>
            {!noModules && <OkModulesConnect.CrudInsertComp {...props} />}
        </>
        {InsertComp && <InsertComp {...props} loadingUid={loadingUid} />}
    </>
    )
}

const mapStateToProps = (state, props) => {
    let atualValues = getFormValues(props.form)(state);

    return { atualValues };

};

CrudForm = reduxForm({})(CrudForm)



CrudForm = connect(mapStateToProps, {})(CrudForm)
export default (CrudForm)