import Page from './components/Page'
import firebaseUtil from '../../../firebaseUtil'
import { getUserUid } from 'OkApp/OkFunctions'



export const routes = () => {
    return [
        { exact: true, path: "/Login", component: Page },
    ]
}
export const menus = ({ history, okApp }) => {


    let m = []


    if (!getUserUid() && !okApp) {
        m.push({ text: "Login", iconKey: 'PermIdentity', action: () => { history.push("/Login") } })
        m.push({ text: "Solicitar licença demo", iconKey: 'Assignment', action: () => { history.push("/request-demonstration") } })
    }
    if (getUserUid() && !okApp) {
        m.push({ index: 9999999, text: "Sair", iconKey: 'ExitToApp', confirmText: "Deseja realmente sair?", action: () => { firebaseUtil.logout().then(() => { history.push("/Login") }); } })
    }


    return m;
}

