import React from 'react'
import { Card, Typography, IconButton } from '@material-ui/core'
import { useUserSnap } from 'OkApp/UseUtils';
import { ContentLoading } from 'components';
import { Delete } from '@material-ui/icons';


export default function ComentarioItem({ comment, deleteComment, ehNovo }) {
    const { dateCreate, userUid, apiName } = comment;
    let userload = useUserSnap(userUid);


    let strDate = dateCreate ? new Date(dateCreate).toLocaleDateString('pt-BR', { timeZone: 'UTC' }) : "-";

    return (<Card style={{ padding: 5, marginBottom: 15 }} variant="outlined">
        <div style={{ flex: 1 }}>
            <div style={{ display: 'flex', justifyContent: "space-between" }}>
                {/* <Typography sx={{ whiteSpace: 'pre-line' }} variant='subtitle1'>{comment.text}</Typography> */}
                <Typography variant='subtitle1'>
                    {comment.text.split("\n").map((i, key) => {
                        return <div key={key}>{i}</div>;
                    })}
                </Typography>
                {/* {ehNovo && <IconButton size='small' onClick={() => deleteComment(comment)}><Delete /></IconButton>} */}
            </div>
            <div style={{ display: 'flex', justifyContent: "space-between" }}>
                {apiName ?
                    < Typography variant='caption'>{apiName}</Typography>
                    :
                    <>
                        {userload ? <Typography variant='caption'>{userload.name}</Typography> : <ContentLoading style={{ height: 14, width: 200, margin: 0 }} />}
                    </>
                }
                <Typography variant='caption'>{strDate}</Typography>

            </div>
        </div>
    </Card>
    )
}
