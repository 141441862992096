
import React from 'react'

import OkTypedComponent from 'OkApp/components/OkTypedComponent';
import OkCompAdapter from 'OkApp/components/OkCompAdapter';
import LabelInputAuto from 'OkApp/components/LabelInputAuto';
import Input from 'components/Input';
import { useAtualValues } from 'OkApp/UseUtils';


const modelo = {
  typeComponente: "InputComp",
  field: "nome",
  label: "Nome",
  type: "text",
  variant: "standard",
  multiline: false

}
const AdapterOkComp = (props) => {

  let propsComp = {};

  return <OkCompAdapter  {...props} propsComp={propsComp} component={Input} />
}
const EditorOkComp = (props) => {
  const multiline = useAtualValues("multiline");
  let type = useAtualValues("type");
  if (!type) {
    type = "text"
  }

  return <>

    <OkTypedComponent typeComponente='InputComp' field='field' label='Nome Salvo' />
    <LabelInputAuto {...props} />
    <OkTypedComponent typeComponente='InputComp' field='helperText' label='Texto de ajuda' />
    <div style={{
      display: 'flex',

    }}>
      <OkTypedComponent typeComponente='ComboBoxComp' field='type' label='Tipo' options={['text', 'number', 'date', 'datetime-local', "time"]} toStringItem={(v) => {
        switch (v) {
          case 'text': return "Texto"
          case 'number': return "Número"
          case 'date': return "Data"
          case 'datetime-local': return "Data e Hora"
          case 'time': return "Hora"
          default: return v;
        }
      }} />

      <OkTypedComponent typeComponente='ComboBoxComp' field='variant' label='Variante' options={['standard', 'filled', 'outlined']} toStringItem={(v) => {
        switch (v) {
          case 'standard': return "Padrão"
          case 'filled': return "Preenchido"
          case 'outlined': return "Delineado"

          default: return v;
        }
      }} />





    </div>
    {type === "text" && <>
      <div style={{ display: "flex", justifyContent: 'space-between' }}>

        <OkTypedComponent typeComponente='SwitchComp' field='multiline' label='Multilinhas' />
        {multiline &&
          <OkTypedComponent typeComponente='InputComp' field='rows' label='Fixar nº de Linhas' type="number" helperText="Deixe em branco para ficar automático" />}
      </div>

      <OkTypedComponent typeComponente='InputComp' field='maskField' label='Máscara' />
      <OkTypedComponent typeComponente='SwitchComp' field='showcopybuttom' label='Exibir botão copiar' />
    </>
    }
    {type === "number" && <>
      <div style={{ display: "flex", justifyContent: 'space-between' }}>
        <OkTypedComponent typeComponente='InputComp' field='casasDecimais' label='Casas Decimais' type="number" />
      </div>
    </>
    }


    {(type === "date" || type === "datetime-local" || type === "time") && <>
      <div style={{ display: "flex", justifyContent: 'space-between' }}>
        <OkTypedComponent typeComponente='SwitchComp' field='dataInicialHoje' label='Data e hora atual como inicial' />
      </div>
    </>
    }


   

  </>
}

const InputComp = {
  typeComponente: "InputComp",
  configView: {
    grupo: "Básico",
    nome: "Campo Entrada (Texto,Número,Data)",
    descricao: "Campo de entrada de texto,numero, ou datas geral que pode ser formatado",
    helpUrl: "https://okapp.com.br/app/okappdocs/campotexto",
    modelo
  },
  Component: AdapterOkComp,
  CompEditor: EditorOkComp,
  // editorFullScreen:false,
}

export default InputComp;