import React, { useCallback, useMemo } from 'react'
import { RenderOkForm, LabelInputAuto } from 'OkApp'
import { makeStyles, Accordion, Typography, withStyles } from '@material-ui/core';
import { Paginador } from 'components';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import OkTypedComponent from 'OkApp/components/OkTypedComponent';
import html2canvas from "html2canvas";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Field } from 'redux-form';

import { OkCompFieldModuleAdapterFuncComp } from 'OkApp/modules';
import { observerOkObjList } from 'OkApp/OkFunctions';
import { useHistory } from 'react-router-dom';
import { useAtualValues, useOkApp } from 'OkApp/UseUtils';

import Style from 'style-it';

const useStyles = makeStyles(theme => ({
    painel: {
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
        },
    },

    painelNoMobile: {
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
    },
    heading: {
        fontSize: theme.typography.pxToRem(18),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    pnColapsed: {
        backgroundColor: "#f00"
    },


}));

const EditorOkComp = (props) => {
    let exibeList = useAtualValues('exibidorList');
    return <>
        <OkTypedComponent typeComponente='InputComp' field='field' label='Nome Salvo' />
        <LabelInputAuto {...props} />
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>

            <OkTypedComponent typeComponente='SwitchComp' field='vertical' label='Vertical' />
            <OkTypedComponent typeComponente='SwitchComp' field='minimizavel' label='Minimizavel' />
            <OkTypedComponent typeComponente='SwitchComp' field='fieldset' label='Fieldset' />
        </div>
        <div style={{ display: 'flex' }}>

            <OkTypedComponent typeComponente='SwitchComp' field='exibidorList' label='Exibir dados da lista no campo' />
            {exibeList && <OkTypedComponent typeComponente='InputComp' field='itemPorPagina' label='Item por Pagina' type='number' />}
        </div>



        <OkTypedComponent typeComponente='SwitchComp' field='noChangeFlexMobile' label='Não quebrar linha no Mobile' />

    </>
}
const AccordionSummary = withStyles((theme) => {
    return {
        root: {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
            borderBottom: '1px solid rgba(0, 0, 0, .125)',
            marginBottom: -1,
            minHeight: 56,
            '&$expanded': {
                minHeight: 56,
            },
        },
        content: {
            '&$expanded': {
                margin: '12px 0',
            },
        },
        expanded: {},
    }
})(MuiAccordionSummary);
const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(1),
    },
}))(MuiAccordionDetails);

const FieldList = (props) => {
    const { input: { value }, okComp } = props
    const { children, itemPorPagina } = okComp;
    let list = [];
    if (value) {
        if (value.map) {//eh array
            list = value;
        } else {
            console.log('Field do painel não eh um array', value)

        }
    }

    if (itemPorPagina) {
        return <>
            <Paginador perPage={parseInt(itemPorPagina)} list={list}>
                {propsL => {

                    const { listPage: pageList, indexStart } = propsL
                    return pageList.map(((v, i) => {
                        let prefield = (okComp.prefield ? okComp.prefield : "") + okComp.field + "." + (i + indexStart) + "."

                        return <RenderOkForm key={i} {...props} prefield={prefield} okComp={children} itemList={v} />
                    }))
                }}
            </Paginador>

        </>
    }
    return <>
        {list.map(((v, i) => {
            let prefield = (okComp.prefield ? okComp.prefield : "") + okComp.field + "." + i + "."
            return <RenderOkForm key={i} {...props} prefield={prefield} okComp={children} itemList={v} />
        }))}

    </>

}
const ExibidorChildren = (props) => {

    const { okComp, OverComp, style, styleCssOver, overCssStyleComp } = props
    const { field, children, vertical, exibidorList, noChangeFlexMobile } = okComp;
    const classes = useStyles();
    const { exibeAlertaVazio } = props

    let st = {}
    if (vertical) {
        st.flexDirection = "column"
    }
    if (style) {
        // console.log('stylo somente aplicado em produção: ' + style)
        if (!OverComp) {
            st = { ...st, ...style }
        }
    }
    let vazio = exibeAlertaVazio ? !children || !children.length : false
    let exibidorListUsar = OverComp ? false : exibidorList;
    let fieldUsar = field;
    if (exibidorListUsar) {
        if (props.prefield) {
            fieldUsar = props.prefield + field
        }
    }
    if (styleCssOver) {
        st = { ...st, ...styleCssOver }
    }


    return <>
        <Style>
            {overCssStyleComp ? overCssStyleComp : ".nada2{}"}
            <div style={st} className={noChangeFlexMobile ? classes.painelNoMobile : classes.painel}>
                {vazio && <div style={{ height: 55, width: "100%", background: "#0f0" }}>Painel sem Itens :)</div>}
                {exibidorListUsar ? <Field name={fieldUsar} okComp={{ ...okComp, prefield: props.prefield }} component={FieldList} /> :
                    <> {children && <RenderOkForm   {...props} styleCssOver={undefined} okComp={children} />}</>
                }
            </div>
        </Style>
    </>
}
function Component(props) {
    const { okComp, itemList, okform } = props
    let hystory = useHistory();
    const okApp = useOkApp();
    const observerOkObjs = useCallback(
        (keyOkForm, collectionFilter, resolve, reject, limit) => {
            const reTry = r => {
                try {
                    resolve(r);
                } catch (error) {
                    console.log('observerOkObjs erro no resolve', error)
                }
            }

            return observerOkObjList(okApp, keyOkForm, collectionFilter, reTry, reject, limit)
        },
        [okApp],
    )
    const okfunctions = useMemo(() => {

        const generateComponentImage = async (component) => {
            const canvas = await html2canvas(component);
            const image = canvas.toDataURL("image/png", 1.0);
            return image;
        }
        const redirectTo = (url, state) => {
            hystory.push(url, state);
        }
        const newTab = (url, state) => {
            window.open(url, state);
        }

        const dateToString = (data) => {
            let str = new Date(
                data.getTime() - data.getTimezoneOffset() * 60000
            ).toISOString();
            str = str.split(".")[0];

            return str.split("T")[0];

        }
        const stringToDate = (strDate) => {
            let dateAux = new Date(strDate);
            dateAux = new Date(dateAux.getTime() + dateAux.getTimezoneOffset() * 60000);
            return dateAux;
        }
        let r = {
            okform,
            okComp,
            dataUtil: { dateToString, stringToDate },
            redirectTo,
            newTab,
            observerOkObjs,
            generateComponentImage
        }


        if (itemList) {
            r.itemList = itemList
        }

        return r

    }, [okform, okComp, observerOkObjs, itemList, hystory]);

    // console.log('props aaaa', props)
    const { ...outerProps } = props // esta mandando input dos children do fomulario apenas pra reordenar


    return <OkCompFieldModuleAdapterFuncComp {...outerProps} {...outerProps.propscomp} okfunctions={okfunctions} >
        {(propsN) => {
            // console.log('propsN', propsN)
            if (!propsN) {
                return <div style={{ height: 40, width: '100%', backgroundColor: "#f00", display: 'flex', justifyContent: "center", alignItems: "center" }}>
                    Erro: Verifique o metodo adapterProps nas propriedades. Veja o log.</div>;
            }
            return < Componentv {...propsN} />
        }}
    </OkCompFieldModuleAdapterFuncComp>

}
function Componentv(props) {
    const { okComp } = props
    const { label, minimizavel, fieldset } = okComp;
    const classes = useStyles();

    if (okComp.hidden) {
        return ""
    }

    let stlFieldset = { padding: 0, marginRight: 5, marginLeft: 5, marginBottom: 5, flex: 1 }
    if (props.styleCssOver) {
        stlFieldset = { ...stlFieldset, ...props.styleCssOver }
    }
    let stlAccordion = {}
    if (props.styleCssOver) {
        stlAccordion = { ...stlAccordion, ...props.styleCssOver }
    }

    return <>
        {minimizavel ?
            <Accordion style={stlAccordion}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography className={classes.heading}>{label}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <ExibidorChildren {...props} />
                </AccordionDetails>
            </Accordion>
            :
            <>{fieldset ?
                <fieldset style={stlFieldset}>
                    <legend>{label}</legend>
                    <ExibidorChildren {...props} />
                </fieldset>
                :
                <ExibidorChildren {...props} exibeAlertaVazio />
            }

            </>

        }
    </>


}

const modelo = {
    field: "Painel",
    label: "Painel",
    typeComponente: "PanelFlexComp",
    vertical: false,
    minimizavel: false,
    fieldset: false,
    children: []
}
const comp = {
    typeComponente: "PanelFlexComp",
    configView: {
        grupo: 'Container',
        nome: 'Painel',
        descricao: 'Painel que pode receber componentes dentro',
        helpUrl: 'https://okapp.com.br/app/okappdocs/painelflexcomp',
        modelo,
    },

    Component: Component,
    CompEditor: EditorOkComp,
    editorProps: { hideModules: ["Pesquisa"] }
}



export default comp;