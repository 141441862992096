import { useState, useEffect } from 'react';

import { Box, Button, Card, CardContent, CircularProgress, Container, List, ListItem, ListItemText, useMediaQuery, useTheme, Typography } from '@material-ui/core';
import { Check } from '@material-ui/icons';

import PaymentIntentComp from 'OkApp/DinamicComponents/financial/AssinaturaStripe/components/PaymentIntentComp';

import { usePerfil } from 'useAuth';
import { updatePerfilUid } from 'dbApi';
import { formatCurrency } from 'UtilFunctions';
import firebaseUtil from 'firebaseUtil';
import useNotification from 'notification';

import { createAssinatura, getPaymentIntentPendent, useAssinaturasStripeProdutos, useConsumerStripe } from 'OkApp/StripeUtils';
import { LogTracker } from 'OkApp/OkTracker';
import { useCrudContext } from 'crud';
// import { CountdownTimer } from './components/CountDownTimer/timer';

const servCancelStripeSubscription = firebaseUtil.funcServCancelStripeSubscription()

function LinhaAssinatura({ consumerStripe, assinatura, setGeralLoadding, setLoadingInicial }) {
    const [paymentIntent, setPaymentIntent] = useState(null)
    const [loading, setLoading] = useState(false)

    const return_url = window.location.href

    const perfil = usePerfil()

    const { showError, showSuccess } = useNotification()

    const handleCancelSubscription = async () => {
        setLoading(true)
        try {
            await servCancelStripeSubscription(assinatura.id)
            showSuccess("Assinatura cancelada!")
            LogTracker("OkApp - Cancelou Intenção de assinatura Stripe", assinatura, perfil)
        } catch (error) {
            console.log("error ", error);
            showError("Humm... Ocorreu um erro. Tente novamente.")
        } finally {
            setLoading(false)
            setLoadingInicial(false)
        }
    }

    useEffect(() => {
        let observer = null
        if (assinatura && assinatura.status === "incomplete" && consumerStripe) {
            if (!paymentIntent) {
                let idLastInvoise = assinatura.latest_invoice
                getPaymentIntentPendent(null, consumerStripe.stripeId, idLastInvoise, (listPaymentIntent) => {
                    if (!paymentIntent) {
                        let listPaymentIntentIncompletas = listPaymentIntent.filter(a => a.status === "requires_payment_method")
                        if (listPaymentIntentIncompletas && listPaymentIntentIncompletas.length) {
                            setPaymentIntent(listPaymentIntentIncompletas[0])
                        }
                    }
                })
            }
        } else {
            setPaymentIntent(null)
        }
        return () => {
            if (observer) observer()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assinatura, consumerStripe?.stripeId, paymentIntent])

    if (!assinatura.status) {
        return <div>Carregando...</div>
    }
    const getStatus = (a) => {
        if (a.status === "active") return "Ativa"
        if (a.status === "canceled") return "Cancelada"
        if (a.status === "incomplete_expired") return "Pagamento Expirado"
        if (a.status === "incomplete") return "Pendente"
        return a.status
    }
    const getColor = (a) => {
        if (a.status === "active") return "#96ff96"
        if (a.status === "canceled") return "#f55b5b"
        if (a.status === "incomplete_expired") return "#f55b5b"
        if (a.status === "incomplete") return "#e5f911"
        console.log('a', a)
        return "unset"
    }

    return (
        <Box width={"100%"}>
            <Box style={{ display: "flex", flexWrap: "wrap", justifyContent: 'space-between', padding: 8, backgroundColor: getColor(assinatura), borderRadius: 8 }}>
                <Box display="flex" flexDirection="column" style={{ gap: 5 }}>
                    <Typography style={{ fontSize: 14 }}><b>Status: </b> {getStatus(assinatura)}</Typography>
                    <Typography style={{ fontSize: 14 }}><b>Criação: </b> {new Date(assinatura.created * 1000).toLocaleDateString()}</Typography>
                </Box>
                <Box display="flex" flexDirection="column" style={{ gap: 5 }}>
                    <Typography style={{ fontSize: 14 }}><b>Valor: </b>{formatCurrency((assinatura.plan.amount / 100))}</Typography>
                    <Typography style={{ fontSize: 14 }}><b>Período: </b> {new Date(assinatura.current_period_start * 1000).toLocaleDateString()} a {new Date(assinatura.current_period_end * 1000).toLocaleDateString()}</Typography>
                </Box>
            </Box>
            {paymentIntent && <PaymentIntentComp loading={loading} paymentIntent={paymentIntent} setGeralLoadding={setGeralLoadding} return_url={return_url} handleCancelSubscription={handleCancelSubscription} />}
        </Box>
    )
}

const configPlan = { product: { id: "prod_NuCNx91Zrt4Xxd" }, price: { id: 'price_1N8NyuDPdH32xk2QpTm6JnLG' } }//debug

export default function Planos() {
    const [geralLoadding, setGeralLoadding] = useState("")
    const [loading, setLoading] = useState(false)
    const { showError, showSuccess } = useNotification()
    const { setTitle } = useCrudContext()

    useEffect(() => {
        setTitle("Planos")
        return () => setTitle("")

    }, [setTitle])

    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down('md'));

    const perfil = usePerfil();

    const consumerStripe = useConsumerStripe()
    const assinaturasAtual = useAssinaturasStripeProdutos(null, perfil?.uid, configPlan.product.id);
    const assinaturaAtiva = (assinaturasAtual || []).filter(a => a.status === 'active')
    const assinaturaPendente = (assinaturasAtual || []).filter(a => a.status === 'incomplete')

    const handleCancelSubscription = async (assinatura) => {
        setLoading(true)
        try {
            await servCancelStripeSubscription(assinatura.id)
            showSuccess("Assinatura cancelada!")
            LogTracker("OkApp - Cancelou Assinatura Stripe", assinatura, perfil)
        } catch (error) {
            console.log("error ", error);
            showError("Humm... Ocorreu um erro. Tente novamente.")
        } finally {
            setLoading(false)
        }
    }

    const criarAssinatura = async () => {
        let stripeId = consumerStripe.stripeId || consumerStripe.firebaseUID
        try {
            let assinaturaObj = {
                customer: stripeId,
                items: [{ price: configPlan.price.id }],
                payment_behavior: 'default_incomplete',
                payment_settings: {
                    save_default_payment_method: 'on_subscription',
                    payment_method_types: ["card"]
                },
                expand: ['latest_invoice.payment_intent'],
            }
            await new Promise((resolve) => {
                createAssinatura(null, perfil.uid, assinaturaObj, assCreate => {
                    resolve()
                })
            })
            LogTracker("OkApp - Iniciou Assinatura Stripe", assinaturaObj, perfil)
        } catch (error) {
            console.log("error ", error);
            setLoading(false)
        }
    }

    const contratar = async () => {
        setLoading(true)
        try {
            updatePerfilUid(perfil.uid, { clicouCompra: new Date().toISOString() })
            await criarAssinatura()
        } catch (error) {
            console.log("error ", error);
        } finally {

        }
    }


    useEffect(() => {
        if (assinaturasAtual && geralLoadding === "Sincronizando dados...") {
            let assPn = assinaturasAtual.filter(a => a.status === 'incomplete')
            if (assPn.length) {
                setGeralLoadding("")
            }
        }
        return () => { }
    }, [geralLoadding, assinaturasAtual])


    return (
        // <CountdownTimer />
        <Box style={{ margin: "0 auto", paddingTop: 10 }}>
            <Container maxWidth={mobile ? "sm" : "lg"} style={{ marginBottom: 20 }} >
                {assinaturaAtiva.length > 0 &&
                    <Box width="95%" display="flex" flexDirection="column" padding={1} style={{ alignItems: "center", margin: "0 auto", background: "#A2FFB6", borderRadius: 6 }}>
                        <Typography variant='subtitle1' color='textPrimary' style={{ fontWeight: 'bold', textAlign: 'center' }}>Você já possui um plano ativo</Typography>
                        <Box>
                            <Typography variant='body2' color='textPrimary'><strong>Valor pago:</strong> {formatCurrency((assinaturaAtiva[0].plan.amount / 100))}</Typography>
                        </Box>
                        <Box display="flex" style={{ flexWrap: "wrap", gap: 10, alignItems: "center", justifyContent: 'center' }}>
                            <Typography variant='body2' color='textPrimary'><strong>Data aquisição:</strong> {new Date(assinaturaAtiva[0].current_period_start * 1000).toLocaleDateString()}</Typography>
                            <Typography variant='body2' color='textPrimary'><strong>Data vencimento:</strong> {new Date(assinaturaAtiva[0].current_period_end * 1000).toLocaleDateString()}</Typography>
                        </Box>
                    </Box>
                }
                <Box display="flex" justifyContent="center" style={{ paddingBottom: 25, paddingTop: 15 }}>
                    <Typography variant="h4" color='primary' style={{ fontFamily: "poppins", fontWeight: "revert" }} >
                        Plano Ultimate
                    </Typography>
                </Box>
                <Box display="flex" justifyContent="center">
                    <Card style={{
                        border: '3px solid #575FFF',
                        borderRadius: "8px",
                        boxShadow: "6px 5px 16px -5px rgba(0,0,0,0.58)"
                    }}>
                        <Box display="flex" flexDirection={mobile ? "column" : "row"}>
                            <CardContent
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: "center",
                                    borderRight: mobile ? "" : '1px solid lightgray',
                                    borderBottom: mobile ? '1px solid lightgray' : ""
                                }}>
                                <Typography variant="h4" gutterBottom style={{ fontWeight: 'bold', fontFamily: "poppins" }}>
                                    {formatCurrency(199)}/mês
                                </Typography>

                                <List>
                                    <ListItem style={{ display: 'flex', alignItems: 'flex-start' }}>
                                        <Check style={{ color: "green", marginRight: 8 }} />
                                        <Box style={{ width: "100%", display: 'flex', flexDirection: 'column', }}>
                                            <ListItemText primary="3 projetos de app" />
                                            <ListItemText primary="" style={{ borderBottom: "1px solid lightgray", paddingTop: 5 }} />
                                        </Box>
                                    </ListItem>
                                    <ListItem style={{ display: 'flex', alignItems: 'flex-start' }}>
                                        <Check style={{ color: "green", marginRight: 8 }} />
                                        <Box style={{ width: "100%", display: 'flex', flexDirection: 'column', }}>
                                            <ListItemText primary="Cópia dos modelos/templates" />
                                            <ListItemText primary="" style={{ borderBottom: "1px solid lightgray", paddingTop: 5 }} />
                                        </Box>
                                    </ListItem>
                                    <ListItem style={{ display: 'flex', alignItems: 'flex-start' }}>
                                        <Check style={{ color: "green", marginRight: 8 }} />
                                        <Box style={{ width: "100%", display: 'flex', flexDirection: 'column', }}>
                                            <ListItemText primary="500mb de espaço (exceto registros, compartilhado entre os projetos)" />
                                            <ListItemText primary="" style={{ borderBottom: "1px solid lightgray", paddingTop: 5 }} />
                                        </Box>
                                    </ListItem>
                                    <ListItem style={{ display: 'flex', alignItems: 'flex-start' }}>
                                        <Check style={{ color: "green", marginRight: 8 }} />
                                        <Box style={{ width: "100%", display: 'flex', flexDirection: 'column', }}>
                                            <ListItemText primary="Até 50.000 requisições/mês (operações e gravação e leitura)" />
                                            <ListItemText primary="" style={{ borderBottom: "1px solid lightgray", paddingTop: 5 }} />
                                        </Box>
                                    </ListItem>
                                    <ListItem style={{ display: 'flex', alignItems: 'flex-start' }}>
                                        <Check style={{ color: "green", marginRight: 8 }} />
                                        <Box style={{ width: "100%", display: 'flex', flexDirection: 'column', }}>
                                            <ListItemText primary="Acesso a todos os nossos cursos lançados (com certificado) *" />
                                            <ListItemText primary="" style={{ borderBottom: "1px solid lightgray", paddingTop: 5 }} />
                                        </Box>
                                    </ListItem>
                                    <ListItem style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        {/* <ListItemText primary="Acesso a todos os nossos cursos lançados (com certificado)*" /> */}
                                        <Typography variant='subtitle2' style={{ textAlign: "center", fontSize: 13 }}>* Para assinantes com as mensalidades em dia</Typography>
                                    </ListItem>
                                </List>
                                <Box display="flex" justifyContent="center" width={250} style={{ marginBottom: 20 }}>
                                    {assinaturaAtiva.length > 0 ?
                                        <Button
                                            fullWidth
                                            variant="contained"
                                            onClick={() => handleCancelSubscription(assinaturaAtiva[0])}
                                            style={{ background: loading ? "#E0E0E0" : "#f44336", color: "white" }}
                                            disabled={loading}
                                        >
                                            {loading ?
                                                <CircularProgress size={24} />
                                                :
                                                "Cancelar"
                                            }
                                        </Button>
                                        :
                                        <Button
                                            fullWidth
                                            variant="contained"
                                            color="primary"
                                            onClick={contratar}
                                            disabled={loading || (assinaturaPendente || []).length > 0}
                                        >
                                            {loading ?
                                                <CircularProgress size={24} />
                                                :
                                                "Contratar"
                                            }
                                        </Button>
                                    }
                                </Box>


                            </CardContent>
                            {assinaturaPendente.length > 0 &&
                                <Box display="flex" alignItems="center" flexDirection="column" padding={2}>
                                    <Typography style={{ fontSize: 14, textAlign: 'center', width: "80%" }}>
                                        Você tem uma solicitação em andamento, confirme ou cancele para criar uma nova
                                    </Typography>
                                    {(assinaturaPendente || []).map((a, i) => <LinhaAssinatura key={i} perfil={perfil} setLoadingInicial={setLoading} consumerStripe={consumerStripe} assinatura={a} geralLoadding={geralLoadding} setGeralLoadding={setGeralLoadding} />)}
                                </Box>
                            }
                        </Box>
                    </Card>
                </Box>
            </Container >
        </Box >
    )
}
