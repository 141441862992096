import React from 'react'
import { getPermiteEditarOkForm, } from 'OkApp/OkFunctions';
import RenderOkForm from '../RenderOkForm';


export default function RotaRender(props) {
    const { customForm } = props
    let permiteEditar = getPermiteEditarOkForm(customForm);
    // console.log('RotaRender', customForm.route, props)

    if (!customForm) {
        <div>loading Route</div>
    }


    return (<RenderOkForm okform={customForm} starter={true} permiteEditar={permiteEditar} />)
}
