import React from 'react'
import ItemMenu from '../ItemMenu'
import { useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ListItem from '@material-ui/core/ListItem';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ViewQuiltIcon from '@material-ui/icons/ViewQuilt';
import CompI18n from 'i182';


import LogoOkapp from 'resources/image/logo-horizontal.png'
import { Avatar, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useAtualOkForm } from 'OkApp/UseUtils';
import { isAuthenticated } from 'useAuth';
import { permiteEditar } from 'OkApp/modules/PermissionMeta/functions';
import { useOkAppContext } from 'OkApp/context';

export default function ContainerMenu({ menus, classes, open, handleDrawerClose }) {
    const { okApp, setOkApp } = useOkAppContext();
    const atualOkForm = useAtualOkForm();

    const history = useHistory();
    const theme = useTheme();


    let listDefault = menus.filter((m) => !m.type)
    let listOkApp = menus.filter((m) => m.type === 'okapp')

    let scr = '';

    if (okApp && okApp.logo) {
        scr = `data:image/png;base64,${okApp.logo}`;
    }

    let permiteEditarForm = false;

    if (okApp && isAuthenticated() && permiteEditar(okApp)) {
        permiteEditarForm = true;
    }

    return (<Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
            paper: classes.drawerPaper,
        }}
    >
        <div className={classes.drawerHeader} style={{ justifyContent: !okApp ? 'flex-end' : 'space-between' }}>
            {okApp && <div className={classes.aboutApp} onClick={() => {
                // console.log('Vai para a tela de sobre')
                // history.push('/About', okapp)
                history.push('/app/' + okApp.route)
            }}>
                {scr &&
                    <Avatar variant={'rounded'} alt={okApp.name} style={{ margin: 10, height: 30, width: 30 }} src={scr} />}
                <Typography variant="h6" noWrap style={{ flex: 1, color: theme.palette.secondary.contrastText, fontSize: 14 }} >
                    {okApp.name}
                </Typography>

            </div>}

            <IconButton onClick={handleDrawerClose}>
                {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
        </div>
        <Divider />
        <List>
            {listDefault.map((menu, index) => <ItemMenu key={index} menu={menu} index={index} handleDrawerClose={handleDrawerClose} />)}
        </List>
        <Divider />
        <CompI18n />
        <div style={{ display: "flex", flex: '1' }}></div>
        {permiteEditarForm &&
            <>
                <Divider />
                {atualOkForm &&
                    <>
                        <ListItem button style={{ backgroundColor: "#ae1ebb" }} onClick={() => {
                            history.push("CustomForm?uid=" + atualOkForm.uid)
                            handleDrawerClose()
                        }}
                        >
                            <ListItemIcon >
                                <ViewQuiltIcon />
                            </ListItemIcon>
                            <ListItemText
                                disableTypography
                                primary={<Typography  ><span>Editar OkForm Atual</span></Typography>}
                            />


                        </ListItem>
                        <Divider />
                    </>
                }

                <List>
                    {listOkApp.map((menu, index) => <ItemMenu key={index} menu={menu} index={index} handleDrawerClose={handleDrawerClose} />)}
                </List>

            </>
        }
        <Divider />
        <ListItem button onClick={() => {
            history.push("/")
            setOkApp(null)
            handleDrawerClose()
        }}
            style={{ display: "flex", justifyContent: "space-around" }} >
            <Typography variant={'caption'}>powered by</Typography>

            <img alt="logoOkapp" style={{ height: 32 }} src={LogoOkapp} />
        </ListItem>
        <Typography style={{alignSelf: "center", fontSize: 11 }}>v.{process.env.REACT_APP_VERSION}</Typography>

    </Drawer >)
}


