
import { criaFuncPraExecutar, useAtualValues, useReduxFormProps } from 'OkApp/UseUtils';
import React, { useEffect, useState } from 'react'
import ReactPlayer from 'react-player'
import { getBlobOkFileControl } from './OkLocalFiles';
import "./style.css"
export default function CompView(props) {

    const { input: { value }, functionsmap,  useCache, type, okfunctions, autoPlay } = props

    const [srcUsar, setSrcUsar] = useState(null)

    const formProps = useReduxFormProps();
    const atualValues = useAtualValues();

    useEffect(() => {
        switch (type) {
            case 'url':
                setSrcUsar(value)
                break;
            case 'anexo':
                //console.log('value', value)
                if (value && value.storagePath) {
                    if (useCache && window.cordova) {
                        console.log('MOBILE comCache')
                        setSrcUsar(value.downloadURL)
                        getBlobOkFileControl(value.storagePath, value.type, value.downloadURL, (updateNote) => {
                            console.log('updateNote', updateNote)
                        }).then((nativeurl) => {
                            setSrcUsar(nativeurl)
                        })
                    } else {
                        setSrcUsar(value.downloadURL)
                    }
                }
                break;

            default:
                break;
        }

        return () => {
            setSrcUsar(null)
        }
    }, [type, useCache, value])

    let fconEnded = criaFuncPraExecutar(functionsmap, 'onEnded', { ...okfunctions, formProps, atualValues })

    const onEnded = () => {
        if (fconEnded) {
            fconEnded()
        }
    }
    return (
        <div className='PlayerVideoCompDiv'>
            <ReactPlayer
                width="100%"
                height="100%"
                playing={autoPlay}
                url={srcUsar}
                controls={true}
                className="PlayerVideoCompPlayer"
                onEnded={onEnded}
                config={{
                    file: {
                        attributes: {
                            controlsList: 'nodownload'
                        }
                    }
                }}
                onProgress={p => {
                    //console.log('onProgress', p)
                }}
            />

        </div>
    )
}
