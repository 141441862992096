import React, { useEffect, useState } from 'react'
import { useAtualValues, useCreateFunctionFromFieldCodeScope, useReduxFormProps } from 'OkApp/UseUtils'
import _ from 'lodash'
import { useOkAppContext } from 'OkApp/context';
import { getOkObjListSnapDatabase } from 'OkApp/OkFunctions';
import { Button } from '@material-ui/core';
import QueryFactory from 'OkApp/QueryFactory';

const doBusca = (okApp, database, uidOkForm, funcFilter, resolve, reject, limite) => {
    //  let colCustomForm = 'Project/' + okApp.uid + '/ValuesCustomForm/' + uidOkForm + "/Values";



    return getOkObjListSnapDatabase(okApp, database, uidOkForm, funcFilter, resolve, reject, limite)
}
export default function CompView(props) {

    const { input: { value, onChange }, okfunctions, field, label, developer, } = props
    const { auxOkForm, limitResultados, collectionQuery, fieldsFilter, msgLimite, type, textBotao } = props//okcomp
    const [limit, setLimit] = useState(null);
    const [filters, setFilters] = useState({})
    const { okApp, database } = useOkAppContext();
    const uidOkForm = auxOkForm ? auxOkForm.uid : null;
    const atualValues = useAtualValues();
    const formProps = useReduxFormProps();
    const colQuerryFunc = useCreateFunctionFromFieldCodeScope(collectionQuery, 'collectionQuerry', { ...okfunctions, atualValues, formProps });
    const funcAjustFilterList = useCreateFunctionFromFieldCodeScope(collectionQuery, 'ajustFilterList', { ...okfunctions, atualValues, formProps });



    useEffect(() => {
        if (atualValues) {
            let filterNew = {}
            // let filterNew = { ...atualValues };
            // delete filterNew[field]

            if (fieldsFilter) {
                filterNew = {};
                fieldsFilter.forEach(f => {
                    filterNew[f] = atualValues[f]
                });
            }


            if (!_.isEqual(filterNew, filters)) {
                setFilters(filterNew)
            }
        }
    }, [atualValues, field, fieldsFilter, filters])

    useEffect(() => {

        let observer = null
        if (uidOkForm && colQuerryFunc && type !== 'onClick') {
            if (type === 'getAll') {
                observer = doBusca(okApp, database, uidOkForm, null,
                    (r) => {
                        onChange(r)
                    }, (err) => {
                        console.log('err', err)
                        onChange([])
                    }, limit
                )
            }
            if (!type || type === 'onChange') {
                const funcFilter = (col) => {                   
                    let qF = new QueryFactory(col)
                    qF = colQuerryFunc(qF, filters)
                    return qF.getResultCol();
                }

                observer = doBusca(okApp, database, uidOkForm, funcFilter,
                    (r) => {
                        if (funcAjustFilterList) {
                            onChange(funcAjustFilterList(r, filters))
                        } else {

                            onChange(r)
                        }
                    }, (err) => {
                        console.log('err', err)
                        onChange([])
                    }, limit
                )
            }
            if (type === 'onClick') {

            }

        }

        return () => {
            if (observer) observer()
        }
    }, [colQuerryFunc, database, filters, funcAjustFilterList, limit, okApp, onChange, type, uidOkForm])

    useEffect(() => {
        if (limitResultados) {
            setLimit(parseInt(limitResultados))
        } else {
            setLimit(null)
        }

    }, [limitResultados])

    const pesquisaPorClick = () => {
        console.log('pesquisaPorClick')
        const funcFilter = (col) => {
            col = colQuerryFunc(col, atualValues)
            return col;
        }
        let observer = doBusca(okApp, database, uidOkForm, funcFilter,
            (r) => {

                if (funcAjustFilterList) {
                    onChange(funcAjustFilterList(r, filters))
                } else {

                    onChange(r)
                }
                observer();
            }, (err) => {
                console.log('err', err)
                onChange([])
                observer();
            }, limit
        )
    }
    let showAuxDeveloper = type !== 'onClick' && developer
    return (<>
        {type === 'onClick' && <Button variant='contained' color='primary' onClick={pesquisaPorClick}>{textBotao || "Pesquisar"}</Button>}
        {showAuxDeveloper && <div style={{ height: 40, width: '100%', backgroundColor: "#ff0", display: 'flex', justifyContent: "center", alignItems: "center" }}>FILTRO Painel EM ELABORAÇÃO: {label}</div>}
        {(value && limit === value.length) && <div>{msgLimite || "Filtre a consulta. Atingiu o limite de registros na consulta:"} {limit} </div>}

    </>
    )
}