import React, { useState, useRef } from 'react'
import produce from "immer"
import './style.css'
import { Tooltip } from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';

import UpIcon from '@material-ui/icons/ArrowDropUp';
import DownIcon from '@material-ui/icons/ArrowDropDown';

import MoreOptions from './MoreOptions'
import { DialogAddEditComp } from 'OkApp';
import Measure from 'react-measure'

export default function OverComp(props) {


    const { okform, children, okComp, editComponent, moveUp, moveDown, styleCssOver } = props

    const [dimensions, setDimensions] = useState(null) 
    const [over, setOver] = useState(false)
    const refAddCompDialog = useRef(null);

    const salvarEdicao = (v) => {
        editComponent(v)
    };
    const addFilho = (v) => {
        let copy = { ...okComp, children: v }
        editComponent(copy)
    };

    const updateComp = (c) => {
        const nextState = produce(okComp.children || [], draftState => {
            let indexOf = draftState.map(c => c.uid ? c.uid : c.field).indexOf(c.uid);
            if (indexOf === -1) {
                draftState.push(c);
            } else {
                draftState[indexOf] = c;
            }

        })

        addFilho(nextState)
    }
    const strComp = okComp.label + " - " + (okComp.field ? okComp.field : "Sem Field") + " - " + okComp.typeComponente;    
    let styleUsar = styleCssOver || {}
    return (
        <>
            <Measure
                bounds
                onResize={contentRect => {
                    setDimensions(contentRect.bounds)
                }}
            >
                {({ measureRef }) => (

                    <div ref={measureRef} className={over ? okComp.children ? 'overContainerChildren' : "overContainer" : "notOverContainer"}
                        style={styleUsar}
                        onMouseEnter={() => setOver(true)} onMouseLeave={() => setOver(false)}>
                        {children}
                        {over && <span className={okComp.children ? okComp.children.length ? 'overActionsParentComFilhos' : 'overActionsParent' : "overActions"}>

                            {okComp.children && <Tooltip title={<div>Adicionar Componente<br />{strComp}</div>}>
                                <AddIcon className="actionIconOver" onClick={() => {
                                    refAddCompDialog.current.showAddComp();
                                    // setAdd(true)

                                }} /></Tooltip>}
                            <Tooltip title={<div>Editar Componente<br />{strComp}</div>}>
                                <EditIcon className="actionIconOver" onClick={() => refAddCompDialog.current.showEditarComp(okComp)} />
                            </Tooltip>

                            {(dimensions && dimensions.width > 100) ?
                                <>
                                    <Tooltip title={<div>Mover para cima<br />{strComp}</div>}>
                                        <UpIcon className="actionIconOver" onClick={() => moveUp(okComp)} />
                                    </Tooltip>
                                    <Tooltip title={<div>Mover para baixo<br />{strComp}</div>}>
                                        <DownIcon className="actionIconOver" onClick={() => moveDown(okComp)} />
                                    </Tooltip>
                                </>
                                : ""}
                            <MoreOptions {...props} strComp={strComp} />

                        </span >}
                    </div>
                )}
            </Measure>
            <DialogAddEditComp ref={refAddCompDialog}
                okform={okform}
                updateComp={updateComp}
                editComp={salvarEdicao}
            />
        </>
    )
}
