import React, { useCallback, useEffect, useState } from 'react'
import { useCrudContext } from 'crud';
import useNotification from 'notification';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { salvarObj, deleteObj } from 'dbApi';
import { getAtualValuesDoForm } from 'OkApp/functions';
import { Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Button } from '@material-ui/core';
import { usePerfil } from 'useAuth';
import { LogTracker } from 'OkApp/OkTracker';

export default function CrudControlForm(props) {
    const { pristine, initialize, handleSubmit, submitting, initialValues, colectionFirebase, atualValues, setOpenPesquisa } = props;

    const uid = atualValues ? atualValues.uid : null;


    const { addActions, removeActions } = useCrudContext();
    const { showError, showSuccess } = useNotification();
    const perfil = usePerfil()
    const { t } = useTranslation();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const history = useHistory();
    React.useEffect(() => {
        const unblock = history.block((location, action) => {
            if (!pristine) {
                if (location.pathname !== history.location.pathname) {
                    if (location.search !== history.location.search) {


                        return window.confirm("Deseja continuar e perder os dados não salvos?");
                    }
                }
            }
            return true;
        });

        return () => {
            unblock();
        };
    }, [history, pristine]);

    const [open, setOpen] = React.useState(false);
    const [textoConfirm, setTextoConfirm] = useState("")
    const txtConfimDelete = "Deletar";


    const handleClose = () => {
        setOpen(false);
        setTextoConfirm("")
    };


    const actionNovo = useCallback(() => {
        history.push(history.location.pathname);
        initialize(initialValues);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history, initialValues])


    const acaoDeletar = () => {

        let objDeletar = atualValues;


        console.log('acaoDeletar', objDeletar)
        setTextoConfirm("")
        if (colectionFirebase) {
            let objFormPermission = { uid: objDeletar.uid }

            let strCollectionRota = colectionFirebase + 'Permission'
            deleteObj(objDeletar, colectionFirebase,
                (r) => {
                    console.log("CRUD - Deletado com sucesso", r.msg);
                    showSuccess(r.msg)
                    history.push(history.location.pathname);
                    initialize(initialValues);
                },
                (err) => {
                    console.log("CRUD - Deletado error", err);
                    showError(err.message)
                }
                , [{
                    obj: objFormPermission, strCollection: strCollectionRota
                }]
            )
        } else {
            showError('Sem collection para excluir')
        }
    }


    // eslint-disable-next-line react-hooks/exhaustive-deps
    const actionSave = useCallback(handleSubmit((obj) => {
        if (!obj.name) {

            var backspaceEvnt = new KeyboardEvent('keydown', { altKey: true, code: 'KeyC' });

            window.dispatchEvent(backspaceEvnt);



            showError('Um nome deve ser informado para o formulário')

            return
        }

        let nameFormDadosInitial = "CustomForm-Route-Custonormemdesenvolvimento"
        if (obj.guardarValorComoInicial) {
            let initialValuesEita2 = getAtualValuesDoForm(nameFormDadosInitial);
            console.log('initialValuesEita2', initialValuesEita2)
            obj.initialValues = initialValuesEita2;
        } else {
            obj.initialValues = null;
        }
        if (colectionFirebase) {
            let objFormPermission = { uid: obj.uid, name: obj.name, route: obj.route }
            if (obj.meta) {
                objFormPermission.meta = obj.meta
            }
            if (obj.metaOkApp) {
                objFormPermission.metaOkApp = obj.metaOkApp
            }

            let strCollectionRota = colectionFirebase + 'Permission'
            salvarObj(obj, colectionFirebase,
                (r) => {
                    let hasSearch = obj.children.filter((item) => item.typeComponente === "FiltroPainelComp")

                    if (!queryParams.has('uid')) {
                        if (hasSearch && hasSearch.length > 0) {
                            LogTracker("OkApp - criou novo formulário de consulta", { formUid: obj.uid, formName: obj.name, formRoute: obj.route }, perfil)
                        } else {
                            LogTracker("OkApp - criou novo formulário simples", { formUid: obj.uid, formName: obj.name, formRoute: obj.route }, perfil)
                        }
                    }

                    console.log("CRUD - Sucesso Salvou: ", r.msg);

                    showSuccess(r.msg)

                    if (obj.uid) {
                        history.push("CustomForm?uid=" + obj.uid)
                    }
                },
                (err) => {
                    console.log("CRUD - error", err);
                    // showError(err.message)
                }
                , [{
                    obj: objFormPermission, strCollection: strCollectionRota
                }]
            )
        } else {
            showError('Sem collection para salvar')
        }
    }), [colectionFirebase, handleSubmit, showError, showSuccess])

    useEffect(() => {
        let add = [];
        add.push({ uid: 'crud.actions.delete', text: t('crud.actions.delete'), icon: null, action: () => setOpen(true), props: { style: { backgroundColor: "#f00" }, hidden: (pristine ? !uid : false) }, disabled: submitting })
        add.push({ uid: 'crud.actions.new', text: t('crud.actions.new'), icon: null, action: actionNovo, props: { hidden: (pristine ? !uid : false) }, disabled: submitting })
        add.push({ uid: 'crud.actions.save', text: t('crud.actions.save'), icon: null, action: actionSave, props: { disabled: (pristine || submitting) } })
        add.push({ uid: 'crud.action.pesquisa', index: 30, text: "Pesquisar", icon: null, action: () => setOpenPesquisa(true) })

        addActions(add)
        return () => { removeActions(['crud.actions.delete', 'crud.actions.new', 'crud.actions.save', 'crud.action.pesquisa']); };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pristine, submitting, t, uid])
    return (
        <>

            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Excluir Registros</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Esta ação excluirá permanentemente esse registro. O registro removido NÃO PODE ser restaurado! Tem certeza ABSOLUTA?
                        <br />
                        <br />
                        Esta ação pode levar à perda de dados. Para evitar ações acidentais, solicitamos que você confirme sua intenção.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        value={textoConfirm}
                        label={'Digite "' + txtConfimDelete + '" para confirmar a exclusão'}

                        onChange={(event) => {
                            setTextoConfirm(event.target.value);
                        }}

                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary">
                        Cancelar
                    </Button>
                    <Button variant='contained'
                        onClick={() => {
                            handleClose()
                            acaoDeletar();
                        }}
                        color="primary"
                        disabled={txtConfimDelete.toUpperCase() !== textoConfirm.toUpperCase()}
                    >
                        Excluir Registro
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
