import React from 'react'
import OkCompAdapter from 'OkApp/components/OkCompAdapter'
import CompView from './components/CompView'

const typeComponente = 'ListaItens' //deve ter o mesmo nome de exportação no index

const AdapterOkComp = (props) => {
    // const { okComp } = props
    let propsComp = { }
    return <OkCompAdapter {...props} propsComp={propsComp} component={CompView} />
}

const modelo = {
    typeComponente,
    field: 'compras',
    label: 'Lista de Compras',
    adicionavel:true,
    removivel:true


}

const ListaItens = {
    typeComponente,
    configView: {
        grupo: 'Básico',
        nome: 'Lista de Textos',
        descricao: 'Lista de Textos que podem ser selecionados',
        helpUrl: 'https://okapp.com.br/app/okappdocs/listaitens1',
        modelo,
        permitemover:false,
        editors: {
            possiveis: { typeComponente: "ListaItens", label: "Possiveis Inicial", props: {} },
        }
    },
    Component: AdapterOkComp
}

export default ListaItens;