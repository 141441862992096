import React, { useEffect, useMemo, useState } from 'react'
import OkCompAdapter from 'OkApp/components/OkCompAdapter'
import { OkTypedComponent, LabelInputAuto } from 'OkApp'
import { criaFuncPraExecutar, useAtualValues, useReduxFormProps } from 'OkApp/UseUtils'

const typeComponente = 'ObserverFunction'


const ObserverFunction = (props) => {
    const [stateValue, setStateValue] = useState(null)
    const { developer, label, input: { value, field }, okfunctions, funcObserver, field: fieldComp } = props
    const formProps = useReduxFormProps();
    const atualValues = useAtualValues();


    // let valuesArray = null
    // if (!field && fieldComp.includes(',')) {
    //     let splitField = fieldComp.split(',');
    //     valuesArray = [];
    //     splitField.forEach((f) => {
    //         valuesArray.push(atualValues[f])
    //     })
    // }
    const valuesArray = useMemo(() => {
        if (!field && fieldComp.includes(',')) {
            let valuesArray = [];
            let splitField = fieldComp.split(',');

            splitField.forEach((f) => {
                valuesArray.push(atualValues[f])
            })
            return valuesArray
        }
    }, [atualValues, field, fieldComp])
    useEffect(() => {
        let funcOnChangeValue = criaFuncPraExecutar(funcObserver, 'onChangeValue', { ...okfunctions, formProps, atualValues })
        if (funcOnChangeValue) {
            try {
                if (valuesArray) {
                    if (!stateValue || JSON.stringify(stateValue) !== JSON.stringify(valuesArray)) {
                        setStateValue(valuesArray)
                        funcOnChangeValue(...valuesArray)
                    }
                } else {
                    funcOnChangeValue(value)
                }
            } catch (error) {
                console.log('ERRO FUNC OBSERVER', error)
            }
        }


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [funcObserver, okfunctions, value, valuesArray])
    return <>
        {developer &&
            <div style={{ height: 40, width: '100%', backgroundColor: "#f0f", flex: 0, display: 'flex', justifyContent: "center", alignItems: "center" }}>
                ObserverFunction: {label}
            </div>
        }
    </>
}


const AdapterOkComp = (props) => {
    let propsComp = { developer: props.developer }
    return <OkCompAdapter {...props}  styleCssOver={{ flex: 0 }} propsComp={propsComp} component={ObserverFunction} />
}


const startFunc = `function onChangeValue(value) {
    // console.log('value',value);
    // console.log('this',this);
}
`
const EditorOkComp = (props) => {

    return <div style={{ display: 'flex', flexDirection: 'column', height: "100%" }}>
        <div style={{ display: 'flex' }}>
            <OkTypedComponent typeComponente='InputComp' field='field' label='Campo Observer' />
            <LabelInputAuto {...props} />
        </div>
        <OkTypedComponent typeComponente='FunctionsEditor' field='funcObserver' label='Funções observer'

            startFunc={startFunc}
        />

    </div>
}

const modelo = {
    typeComponente,
    field: 'propObservada',
    label: 'Observador',
}


const ObserverFunctionComp = {
    typeComponente,
    configView: {
        grupo: 'Avançado',
        nome: 'Observador de alteração',
        descricao: 'Executa a função programada quando o valor da propriedade observada mudar.()',
        helpUrl: 'https://www.google.com.br/',
        notView: true,
        modelo
    },
    Component: AdapterOkComp,
    CompEditor: EditorOkComp
}
export default ObserverFunctionComp
