import React from 'react'
import OkCompAdapter from 'OkApp/components/OkCompAdapter'
import ReactRender from './components/ReactRender';
import Editor from './components/Editor';

const AdapterOkComp = (props) => {

  const updateCode = (code) => {
    props.editComponent({ uid: props.okComp.uid, code: code })
  }
  let propsComp = { developer: props.developer, updateCode }

  return <OkCompAdapter {...props} propsComp={propsComp} noStyleStart={true} component={ReactRender} />
}




const modelo = {
  typeComponente: "ReactRenderComp",
  field: "codeReact",
  label: "codeReact",
  noInline: true,
  showCode: true,
  // code: `render (<div dangerouslySetInnerHTML={{ __html: value||'Exemplo: exibe valor do campo como html' }} />)`
  code: `        const Wrapper = ({ children }) => (
        <div
          style={{
            background: "papayawhip",
            width: "100%",
            padding: "2rem",
          }}
        >
          {children}
          <div dangerouslySetInnerHTML={{ __html: value||'Exemplo: exibe valor do campo como html' }} />
        </div>
      );
      
      const Title = () => (
        <h3 style={{ color: "palevioletred" }}>Hello World!</h3>
      );
      
      render(
        <Wrapper>
          <Title />
        </Wrapper>
      );`

}

const ReactRenderComp = {
  typeComponente: "ReactRenderComp",
  legado: true,
  configView: {
    grupo: 'Avançado',
    nome: 'Componente React',
    descricao: 'Componente React',
    helpUrl: 'https://www.google.com.br/',
    modelo,
  },
  Component: AdapterOkComp,
  CompEditor: Editor

}

export default ReactRenderComp;