import React from 'react'

import useCriaControlerField from '../../functionsUtil'

import _ from 'lodash';
import { getValueToPdfFunc2Comp } from '..';
import { Combobox, Input, SeletorDePath } from 'components';

function CondicionalReportComp({ value, onChange, valueRef, removeLinha }) {
    const criaControler = useCriaControlerField(value, onChange)

    return (
        <div>
            <div style={{ paddingBottom: 10 }}>
                <SeletorDePath {...criaControler("chave")} label='Chave/Valor/Caminho' data={valueRef} />
            </div>
            <div style={{ flex: 1, display: 'flex' }}>

                <Combobox style={{ flex: 1, paddingLeft: 5, paddingRight: 5 }} {...criaControler("tipoCompara")} label="Tipo Valor" itens={['exist', 'equals', "different"]} toStringItem={['Tem Valor', 'Igual a', "Diferente de"]} />
                <div style={{ flex: 1, }}>
                    <Input label='Valor Comparar' {...criaControler("valorRef")} />

                </div>
            </div>
        </div>

    )
}
async function convertToPdf(compConfig, json, meta) {
    let v = _.get(json, compConfig.chave)
    if (compConfig.tipoCompara === "equals") {
        // eslint-disable-next-line eqeqeq
        if (v != compConfig.valorRef) {//assim mesmo, pra compara raso
            return null;
        }
    } else
        if (compConfig.tipoCompara === "different") {
            // eslint-disable-next-line eqeqeq
            if (v == compConfig.valorRef) {//assim mesmo, pra compara raso
                return null;
            }

        } else
            if (!v) {
                return null;
            }


    let linhas = []

    for (let iii = 0; iii < compConfig.lista.length; iii++) {
        const c = compConfig.lista[iii];
        linhas.push([await getValueToPdfFunc2Comp(c, json, meta)])
    }


    let defaultConfig = {    
        margin: [0,-2],    
        layout: 'noBorders',
        table: {
            body: linhas,

            widths: ["*"]
        }
    };


    return defaultConfig



}


const CondicionalReport = {
    typeComponent: "CondicionalReport",
    modelo: {
        nome: "Condição",
        typeComponent: "CondicionalReport",
        orientacaoPainel: 'vertical',
        lista: []
    },
    Component: CondicionalReportComp,
    convertToPdfFunc: convertToPdf,


}


export default CondicionalReport
