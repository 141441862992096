import { Button } from '@material-ui/core'
import React from 'react'


export default function PageSemPermissaoApp() {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

            <h1>Você não possui permissão para acessar este aplicativo. :(</h1>
            <div>

                <Button variant='contained' color='primary' onClick={() => window.open('.')} >
                    Ir para Início
                </Button>
            </div>
        </div>
    )
}
