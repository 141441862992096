import React, { useEffect } from 'react'
import './style.css'
import Menu from '../menu'

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import Style from 'style-it'
import { useOkAppContext } from 'OkApp/context';

import AppBarTop from './AppBarTop';
import RoutesMap from './RoutesMap';
import { useMemo } from 'react';
import { useCrudContext } from 'crud';



const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarLogo: {
        flex: 1,
        paddingTop: 3,
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,

    },
    drawerPaper: {
        width: drawerWidth,
        backgroundColor: theme.palette.secondary.main,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: 'space-between',
    },
    aboutApp: {
        display: 'flex',
        alignItems: 'inherit',
        cursor: 'pointer',
        maxWidth: 180,

    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        width: "100%",
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,

    },
}));



export default function MuiTheme(props) {


    const { menus, routes } = props
    const menususar = useMemo(() => menus, [menus]);

    const classes = useStyles();

    const [open, setOpen] = React.useState(false);
    const { okApp } = useOkAppContext();
    const { visibilityTopBar } = useCrudContext();

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };
    let styleApp = '.nada{}'//prescisa de algo pra nao bugar o layout
    if (okApp && okApp.globalStyle) {
        styleApp = okApp.globalStyle
    }
    useEffect(() => {
        handleDrawerClose()

    }, [okApp])
    return (
        <Style>
            {styleApp}


            < div className="ContainerMenuRoot">
                <AppBarTop classes={classes} handleDrawerOpen={handleDrawerOpen} open={open} />

                <Menu menus={menususar} classes={classes} open={open} handleDrawerClose={handleDrawerClose} />

                <main
                    className={clsx(classes.content, {
                        [classes.contentShift]: open,
                    })}

                >

                    <div className={classes.drawerHeader} style={{
                        display: visibilityTopBar ? 'flex' : "none",
                    }} />
                    <RoutesMap routes={routes} />

                </main>
            </div >


        </Style >
    )



}
