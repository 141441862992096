import React from 'react'
import OkCompAdapter from 'OkApp/components/OkCompAdapter'

import Editor from './components/Editor'
import CompView from './components/CompView'
import _ from 'lodash'
import { getOkCompCore } from 'OkApp/functions'

const typeComponente = 'TabelaOkComp' //deve ter o mesmo nome de exportação no index

const AdapterOkComp = (props) => {
    let propsComp = {}

    return <OkCompAdapter {...props} propsComp={propsComp} component={CompView} />
}

const modelo = {
    typeComponente,
    field: 'lista',
    label: 'Tabela',
    exibeLabel: true,
    exibeAdd: true,
    msgSemRegistro: "Sem Registros",

}


const TabelaOkComp = {
    typeComponente,
    configView: {
        grupo: 'Básico',
        nome: 'Tabela Com Componentes',
        descricao: 'Tabela Com Componentes',
        helpUrl: 'https://okapp.com.br/app/okappdocs/tabelacomponentes',
        modelo,
    },
    Component: AdapterOkComp,
    CompEditor: Editor,
    editorFullScreen:true,
    beforeSave: async (obj, okComp) => {
        let tabelaValores = _.get(obj, okComp.field);
        if (tabelaValores) {
            for (let index = 0; index < tabelaValores.length; index++) {
                for (let i2 = 0; i2 < okComp.colunas.length; i2++) {
                    const col = okComp.colunas[i2];
                    let confComp = getOkCompCore(col.typeComponente)
                    if (confComp.beforeSave) {
                        tabelaValores[index] = await confComp.beforeSave(tabelaValores[index], col)
                    }
                }
            }

        }


        return obj;
    }
}

export default TabelaOkComp

