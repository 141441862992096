import React from 'react'
import { Typography, Button } from '@material-ui/core';
import Kamban from './Kamban';
import produce from "immer"
import { useOkApp, useOkForm } from 'OkApp/UseUtils';
import { saveOkObj } from 'OkApp/OkFunctions';
import { useHistory } from 'react-router-dom';
import { ajusteRoute } from 'OkApp/context';
import _ from 'lodash'
import { RenderOkForm } from 'OkApp';

export default function CompView(props) {
    const { input: { value, onChange }, field, fieldKamban, tipoView, colunas, fieldsShow, auxOkForm, colNull, showBtVer, newTab, showQt, painelCard } = props;
    const okApp = useOkApp();
    const history = useHistory();
    const okForm = useOkForm(auxOkForm ? auxOkForm.uid : null);
    const getPreField = (item) => {
        let index = 0
        for (let i = 0; i < value.length; i++) {
            const element = value[i];
            if (element.uid === item.uid) {
                index = i;
            }
        }

        let prefield = field + "." + index + "."
        return prefield;
    }
    const onDrag = (item, colPara, indexPara, colDe, indexDe) => {
        const nextState = produce(value || [], draftState => {
            let indexOf = draftState.map(l => l.uid).indexOf(item.uid);
            let itemUp = draftState[indexOf];
            itemUp[fieldKamban] = colPara;
            itemUp.indexKanban = indexPara;
        })

        onChange(nextState.slice())


        // let updateItem = { uid: item.uid, indexKanban: indexPara };
        let updateItem = { ...item, indexKanban: indexPara };
        updateItem[fieldKamban] = colPara;
        //salval up
        let uidOkForm = auxOkForm ? auxOkForm.uid : null;
        if (uidOkForm) {


            saveOkObj(okApp, uidOkForm, _.cloneDeep(updateItem),
                (r) => {
                    // console.log('salvou', r)
                },
                (err) => {
                    // console.log('erro ao salvar', err)
                }
            )
        }



    }
    const renderItem1 = (item, coluna) => {
        
        return (<>
            {(!tipoView || tipoView === "fields") ?
                <>
                    {fieldsShow ?
                        <div>
                            {fieldsShow.map((f, i) => <Typography key={i}>{item[f]}</Typography>)}
                            {showBtVer &&
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button variant="contained" size="small" color="primary"
                                        onClick={() => {
                                            if (newTab) {
                                                window.open(ajusteRoute(okApp, okForm) + "?uid=" + item.uid);
                                            } else {

                                                history.push(ajusteRoute(okApp, okForm) + "?uid=" + item.uid)
                                            }
                                        }
                                        }
                                    >
                                        Ver
                                    </Button>

                                </div>
                            }

                        </div> :
                        <div>
                            <Typography>{item.sequencialIndex}</Typography>
                            <Typography>{item["titulo"]}</Typography>

                        </div>
                    }
                </>
                :
                <>
                    {painelCard.map((c, i) => <RenderOkForm key={"CompCard" + c.uid} prefield={getPreField(item)} okComp={c} okCompsIrmaos={painelCard} renderForm={false} />)}
                </>
            }



        </>)
    }

    const matchColumn = (item, column) => {
        if (!column) {
            return !item[fieldKamban];
        }

        return item[fieldKamban] === column
    }

    return (
        <Kamban
            lista={value || []}
            colunas={colunas || ["Informe uma coluna"]}
            onDrag={onDrag}
            renderItem={renderItem1} matchColumn={matchColumn}
            colNull={colNull}
            showQt={showQt}
        />

    )
}
