import React from 'react'
import { DialogTitle, Button, Dialog, DialogActions, useTheme, useMediaQuery, CircularProgress, Typography, DialogContent } from '@material-ui/core';
import { useAtualValues, useOkApp, usePerfilUser } from 'OkApp/UseUtils';
import { useHistory } from 'react-router-dom';
import { useRelatorios } from 'OkReport';
import ItemRelatorio from './ItemRelatorio';
import { permiteEditar } from 'OkApp/modules/PermissionMeta/functions';
import { useReduxForm } from 'crud/useForm';

export default function PainelEmitir(props) {

    const { type, chaveRelatorio, label, textBotao, somenteSalvo, propscomp: { getTextoCabecalho1, getTextoCabecalho2, getTituloRelatorio, getNomeArquivo,getLogo } } = props
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    let atualValues = useAtualValues();
    let okApp = useOkApp()
    let history = useHistory()
    const relatorios = useRelatorios(chaveRelatorio);
    
    let permiteElaborar = permiteEditar(okApp);
    const formProps = useReduxForm();
    const perfil = usePerfilUser();
    let metaPre = { textoCabecalho1: "", perfil }
    if (getTextoCabecalho1) {
        metaPre.textoCabecalho1 = getTextoCabecalho1(props);
    }
    if (getTextoCabecalho2) {
        metaPre.textoCabecalho2 = getTextoCabecalho2(props);
    }
    if (getTituloRelatorio) {
        metaPre.tituloRelatorio = getTituloRelatorio(props);
    }
    if (getNomeArquivo) {
        metaPre.nomeArquivo = getNomeArquivo(props);
    }
    if (getLogo) {
        metaPre.logo = getLogo(props);
    }
    let jsonValue = atualValues
    const elaborarNovo = () => {
        setOpen(false);

        history.push("/app/" + okApp.route + "/OkReport", { jsonValue: jsonValue, chaveRelatorio, metaPre })

    };
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    let disabedButton = false;
    if (somenteSalvo && (!formProps.pristine || !formProps.initialized)) {
        disabedButton = true;
    }

    if (type === "dialogPainel") {
        return (
            <div>
                <Button variant="contained" color="primary"
                    style={{ marginBottom: 15, marginTop: 4, whiteSpace: 'nowrap' }}
                    onClick={handleClickOpen} disabled={disabedButton}>
                    {textBotao || label || "Gerar Relatório"}
                </Button>
                <div>

                    <Dialog
                        fullScreen={fullScreen}
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="responsive-dialog-title"
                        PaperProps={{ style: { height: 'auto', flex: 1 } }}

                    >


                        <DialogTitle id="responsive-dialog-title">{"Relatórios"}</DialogTitle>
                        <DialogContent>
                            <div style={{ paddingLeft: 5, paddingRight: 5, minWidth: 350 }}>
                                {relatorios ? <>{relatorios.map((r, i) => <ItemRelatorio
                                    key={i}
                                    okApp={okApp}
                                    relatorio={r}
                                    fechaDialog={() => setOpen(false)}
                                    jsonValue={jsonValue}
                                    metaPre={metaPre}
                                    permiteElaborar={permiteElaborar}
                                    auxAction={handleClose} />)}</> : <CircularProgress />}
                                {relatorios ? <>{!relatorios.length && <Typography style={{ textAlign: 'center' }}>Sem relatório</Typography>}</> : ""}
                            </div>
                        </DialogContent>
                        <DialogActions style={{ marginTop: 15 }}>

                            {permiteElaborar && <Button variant='contained' onClick={elaborarNovo} color="primary">
                                Elaborar relatorio
                            </Button>}
                            <Button onClick={handleClose} color="primary">
                                Cancelar
                            </Button>
                        </DialogActions>

                    </Dialog>

                </div>
            </div>
        )
    }
    return (
        <div>
            type de comp de relatorio desconhecido
        </div>
    )
}
