import React from 'react'
import OkCompAdapter from 'OkApp/components/OkCompAdapter'

import { OkTypedComponent, LabelInputAuto } from 'OkApp'

import QRCode from 'qrcode.react'
const typeComponente = 'QrCodeComp'



const AdapterOkComp = (props) => {

    const { okComp } = props;


    return <OkCompAdapter {...props} styleCssOver={{ display: 'flex' ,justifyContent: 'center'}} component={(props) => {
        return <QRCode size={okComp.size} value={okComp.qrlabel ? okComp.label : props.input.value ? props.input.value : "VAZIO"} />
    }} />

}


const EditorOkComp = (props) => {



    return <div style={{ display: 'flex', flexDirection: 'column', }}>
        <OkTypedComponent typeComponente='InputComp' field='field' label='Nome Salvo' />
        <LabelInputAuto {...props} />
        <OkTypedComponent typeComponente='InputComp' type='number' field='size' label='Tamanho' />
        <OkTypedComponent typeComponente='SwitchComp' field='qrlabel' label='Usar Label como conteudo' />
    </div >
}

const modelo = {
    typeComponente,
    field: 'qrEndereco',
    label: 'QR Code',
    qrlabel: false,
    size: 128,


}

const QrCodeComp = {
    typeComponente,
    configView: {
        grupo: 'Básico',
        nome: 'Qr Code',
        descricao: 'Qr Code',
        helpUrl: 'https://okapp.com.br/app/okappdocs/botao1',
        modelo
    },
    Component: AdapterOkComp,
    CompEditor: EditorOkComp
}


export default QrCodeComp