import { InputLabel } from '@material-ui/core'
import React from 'react'

export default function BackgroundColor({ styleAtual, value, onChange }) {
    return (
        <div className='OkStyleEditor-comp'>
            <InputLabel>Background Color</InputLabel>
            <div className='OkStyleEditor-row'>
                <input type="color" onChange={(v) => onChange(v.target.value)} value={value} />
            </div>
        </div>
    )
}
