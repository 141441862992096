import { InputLabel } from '@material-ui/core';
import useOKControlerField from 'components/OkStyleEditor/functionsUtils';
import React from 'react'

export default function Margin({ styleAtual, value, onChange }) {
    const okControler = useOKControlerField(value, onChange);



    return (
        <div className='OkStyleEditor-comp'>
            <InputLabel>Margin</InputLabel>
            <div className='OkStyleEditor-row'>
                <div className='OkStyleEditor-cell50'>
                    <InputLabel>Top</InputLabel>
                    <input type='number' {...okControler('marginTop')} />
                </div>
                <div className='OkStyleEditor-cell50'>
                    <InputLabel>Right</InputLabel>
                    <input type='number' {...okControler('marginRight')} />
                </div>
            </div>
            <div className='OkStyleEditor-row'>
                <div className='OkStyleEditor-cell50'>
                    <InputLabel>Bottom</InputLabel>
                    <input type='number' {...okControler('marginBottom')} />
                </div>
                <div className='OkStyleEditor-cell50'>
                    <InputLabel>Left</InputLabel>

                    <input type='number' {...okControler('marginLeft')} />
                </div>

            </div>
        </div>
    )
}
