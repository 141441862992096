import React, { useEffect, useState } from 'react'
import OkCompAdapter from 'OkApp/components/OkCompAdapter'

import { OkTypedComponent, LabelInputAuto } from 'OkApp'

import AudioReactRecorder, { RecordState } from 'audio-react-recorder'
import { deleteObject, getDownloadURL, ref } from "firebase/storage";

import _ from 'lodash'

import Delete from '@material-ui/icons/Delete'
import Mic from '@material-ui/icons/Mic'
import Send from '@material-ui/icons/Send'

import "./style.css"
import firebaseUtil from 'firebaseUtil'
import { useOkApp } from 'OkApp/UseUtils'
import { Fab } from '@material-ui/core'
import AudioPlayer from 'components/AudioPlayer'

const typeComponente = 'AudioRecordButtonComp'

const CompView = ({ input: { value, onChange }, okfunctions }) => {
    const okApp = useOkApp();
    const { okform } = okfunctions
    let tmpFolder = (okApp ? okApp.uid + "/" : "") + (okform ? okform.uid + "/" : "") + "audios/";
    const [recordState, setRecordState] = useState(RecordState.STOP)
    const [audioData, setAudioData] = useState(null)

    const start = () => {
        setRecordState(RecordState.START)
    }

    const stop = () => {
        setRecordState(RecordState.STOP)
    }

    //audioData contains blob and blobUrl
    const onStop = async (audioDataR) => {

        setAudioData(audioDataR)

        let tmpPasta = tmpFolder + audioDataR.url.split("/").reverse()[0]
        while (tmpPasta.includes('-')) {
            tmpPasta = tmpPasta.replace("-", '')
        }
        onChange({ urlLocal: audioDataR.url, tmpFolder: tmpPasta })

    }


    const deleteAudio = () => {
        if (value && value.downloadURL) {

            let arquivo = firebaseUtil.getStorageRef(value.tmpFolder)
            deleteObject(arquivo).then(function () {
                // File deleted successfully
                console.log('deletado')
                setAudioData(null)
                onChange(null)
            }).catch(function (error) {
                // Uh-oh, an error occurred!
                console.log('error', error)
            });

        } else {

            setAudioData(null)
        }
    }

    let urlUsar = null
    if (audioData) {
        urlUsar = audioData.url
    }
    if (value) {
        if (value.downloadURL) {
            urlUsar = value.downloadURL
        }
    }
    let exibeDeletar = (value && value.downloadURL) || audioData
    let exibeMic = RecordState.START !== recordState && !audioData
    let exibeConclui = RecordState.START === recordState
    if (value && value.downloadURL) {
        exibeMic = false;
    }
    useEffect(() => {
        if (value.downloadURL) {
            
            let arquivo = firebaseUtil.getStorageRef(value.tmpFolder)            
            arquivo.getDownloadURL().then(r => console.log('r', r)).catch(erro => {
                onChange(null);
            })
        }
        return () => { }
    }, [onChange, value.downloadURL, value.tmpFolder])

    return <div style={{ display: "flex", alignItems: 'center', justifyContent: 'center', }}>
        <div className='contAudio'>


            <AudioReactRecorder canvasHeight={0} canvasWidth={0} state={recordState} onStop={onStop} />
            <AudioPlayer url={urlUsar} />
            <div style={{ display: "flex", alignItems: 'center', gap: 3, }}>
                {exibeMic && <Fab size='small' onClick={start} color='primary'><Mic /></Fab>}
                {exibeConclui && <Fab size='small' onClick={stop} color='secondary'><Send /></Fab>}
                {exibeDeletar && <Fab size='small' color='secondary' onClick={deleteAudio}><Delete /></Fab>}
            </div >
        </div >
    </div >
}
const AdapterOkComp = (props) => {

    return <OkCompAdapter {...props} component={CompView} />

}


const EditorOkComp = (props) => {




    return <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <div>
            <OkTypedComponent typeComponente='InputComp' field='field' label='Nome Salvo' />
        </div>
        <div>
            <LabelInputAuto {...props} />
        </div>


    </div >
}

const modelo = {
    typeComponente,
    field: 'audio',
    label: 'Gravar',


}

const AudioRecordButtonComp = {
    typeComponente,
    configView: {
        grupo: 'Avançado',
        nome: 'Gravar Audio',
        descricao: 'Gravar Audio',
        helpUrl: 'https://okapp.com.br/app/okappdocs',
        modelo
    },
    Component: AdapterOkComp,
    CompEditor: EditorOkComp,
    beforeSave: async (obj, okComp) => {

        console.log('beforeSave', obj, okComp)
        console.log('this', this)
        let audioField = _.get(obj, okComp.field);
        if (audioField) {
            console.log('audioField', audioField)

            let pasta = firebaseUtil.getStorageRef(audioField.tmpFolder)

            // console.log('storage', storage)
            console.log('pasta', pasta)


            let blob = await makeRequest(audioField.urlLocal);

            console.log('blob', blob)
            let rPut = await pasta.put(blob)
            console.log('rPut', rPut)
            const downloadURL = await getDownloadURL(audioField.tmpFolder);

            console.log('downloadURL', downloadURL)
            audioField.downloadURL = downloadURL;
            //    .then(async (snapshot) => {
            //         console.log('Uploaded a blob or file!', snapshot);
            //         const downloadURL = await storage
            //             .child(audioField.tmpFolder)
            //             .getDownloadURL();
            //         console.log('downloadURL', downloadURL)
            //         audioField.downloadURL = downloadURL;
            //     });

            console.log('pasta2', pasta)
        }

        return obj;
    }
}
function makeRequest(url) {
    return new Promise(function (resolve, reject) {
        let xhr = new XMLHttpRequest();
        xhr.open("GET", url);
        xhr.responseType = "blob";
        xhr.addEventListener('load', function () {
            resolve(xhr.response);
        });


        xhr.send();
    });
}

export default AudioRecordButtonComp