import React from 'react'
import OkCompAdapter from 'OkApp/components/OkCompAdapter'
import { OkTypedComponent, LabelInputAuto } from 'OkApp'
import CompView from './components/CompView'

const typeComponente = 'ComentariosComp'

const AdapterOkComp = (props) => {
    let propsComp = {}
    return <OkCompAdapter {...props} propsComp={propsComp} component={CompView} />
}
const EditorOkComp = (props) => {

    return <>

        <OkTypedComponent typeComponente='InputComp' field='field' label='Nome Salvo' />
        <LabelInputAuto {...props} />
        <OkTypedComponent typeComponente='InputComp' field='newComenteLabel' label='Texto novo comentário' />
        <OkTypedComponent typeComponente='InputComp' field='textBtComentar' label='Texto botão comentar' />
        <OkTypedComponent typeComponente='InputComp' field='msgSemComentario' label='Mensagem sem comentários' />

    </>
}

const modelo = {
    typeComponente,
    field: 'comentarios',
    label: 'Comentários',
}


const ComentariosComp = {
    typeComponente,
    configView: {
        grupo: 'Avançado',
        nome: 'Comentários',
        descricao: 'Comentários',
        helpUrl: 'https://www.google.com.br/',
        modelo
    },
    Component: AdapterOkComp,
    CompEditor: EditorOkComp
}

export default ComentariosComp