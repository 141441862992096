import React, { useCallback, useEffect } from 'react'
import { useCrudContext } from 'crud';
import useNotification from 'notification';
import { useTranslation } from 'react-i18next';

import { salvarObj } from 'dbApi';

export default function CrudControlForm({ pristine, handleSubmit, submitting, colectionFirebase, atualValues }) {

    const uid = atualValues ? atualValues.uid : null;

    const { addActions, removeActions } = useCrudContext();
    const { showError, showSuccess } = useNotification();
    const { t } = useTranslation();
// eslint-disable-next-line react-hooks/exhaustive-deps
    const actionSave = useCallback( handleSubmit((obj) => {
        if (colectionFirebase) {

            salvarObj(obj, colectionFirebase,
                (r) => {
                    console.log("CRUD - Sucesso Salvou: ", r.msg);
                    showSuccess(r.msg)
                },
                (err) => {
                    console.log("CRUD - error", err);
                    showError(err.message)
                }
                , )

        } else {
            showError('Sem collection para salvar')
        }
    }), [colectionFirebase, handleSubmit, showError, showSuccess])

    useEffect(() => {
        let add = [];
        add.push({ uid: 'crud.actions.save', text: t('crud.actions.save'), icon: null, action: actionSave, props: { disabled: (pristine || submitting) } })
        addActions(add)
        return () => { removeActions(['crud.actions.save']); };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pristine, submitting, t, uid])
    return (
        <>        </>
    )
}
