import React from 'react'

import { Field } from 'redux-form';
import { CodeEditor } from 'components';
import _ from 'lodash'
import { createFunctionMap, getFunctionCodeToFuncMap, defaultSnippets } from 'OkApp/CodeProcessor';



import { useAtualValues, useReduxFormProps } from 'OkApp/UseUtils';
import OkFormAbaAdapter from 'OkApp/components/OkFormAbaAdapter';

export function CompAddCustomComponent(props) {
    let snippets = [
        {
            name: 'adapterProps',
            code: 'function adapterProps(props) {\n\tconst { okfunctions,input:{value} } = props\n\tconst { okComp,atualValues } = okfunctions\n\treturn props;\n\t//return {...props, input:{...props.input, value:"ChangeValue"}};\n}'
        },
        {
            name: 'OKadapterProps-ocultar',
            code: `function adapterProps(props) {
                const { okfunctions } = props;
                if (props.developer) {
                  return props;
                }
                const { okComp, atualValues } = okfunctions;
                if ( !atualValues.uid) {
                  return props; //Exibe se nao tiver um Uid 
                } else {
                  return { ...props, okComp: { ...okComp, hidden: true } }; //Oculta componente
                }
              }
            `
        },
        {
            name: 'OKadapterProps-disabledSeNull',
            code: `function adapterProps(props) {
                const { okfunctions,input:{value} } = props                             
                const { okComp } = okfunctions;
                if ( value) {
                  return props; //Exibe se nao tiver valor
                } else {
                  return { ...props, disabled:true}; //Oculta componente
                }
              }
            `
        },
        {
            name: 'OKadapterProps-style',
            code: `function adapterProps(props) {
                const {
                  okfunctions,
                  input: { value },
                } = props;
                const { okComp } = okfunctions;
              
                if (true) {
                  return { ...props, styleCssOver: { backgroundColor: "#f00" } }; 
                } else {
                  return props; 
                }
              }
            `
        }
    ]


    return <div>
        <Field name='metaOkApp.code.componentFunctions' snippets={defaultSnippets.concat(snippets)} component={CodeEditor} height={500} label={"Funções pra Componente"} />
    </div>
}


export function OkCompFieldModuleAdapterFuncComp(props) {
    const { modulosrestantes } = props

    const { okfunctions } = props
    const { okComp } = okfunctions
    let componentFunctions = _.get(okComp, 'metaOkApp.code.componentFunctions');

    let funcAdapterProps = false
    if (componentFunctions) {
        let mapFunc = createFunctionMap(componentFunctions);
        if (mapFunc) {

            let funcAdapterPropsField = getFunctionCodeToFuncMap(mapFunc, "adapterProps")

            if (funcAdapterPropsField) {
                funcAdapterProps = funcAdapterPropsField;
            }
        }
    }

    for (let index = 0; index < modulosrestantes.length; index++) {
        const M = modulosrestantes[index];
        if (M.OkCompFieldModuleAdapterFuncComp) {
            let Comp = M.OkCompFieldModuleAdapterFuncComp
            if (Comp) {



                return <Comp {...props} modulosRestantes={modulosrestantes.slice(index + 1)}>
                    {(p) => {
                        if (funcAdapterProps) {

                            return <CompComAtualValues {...p}>
                                {(props2) => {
                                    let newProps = funcAdapterProps(props2)
                                    return <>{props.children(newProps)}</>
                                }}</CompComAtualValues>
                        } else {

                            return <> {props.children(p)} </>
                        }
                    }}
                </Comp >
            }
        }

    }

    if (funcAdapterProps) {
        return <CompComAtualValues {...props}>
            {(props2) => {
                let newProps = funcAdapterProps(props2)
                return <>{props.children(newProps)}</>
            }}</CompComAtualValues>


    } else {

        return <>{props.children(props)}</>
    }
}
function CompComAtualValues(props) {

    // console.log('CompComAtualValues', props)
    const atualValues = useAtualValues();
    const formProps = useReduxFormProps();
    let newProps = { ...props, okfunctions: { ...props.okfunctions, atualValues, formProps } }
    return <>{props.children(newProps)}</>
}

export function OkCompFieldModuleAdapterFunc(props) {

    const { okfunctions } = props
    const { okComp } = okfunctions
    let componentFunctions = _.get(okComp, 'metaOkApp.code.componentFunctions');
    let returnProps = props

    if (componentFunctions) {
        let mapFunc = createFunctionMap(componentFunctions);
        if (mapFunc) {

            let funcAdapterPropsField = getFunctionCodeToFuncMap(mapFunc, "adapterProps")

            if (funcAdapterPropsField) {
                //alterar dodo o modulo de inserção de codigo no componente "adapterProps" para FunctionsEditor igual o observer
                returnProps = funcAdapterPropsField({ ...returnProps, })// falta o  atualValues, formProps aqui
            }
        }
    }
    return returnProps;
}
export function OkFormMetaAba(props) {

    let snippets = [
        // {
        //     name: 'onLoad',
        //     code: 'function onLoad(obj) {\n\tconsole.log("onLoad",this)\n}'
        // },
        {
            name: 'Okapp-beforeSave',
            code: 'function beforeSave(obj) {\n\tconsole.log("beforeSave",this)\n\n\tconsole.log("obj",obj)\n}'
        },


    ]



    return <OkFormAbaAdapter keyTab={"Code"} {...props}>
        <div style={{ display: 'flex', height: "100%" }}>

            <Field name='metaOkApp.codeForm' snippets={defaultSnippets.concat(snippets)} component={CodeEditor} label={"Funções para Componente"} />
        </div>
    </OkFormAbaAdapter>
}
export function ModuleFuncBeforeSave(obj, form) {
    let codeForm = _.get(form, 'metaOkApp.codeForm');
    if (codeForm) {
        let mapFunc = createFunctionMap(codeForm);
        let beforeSave = getFunctionCodeToFuncMap(mapFunc, "beforeSave")
        if (beforeSave) {
            beforeSave(obj, form)
        }
        //  _.set(obj, "metaOkApp.permission.public", true)
    }

    return obj;
}


export const data = { name: "CodeModule", abaMetaGeral: "CODE", abaMetaCustomComponent: "CODE" }