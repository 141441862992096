import React from 'react'

import * as DefaultPesquisa from './DefaultPesquisa'
import * as MenuControl from './MenuControl'
import * as PermissionMeta from './PermissionMeta'
import * as CrudControlModule from './CrudControlModule'
import * as CodeModule from './CodeModule'
import * as DevAux from './DevAux'
import * as StyleCompModule from './StyleCompModule'
import * as GestaoProjetoModule from './GestaoProjetoModule' //  inativo para lancamento. 
import * as HistoricoFormControl from './HistoricoFormControl' //  inativo para lancamento. 
import { useCrudContext } from 'crud';
import { usePerfilUser } from 'OkApp/UseUtils'

export const OkModules = [MenuControl, CrudControlModule, DefaultPesquisa, PermissionMeta, StyleCompModule, CodeModule, DevAux, HistoricoFormControl, GestaoProjetoModule]

export default OkModules

export function CrudInsertComp(props) {

    const { addActions, removeActions, setVisibilityTopBar } = useCrudContext();

    return <>
        {OkModules.map((M, i) => {
            if (M.CrudInsertComp) {
                return <M.CrudInsertComp key={"CrudInsertComp-" + i} {...props} addActions={addActions} removeActions={removeActions} setVisibilityTopBar={setVisibilityTopBar} />
            }
            return '';
        })}
    </>
}
export function OkFormMetaAba(props) {
    return <>
        {OkModules.map((M, i) => {
            if (M.OkFormMetaAba) {
                return <M.OkFormMetaAba key={"CrudInsertComp-" + i} {...props} />
            }
            return '';
        })}
    </>
}


export function OkCompModuleAdapter(props) {

    const { OverComp } = props;
    const { Component, ...other } = props;
    const perfilUser = usePerfilUser();
    let propsAdapter = { ...other, perfilUser };
    OkModules.forEach((M, i) => {
        if (M.OkCompModuleAdapterFunc) {
            propsAdapter = M.OkCompModuleAdapterFunc(propsAdapter);
        }
    })
    // console.log('OkCompModuleAdapter',propsAdapter)

    return <>
        {OverComp ? <OverComp key={props.okComp.uid}  {...propsAdapter}>
            <Component {...propsAdapter} />
        </OverComp>
            :
            <Component {...propsAdapter} />
        }
    </>
}
export function OkCompFieldModuleAdapterFuncComp(props) {
    // console.log('OkCompFieldModuleAdapterFuncComp', props)
    //{ ComponentView, outerProps }


    for (let index = 0; index < OkModules.length; index++) {
        const M = OkModules[index];
        if (M.OkCompFieldModuleAdapterFuncComp) {
            let Comp = M.OkCompFieldModuleAdapterFuncComp
            if (Comp) {


                return <Comp {...props} modulosrestantes={OkModules.slice(index + 1)}>
                    {(p) => <> {props.children(p)} </>}
                </Comp >
            }
        }

    }
    // OkModules.forEach((M, i) => {
    //     if (M.OkCompFieldModuleAdapterFuncComp) {
    //         let Comp = M.OkCompFieldModuleAdapterFuncComp
    //         instance = <Comp {...props}>{(p) => <RootComp {...p} />}</Comp>
    //         // RootComp = <Comp {...props}>{(p) => <RootComp {...p} />}</Comp>

    //     }
    // })
    // console.log('listaModulo', listaModulo)
    // // console.log('ComponentView', ComponentView)
    // console.log('RootComp', RootComp)
    // return <>{instance}</>

    //>{props.children(propsR)}</>
}
export function OkCompFieldModuleAdapterFunc(props) {

    let propsAdapter = props;
    OkModules.forEach((M, i) => {
        if (M.OkCompFieldModuleAdapterFunc) {
            propsAdapter = M.OkCompFieldModuleAdapterFunc(propsAdapter);
        }
    })
    return propsAdapter
}


export function ModuleFuncBeforeSave(obj, okform) {


    OkModules.forEach((M, i) => {
        if (M.ModuleFuncBeforeSave) {
            obj = M.ModuleFuncBeforeSave(obj, okform);
        }
    })
    return obj
}




export function ModulesForms(props) {
    let forms = [];

    OkModules.forEach((M, i) => {
        if (M.ModulesForms) {
            forms = forms.concat(M.ModulesForms(props));
        }
    })
    return forms
}
export function ModulesOkComps() {
    let comps = [];

    OkModules.forEach((M, i) => {
        if (M.ModuleOkComps) {

            comps = comps.concat(M.ModuleOkComps());
        }
    })
    return comps
}


export const OkModulesConnect = { CrudInsertComp, OkFormMetaAba }




