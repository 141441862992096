import Page from './components/Page'

export const routes = () => {
    return [
        { exact: true, path: "/Perfil", component: Page },
    ]
}
export const menus = ({ history }) => {
    return [
        { text: "Perfil", iconKey: 'Person', action: () => { history.push("/Perfil") }, index: "88888" },
    ]
}

