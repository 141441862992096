import Form from './components/Form'
import Consulta from './components/Consulta'

export const routes = () => {
    return [
        { exact: true, path: "/Projeto", component: Form },
        { exact: true, path: "/MeusProjetos", component: Consulta },
    ]
}
export const menus = ({ history }) => {
    return [
        // { text: "Meus Projetos", iconKey: "Apps", action: () => { history.push("/MeusProjetos") } ,index:'3'},
    ]
}

