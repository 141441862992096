import React from 'react'
import OkCompAdapter from 'OkApp/components/OkCompAdapter'
import SeletorUnicoPorPesquisa from './component/SeletorUnicoPorPesquisa'
import Editor from './component/Editor'
import _ from 'lodash'


const typeComponente = 'SeletorOkObjUnico' //deve ter o mesmo nome de exportação no index

const AdapterOkComp = (props) => {
    let propsComp = {}
    return <OkCompAdapter {...props} propsComp={propsComp} component={SeletorUnicoPorPesquisa} />
}

const modelo = {
    typeComponente,
    field: 'responsavel',
    label: 'Responsavel',
    tituloPesquisa: '',
}

const SeletorOkObjUnico = {
    typeComponente,
    configView: {
        grupo: 'Consulta',
        nome: 'Seletor de Objeto',
        descricao: 'Seletor de Objeto',
        helpUrl: 'https://okapp.com.br/app/okappdocs/seletorokobjunico',
        modelo,
    },
    Component: AdapterOkComp,
    CompEditor: Editor,
    beforeSave: (obj, okComp) => {
        let objField = _.get(obj, okComp.field);
        if (objField) {
            let sel = { uid: objField.uid }
            okComp.saveFields.forEach(f => {
                sel[f] = objField[f] === undefined ? null : objField[f];
            });
            _.set(obj, okComp.field, sel)
        }
        return obj;
    }
}


export default SeletorOkObjUnico;

