
import * as Home from './Home'
import * as Register from './Register'
import * as Login from './Login'
import * as TermosUso from './TermosUso'
import * as PoliticasPrivacidade from './PoliticasPrivacidade';
import * as AboutApp from './AboutApp';
import * as PasswordRecovery from './PasswordRecovery'
import * as DemonstrationRequestForm from './DemonstrationRequestForm'
import { isAuthenticated } from '../../useAuth';

export const routes = () => {
    let r = [];
    if (!isAuthenticated()) {
        r = r.concat(Home.routes());
        r = r.concat(Register.routes());
        r = r.concat(PasswordRecovery.routes());
    }
    r = r.concat(DemonstrationRequestForm.routes());
    r = r.concat(TermosUso.routes());
    r = r.concat(PoliticasPrivacidade.routes());
    r = r.concat(AboutApp.routes());
    r = r.concat(Login.routes());
    return r
}
export const menus = (props) => {
    const { okApp } = props;

    let r = [];
    if (!isAuthenticated()) {
        r = r.concat(Home.menus(props));
        r = r.concat(Register.menus(props));
    }

    r = r.concat(Login.menus(props));

    if (!okApp && isAuthenticated()) {
        r = r.concat(TermosUso.menus(props));
        r = r.concat(PoliticasPrivacidade.menus(props));
    }
    return r
}

