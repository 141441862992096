import React from 'react'
import OkCompAdapter from 'OkApp/components/OkCompAdapter'
import EditorComp from './components/EditorComp'
import CompView from './components/CompView'
import { createDefaultFuncMapStr } from 'OkApp/CodeProcessor'
const typeComponente = 'PlayerVideoComp'

const AdapterOkComp = (props) => {
    let propsComp = {};
    return <OkCompAdapter {...props} propsComp={propsComp} component={CompView} />
}
export const PlayerVideoTypes = ['url', 'anexo']
const modelo = {
    typeComponente,
    field: 'urlVideo',
    label: 'Video Player',
    type: 'anexo',
    autoPlay: false,
    functionsmap: createDefaultFuncMapStr("onEnded", "function onEnded() {\n\tconsole.log('this', this);\n}"),
}


const ImportarNfe = {
    typeComponente,
    configView: {
        grupo: 'Básico',
        nome: 'Video Player',
        descricao: 'Video Player',
        helpUrl: 'https://www.google.com.br/',
        modelo
    },
    Component: AdapterOkComp,
    CompEditor: EditorComp
}
export default ImportarNfe
