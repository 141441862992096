
import _ from 'lodash'
import { getUserUid } from 'OkApp/OkFunctions';


export function permiteEditar(obj) {
    let userUid = getUserUid();
    let permission = _.get(obj, 'metaOkApp.permission');

    if (userUid && permission) {
        if (permission.owner === userUid || (permission.usersWrite && permission.usersWrite[userUid])) {
            return true;
        }
    }
    return false;
}
export function permiteEditarPublico(obj) {
    let userUid = getUserUid();
    if (userUid) {
        if (obj.metaOkApp && obj.metaOkApp.permission) {
            if (obj.metaOkApp.permission.owner === userUid || (obj.metaOkApp.permission.usersWrite && obj.metaOkApp.permission.usersWrite[userUid])) {
                return true;
            }

            if (_.isEmpty(obj.metaOkApp.permission.usersWrite) || ninguemTemPermissao(obj.metaOkApp.permission.usersWrite)) {
                return true;
            }
        }
    } else {
        if (obj.metaOkApp && obj.metaOkApp.permission && obj.metaOkApp.permission.queryPermission) {
            if (obj.metaOkApp.permission.queryPermission.includes("PUBLIC")) {
                return true;
            }
        }
    }
    return false;
}

function ninguemTemPermissao(obj) {
    let rrr = !_.values(obj).some(x => x);
    return rrr;
}

export function permiteVer(obj, perfilUser) {
    let userUid = getUserUid();
    // console.log('obj', obj)
    let permission = _.get(obj, 'metaOkApp.permission');
    if (userUid && permission && permission.queryPermission) {
        if (permission.queryPermission.includes(userUid) || permission.queryPermission.includes("PUBLIC")) {
            return true;
        }
    }
    // console.log('perfilUser', perfilUser)
    if (perfilUser) {
        const { okGrupoPermissao } = perfilUser
        if (permission.groups && permission.groups[okGrupoPermissao]) {
            let pemUser = permission.groups[okGrupoPermissao];
            return pemUser["Ver"];

        }
    }
    return false;
}

export function permiteVerOkComp(okform, okComp, perfilUser) {
    return temPermissaoChaveOkComp(okform, _.get(okComp, 'metaOkApp.permission.chaveVer'), perfilUser);

}

export function temPermissaoChaveOkComp(okForm, chave, perfilUser) {
    if (!chave) {
        return true;
    }
    let userUid = getUserUid();
    let permission = _.get(okForm, 'metaOkApp.permission');
  
    if (userUid && permission) {
        if (permission.owner === userUid)
            return true;
        if (permission.usersRead && permission.usersRead[userUid]) {            
            let pemUser = permission.usersRead[userUid];            
            return pemUser[chave];
        }

        if (perfilUser) {
            const { okGrupoPermissao } = perfilUser
            if (permission.groups && permission.groups[okGrupoPermissao]) {
                let pemUser = permission.groups[okGrupoPermissao];
                return pemUser[chave];

            }
        }
    }
    return false;
}

