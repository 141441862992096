import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';

import { usePerfil } from 'useAuth';

import Carousel from 'components/Carousel';

import { useListOkApp } from 'OkApp/UseUtils';

import { ArrowForwardIos } from '@material-ui/icons';
import { Box, Button, Typography, useMediaQuery, useTheme } from '@material-ui/core'

import _ from 'lodash'

export default function FavoritosProprio() {
    const [projetosFilter, setProjetosFilter] = useState(null)
    const [expanded, setExpanded] = useState(true);

    const perfil = usePerfil()
    const projetos = useListOkApp();
    const history = useHistory()

    const theme = useTheme()
    const xs = useMediaQuery(theme.breakpoints.down('xs'));

    const handleOpenFavs = () => {
        setExpanded(!expanded);
        history.push('/Favoritos')
    };

    useEffect(() => {
        if (projetos && perfil && perfil.projetosFavoritos) {
            let p = projetos.filter((p) => {
                if (!perfil.projetosFavoritos[p.uid]) {
                    return false;
                }
                if (_.get(p, "metaOkApp.permission.owner") === perfil.uid) {
                    return true
                }
                if (!_.get(p, "listado")) {
                    return true
                }

                let publicp = _.get(p, "metaOkApp.permission.public");
                let e = publicp ? false : true;

                return e;
            })

            p.sort((a, b) => {
                if (a.name > b.name) {
                    return 1;
                }
                if (a.name < b.name) {
                    return -1;
                }

                return 0
            })
            setProjetosFilter(p)
        } else {
            setProjetosFilter(null)
        }

    }, [projetos, perfil])

    if ((!projetosFilter || !projetosFilter.length)) {
        return <></>
    }

    return (
        <Box style={{ marginBottom: 30 }}>
            <Box display="flex" alignItems="center" justifyContent="space-between" style={{ margin: '0 15px 0 15px' }}>
                <Typography style={{ fontSize: xs ? 18 : 24, fontWeight: "bold", marginRight: "10px", fontFamily: 'poppins' }}>Favoritos</Typography>
                <Button
                    onClick={handleOpenFavs}
                    aria-expanded={expanded}
                    color='secondary'
                    size='small'
                    variant='contained'
                >
                    <Typography style={{ fontSize: xs ? 12 : 15, fontWeight: "bold", marginRight: "10px", textTransform: 'uppercase', fontFamily: 'poppins' }}>Ver todos</Typography>
                    <ArrowForwardIos style={{ width: 18, height: 18 }} />
                </Button>
            </Box>
            <Box>
                {projetosFilter &&
                    <Carousel values={projetosFilter} />
                }
                {(!projetosFilter || !projetosFilter.length) && <p>Nenhum projeto marcado como favorito</p>}
            </Box>
        </Box>
    )
}
