
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';

import Carousel from 'components/Carousel';

import { useListOkApp } from 'OkApp/UseUtils';

import { Box, Button, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { ArrowForwardIos } from '@material-ui/icons';

import _ from 'lodash'

export default function PainelPublicoHome({ label = "Públicos" }) {
    const [projetosFilter, setProjetosFilter] = useState(null)

    const projetos = useListOkApp();
    const history = useHistory()

    const theme = useTheme()
    const xs = useMediaQuery(theme.breakpoints.down('xs'));

    const handleOpenPublic = () => {
        history.push("/ProjetosPublicos")
    };

    useEffect(() => {
        if (projetos) {
            setProjetosFilter(projetos.filter((p) => {
                if (p.status !== "ready") {
                    return false
                }
                let publicp = _.get(p, "metaOkApp.permission.public");
                if (publicp) {
                    publicp = _.get(p, "listado");
                }

                return publicp;
            }))
        } else {
            setProjetosFilter(null)
        }
    }, [projetos])

    return (
        <Box style={{ marginBottom: 30 }}>
            <Box display="flex" alignItems="center" justifyContent="space-between" style={{ margin: '0 15px 0 15px' }}>
                <Typography style={{ fontSize: xs ? 18 : 24, fontWeight: "bold", marginRight: "10px", fontFamily: 'poppins' }}>{label}</Typography>
                <Button
                    onClick={handleOpenPublic}
                    color='secondary'
                    variant='contained'
                    size='small'
                    style={{ display: "flex", justifyContent: "space-between" }}
                >
                    <Typography style={{ fontSize: xs ? 12 : 15, fontWeight: "bold", marginRight: "10px", fontFamily: 'poppins' }}>Ver todos</Typography>
                    <ArrowForwardIos style={{ width: 18, height: 18 }} />

                </Button>
            </Box>
            {projetosFilter &&
                <Carousel values={projetosFilter} />
            }
        </Box>
    )
}
