import React, { useEffect, useState } from 'react'
import { Field } from 'redux-form'
import useAuth from 'useAuth';
import { CrudForm } from 'crud'
import { useOkAppContext } from 'OkApp/context';
import LeftComp from '../LeftComp';

import { useHistory, useLocation } from 'react-router-dom';
import SplitPane from 'react-split-pane';
import Pane from 'react-split-pane/lib/Pane'
import "./style.css"
import Render from './Render';
import { useContext } from 'react'
import { ReduxFormContext } from 'redux-form/es/ReduxFormContext'

import CrudControlForm from './CrudControlForm';
import PesquisaOkForm from '../PesquisaOkForm';
import { useUserUid } from 'OkApp/UseUtils';




const ComInt = (props) => {

    const { adiconouUid, setAdiconouUid } = props
    const useReduxForm = useContext(ReduxFormContext);
    return <SplitPane className="my-split-pane" split="vertical"  >
        <Pane initialSize="50px" minSize="5px" >
            <LeftComp openProps={props.openProps} okform={useReduxForm.values} />
        </Pane>

        <div style={{ position: "relative", backgroundColor: "#fff", flex: 1, boxSizing: "border-box", overflow: "auto", display: 'flex', flexDirection: 'column' }}  >
            <Field name="children" component={Render} openProps={props.openProps} setGetInitialValues={props.setGetInitialValues} okform={useReduxForm.values} changeCustomForm={useReduxForm.change} adiconouUid={adiconouUid} setAdiconouUid={setAdiconouUid} />
        </div>
    </SplitPane>
}

function ProjectForm(props) {
    const userUid = useUserUid();
    const { okApp } = useOkAppContext();
    const initialValues = {
        metaOkApp: {
            permission: { dadosPublicos: true, public: true, publicNoUser: false, owner: userUid }
        },
        meta: {
            actions: { type: 'padrao', novo: true, salvar: true },
            pesquisa: { showpesquisa: true, buscaDepoisFiltra: true }
        }

    }

    if (okApp && okApp.okform) {
        if (okApp.okform.dadosPublicos !== undefined) {
            initialValues.metaOkApp.permission.dadosPublicos = okApp.okform.dadosPublicos
        }
        if (okApp.okform.public !== undefined) {
            initialValues.metaOkApp.permission.public = okApp.okform.public
        }
        if (okApp.okform.publicNoUser !== undefined) {
            initialValues.metaOkApp.permission.publicNoUser = okApp.okform.publicNoUser
        }

    }

    const { authenticatedOnly, redirectPosAuth } = useAuth();
    const [openPesquisa, setOpenPesquisa] = useState(false)
    const [adiconouUid, setAdiconouUid] = useState(null)


    const history = useHistory();
    const { pathname } = useLocation();


    let colectionFirebase = null;
    if (okApp) {
        colectionFirebase = 'Project/' + okApp.uid + '/CustomForm';
    }

    const openProps = (uid) => {
        setAdiconouUid(uid)
    }

    useEffect(() => {
        authenticatedOnly()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        if (!okApp)
            redirectPosAuth()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [okApp])

    return (<>
        <CrudForm
            title={"Elaborar Formulario"}
            form={"CustomForm"}
            initialValues={initialValues}
            colectionFirebase={colectionFirebase}
            noModules
            noUseDB
            InsertComp={CrudControlForm}
            setOpenPesquisa={setOpenPesquisa}

        >
            <ComInt openProps={openProps} adiconouUid={adiconouUid} setAdiconouUid={setAdiconouUid}  {...props} />
        </CrudForm>

        <PesquisaOkForm openPesquisa={openPesquisa} setOpenPesquisa={setOpenPesquisa} onSelect={(f) => {
            history.push(pathname + "?uid=" + f.uid)
        }} />

    </>

    )
}

export default ProjectForm