import { Button } from '@material-ui/core'
import { useOkApp, useUserUid } from 'OkApp/UseUtils';
import React, { useEffect } from 'react'
import { useState } from 'react'
import _ from 'lodash'
import BackupIcon from '@material-ui/icons/Backup';
import useNotification from 'notification';
import firebaseUtil from 'firebaseUtil';
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";

export default function UploadButtonControl(props) {
    const okApp = useOkApp();
    const userUid = useUserUid();
    const { showWarning, showError } = useNotification()
    const { tmpFolder, typeAccept, multiple, disabled, uploadSuccess, limitFiles } = props
    const [isUploading, setIsUploading] = useState(false)
    const [progress, setProgress] = useState(0)
    // const [sizeLarge, setSizeLarge] = useState(false)
    const [msgError, setMsgError] = useState("")
    const [limiteSize, setLimiteSize] = useState(1024 * 1024 * 1)

    useEffect(() => {
        let okL = _.get(okApp, "configComps.AnexosComp.maxSize")
        if (okL) {
            setLimiteSize(1024 * 1024 * okL)
        }
        return () => {

        }
    }, [okApp])

    const bytesToSize = (bytes) => {
        var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes === 0) return '0 Byte';
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
    }

    const handleSelectFile = (e) => {
        setMsgError("");
        const files = e.target.files
        if (files.length > limitFiles) {
            showWarning('O limite de anexos é de ' + limitFiles + ' arquivos.')
            setMsgError('O limite de anexos é de ' + limitFiles + ' arquivos.')
            return;
        }

        let temArquivoGrande = false
        for (let index = 0; index < files.length; index++) {
            const f = files[index];
            if (f.size > limiteSize) {
                temArquivoGrande = true
                setMsgError("O tamanho máximo de um anexo é de " + bytesToSize(limiteSize));
                showWarning('O arquivo "' + f.name + '" ultrapassa o tamanho máximo permitido de ' + bytesToSize(limiteSize))
            }
        }
        if (!temArquivoGrande) {//upload

            for (let index = 0; index < files.length; index++) {
                const file = files[index];
                starUploadFile(file)
            }
        }

    }
    const uploadCompleted = (file, meta, downloadURL, realName) => {
        setIsUploading(false);
        setProgress(0);
        const { timeCreated, updated, name, size, fullPath, contentType } = meta
        let objAnexo = { dateCreate: timeCreated, dateUpdate: updated, downloadURL, filename: name, realName, size, storagePath: fullPath, type: contentType, userUid }
        console.log('uploadCompleted', objAnexo)
        uploadSuccess(objAnexo)

    }

    const starUploadFile = (file) => {
        if (file.size <= limiteSize) {
            setIsUploading(true);
            setProgress(0);
            

            let nameUid = generateUid() + "-" + file.name
            let realName = file.name;
            
            let docRef = firebaseUtil.getStorageRef(tmpFolder + nameUid)            
            // Upload file and metadata to the object 'images/mountains.jpg'

            var uploadTask = uploadBytesResumable(docRef, file)
            // Listen for state changes, errors, and completion of the upload.
            uploadTask.on('state_changed', // or 'state_changed'
                (snapshot) => {
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    console.log('Upload is ' + progress + '% done');
                    setProgress(progress.toFixed(2))
                    switch (snapshot.state) {
                        case 'paused': // or 'paused'
                            console.log('Upload is paused');
                            break;
                        case 'running': // or 'running'
                            // console.log('Upload is running');
                            break;
                        default:
                            console.log('default', snapshot.state);
                    }
                },
                (error) => {
                    // A full list of error codes is available at
                    // https://firebase.google.com/docs/storage/web/handle-errors
                    setIsUploading(false);
                    switch (error.code) {
                        case 'storage/unauthorized':

                            // User doesn't have permission to access the object
                            showError("User doesn't have permission to access the object")
                            break;
                        case 'storage/canceled':
                            // User canceled the upload
                            showError("User canceled the upload")
                            break;

                        // ...

                        case 'storage/unknown':
                            // Unknown error occurred, inspect error.serverResponse
                            showError("Unknown error occurred, inspect error.serverResponse")
                            break;
                        default:
                            console.log('default error.code', error.code);
                    }
                },
                () => {
                    // Upload completed successfully, now we can get the download URL
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        console.log('File available at', downloadURL);
                        uploadCompleted(file, uploadTask.snapshot.metadata, downloadURL, realName)
                    });
                    // uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                    //     uploadCompleted(file, uploadTask.snapshot.metadata, downloadURL, realName)
                    // });
                }
            )
        }
    }

    function generateUid() {
        const CHARS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

        let autoId = ''

        for (let i = 0; i < 20; i++) {
            autoId += CHARS.charAt(
                Math.floor(Math.random() * CHARS.length)
            )
        }

        return autoId
    }

    return (
        <div className='UploadButtonControl'>
            {msgError && <div className='UploadButtonMagError'>{msgError}</div>}
            <Button variant="contained" color="primary" size={'small'} component="label" disabled={isUploading || disabled}
                startIcon={isUploading ? null : <BackupIcon />}>
                {isUploading ? "Enviando: " + progress + "%..." : "Enviar Anexo"}
                <input
                    type="file"
                    onChange={handleSelectFile}
                    accept={typeAccept}
                    multiple={multiple}
                    hidden
                />
            </Button>
        </div>
    )
}
