import { Box, LinearProgress, Typography } from "@material-ui/core";

/* 
    Para utilizar basta passar como props o valor atual do progresso na chave "value" 
    e o valor final do progresso na chave "maxvalue" 
*/


export default function LinearProgressWithLabel(props) {

    const percentage = (props.value / props.maxvalue) * 100

    return (
        <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
                <LinearProgress variant="determinate" value={percentage} />
            </Box>
            <Box minWidth={{}}>
                <Typography variant="body2" color="textSecondary">
                    {`${Math.round(percentage)}/100%`}
                </Typography>
            </Box>
        </Box>
    );
}