import React, { useEffect, useState } from 'react'
import { TextField, Button, Typography, Card, CircularProgress } from '@material-ui/core'
import { useAtualValues, useReduxFormProps, useUserUid } from 'OkApp/UseUtils';
import ComentarioItem from './ComentarioItem';
import { getNewSmallUid } from 'OkApp/functions';
import { serverTimestampFirestore } from 'dbApi';
import useSpeechToText from 'react-hook-speech-to-text';
import axios from 'axios';
import _ from 'lodash'
import firebaseUtil from 'firebaseUtil';

// const { Configuration, OpenAIApi } = require("openai");
const openAIKey = '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'

// generata api https://platform.openai.com/account/api-keys
//api key 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
// const configuration = new Configuration({
//     apiKey: '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',
// });
// const openai = new OpenAIApi(configuration);



const gptChat = firebaseUtil.funcServGptChat();
export default function CompView(props) {
    const openai = axios.create({
        baseURL: "https://api.openai.com/v1",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${openAIKey}`,
        },
    });
    const [inicializado, setInicializado] = useState(false)
    const [autoSend, setAutoSend] = useState(false)
    const atualValues = useAtualValues();
    const { field, input: { value, onChange } } = props//general
    const formProps = useReduxFormProps();
    const { label, camposPreencher, pronptOrientacao, campoResultadoFiltros,model } = props//okcomp
    const { newComenteLabel, textBtComentar, msgSemComentario, } = props//okcomp
    const [apiResponse, setApiResponse] = useState([])
    const [text, setText] = useState("");
    const [errorTest, setErrorTest] = useState("");
    const [novos, setNovos] = useState([])
    const [loadingGpt, setLoadingGpt] = useState(false)
    const userUid = useUserUid();

    const {
        error,
        isRecording,
        //results,
        startSpeechToText,
        stopSpeechToText,
        interimResult

    } = useSpeechToText({
        continuous: true,
        useLegacyResults: false,
        timeout: 10000,
        speechRecognitionProperties: { interimResults: true }
    });

    useEffect(() => {
        if (interimResult !== undefined) {

            setText(interimResult)
        } else {
            if (isRecording && text && autoSend) {
                addComentario()
            }
        }

    }, [interimResult])


    if (error) return <p>Web Speech API is not available in this browser </p>;

    const addComentarioGPT = (resposta) => {

        if (resposta) {

            let newComment = { apiName: "GPT", text: resposta }

            newComment.uid = getNewSmallUid();
            newComment.dateCreate = new Date().toString();

            onChange(v => (v || []).concat([{ ...newComment, index: (v || []).length }]))
            setNovos(n => n.concat([newComment.uid]))
            setErrorTest("")
            setText("")
        } else {
            setErrorTest("Infome o comentario")
        }
    }
    const addComentario = () => {
        if (!autoSend && isRecording) {
            stopSpeechToText()
        }
        if (text) {

            let newComment = { userUid, text }

            newComment.uid = getNewSmallUid();
            newComment.dateCreate = new Date().toString();

            onChange(v => ([]).concat([{ ...newComment, index: (v || []).length }]))
            setNovos(n => n.concat([newComment.uid]))
            setErrorTest("")
            setText("")
            consultaApiGpt(text).then(r => {
                console.log('resposta', r)
                addComentarioGPT(r)
                setApiResponse(old => old.concat([r]))
            })
        } else {
            setErrorTest("Infome o comentario")
        }
    }
    const deleteComment = (comment) => {
        onChange(v => {
            let arr = (v.slice() || []);
            let indexOf = arr.map(c => c.uid).indexOf(comment.uid)
            if (indexOf !== -1) {
                arr.splice(indexOf, 1);
                return arr;
            }

        })


    }
    const onKeyPress = (e) => {
        if (e.key === 'Enter') {
            addComentario();
        }

    }


    let listSort = value || []
    listSort.sort((a, b) => {
        let r = 0;
        if (a.dateCreate !== serverTimestampFirestore() && b.dateCreate !== serverTimestampFirestore()) {
            r = new Date(b.dateCreate) - new Date(a.dateCreate)
        } if (r === 0) {
            r = b.index - a.index
        }
        return r;
    })


    const consultaApiGpt = async (prompt) => {
        let atualv = {}
        let atualv2 = _.cloneDeep(atualValues)
        delete atualv2[field]
        for (const key in camposPreencher) {
            if (Object.hasOwnProperty.call(camposPreencher, key)) {
                const element = camposPreencher[key];
                _.set(atualv, element, _.get(atualValues, element))
            }
        }

        let messages = []
        // if (!inicializado) {

            messages.push({ role: "system", content: 'Voce esta integrado a uma plataforma chamada OkApp que permite preenxer e consultar dados de formularios que são armazenados em objetos JSON. Voce é um assistete de preenximento e consulta de dados dentro desta plataforma.' })
            messages.push({ role: "system", content: 'Responda preferencialmente com os dados da tela e dos campos atuais.' })
            messages.push({ role: "system", content: 'Voce está em um formulario que possue os sequintes campos: ' + (camposPreencher ? JSON.stringify(camposPreencher) : "[]") })
            messages.push({ role: "system", content: 'O valor atual do objeto da tela é: ' + (atualv ? JSON.stringify(atualv) : "{}") })
            messages.push({ role: "system", content: 'As funcões recebidas devem ser apenas utilazadas e não usadas de exemplo em respostas de como fazer.' })
            if (pronptOrientacao) {
                messages.push({ role: "system", content: pronptOrientacao })
            }
        // }

        console.log('messages', messages)


        let userPrompt = prompt
        console.log('userPrompt', userPrompt)
        messages.push({ role: "user", content: userPrompt })

        // const model = "gpt-3.5-turbo"
        // const model = "gpt-4"
        const url = 'https://api.openai.com/v1/chat/completions'
        const functions = []
        functions.push(



            {
                "name": "changeValues",
                "description": "Recebe um JSON e atualiza valor as variaveis do objeto da tela respeitando os nomes dos campos de referencia e a estrutura de dados dos valores de referencia. Somente deve ser chamada esta função se o usuario solicitar para alterar, preenxer, melhorar ou outra ação que compreendace a alteraçao de um valor existente.",
                "parameters": {
                    "type": "object",
                    "properties": {
                        "obj": {
                            "type": "object",
                            "description": "Objeto json com os valores atualizados",
                        },
                    },
                    "required": ["obj"],
                },
            }
        )
        if (campoResultadoFiltros) {

            functions.push(
                {
                    "name": "showObjets",
                    "description": "Recebe por parametro uma lista de objetos e exibe eles, esses objetos obrigatoriamente são copias de outros objetos que já estão nos valores da tela e atendem a alguma solicitação do usuario",
                    "parameters": {
                        "type": "object",
                        "properties": {
                            "objects": {
                                "type": "object",
                                "description": "Array de objetos a serem exibidos, obrigatoriamente eles possuem um campo uid",
                            },
                        },
                        "required": ["objects"],
                    },
                })

        }
            //  {
            //     "name": "findObj",
            //     "description": "Exibe o objeto json unico(não array) encontrado que atende a solicitação do usuario. Esta função deve ser chamada com um objeto copiado que deve estar obrigatoriamente nos valores da tela e deve conter um campo de uid.",
            //     "parameters": {
            //         "type": "object",
            //         "properties": {
            //             "obj": {
            //                 "type": "object",
            //                 "description": "Objeto unico json encontrado",
            //             },
            //         },
            //         "required": ["obj"],
            //     },
            // }
        
    const params = {
        model: model||"gpt-3.5-turbo",
        functions,
        messages,
    }

    // console.log('params', params)
    setLoadingGpt(true)
    const resolveChoice = (choice, resolve) => {
        setInicializado(true)
        const chatResponse = choice.message.content;
        console.log('choice', choice)
        if (choice.finish_reason === "function_call") {
            let function_call = choice.message.function_call
            let argumentos = function_call.arguments
            if (function_call.name === "changeValues") {
                console.log('argumentos', argumentos)

                try {

                    let jsonR = JSON.parse(argumentos.trim())
                    // console.log('jsonR', jsonR)

                    for (const key in jsonR) {
                        if (Object.hasOwnProperty.call(jsonR, key)) {
                            const element = jsonR[key];
                            formProps.change(key, element)
                        }
                    }

                } catch (error) {
                    console.log('erro ao criar o json')
                }

                setLoadingGpt(false)
                resolve(chatResponse + "\nAtualizar Valores: " + argumentos)
            }
            if (function_call.name === "showObjets") {
                setLoadingGpt(false)
                console.log('campoResultadoFiltros',campoResultadoFiltros)
                console.log('argumentos',argumentos)
                formProps.change(campoResultadoFiltros, argumentos.objects)
                resolve(chatResponse + "\n showObjets: " + argumentos)
            }

            if (function_call.name === "findObj") {
                setLoadingGpt(false)
                resolve(chatResponse + "\nfindObj: " + argumentos)
            }
        }

        // try {

        //     let jsonR = JSON.parse(chatResponse.trim())
        //     // console.log('jsonR', jsonR)

        //     for (const key in jsonR) {
        //         if (Object.hasOwnProperty.call(jsonR, key)) {
        //             const element = jsonR[key];
        //             formProps.change(key, element)
        //         }
        //     }

        // } catch (error) {
        //     console.log('erro ao criar o json')
        // }
        setLoadingGpt(false)
        resolve(chatResponse)
    }

    return new Promise(async resolve => {
        try {
            // gptChat({ chatObj: params }).then(response222 => {
            //     console.log('response222', response222)
            //     let choice = response222.data.choices[0]
            //     resolveChoice(choice, resolve)
            // })

            const response = await axios.post(url, params, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${openAIKey}`,
                    // 'User-Agent': 'Mozilla/5.0'
                }
            });

            let choice = response.data.choices[0]
            resolveChoice(choice, resolve)
        } catch (error) {
            console.error("Error creating chat completion:", error);
        }

    })




}
return (
    <Card style={{ padding: 5, marginBottom: 15 }}>
        <div style={{ display: 'flex', justifyContent: "space-between", paddingBottom: 10 }}>
            <div style={{ flex: 1 }}>
                <Typography variant="h6">{label} - Preenchedor por Voz</Typography>

                <TextField
                    value={text}
                    onChange={event => setText(event.target.value)}
                    label={"Comando de atualização"}
                    error={(errorTest) ? true : false}
                    helperText={errorTest}
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                    }}
                    style={{ marginBottom: 15 }}
                    onKeyPress={onKeyPress}
                />
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end", flexDirection: "column", padding: "15px 10px" }}>
                <div>
                    <Button style={{ marginRight: 5 }} variant="contained" color="primary" size="small" onClick={isRecording ? stopSpeechToText : startSpeechToText}> {isRecording ? 'Ouvindo...(Parar)' : autoSend ? "Iniciar Escuta" : 'Falar'}</Button>
                    <Button variant="contained" color="primary" size="small" onClick={addComentario}>{textBtComentar || "Enviar"}</Button>
                </div>
            </div>
        </div>
        {loadingGpt && <CircularProgress />}
        {listSort.map((c, i) => <ComentarioItem key={i} comment={c} index={i} deleteComment={deleteComment} ehNovo={novos.includes(c.uid)} />)}
        {!listSort.length && <Typography style={{ textAlign: 'center' }}>{msgSemComentario || "Sem comentários"}</Typography>}

    </Card>
)
}
