import React, { useEffect, useState } from 'react'
import { useParams, Route, Switch, useRouteMatch, useHistory } from 'react-router-dom'
import { getListOkAppsRoutes, getListOkApps, salvarPerfilLogin } from 'dbApi'

import { useOkAppContext } from 'OkApp/context';
import PageLogin from "../../../public/Login/components/Page";
import PageRegister from "../../../public/Register/components/Page";
import useAuth, { isAuthenticated } from 'useAuth';
import RoutesMapDinamic from './RoutesMapDinamic';

import OKAppLoading from 'components/OKAppLoading';



const RoutesMapDinamicSemHistore = ({ routes }) => {
    return <RoutesMapDinamic routes={routes} />
}
export default function DinamicConfig() {
    const { appRoute } = useParams();
    const { url } = useRouteMatch();



    const { okApp, setOkApp, routes, loadingOkApp, setLoadingOkApp, loadingRoutes } = useOkAppContext()
    const history = useHistory();
    let loading = !okApp || loadingOkApp || !routes || loadingRoutes

    if (loading) {
        // console.log('loading')
        // console.log('!okApp || loadingOkApp || !routes || loadingRoutes', !okApp, loadingOkApp, !routes, loadingRoutes)
    }
    let authenticated = isAuthenticated();
    const { user } = useAuth();

    const [existeAppParaRota, setExisteAppParaRota] = useState(undefined);


    useEffect(() => {


        const redirectSeNaoTemApp = (onlyRoute) => {
            console.log("app nao encontradao", appRoute);
            console.log('okApp', okApp)
            console.log('existeAppParaRota', existeAppParaRota)

            // history.push("/Login")\
            //se auth informar que nao tem permição para esse app e falar pra onde ir, (verificar se realmente o app existe)
            //se não auth, 404
            setLoadingOkApp(false)
            if (authenticated) {

                //nao existe ou nao tem permissao
                if (existeAppParaRota !== undefined) {
                    if (existeAppParaRota) {
                        //exibir que nao tem permissao e o app não tem nada publico
                        // setOkApp(existeAppParaRota)


                        history.push("/NotAuthorized?rota=" + appRoute)


                    } else {
                        //não exite app para essa rota
                        history.push("/AppNotFound?rota=" + appRoute)

                    }
                }

            } else {
                //não exite app para essa rota(Devia ter pelo menos a rota de login )
                history.push("/AppNotFound?rota=" + appRoute)
            }
        }
        const recebeuDocs = (docs, onlyRoute) => {
            if (docs.size === 1) {//achou o app
                // console.log('Set OKApp')
                if (onlyRoute) {

                    setOkApp({ ...docs.docs[0].data(), onlyRoute: true })
                } else {
                    setOkApp(docs.docs[0].data())
                }
                if (authenticated) {
                    salvarPerfilLogin(user(), docs.docs[0].data())
                }
            } else {
                console.log("mais de um app encontrado para esta rota.. ERRO!!!!!!", appRoute);
                console.log('docs', docs)
                docs.forEach(docSnapshot => {
                    console.log("ddddd", docSnapshot.data());
                });

            }
        }

        if (authenticated) {
            if (!okApp || okApp.onlyRoute || okApp.route !== appRoute) {

                // console.log('buscaOKapp authenticated', appRoute)
                setLoadingOkApp(true)
                getListOkApps(appRoute)
                    .then((r) => {

                        if (!r.size) {
                            redirectSeNaoTemApp()
                        } else {
                            // console.log('recebeuDocs authenticated')
                            recebeuDocs(r)
                        }

                    })
                    .catch((error) => {
                        console.log("catch getCollection:", error);
                    });
            }
        } else {
            if (!okApp || okApp.route !== appRoute) {
                // console.log('buscaOKapp nao authenticated')
                setLoadingOkApp(true)
                getListOkAppsRoutes(appRoute)
                    .then((r) => {
                        if (!r.size) {
                            redirectSeNaoTemApp(true)
                        } else {
                            console.log('recebeuDocs nao authenticated')
                            recebeuDocs(r, true)
                        }
                    })
                    .catch((error) => {
                        console.log("catch getCollection:", error);
                    });
            }
        }

        return () => {

            //console.log('Clear OKApp1')
            // setOkApp(null);

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appRoute, authenticated, existeAppParaRota])


    useEffect(() => {
        // console.log('BBBBBBBBBBBBBBBBB appRoute',appRoute)
        getListOkAppsRoutes(appRoute)
            .then((r) => {
                if (r.size) {
                    setExisteAppParaRota(r.docs[0].data())
                } else {
                    setExisteAppParaRota(false)
                }

            })
            .catch((error) => {
                setExisteAppParaRota(false)
            });

        return () => setExisteAppParaRota(undefined)
    }, [appRoute])

    let newRoutes = routes ? routes.slice() : []

    if (newRoutes.map(r => r.path).includes("/app/:appRoute/")) {
        // console.log('tem inicio sem')
    } else {
        if (!authenticated) {
            newRoutes.push({
                path: '/app/:appRoute/', exact: true, component: () => <PageLogin okApp={okApp} pagePosLogin={"/app/" + okApp.route + "/"} />
            })
        }
    }



    // newRoutes.push({
    //     path: '/app/:appRoute/', component: () => <Page404 />
    // })


    return (<>
        {(loading) ? <OKAppLoading full /> :
            <>
                <Switch>
                    <Route exact path={`${url}/Login`}>
                        <PageLogin okApp={okApp} pagePosLogin={"/app/" + okApp.route + "/"} />
                    </Route>
                    <Route exact path={`${url}/Register`}>
                        <PageRegister okApp={okApp} pagePosLogin={"/app/" + okApp.route + "/"} />
                    </Route>
                    <RoutesMapDinamicSemHistore routes={newRoutes} />

                </Switch>
            </>
        }
    </>
    )
}
