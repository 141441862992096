import React from 'react'
import './style.css'

import clsx from 'clsx';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

import { Button, useMediaQuery, useTheme } from '@material-ui/core';
import { useCrudContext } from 'crud';
import LogoOkapp from './okapplogo.png'
import { permiteEditar } from 'OkApp/modules/PermissionMeta/functions';
import { useOkApp } from 'OkApp/UseUtils';
import * as Icons from '@material-ui/icons';


const sortActions = (menus) => {
    return menus.sort((a, b) => {
        let ac = a.index || 0;
        let bc = b.index || 0;

        if (ac < bc)
            return -1;
        if (ac > bc)
            return 1;

        ac = a.text;
        bc = b.text;
        if (ac < bc)
            return -1;
        if (ac > bc)
            return 1;

        return 0;
    });
}
export default function AppBarTop(props) {
    const { classes, handleDrawerOpen, open } = props;
    const { title, logoForm, actions, visibilityTopBar } = useCrudContext();
    const sortedActions = sortActions(actions || [])
    const okapp = useOkApp();
    const dev = permiteEditar(okapp);

    const theme = useTheme();

    const mobile = useMediaQuery(theme.breakpoints.down('sm'));

    let imgFormLogo = null

    if (okapp && okapp.showLogoForm && okapp.logo) {
        imgFormLogo = `data:image/png;base64,${okapp.logo}`
    }

    if (logoForm) {
        imgFormLogo = logoForm
    }

    return (<>

        {(dev && !visibilityTopBar && !open) && <div style={{ position: 'absolute', zIndex: 7000, paddingLeft: 16, margin: 10 }}>

            <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx(classes.menuButton, open && classes.hide)}
            >
                <MenuIcon />
            </IconButton>
            <div style={{ fontSize: 9, marginTop: -15, marginLeft: -5 }}>
                Dev Only
            </div>

        </div>}
        <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
                [classes.appBarShift]: open,
            })}
            style={{
                display: visibilityTopBar ? 'flex' : "none",
                zIndex: 1290
            }}
        >
            <Toolbar className="topTollBar">
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    className={clsx(classes.menuButton, open && classes.hide)}
                >
                    <MenuIcon />
                </IconButton>
                {title === "OkApp" ?
                    (
                        <div className={classes.appBarLogo}>
                            <img alt="mainImage" style={{ height: 40 }} src={LogoOkapp} />
                        </div>
                    )
                    :
                    <>
                        {imgFormLogo &&
                            <img alt="mainImageTop" style={{ height: 40, flex: 0, paddingRight: 15, marginLeft: -15 }} src={imgFormLogo} />
                        }
                        <Typography variant="h6" noWrap style={{ flex: 1, fontSize: mobile ? 15 : 'none' }}>
                            {title}
                        </Typography>
                    </>
                }

                <div className="actionsAppBar" >
                    {sortedActions.map((a, index) => {

                        if (a)

                            if ((a.props && !a.props.hidden) || !a.props) {
                                let startIcon = null;
                                if (a.icon) {
                                    const RecIcon = Icons[a.icon]
                                    startIcon = <RecIcon />
                                }
                                if (mobile && startIcon) {
                                    return (<IconButton className='topbarbuttomaction' key={"BtActionCrud" + index}
                                        variant="contained" color="secondary" onClick={a.action} {...a.props}  >
                                        {startIcon}
                                    </IconButton>)
                                }
                                return (<Button key={"BtActionCrud" + index}
                                    startIcon={startIcon}
                                    variant="contained" color="secondary" onClick={a.action} {...a.props}  >
                                    {a.text}
                                </Button>)
                            }
                            else
                                return ""
                        else
                            return ""
                    })}

                </div>
            </Toolbar>
        </AppBar >
    </>
    )
}
