import { Typography, useMediaQuery, useTheme } from '@material-ui/core'
import { useListOkApp } from 'OkApp/UseUtils';
import React, { useEffect, useState } from 'react'
import CardProjeto from 'routes/private/components/CardProjeto';
import _ from 'lodash'
import { usePerfil } from 'useAuth';
export default function FavoritosPublico() {
    const [projetosFilter, setProjetosFilter] = useState(null)
    const perfil = usePerfil()
    const projetos = useListOkApp();

    const theme = useTheme()
    const xs = useMediaQuery(theme.breakpoints.down('xs'));
    const sm = useMediaQuery(theme.breakpoints.down('sm'));
    const md = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        if (projetos && perfil && perfil.projetosFavoritos) {
            let p = projetos.filter((p) => {
                if (!perfil.projetosFavoritos[p.uid]) {
                    return false;
                }
                let publicp = _.get(p, "metaOkApp.permission.public");
                if (publicp) {
                    publicp = _.get(p, "listado");
                }

                return publicp;
            })

            p.sort((a, b) => {


                if (a.name > b.name) {
                    return 1;
                }
                if (a.name < b.name) {
                    return -1;
                }

                return 0
            })
            setProjetosFilter(p)



        } else {
            setProjetosFilter(null)
        }

    }, [projetos, perfil])
    return (
        <div>
            <Typography
                style={{ fontSize: xs ? 18 : 24, fontWeight: "bold", marginRight: "10px", fontFamily: 'poppins' }}
            >
                Favoritos público
            </Typography>

            <div style={{ display: 'grid', gridTemplateColumns: xs ? 'repeat(1, 1fr)' : sm ? 'repeat(1, 1fr)' : md ? 'repeat(2, 1fr)' : 'repeat(3, 1fr)', }}>
                {projetosFilter && projetosFilter.map((p, i) => <CardProjeto key={i} projeto={p} />)}
                {(!projetosFilter || !projetosFilter.length) && <p>Nenhum projeto marcado como favorito</p>}
            </div>
        </div>
    )
}
