import React from 'react'

import { OkTypedComponent, LabelInputAuto } from 'OkApp'
import CompView from './components/CompView'
const typeComponente = 'PainelAbaComp'

const AdapterOkComp = (props) => {
    return <CompView {...props} />
}
const EditorOkComp = (props) => {

    return <>
        <OkTypedComponent typeComponente='InputComp' field='field' label='Nome Salvo' />
        <LabelInputAuto {...props} />
        <OkTypedComponent typeComponente='SwitchComp' field='vertical' label='Vertical' />
        <OkTypedComponent typeComponente='SwitchComp' field='ocultarAbasSeUnica' label='Ocultar titulo das abas se só tiver uma' />

    </>
}

const modelo = {
    typeComponente,
    field: 'painelAbas',
    label: 'Painel Abas',
    children: []
}


const PainelAbaComp =  {
    typeComponente,
    configView: {
        grupo: 'Container',
        nome: 'Painel Abas',
        descricao: 'Painel Abas',
        helpUrl: 'https://okapp.com.br/app/okappdocs/painelAbas',
        modelo
    },
    Component: AdapterOkComp,
    CompEditor: EditorOkComp
}
export default PainelAbaComp