import React, { useState, useEffect } from 'react'
import { TextField, Accordion, AccordionSummary, Typography, AccordionDetails, makeStyles, MenuItem, Tooltip, withStyles, Link } from '@material-ui/core'


import InfoIcon from '@material-ui/icons/Info';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { sortArrayObj, getOkCompCore } from 'OkApp/functions';
import { DinamicComponentsList } from 'OkApp';
import { usePerfil } from 'useAuth';



const useStyles = makeStyles(theme => ({

    exPainel: {
        // backgroundColor:"#0f0"
    },
    exTitle: {

        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.contrastText,

    },
    exDetalhe: {
        padding: 0,
        display: 'block'
    },
    itemCOmp: {
        width: "100%",
        display: 'flex',
        justifyContent: 'space-between',
    },
    itemCOmpSel: {
        width: "100%",
        backgroundColor: "#f9b300",
        display: 'flex',
        justifyContent: 'space-between',
    }
}));

export default function ListComponents({ addComponet }) {

    const classes = useStyles();
    const [filtro, setFiltro] = useState("")

    const [listGrupos, setListGrupos] = useState([])
    const [keyComps, setKeyComps] = useState([])
    const [selTypeComp, setSelTypeComp] = useState('')

    const perfil = usePerfil();





    useEffect(() => {

        const atendeFiltro = (comp, filtro) => {

            if (comp.status === 'Desenvolvimento') {
                if (process.env.NODE_ENV === "production")
                    return false;
            }


            if (comp.legado) {
                return false;
            }
            if (!filtro) {
                return true;
            }

            if (comp.configView.nome.search(new RegExp(filtro, "i")) === -1) {
                return false;
            }


            return true;
        }

        let NovosGrupos = {}
        DinamicComponentsList.forEach(c => {

            if (atendeFiltro(c, filtro)) {

                let grupo = c.configView.grupo || "Padrão";
                let compsGrupo = NovosGrupos[grupo] || { title: grupo, comps: [] };
                compsGrupo.comps.push(c);
                compsGrupo.comps = sortArrayObj(compsGrupo.comps, [(c) => c.configView.nome])
                if (compsGrupo.title === "Básico") compsGrupo.index = 0
                if (compsGrupo.title === "Container") compsGrupo.index = 1
                if (compsGrupo.title === "Consulta") compsGrupo.index = 2
                NovosGrupos[grupo] = compsGrupo
            }
        })


        let listGrupos1 = []
        for (const key in NovosGrupos) {
            const c = NovosGrupos[key];
            listGrupos1.push(c)
        }

        listGrupos1 = sortArrayObj(listGrupos1, [(g) => {

            if (g.index !== undefined)
                return g.index;
            return g.title
        }])
        setListGrupos(listGrupos1);

        let listaCompk = [];
        listGrupos1.forEach(g => {
            g.comps.forEach(c => listaCompk.push(c.typeComponente))

        });
        setKeyComps(listaCompk)

    }, [filtro, perfil])

    const HtmlTooltip = withStyles(theme => ({
        tooltip: {
            backgroundColor: '#f5f5f9',
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 220,
            fontSize: theme.typography.pxToRem(12),
            border: '1px solid #dadde9',
        },
    }))(Tooltip);
    const RenderComp = ({ comp }) => {
        const { configView } = comp;

        return <MenuItem className={selTypeComp === comp.typeComponente ? classes.itemCOmpSel : classes.itemCOmp} onClick={() => addComponet(comp)}>


            <Typography variant="body2" gutterBottom>{configView.nome}</Typography>
            <HtmlTooltip title={<React.Fragment>
                <Typography variant="body2" gutterBottom color="inherit">{configView.nome}</Typography>
                <em>{configView.descricao}</em>
                <br />
                {configView.helpUrl &&
                    <Link target="_blank" href={configView.helpUrl} onClick={(evt) => evt.stopPropagation()} >
                        Mais informações...
                    </Link>
                }

            </React.Fragment>} placement="right" interactive>
                <InfoIcon />
            </HtmlTooltip>

        </MenuItem>

    }

    const RenderGrupo = ({ grupo, selTypeComp }) => {
        return (<Accordion className={classes.exPainel} defaultExpanded>
            <AccordionSummary
                className={classes.exTitle}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography variant="body1" gutterBottom>{grupo.title}</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.exDetalhe}>
                {grupo.comps.map((comp, index) => <RenderComp key={index} comp={comp} selTypeComp={selTypeComp} />)}
            </AccordionDetails>
        </Accordion >)
    }

    const onKeyDown = (e) => {
        let min = 0;
        let max = keyComps.length - 1;
        if (e.key === 'ArrowUp') {
            let indexOf = keyComps.indexOf(selTypeComp);
            indexOf--;
            if (indexOf < min) {
                indexOf = min;
            }
            if (indexOf > max) {
                indexOf = max;
            }
            let newSel = keyComps[indexOf];
            setSelTypeComp(newSel)

        }
        else if (e.key === 'ArrowDown') {
            let indexOf = keyComps.indexOf(selTypeComp);
            indexOf++;
            if (indexOf < min) {
                indexOf = min;
            }
            if (indexOf > max) {
                indexOf = max;
            }
            let newSel = keyComps[indexOf];
            setSelTypeComp(newSel)
        }
    }


    const onKeyPress = (e) => {
        if (e.key === 'Enter') {
            if (selTypeComp) {
                console.log('Enter add comp')
                let cop = getOkCompCore(selTypeComp);
                addComponet(cop)
            }
        }

    }


    return (
        <div style={{ width: "100%" }}>
            <TextField
                style={{ padding: 3 }}
                value={filtro}
                onChange={(e) => setFiltro(e.target.value)}
                placeholder="Filtrar"
                fullWidth
                autoFocus
                onKeyDown={onKeyDown}
                onKeyPress={onKeyPress}
            />
            <div>
                {listGrupos.map((g, index) => <RenderGrupo key={index} grupo={g} selTypeComp={selTypeComp} />)}
                {!listGrupos.length && <Typography style={{ width: '100%', display: "flex", justifyContent: "center" }} variant="caption">Nenhum item para o filtro</Typography>}
            </div>
        </div>
    )
}
