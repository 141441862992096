import useOKControlerField from 'components/OkStyleEditor/functionsUtils';
import React from 'react'
import { InputLabel } from '@material-ui/core'


export default function Font({ styleAtual, value, onChange }) {
    const okControler = useOKControlerField(value, onChange);


    return (
        <div className='OkStyleEditor-comp'>
            <InputLabel>Font</InputLabel>
            <div className='OkStyleEditor-row'>

                <div className='OkStyleEditor-cell50'>
                    <InputLabel>Size</InputLabel>
                    <input type='number' {...okControler('fontSize')} />
                </div>
                <div className='OkStyleEditor-cell50'>
                    <InputLabel>Color</InputLabel>
                    <input type='color' {...okControler('color')} />
                </div>


            </div>

            <div className='OkStyleEditor-row'>
                <div className='OkStyleEditor-cell50'>
                    <InputLabel>Style</InputLabel>
                    <select {...okControler('fontStyle')} >
                        <option value={'normal'}>Regular</option>
                        <option value={'italic'}>Italic</option>
                    </select>
                </div>
                <div className='OkStyleEditor-cell50'>
                    <InputLabel>Blold</InputLabel>
                    <select {...okControler('fontWeight')} >
                        <option value={""}>No</option>
                        <option value={700}>Yes</option>
                    </select>
                </div>
            </div>
        </div >
    )
}
