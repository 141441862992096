import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';


const useStyles = makeStyles((theme) => ({
    root: {
        // position: 'relative',
            //  width: '100%',
        //     overflow: 'hidden',
              paddingBottom: '56.25%',
        //    // height: '100%',
        //      height: 0,
        
    },
    cardCarrocel: {
        position: 'relative',
        width: '100%',
        overflow: 'hidden',
        // height: '100%',
        paddingBottom: '56.25%',

    },
    iframeVideo: {
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        height: '100%',
        width: '100%',
        position: 'absolute',
    },

}));

export default function Video() {
    const classes = useStyles();
    const embedId = 'RxrOvfaAna8'
    const embedId2 = '1MKc8EVafGc'
    return (
        <div className={classes.root}>
            <Carousel  showArrows={true}>
                <div className={classes.cardCarrocel} >
                    <iframe
                        className={classes.iframeVideo}
                        src={`https://www.youtube.com/embed/${embedId}`}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="Embedded youtube"
                    />
                    {/* <p className="legend">Legend 1</p> */}
                </div>
                <div className={classes.cardCarrocel}>
                    <iframe
                        className={classes.iframeVideo}
                        src={`https://www.youtube.com/embed/${embedId2}`}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="Embedded youtube2"
                    />
                    {/* <p className="legend">Legend 2</p> */}
                </div>
            </Carousel>

        </div>
    )
}
