
import { isAuthenticated } from '../../useAuth';
import ListPerfil from './ListPerfilProjeto'
import EditPerfil from './EditPerfilprojeto'
import _ from 'lodash'
export const routes = ({ perfilUser }) => {
    let r = [];

    if (isAuthenticated() && _.get(perfilUser, 'tipoPerfil') === "admin") {
        r = r.concat([{ exact: true, path: "/ListPerfis", component: ListPerfil }]);
        r = r.concat([{ exact: true, path: "/EditPerfil", component: EditPerfil }]);
    }
    return r
}
export const menus = (props) => {
    const { okApp, history, perfilUser } = props

    let r = [];
    if (!okApp) {//não esta dentro de um OkApp
        if (isAuthenticated() && _.get(perfilUser, 'tipoPerfil') === "admin") {
            r = r.concat([
                { text: "Administração", action: () => { history.push("/ListPerfis") }, iconKey: 'Settings', index: "999999" },

                // { text: "Administração", action: () => { history.push("/ListPerfis") }, iconKey: 'Settings', index: "999999" }
            ]);

        }
    }
    return r
}

