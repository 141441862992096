import React, { useState, useEffect } from "react";
import { useOkAppContext } from "OkApp/context";
import { getComponetWith } from "OkApp/functions";
import { PesquisaDialog } from "components";
import _ from 'lodash'
import { getFunctionCodeToFuncMap } from "OkApp/CodeProcessor";

export default function PesquisaOkFormObj(props) {
    const { multiple, okform, showPesquisa, setShowPesquisa, titulo, onSelect, funcFilter } = props;
    const [colunas, setColunas] = useState([])
    const [filtros, setFiltros] = useState([])
    const { okApp, database } = useOkAppContext();
    let colectionFirebase = null;
    if (okApp && okform) {
        colectionFirebase = 'Project/' + okApp.uid + '/ValuesCustomForm/' + okform.uid + "/Values";
    }


    useEffect(() => {
        if (okform) {

            const componets = getComponetWith(okform, { meta: { pesquisa: { coluna: true } } })
            if (componets) {
                let cols = []
                componets.forEach(comp => {
                    let funcToString = getFunctionCodeToFuncMap(comp.funcToString, "toStringPesquisa");

                    let newCol = { ...comp, ...comp.meta.pesquisa, funcToString };
                    cols.push(newCol)
                    // cols.push({ ...comp, ...comp.meta.pesquisa })
                })

                if (!cols.length) {
                    cols.push({ label: "UID", field: "uid" })
                }
                setColunas(cols)
            }


            const componetsFiltros = getComponetWith(okform, { meta: { pesquisa: { filter: true } } })

            if (componetsFiltros) {
                let fil = []
                componetsFiltros.forEach(comp => {
                    fil.push({ ...comp, ...comp.meta.pesquisa, okComp: comp })
                })
                setFiltros(fil)
            }

        }
    }, [okform])

    let tituloUsar = "Pesquisa de ";
    if (titulo) {
        tituloUsar = titulo
    } else {

        if (okform.meta && okform.meta.pesquisa && okform.meta.pesquisa.titlepesquisa) {
            tituloUsar = okform.meta.pesquisa.titlepesquisa
        } else {
            tituloUsar = tituloUsar + okform.name
        }
    }
    const filtraNaTela = _.get(okform, 'meta.pesquisa.buscaDepoisFiltra')
    return (
        <PesquisaDialog open={showPesquisa} handleClose={() => setShowPesquisa(false)}
            multiple={multiple}
            title={tituloUsar}
            database={database}
            filtraNaTela={filtraNaTela}
            colectionFirebase={colectionFirebase}
            onSelect={(selecionou) => {
                setShowPesquisa(false);
                if (selecionou.length) {
                    onSelect(selecionou)
                }
            }}
            columns={colunas || []}
            filters={filtros || []}
            funcFilter={funcFilter}
        />



    )
}
