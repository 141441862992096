import React, { useCallback, useEffect } from 'react'
import { useCrudContext } from 'crud';
import useNotification from 'notification';
import { useTranslation } from 'react-i18next';

import { getListOkAppsRoutes, resalvaPerfislLogin, salvarObj, salvarObjDatabase } from 'dbApi';
import { useListMeusOkApp } from 'OkApp/UseUtils';
import { usePerfil } from 'useAuth';
import { useHistory } from 'react-router-dom';
import app from 'firebase/compat/app';
import { LogTracker } from 'OkApp/OkTracker';
import { useLocation } from 'react-router-dom';

export default function CrudControlForm({ pristine, handleSubmit, submitting, colectionFirebase, atualValues }) {
    const perfil = usePerfil();
    const projetos = useListMeusOkApp();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    let numLimiteProjetos = 1;
    const uid = atualValues ? atualValues.uid : null;
    const route = atualValues ? atualValues.route : null;
    const { addActions, removeActions } = useCrudContext();
    const { showError, showSuccess } = useNotification();
    const { t } = useTranslation();
    const history = useHistory();

    let atigiuLimiteProjetos = false;
    if (perfil) {

        if (perfil.numProject) {
            numLimiteProjetos = parseInt(perfil.numProject);
        }
    }

    if (!uid && projetos && projetos.length >= numLimiteProjetos) {
        atigiuLimiteProjetos = true;
    }

    const actionVerificaIdFirebase = useCallback((obj, success, fail) => {
        let database = null;
        let config = { projectId: obj.firebaseProjectId };
        if (!config.projectId) {
            success();
        }

        if (config.projectId) {
            try {
                let iii = app.apps.map(item => item.name).indexOf('third' + config.projectId)
                if (iii === -1) {
                    app.initializeApp(config, 'third' + config.projectId);

                    iii = app.apps.map(item => item.name).indexOf('third' + config.projectId);
                }
                // let iii = app.apps.map(item => item.name).indexOf('third' + config.projectId)
                let fireApp = app.apps[iii];

                if (fireApp) {
                    database = fireApp.firestore();
                }

                if (database) {
                    salvarObjDatabase(database, { nome: 'valid' }, colectionFirebase,
                        (r) => {

                            if (success) {
                                success();
                            }
                        },
                        (err) => {
                            if (fail) {
                                fail();
                            }
                            showError('Firebase ProjectId está incorreto. Nada foi salvo!')
                        })
                }
            } catch (error) {
                console.error('Erro ao conectar com o banco ', error);
            }
        }
    }, [colectionFirebase, showError])

    const validaRota = (obj, indisponivel, disponivel) => {

        getListOkAppsRoutes(obj.route).then(docs => {
            if (docs.size) {
                let data = docs.docs[0].data();
                if (obj.uid && (obj.uid === data.uid)) {
                    if (disponivel) {
                        disponivel();
                    }
                } else {
                    if (indisponivel) {
                        indisponivel();
                    }
                }
            } else {
                if (disponivel) {
                    disponivel();
                }
            }
        }).catch(err => {
            console.error('Falha ao consultar a rota ', err);
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const actionSave = useCallback(handleSubmit((obj) => {
        if (colectionFirebase) {
            let rotaObj = { uid: obj.uid, name: obj.name, route: obj.route }

            if (obj.logo) {
                rotaObj.logo = obj.logo
            }
            if (obj.style) {
                rotaObj.style = obj.style
            }
            if (obj.formPerfil) {
                rotaObj.formPerfil = obj.formPerfil

            } else {
                rotaObj.formPerfil = null
            }
            if (obj.formInicio) {
                rotaObj.formInicio = obj.formInicio
            } else {
                rotaObj.formInicio = null
            }

            if (obj.formInicioNotAuth) {
                rotaObj.formInicioNotAuth = obj.formInicioNotAuth
            } else {
                rotaObj.formInicioNotAuth = null
            }
            if (obj.showLogoForm) {
                rotaObj.showLogoForm = obj.showLogoForm
            } else {
                rotaObj.showLogoForm = null
            }
            if (obj.ocultarLoginMenu) {
                rotaObj.ocultarLoginMenu = obj.ocultarLoginMenu
            } else {
                rotaObj.ocultarLoginMenu = null
            }
            let strCollectionRota = "Routes"
            actionVerificaIdFirebase(obj, retorno => {
                validaRota(obj, indisponivel => {
                    showError('Rota já utilizada!')
                }, disponivel => {
                    salvarObj(obj, colectionFirebase,
                        (r) => {
                            if (!queryParams.has('uid')) {
                                LogTracker("OkApp - criou novo projeto", { projectUid: obj.uid, projectName: obj.name, projectRoute: obj.route }, perfil)
                            }
                            console.log("CRUD - Sucesso Salvouuuu: ", r.msg);
                            showSuccess(r.msg)
                            resalvaPerfislLogin(obj)
                            if (obj.uid) {
                                history.push("Projeto?uid=" + obj.uid)
                            }
                        },
                        (err) => {
                            console.log("CRUD - error", err);
                            // showError(err.message)
                        }
                        , [{
                            obj: rotaObj.uid ? rotaObj : null, strCollection: strCollectionRota
                        }])
                })
            })

            //Reatibui user que ja acessaram app aos perfis



        } else {
            showError('Sem collection para salvar')
        }
    }), [colectionFirebase, handleSubmit, showError, showSuccess, actionVerificaIdFirebase])
    const abrirProjeto = useCallback(() => {
        history.push("app/" + route)

    }, [history, route])

    useEffect(() => {
        let add = [];
        if (!atigiuLimiteProjetos) {

            add.push({ uid: 'projeto.irpararota', text: "Ver Projeto", icon: null, action: abrirProjeto, props: { disabled: !(pristine || submitting) } })
            add.push({ uid: 'crud.actions.save', text: t('crud.actions.save'), icon: null, action: actionSave, props: { disabled: (pristine || submitting) } })
        }

        addActions(add)
        return () => { removeActions(['crud.actions.save', 'projeto.irpararota']); };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pristine, submitting, t, uid, atigiuLimiteProjetos])
    return (
        <>        </>
    )
}
