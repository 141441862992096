import React, { useState, useEffect } from 'react'
import './style.css'
import { LiveProvider, LivePreview, LiveError } from 'react-live';
import { CodeEditor, Paginador } from 'components';
import _ from 'lodash'
import { defaultSnippets } from 'OkApp/CodeProcessor'
import NewWindow from 'react-new-window'

import * as recharts from 'recharts'
import * as materialUiCore from '@material-ui/core';
import { Helmet } from "react-helmet";
import { useAtualValues, useReduxFormProps } from 'OkApp/UseUtils';
export const reactLiveHome = {
    plain: {
        color: '#e7d2ed'
    },
    styles: [
        {
            types: ['prolog', 'comment', 'doctype', 'cdata'],
            style: {
                color: 'hsl(30, 20%, 50%)'
            }
        },
        {
            types: ['property', 'tag', 'boolean', 'number', 'constant', 'symbol'],
            style: { color: '#f677e1' }
        },
        {
            types: ['attr-name', 'string', 'char', 'builtin', 'insterted'],
            style: {
                color: 'hsl(75, 70%, 70%)'
            }
        },
        {
            types: [
                'operator',
                'entity',
                'url',
                'string',
                'variable',
                'language-css'
            ],
            style: {
                color: 'hsl(40, 90%, 70%)'
            }
        },
        {
            types: ['deleted'],
            style: {
                color: 'rgb(255, 85, 85)'
            }
        },
        {
            types: ['italic'],
            style: {
                fontStyle: 'italic'
            }
        },
        {
            types: ['important', 'bold'],
            style: {
                fontWeight: 'bold'
            }
        },
        {
            types: ['regex', 'important'],
            style: {
                color: '#e90'
            }
        },
        {
            types: ['atrule', 'attr-value', 'keyword'],
            style: {
                color: '#f677e1'
            }
        },
        {
            types: ['punctuation', 'symbol'],
            style: {
                opacity: '0.7'
            }
        }
    ]
};

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }
    static getDerivedStateFromError(error) {
        console.log('getDerivedStateFromError', error)
        error['shouldIgnore'] = true;
        // Atualiza o state para que a próxima renderização mostre a UI alternativa.
        return { hasError: true };
    }


    componentDidCatch(error, info) {
        this.setState({ hasError: true });
        console.log('Capiturou error:', error)
        console.log('Capiturou error:', info)
    }


    render() {
        if (this.state.hasError) {
            // Você pode renderizar qualquer alternativa de UI
            return <div style={{ height: 40, width: '100%', backgroundColor: "#f00", display: 'flex', justifyContent: "center", alignItems: "center" }}>Erro no component. Veja o log.</div>;
        }
        return this.props.children;
    }
}

export default function ReactRender(props) {
    const [codeInternal, setCode] = useState("")
    const [showDialogCode, setshowDialogCode] = useState(false)
    const { code, editinview, developer, okfunctions, updateCode, input: { value } } = props;
    const atualValues = useAtualValues();
    const formProps = useReduxFormProps();
    const scope = { ...okfunctions, value, recharts, materialUiCore, Paginador, Helmet, atualValues, formProps };
    const onBlur = (code) => {//salvar comp 
        setCode(code);
        updateCode(code)
    }
    useEffect(() => { 
        if (!_.isEqual(code, codeInternal)) {
            setCode(code)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [code])

  
    return (<>

        <LiveProvider code={codeInternal} scope={scope} noInline={true} theme={reactLiveHome}>
            <ErrorBoundary>
                <LivePreview className={"StyledPreview"} />
            </ErrorBoundary>

            {(editinview && developer) && <>
                <materialUiCore.Button variant='contained' onClick={() => setshowDialogCode(true)}>Código em dialog</materialUiCore.Button>
                {!showDialogCode && <>                    
                    <CodeEditor input={{ value: codeInternal, onChange: setCode, onBlur }} height={1000} snippets={defaultSnippets.concat([])} label="React editor:" />
                    <LiveError className={"StyledError"} />
                </>
                }
            </>
            }
        </LiveProvider>

        {showDialogCode &&
            <NewWindow onUnload={() => setshowDialogCode(false)} >
                <LiveProvider code={codeInternal} scope={scope} noInline={true} theme={reactLiveHome}>
                    <div style={{ display: "flex", flexDirection: 'column', height: "100%" }}>
                        <div style={{ width: 1500, flex: 1, position: "relative" }}>
                            <CodeEditor input={{ value: codeInternal, onChange: setCode, onBlur }} useWorker={false} height={800} snippets={defaultSnippets.concat([])} label="React editor:" />
                        </div>
                        <div style={{ flex: 0 }}>
                            <LiveError className={"StyledError"} />
                        </div>
                    </div>

                </LiveProvider>
            </NewWindow>
        }

    </>
    )
}
