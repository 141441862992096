import React, { useState, useEffect } from 'react'
import { CrudForm } from 'crud'
import CrudControlForm from './CrudControlForm'
import { useOkApp } from 'OkApp/UseUtils'
import { OkTypedComponent } from 'OkApp';
import { useHistory, useLocation } from 'react-router-dom';
import { PesquisaDialog } from 'components';
import CodeEView from './CodeEView';
import PainelAbas from 'components/PainelAbas';
import EditorPermissions from 'OkApp/modules/PermissionMeta/components/EditorPermissions';
import ElaboradorVisual from '../ElaboradorVisual';
import VisualizadorPdf from './VisualizadorPdf';
import { Field } from 'redux-form';

import './style.css'
import SlyleRelatorioEditor from '../StyleRelatorioEditor';
import { Button, InputLabel, Typography } from '@material-ui/core';
import useOKControlerField from 'components/OkStyleEditor/functionsUtils';
const startFunctions = `function gerarRelatorio(f,dados, meta, imports) {
	//console.log('dados', dados)
	let l = dados;
	let conteudo1 = [];
	conteudo1.push(f.painel([
		[
			{ style: 'cellTitulo', text: 'UID' },
			{ style: 'cellValor', text: l.uid }
        ],
        [
			{ style: 'cellTitulo', text: 'Nome' },
			{ style: 'cellValor', text: l.nome }
			
		],
    ], { widths: [70, '*'] }))
    //console.log('imports', imports)
    // if (imports && imports.ModeloGraficos) {
        // console.log('Meta charts ', meta);
        // conteudo.push({ image: meta.chats.ModeloGraficos })
    // }

	return conteudo1
}

function getGraficos(dados, meta) {//não esta pronto
	//console.log('dados', dados)
	let charts = [];
    charts.push({
        name: 'ModeloGraficos',
        type: 'BarChart',
        columns: ['Jan','Fev','Mar'],
        series: [{ name: '', values: [1,2,5], props: { type: "monotone", fill: "#8884d8" } }]
    })
	return charts
}
`

const ButtonDuplicar = () => {
    return <Field name="uid" component={({ input: { value, onChange } }) => {
        console.log('value template', value)
        return (<Button onClick={() => { onChange(null) }}>Duplicar</Button>

        )
    }} />
}
export default function OkReportElaboracao(props) {
    let okApp = useOkApp();
    const [openPesquisa, setOpenPesquisa] = useState(false)
    const [jsonValue, setJsonValue] = useState({})
    const [metaPre, setMetaPre] = useState({})
    const okControler = useOKControlerField(jsonValue, setJsonValue);
    const history = useHistory();
    const { pathname, state } = useLocation();


    let initialValues = { globalStyle: '.CustomFormContainer{padding:10px; padding-bottom: 45px;}' };

    if (state && state.chaveRelatorio) {
        initialValues.chaveRelatorio = state.chaveRelatorio
    }

    useEffect(() => {
        if (state && state.jsonValue) {
            setJsonValue(state.jsonValue)
        }
        if (state && state.metaPre) {
            console.log('state.metaPre', state.metaPre)
            setMetaPre(state.metaPre)
        }
    }, [state])


    if (!okApp) {
        return <div>loading okApp</div>
    }
    let colectionFirebase = 'Project/' + okApp.uid + '/OkReport';
    const duplicar = () => {
        console.log('props', props)
    }

    return (<div style={{ height: 'calc( 100% - 64px)', position: 'relative' }}>
        <CrudForm
            title={"Elaborar Relatório"}
            form={"OkReportElaboracao"}
            initialValues={initialValues}
            colectionFirebase={colectionFirebase}
            salvar novo
            noModules
            InsertComp={CrudControlForm}
            setOpenPesquisa={setOpenPesquisa}
        >

            <PainelAbas
                style={{ height: '100%' }}
                tabStyle={{ height: '100%' }}
                typoStyle={{ height: "calc( 100% - 48px)" }}
                abaTitulos={["Relatório", "Visualizador", "Permissões", "Code",]}>
                <div>{/* Relatorio */}
                    {/* <TestComp /> */}
                    <div style={{ display: 'flex' }}>
                        <OkTypedComponent typeComponente='InputComp' field='nome' label='Nome' required />
                        <OkTypedComponent typeComponente='InputComp' field='chaveRelatorio' label='Chave' required />
                        <OkTypedComponent typeComponente='InputComp' field='version' label='Versão' helperText="Para controle de vesão do relatório" />
                        {/* <ButtonDuplicar /> */}
                    </div>
                    <OkTypedComponent typeComponente='InputComp' field='descricao' label='Descrição' />
                    <CodeEView jsonValue={jsonValue} label="Gerar Arquivo PDF" metaPre={metaPre} />
                    {/* <CodeEView jsonValue={jsonValue} /> */}
                    <Field name="componentes" label='Componentes' jsonValue={jsonValue} component={ElaboradorVisual} />

                </div>
                <div style={{ display: "flex", flex: 1, height: "100%" }}>

                    <VisualizadorPdf jsonValue={jsonValue} metaPre={metaPre} />
                    <div style={{
                        borderLeft: '2px solid black',
                        paddingLeft: 5,
                        paddingRigth: 5,
                        width: 310

                    }}>
                        <div>

                            <Typography variant="h6" style={{ flex: 1 }}>Documento</Typography>
                            <div className='StyleRelatorioEditor-comp' style={{ marginBottom: 10 }}>
                                <div className='StyleRelatorioEditor-row'>
                                    <div className='StyleRelatorioEditor-cell50'>
                                        <InputLabel>Page Size</InputLabel>
                                        <Field name="pageSize" jsonValue={jsonValue} component={({ input: { value, onChange } }) => {
                                            let optValue = ["A4", "A3", { width: 254, height: 164 }]
                                            let atualValue = optValue.indexOf(value)
                                            if (value && atualValue === -1) {
                                                atualValue = 2
                                            }

                                            return (
                                                <select value={atualValue} onChange={v => {
                                                    let vs = optValue[v.target.value]
                                                    onChange(vs)
                                                }}>
                                                    <option value={0}>A4</option>
                                                    <option value={1}>A3</option>
                                                    <option value={2}>Cartão</option>
                                                </select>
                                            )
                                        }} />

                                    </div>
                                    <div className='StyleRelatorioEditor-cell50'>
                                        <InputLabel>Orientation</InputLabel>
                                        <Field name="pageOrientation" jsonValue={jsonValue} component={({ input: { value, onChange } }) => {

                                            return (
                                                <select value={value} onChange={v => onChange(v.target.value)}>
                                                    <option value={'portrait'}>Portrait</option>
                                                    <option value={'landscape'}>Landscape</option>
                                                </select>
                                            )
                                        }} />
                                    </div>
                                </div>
                                <div className='StyleRelatorioEditor-row'>
                                    <div className='StyleRelatorioEditor-cell50'>
                                        <InputLabel>Margin</InputLabel>
                                        <Field name="pageMargins" jsonValue={jsonValue} component={({ input: { value, onChange } }) => {
                                            let optValue = [null, [10, 10, 10, 10]]
                                            let atual = value ? 1 : 0
                                            return (
                                                <select value={atual} onChange={v => {
                                                    let vs = optValue[v.target.value]
                                                    onChange(vs)
                                                }}>
                                                    <option value={0}>Padrão</option>
                                                    <option value={1}>Minima</option>
                                                </select>
                                            )
                                        }} />

                                    </div>


                                </div>
                                <div className='StyleRelatorioEditor-row'>

                                    <InputLabel>Template de Página</InputLabel>
                                    <Field name="template" jsonValue={jsonValue} component={({ input: { value, onChange } }) => {
                                        console.log('value template', value)
                                        return (
                                            <select value={value} onChange={v => onChange(v.target.value)}>
                                                <option value={'padraoOkApp'}>Padrão OkApp</option>
                                                <option value={'padraoLimpo'}>Limpo</option>
                                            </select>
                                        )
                                    }} />


                                </div>
                            </div>

                        </div>
                        <Field name="styles" jsonValue={jsonValue} component={SlyleRelatorioEditor} />
                    </div>
                </div>


                <div>{/* Permissões */}
                    <EditorPermissions labelEditor="Editores" labelUsers="Visivel para" labelPublico="Publico a todos os Usuários" grupoFixePermission={["Ver"]} />
                </div>
                <OkTypedComponent typeComponente='FunctionsEditor'
                    // startFunc={"function gerarRelatorio(f,dados, meta, imports) {\n\t//console.log('dados', dados)\n\tlet l = dados;\n\tlet conteudo1 = [];\n\tconteudo1.push(f.painel([\n\t\t[\n\t\t\t{ style: 'cellTitulo', text: 'UID' },\n\t\t\t{ style: 'cellValor', text: l.uid }\n\t\t],\n\t], { widths: [70, '*'] }))\n\treturn conteudo1\n}"}
                    startFunc={startFunctions}
                    height={750}
                    field='code' label='Código do Relatório' />

                {/* Estilos */}
                {/* <div>
                    <Field name="styles" jsonValue={jsonValue} component={SlyleRelatorioEditor} />
                    <OkStyleEditorDEV />
                </div> */}

            </PainelAbas>

        </CrudForm>

        <PesquisaDialog open={openPesquisa} handleClose={() => setOpenPesquisa(false)}
            title={"Pesquisa de Relatórios"}
            filtraNaTela={true}
            getAll={true}
            colectionFirebase={colectionFirebase}
            onSelect={(selecionou) => {
                setOpenPesquisa(false);
                if (selecionou.length) {
                    history.push(pathname + "?uid=" + selecionou[0].uid)
                }
            }}
            columns={[{ label: "Nome", field: "nome" }, { label: "Chave", field: "chaveRelatorio" }]}
            filters={[]}
        />
    </div >

    )
}
