import React, { useCallback, useState, useEffect } from 'react'


// import Tabela from 'components/Tabela';
import TabelaGrid from 'components/Tabela Grid';
import { Typography, Button, Card, IconButton, Tooltip } from '@material-ui/core';
import { Add, Delete, MoreHoriz } from '@material-ui/icons';
import _ from 'lodash'
import OkTypedComponent from 'OkApp/components/OkTypedComponent';


import UpIcon from '@material-ui/icons/ArrowDropUp';
import DownIcon from '@material-ui/icons/ArrowDropDown';
import { useOkForm } from 'OkApp/UseUtils';
import PesquisaOkFormObj from 'OkApp/modules/DefaultPesquisa/components/PesquisaOkFormObj';
function array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
        new_index = arr.length - 1;
    }
    if (new_index < 0) {
        new_index = 0;
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
};
export default function CompView(props) {
    // console.log('props',props)
    const { input: { value, onChange }, meta: { touched, error }, field, label, colunas, newModel, exibeAdd, exibeLabel, permiteRemover, permiteMover, ordenarHeader, addButtons, msgSemRegistro, colSortStart, okFormAux, fieldAdd } = props
    const [showPesquisa, setShowPesquisa] = useState(false)
    const [lista, setLista] = useState(null)
    const [columns, setColumns] = useState([])

    // console.log('okFormAux', okFormAux)

    const okFormConsulta = useOkForm(okFormAux ? okFormAux.uid : null);
    useEffect(() => {
        let columnsAux = [];
        if (colunas) {
            columnsAux = columnsAux.concat(colunas)
        }

        if (!columnsAux.length) {
            columnsAux.push({ field: "uid", label: 'UID' });
        }
        // if (permiteRemover) {
        //     //add coluna remove 
        //     columnsAux = [{ field: "COL_TABLE_REMOVER", label: <MoreHoriz />, sizecol: "42" }].concat(columnsAux)

        // }

        // if (permiteMover) {
        //     //add coluna remove 
        //     columnsAux = [{ field: "COL_TABLE_MOVER", label: <MoreHoriz />, sizecol: "42" }].concat(columnsAux)
        // }
        if (permiteRemover || permiteMover) {
            //add coluna remove 
            columnsAux = [{ field: "COL_TABLE_ACTIONS", label: <MoreHoriz />, sizecol: permiteRemover && permiteMover ? "65" : "42", classHeaderAdd: "hideMobile" }].concat(columnsAux)

        }

        setColumns(columnsAux)
        return () => {
            setColumns([])
        }
    }, [colunas, permiteMover, permiteRemover])

    useEffect(() => {
        let lista = value || [];
        // if (colSortStart) {
        //     lista = sortArrayObj(lista, [(i) => i[colSortStart]])
        // }
        setLista(lista)
        return () => {
            setLista([])
        }
    }, [colSortStart, value])

    // let lista = value || [];
    // if (colSortStart) {
    //     console.log('colSortStart', colSortStart)
    //     console.log('lista', lista)
    //     lista = sortArrayObj(lista, [(i) => i[colSortStart]])
    // }

    const addItem = useCallback(() => {
        if (okFormAux) {
            setShowPesquisa(true)
        } else {

            onChange((value) => {
                let newArray = value ? value.slice() : [];
                newArray.push(newModel || {});
                return newArray;
            })
        }
    }, [newModel, okFormAux, onChange])


    const removeItem = useCallback((indexrow, data) => {
        onChange(value => {
            let newArray = value ? value.slice() : [];
            newArray.splice(indexrow, 1);
            return newArray;
        })
    }, [onChange])



    const moveUp = useCallback((indexrow, data) => {
        onChange(value => {
            let newArray = value ? value.slice() : [];
            array_move(newArray, indexrow, indexrow - 1)
            return newArray;
        })
    }, [onChange])

    const moveDown = useCallback((indexrow, data) => {
        onChange(value => {
            let newArray = value ? value.slice() : [];
            array_move(newArray, indexrow, indexrow + 1)
            return newArray;
        })
    }, [onChange])
    const getValueSort = (linha, coluna) => {
        if ("COL_TABLE_ACTIONS" === coluna.field) {
            return "";
        }
        const parseValueShow = (value, linha, coluna,) => {
            if (value) {
                if (value.constructor === String) {

                } else
                    if (value.constructor === Number) {

                    } else
                        if (value.toDate) {//timestamps                           net
                            value = value.toDate().getTime()


                        } else
                            if (value.constructor === Object) {
                                if (coluna && coluna.funcToString) {
                                    try {

                                        value = coluna.funcToString(value, linha, coluna)
                                        value = parseValueShow(value, linha);
                                    } catch (error) {

                                        console.log('error', error)
                                        console.log('coluna', coluna)
                                        value = "ERRO"
                                    }
                                } else {
                                    value = JSON.stringify(value)
                                }
                            }
            }
            return value;
        }
        let value = _.get(linha, coluna.field);
        value = parseValueShow(value, linha, coluna)
        return value;
    }
    const RenderCell = useCallback((props) => {
        const { coluna, linha, indexrow, data } = props
        // console.log('RenderCell', coluna.field)
        if ("COL_TABLE_ACTIONS" === coluna.field) {
            // permiteRemover||permiteMover
            return <div className={'tableGridShowOverRow2'} style={{ display: "flex", alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ display: "flex", flexDirection: "column", }}>
                    {permiteMover && <Tooltip title="Mover para cima">
                        <IconButton size='small' onClick={() => moveUp(indexrow)}><UpIcon /></IconButton>
                    </Tooltip>}
                    {permiteMover && <Tooltip title="Mover para baixo">
                        <IconButton size='small' onClick={() => moveDown(indexrow)}><DownIcon /></IconButton>
                    </Tooltip>}
                </div>

                {permiteRemover && <div><IconButton size='small' ><Delete onClick={() => removeItem(indexrow, data)} /></IconButton></div>}
            </div >

        }
        // if ("COL_TABLE_REMOVER" === coluna.field) {
        //     return <div style={{ display: "flex", justifyContent: 'center', height: '100%' }}>
        //         <IconButton size='small' onClick={() => removeItem(indexrow, data)}><Delete /></IconButton>
        //     </div>
        // }
        // if ("COL_TABLE_MOVER" === coluna.field) {
        //     return <div style={{ display: "flex", flexDirection: "column", justifyContent: 'center', height: '100%' }}>

        //         <Tooltip title="Mover para cima">
        //             <IconButton size='small' onClick={() => moveUp(indexrow)}><UpIcon /></IconButton>
        //         </Tooltip>
        //         <Tooltip title="Mover para baixo">
        //             <IconButton size='small' onClick={() => moveDown(indexrow)}><DownIcon /></IconButton>
        //         </Tooltip>
        //     </div>
        // }

        if (coluna.typeComponente) {
            let label = undefined;
            if (coluna.typeComponente === "ButtonComp") {
                label = coluna.label
            }

            return <OkTypedComponent {...coluna} label={label} field={field + '.' + indexrow + '.' + coluna.field} itemList={linha} intable="true" />
        } else {
            if (coluna.Component) {
                return <coluna.Component {...props} />
            }
            let value = _.get(linha, coluna.field);
            return <Typography component="div">{value || ""}</Typography>
        }
    },
        [field, moveDown, moveUp, removeItem, permiteRemover, permiteMover],
    )
    //  console.log('render tabela',lista)
    return (<Card style={{ padding: 5, marginBottom: 15 }}>
        {exibeLabel &&
            <div style={{ display: 'flex', justifyContent: "space-between" }}>
                <div style={{ display: 'flex' }}>
                    <Typography variant="h6">{label}</Typography>
                    {touched && error && <span style={{ alignSelf: 'center', color: '#ff5964', fontSize: 12 }}>Requerido</span>}
                </div>

                <div style={{ display: 'flex' }}>
                    {exibeAdd && <>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={addItem}
                            startIcon={<Add />}
                            disabled={props.disabled}
                        >
                            Adicionar
                        </Button>
                        {okFormConsulta && <PesquisaOkFormObj
                            multiple={true}
                            okform={okFormConsulta}
                            showPesquisa={showPesquisa}
                            setShowPesquisa={setShowPesquisa}
                            titulo={"Pesquisa de " + okFormConsulta.name}
                            // funcFilter={funcFilter}
                            onSelect={(sel) => {
                                console.log('sel', sel)
                                if (sel) {
                                    if (fieldAdd) {
                                        sel = sel.map(v => {
                                            let r = {}
                                            r[fieldAdd] = v;
                                            return r
                                        })
                                    }
                                    onChange((value) => {
                                        let newArray = value ? value.slice() : [];
                                        // newArray.push(sel[0]);
                                        return newArray.concat(sel);
                                    })
                                }

                            }
                            } />}
                    </>
                    }
                    {addButtons && addButtons}
                </div>
            </div>
        }


        {/* <Tabela
            data={lista}
            columns={columns}
            msgSemRegistro={msgSemRegistro || "Sem Registros"}
            RenderCell={RenderCell}    
        /> */}
        <TabelaGrid

            sortColuns={ordenarHeader}
            data={lista}
            resetData={onChange}
            columns={columns}
            msgSemRegistro={msgSemRegistro || "Sem Registros"}
            getValueSort={getValueSort}
            RenderCell={RenderCell}
        />

    </Card>
    )
}
