import React from 'react'
import OkCompAdapter from 'OkApp/components/OkCompAdapter'

import Editor from './components/Editor'
import CompView from './components/CompView'
import { getFunctionCodeToFuncMap, createDefaultFuncMapStr } from 'OkApp/CodeProcessor'

const typeComponente = 'FilterOkObj' //deve ter o mesmo nome de exportação no index

const AdapterOkComp = (props) => {
    const { okComp } = props;
    let funcGetCompareMap = getFunctionCodeToFuncMap(okComp.functionsmap, 'getCompareMap')

    let propsComp = { developer: props.developer, funcGetCompareMap }

    return <OkCompAdapter {...props} propsComp={propsComp} component={CompView} />
}


// function getCompareMap(props) {
//     //return {}; // busca tudo, colocar filtro aqui ex: {id:1} 
//     return null;//nao busca
// }

const modelo = {
    typeComponente,
    field: 'lista',
    label: 'Filtro Lista de itens',
    limitResultados: '50',
    salvarDados: false,
    functionsmap: createDefaultFuncMapStr('getCompareMap', "function getCompareMap(props) {\n\t//return {}; // busca tudo, colocar filtro aqui ex: {id:1}\n\treturn null;//nao busca\n}"),
}


const FilterOkObj = {
    typeComponente,
    // status: 'Desenvolvimento',
    configView: {
        grupo: 'Consulta',
        nome: 'Filtro de Objeto',
        descricao: 'Seletor de Objeto',
        helpUrl: 'https://okapp.com.br/app/okappdocs/filtroObjetos',
        modelo,
    },
    Component: AdapterOkComp,
    CompEditor: Editor,
    beforeSave: (obj, okComp) => {
        if (!okComp.salvarDados) {
            delete obj[okComp.field]
        }
        return obj;
    }
}

export default FilterOkObj

