import React, { useState, useEffect, useCallback } from "react";
import RenderOkForm from "./components/RenderOkForm";
import { DinamicComponentsList } from "OkApp";
import store from "reducer";
import getFormValues from 'redux-form/es/getFormValues'
import { isNumber } from "lodash";


var objectConstructor = ({}).constructor;


export function getOkCompCore(typeComponente) {
    for (let index = 0; index < DinamicComponentsList.length; index++) {
        const element = DinamicComponentsList[index];
        if (element.typeComponente === typeComponente) {
            return element;
        }
    }
}

export function getComponetWith(okform, json) {
    let listComponets = [];
    componetIteratorOkForm(okform, (okComp) => {
        let atende = true;
        for (var key in json) {
            var attrName = key;
            var attrValue = json[key];
            if (!jsonContain(okComp[attrName], attrValue)) {
                atende = false;
            }

        }
        if (atende) {
            listComponets.push(okComp)
        }
    })
    return listComponets;

}

function jsonContain(a, b) {
    if (!a && b) {
        return false;
    }
    if (a && !b) {
        return false;
    }

    if (b.constructor === objectConstructor) {
        for (var key in b) {
            var attrName = key;
            var attrValue = b[key];

            if (!jsonContain(a[attrName], attrValue)) {
                return false;
            }

        }
    } else {
        if (a !== b) {
            return false;
        }
    }
    return true;
}


export function getNewSmallUid() {
    return Math.random().toString(36).substring(7);
}

export function ajustNewOkComp(okComp, okForm) {

    if (!okComp.uid) {
        okComp.uid = Math.random().toString(36).substring(7)
    }
    if (okForm) {
        let f = okComp.field;
        let listaFil = [];
        componetIteratorOkForm(okForm, (c) => {

            if (c.field && c.field.startsWith(f)) {
                listaFil.push(c.field);
            }
        })
        let addInt = 1;
        let auxf = f;
        while (listaFil.includes(auxf)) {

            auxf = f + "_" + addInt;
            addInt++;
        }
        okComp.field = auxf;
    }
    return okComp;
}

export async function componetIteratorOkFormAsync(okform, func) {
    if (okform.children) {
        for (let index = 0; index < okform.children.length; index++) {
            const filho = okform.children[index];
            await compIteratorAsync(filho, func)
        }
    }
}
async function compIteratorAsync(okComp, func) {
    if (okComp.children) {
        okComp.children.forEach(filho => {
            compIteratorAsync(filho, func)
        });
    }
    await func(okComp)
}
export function componetIteratorOkForm(okform, func) {
    if (okform.children) {
        okform.children.forEach(filho => {
            compIterator(filho, func)
        });
    }
}
function compIterator(okComp, func) {
    if (okComp.children) {
        okComp.children.forEach(filho => {
            compIterator(filho, func)
        });
    }
    func(okComp)
}


export const createOkComponet = (name, typeComponente, label, propsComp) => {
    let propsCompUsar = propsComp || {}
    let compRender = { typeComponente, field: name, label: label || name, ...propsCompUsar }

    return <RenderOkForm key={name} okComp={compRender} />
}


export function useKeyPressed(keyCode) {
    // Keep track of key state
    const [pressed, setPressed] = useState(false)



    // Event handlers
    const onDown = useCallback(event => {
        if (keyCode === event.code) setPressed(true)
    }, [keyCode])

    const onUp = useCallback(event => {
        if (keyCode === event.code) setPressed(false)
    }, [keyCode])

    // Bind and unbind events
    useEffect(() => {
        window.addEventListener("keydown", onDown)
        window.addEventListener("keyup", onUp)
        return () => {
            window.removeEventListener("keydown", onDown)
            window.removeEventListener("keyup", onUp)
        }
    }, [keyCode, onDown, onUp])

    return pressed
}

export function useOnKeyDownAlt(keyCode, action) {
    // Keep track of key state
    const [pressed, setPressed] = useState(false)
    // Event handlers
    const onDown = useCallback(event => {

        if (event.altKey) {
            if (keyCode === event.code) {

                setPressed(true)
                action(event);
            }
        }
    }, [action, keyCode])

    const onUp = useCallback(event => {
        if (event.altKey)
            if (keyCode === event.code) setPressed(false)
    }, [keyCode])

    // Bind and unbind events
    useEffect(() => {
        window.addEventListener("keydown", onDown)
        window.addEventListener("keyup", onUp)
        return () => {
            window.removeEventListener("keydown", onDown)
            window.removeEventListener("keyup", onUp)
        }
    }, [keyCode, onDown, onUp])

    return pressed
}

export function sortArrayObj(array, arrayGetVar, invert) {
    return array.sort((a, b) => {
        let ac = 0
        let bc = 0
        for (let index = 0; index < arrayGetVar.length; index++) {
            const getVar = arrayGetVar[index];

            ac = getVar(a);
            bc = getVar(b);

            if (ac < bc)
                return invert ? 1 : -1;
            if (ac > bc)
                return invert ? -1 : 1;
        }

        return 0;
    });

}

export function getAtualValuesDoForm(nameForm) {
    return getFormValues(nameForm)(store.getState());

}
function toSlug(txt) {
    if (isNumber(txt)) {
        txt = txt + ""
    }
    let str = txt.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from = "ãàáäâèéëêìíïîõòóöôùúüûñç·/_,:;";
    var to = "aaaaaeeeeiiiiooooouuuunc------";
    for (var i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9 -]/g, '').replace(/\s+/g, '-').replace(/-+/g, '-');

    return str;
};
export function includesInsensitive(a, b) {
    return toSlug(a).includes(toSlug(b));
}


