import React, { useEffect, useState } from 'react'
import { useCrudContext } from 'crud'
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import _ from 'lodash'
import './style.css'
import { useListOkApp } from 'OkApp/UseUtils';

import { ContentLoading } from 'components';

import ViewFiltroProjetos from '../components/ViewFiltroProjetos';
import produce from "immer"

const route = "/Projeto";

export default function ConsultaProjeto() {

    const { setTitle, addAction, removeActions } = useCrudContext();
    const [projetosFilter, setProjetosFilter] = useState(null)

    const projetos = useListOkApp();
    const history = useHistory();
    const { t } = useTranslation();

    const updateItem = (item) => {
        const nextState = produce(projetosFilter, draftState => {
            for (let key in draftState) {
                if (draftState[key].uid === item.uid) {
                    draftState[key] = item;
                }
            }
        })
        setProjetosFilter(nextState)
    }
    useEffect(() => {
        setTitle("Aplicativos públicos")
        return () => setTitle("")

    }, [setTitle])


    useEffect(() => {
        addAction({ uid: 'crud.actions.new_project', text: t('crud.actions.new_project'), icon: null, action: () => history.push(route), props: {}, })
        return () => { removeActions(['crud.actions.new_project']) }

    }, [addAction, history, removeActions, t])

    useEffect(() => {
        if (projetos) {
            setProjetosFilter(projetos.filter((p) => {
                if (p.status !== "ready") {
                    return false
                }
                let publicp = _.get(p, "metaOkApp.permission.public");
                if (publicp) {
                    publicp = _.get(p, "listado");
                }

                return publicp;
            }))
        } else {
            setProjetosFilter(null)
        }

    }, [projetos])

    return (
        <div className="projects2Root">


            {!projetosFilter && <div className="projectContainer">
                <div style={{ margin: 10 }}>
                    <ContentLoading style={{ height: 100, width: 100 }} />
                </div>
                <div className="projectData">
                    <ContentLoading style={{ height: 18, width: 250 }} />
                    <div className="projectDescription">  <ContentLoading style={{ height: 12, width: 100 }} /></div>

                </div>
            </div>}
            {/* {projetosFilter && projetosFilter.map((p, i) => <CardProjeto  key={i}  projeto={p} />)} */}
            {projetosFilter && <ViewFiltroProjetos projetos={projetosFilter} updateItem={updateItem} />}
            {(projetosFilter && !projetosFilter.length) && <div className="projectContainer" >

                <div className="projectData">
                    <strong className="projectName" style={{ textAlign: "center" }}>Você não possui nenhum projeto. Cadastre um clicando no botão "NOVO" acima.</strong>
                    {/* <div style={{ margin: "0 auto", paddingTop: 10 }}>
                        <Button variant="contained" color="primary" onClick={novoProject} >Novo Projeto</Button>
                    </div> */}
                </div>
            </div>
            }

        </div >
    )
}
