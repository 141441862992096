import React from 'react'

import { usePerfil } from 'useAuth'
import { useListOkApp } from 'OkApp/UseUtils';

import Carousel from 'components/Carousel';

import { useMediaQuery, useTheme } from '@material-ui/core';
import { Box, Typography } from '@material-ui/core';

export default function UltimosProjetos() {
    let ultimosProjetos = []

    const projetos = useListOkApp();
    const perfil = usePerfil()

    const theme = useTheme()
    const xs = useMediaQuery(theme.breakpoints.down('xs'));

    if (perfil && projetos) {
        if (perfil.mapLastOkAppRoute) {
            let array = Object.keys(perfil.mapLastOkAppRoute).map(i => { return { route: i, date: perfil.mapLastOkAppRoute[i] } });
            array.sort((a, b) => a.date < b.date ? 1 : -1)
            array.splice(0, 6).forEach(r => {
                let rl = projetos.filter(p => p.route === r.route)
                if (rl) {
                    ultimosProjetos = ultimosProjetos.concat(rl)
                }
            });
        }
    }

    return (
        <Box style={{ marginBottom: 30 }} >
            {ultimosProjetos.length > 0 &&
                <>
                    <Box display="flex" alignItems="flex-start" justifyContent="flex-start" style={{ margin: '0 15px 0 15px' }}>
                        <Typography style={{ fontSize: xs ? 18 : 24, fontWeight: "bold", marginRight: "10px", fontFamily: 'poppins' }}>Últimos projetos acessados </Typography>
                    </Box>
                    <Carousel values={ultimosProjetos} />
                </>
            }
        </Box>
    )
}
