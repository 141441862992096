import React, { Component } from 'react'
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';

import { OkCompAdapter } from 'OkApp';




class SwitchItem extends Component {
    render() {
        const {
            input: { value, onChange },
            meta: { touched, error },
            label,
            disabled,
            defaultValue,
        } = this.props;
        let bool = false;

        if (!value && value !== false) {
            if (defaultValue !== undefined) {
                bool = defaultValue;
            }

        } else {
            bool = value
        }

        return (<div>
            <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", flex: '1' }}>
                <Switch checked={bool} onChange={(b) => onChange(b)} disabled={disabled} />
                <Typography >{label}</Typography>
            </div>
            {touched && error &&

                <Typography style={{
                    color: '#ff5964',
                    fontSize: 12,
                }}>{error}</Typography>
            }
        </div>
        )
    }
}



export default function Comp(props) {
    return (<OkCompAdapter {...props} component={SwitchItem} />)
}

