import React from 'react'
import { Card, Typography, Button } from '@material-ui/core'
import { useUserSnap } from 'OkApp/UseUtils';
import { ContentLoading } from 'components';
import { Delete } from '@material-ui/icons';
import ReactPlayer from 'react-player';
function downloadFile(sUrl, nomeArquivo) {

    //iOS devices do not support downloading. We have to inform user about this.
    if (/(iP)/g.test(navigator.userAgent)) {
        //alert('Your device does not support files downloading. Please try again in desktop browser.');
        window.open(sUrl, '_blank');
        return false;
    }

    //If in Chrome or Safari - download via virtual link click
    if (navigator.userAgent.toLowerCase().indexOf('chrome') > -1 || navigator.userAgent.toLowerCase().indexOf('safari') > -1) {
        //Creating new link node.
        var link = document.createElement('a');
        link.href = sUrl;
        link.setAttribute('target', '_blank');

        if (link.download !== undefined) {
            //Set HTML5 download attribute. This will prevent file from opening if supported.
            var fileName = sUrl.substring(sUrl.lastIndexOf('/') + 1, sUrl.length);
            // console.log('fileName', fileName);
            // console.log('nomeArquivo', nomeArquivo);
            // if (nomeArquivo) {
            //     link.download = "nomeArquivo";
            // } else {
            link.download = fileName;
            // }
        }
        //Dispatching click event.
        if (document.createEvent) {
            var e = document.createEvent('MouseEvents');
            e.initEvent('click', true, true);
            link.dispatchEvent(e);

            return true;
        }
    }

    // Force file download (whether supported by server).
    if (sUrl.indexOf('?') === -1) {
        sUrl += '?download';
    }

    window.open(sUrl, '_blank');
    return true;
}
export default function AnexoItem({ anexo, index, deleteAnexo, type, disabled }) {
    const { dateCreate, userUid } = anexo;
    let userload = useUserSnap(userUid);
    let strDate = new Date(dateCreate).toLocaleDateString('pt-BR', { timeZone: 'UTC' });
    let exibeDeletar = true;
    if (disabled) {
        exibeDeletar = false;
    }
    let CompMiniatura = null;
   
    let tipeIcon = 'file';
    if (anexo.type.startsWith('image')) {
        tipeIcon = "image"
    }
    if (anexo.type.startsWith('video')) {
        tipeIcon = "video"
    }
    if (anexo.type.startsWith('audio')) {
        tipeIcon = "audio"
    }

    if (tipeIcon === "image") {
        CompMiniatura = <img alt={'AnexoItemMin' + index} src={anexo.downloadURL} onClick={() => { downloadFile(anexo.downloadURL, anexo.filename) }} />;
    }
    if (tipeIcon === "video") {
        CompMiniatura = <ReactPlayer
            width="100%"
            height="100%"
            url={anexo.downloadURL}
            controls={true}
            className="AnexoItemMiniaturaVideo"
            config={{
                file: {
                    attributes: {
                        controlsList: 'nodownload'
                    }
                }
            }}

        />
    }
  
    if (tipeIcon === "audio") {
        CompMiniatura = <ReactPlayer
            width="100%"
            height="55px"
            url={anexo.downloadURL}
            controls={true}
            className="AnexoItemMiniaturaAudio"
            config={{
                file: {
                    attributes: {
                        controlsList: 'nodownload'
                    }
                }
            }}

        />
    }

    return (
        <Card className='AnexoItemCard' variant="outlined">
          
            <div className='AnexoItemAbrir'>
                <Button variant="contained" color="primary" onClick={() => { downloadFile(anexo.downloadURL, anexo.filename) }}>Ver</Button>
            </div>
            <div className='AnexoItemDados' style={{ flex: 1 }}>
                <Typography variant='subtitle1'>{anexo.realName || anexo.filename}</Typography>
                {userload ? <Typography variant='caption'>{userload.name}</Typography> : <ContentLoading style={{ height: 14, width: 200, margin: 0 }} />}
            </div>
            {CompMiniatura && <div className='AnexoItemMiniatura'>
                {CompMiniatura}
            </div>}
            <div className='AnexoItemDados' style={{ alignItems: 'center' }}>
                {exibeDeletar && <Delete style={{ cursor: 'pointer' }} onClick={() => deleteAnexo(anexo, index)} />}
                <Typography variant='caption'>{strDate}</Typography>
            </div>


        </Card>
    )
}
