import React, { useEffect, useCallback, useState } from 'react'
import _ from 'lodash'

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { deleteObj, salvarObjDatabase } from 'dbApi';
import useNotification from 'notification';
import OkFormAbaAdapter from 'OkApp/components/OkFormAbaAdapter';
import { useOkApp } from 'OkApp/UseUtils';
import { ajusteRoute, useOkAppContext } from 'OkApp/context';
import {  getOkCompCore } from 'OkApp';
import { ModuleFuncBeforeSave } from '..';
import OkTypedComponent from 'OkApp/components/OkTypedComponent';

import { Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Button } from '@material-ui/core';
import { getUserUid } from 'OkApp/OkFunctions';
import { componetIteratorOkFormAsync } from 'OkApp/functions';

const metaVar = "meta.actions.";

const getValue = (chave, obj) => {
    return _.get(obj, metaVar + chave)
}

const DialogConfirm = ({ open, handleClose, acaoDeletar }) => {
    const [textoConfirm, setTextoConfirm] = useState("")
    const txtConfimDelete = "Deletar";

    const handleCloseDo = () => {
        handleClose();
        setTextoConfirm("");
    }
    return <Dialog
        open={open} onClose={handleCloseDo} aria-labelledby="form-dialog-title"
        PaperProps={{ style: { height: 'auto', flex: 1 } }}
    >
        <DialogTitle id="form-dialog-title">Excluir Registros</DialogTitle>
        <DialogContent>
            <DialogContentText>
                Esta ação excluirá permanentemente esse registro. O registro removido NÃO PODE ser restaurado! Tem certeza ABSOLUTA?
                <br />
                <br />
                Esta ação pode levar à perda de dados. Para evitar ações acidentais, solicitamos que você confirme sua intenção.
            </DialogContentText>
            <TextField
                autoFocus
                margin="dense"
                value={textoConfirm}
                label={'Digite "' + txtConfimDelete + '" para confirmar a exclusão'}

                onChange={(event) => {
                    setTextoConfirm(event.target.value);
                }}

                fullWidth
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={handleCloseDo} color="secondary">
                Cancelar
            </Button>
            <Button variant='contained'
                onClick={() => {
                    handleCloseDo()
                    acaoDeletar();
                }}
                color="primary"
                disabled={txtConfimDelete.toUpperCase() !== textoConfirm.toUpperCase()}
            >
                Excluir Registro
            </Button>
        </DialogActions>
    </Dialog>
}

export function CrudInsertComp({ okform, pristine, initialize, handleSubmit, submitting, initialValues, novo, salvar, colectionFirebase, atualValues, addActions, removeActions, setVisibilityTopBar }) {

    const okApp = useOkApp()
    const { database } = useOkAppContext();
    const uid = atualValues ? atualValues.uid : null;

    const { showError, showSuccess } = useNotification();
    const { t } = useTranslation();
    const history = useHistory();

    const [open, setOpen] = React.useState(false);


    const handleClose = () => {
        setOpen(false);

    };
    const acaoDeletar = useCallback(() => {

        let objDeletar = atualValues;


        console.log('acaoDeletar', objDeletar)

        if (colectionFirebase) {
            deleteObj(objDeletar, colectionFirebase,
                (r) => {
                    console.log("CRUD - Deletado com sucesso", r.msg);
                    showSuccess(r.msg)
                    history.push(history.location.pathname);
                    initialize(initialValues);
                },
                (err) => {
                    console.log("CRUD - Deletado error", err);
                    showError(err.message)
                }
            )
        } else {
            showError('Sem collection para excluir')
        }
    }, [atualValues, colectionFirebase, history, initialValues, initialize, showError, showSuccess])

    const actionNovo = useCallback(() => {
        history.push(history.location.pathname);
        initialize(initialValues);
        // console.log('actionNovo') 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history, initialValues])

    useEffect(() => {
        let typeCrud = getValue("typeCrud", okform)
        if (typeCrud) {
            //'itens-multipos', 'unico-user', 'unico-app'
            if (typeCrud !== 'itens-multipos') {
                let uidDev = null;
                if (typeCrud === 'unico-user') {
                    let userUid = getUserUid();
                    uidDev = userUid;
                }
                if (typeCrud === 'unico-app') {
                    uidDev = "UNICO"
                }
                history.push(history.location.pathname + "?uid=" + uidDev);
                initialize({ ...initialValues, uid: uidDev });

            }
        }
        return () => {

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [okform])


    // eslint-disable-next-line react-hooks/exhaustive-deps
    const actionSave = useCallback(handleSubmit(async (obj) => {

        if (colectionFirebase) {
            let ajustObj = obj;
            ajustObj = ModuleFuncBeforeSave(ajustObj, okform);

            await componetIteratorOkFormAsync(okform, async (c) => {
                let confComp = getOkCompCore(c.typeComponente);

                if (confComp && confComp.beforeSave) {
                    ajustObj = await confComp.beforeSave(ajustObj, c)
                }
            })

            console.log('ajustObj salvar', ajustObj)
            salvarObjDatabase(database, ajustObj, colectionFirebase,
                (r) => {
                    console.log("CRUD - Sucesso Salvou: ", r.msg);
                    showSuccess(r.msg)

                    if (ajustObj.uid) {
                        if (okform.posSaveUrl) {
                            history.push(okform.posSaveUrl)
                        } else {
                            history.push(ajusteRoute(okApp, okform) + "?uid=" + ajustObj.uid)
                        }

                    }
                },
                (err) => {
                    console.log("CRUD - error", err);
                    showError(err.message)
                })
        } else {
            showError('Sem collection para salvar')
        }
    }), [colectionFirebase, handleSubmit, showError, showSuccess])
    let hideTopBar = getValue("hideTopBar", okform)
    useEffect(() => {


        setVisibilityTopBar(!hideTopBar)
        return () => {
            setVisibilityTopBar(true);
        }


    }, [okform, uid, hideTopBar, setVisibilityTopBar, okApp])

    useEffect(() => {
        if (!getValue("type", okform) || getValue("type", okform) === 'padrao') {
            let add = [];

            if (getValue("deletar", okform)) {

                add.push({ uid: 'crud.actions.delete', text: getValue("deleteLabel", okform) || t('crud.actions.delete'), icon: "Delete", action: () => setOpen(true), props: { style: { backgroundColor: "#f00" }, hidden: uid ? !pristine : true }, disabled: submitting })
            }

            if (getValue("novo", okform)) {

                add.push({ uid: 'crud.actions.new', text: getValue("novoLabel", okform) || t('crud.actions.new'), icon: 'FiberNew', action: actionNovo, props: { hidden: (uid ? false : pristine) }, disabled: submitting })
            }


            // if (getValue("salvar", okform)) {
            add.push({ uid: 'crud.actions.save', text: getValue("salvarLabel", okform) || t('crud.actions.save'), icon: 'Save', action: actionSave, props: { hidden: !getValue("salvar", okform), disabled: (pristine || submitting) } })
            // }
            if (add.length)
                addActions(add)
        }
        return () => { removeActions(['crud.actions.new', 'crud.actions.save', 'crud.actions.delete']); };
    }, [novo, salvar, okform, pristine, submitting, uid, actionSave, addActions, removeActions, t, actionNovo])

    if (getValue("type", okform) === 'flutuante-inferior') {
        let exibeAlgo = false;
        if (!getValue("novo", okform) && !getValue("salvar", okform)) {
            return <></>
        }
        exibeAlgo = true;
        // if (getValue("barraActionsSempreVisivel", okform)) {
        //     exibeAlgo = true;
        // }

        // if (!exibeAlgo && (getValue("novo", okform) && !(uid ? false : pristine))) {
        //     exibeAlgo = true
        // }
        // if (!exibeAlgo && getValue("salvar", okform) && !pristine) {
        //     exibeAlgo = true
        // }

        if (exibeAlgo) {
            return <div style={{ height: 45 }}>


                <div style={{
                    display: 'flex',
                    backgroundColor: "#ccc",
                    borderRadius: 5,
                    width: '80%',
                    maxWidth: 1100,
                    position: 'fixed',
                    bottom: 0,
                    padding: 7,
                    justifyContent: 'flex-end',
                    left: 0,
                    right: 0,
                    marginLeft: 'auto',
                    marginRight: 'auto',

                }}>
                    {(getValue("deletar", okform) && !(uid ? false : pristine)) &&
                        <>
                            <Button
                                variant='contained'
                                color='secondary'
                                onClick={() => setOpen(true)}
                                style={{ marginLeft: 10, backgroundColor: "#f00" }}
                                hidden={uid ? !pristine : true} disabled={submitting} >
                                {getValue("deleteLabel", okform) || t('crud.actions.delete')}
                            </Button>
                            <DialogConfirm open={open} handleClose={handleClose} acaoDeletar={acaoDeletar} />
                        </>
                    }
                    {(getValue("novo", okform) && !(uid ? false : pristine)) &&
                        <Button
                            variant='contained'
                            color='secondary'
                            onClick={actionNovo}
                            style={{ marginLeft: 10 }}
                            hidden={uid ? false : pristine} disabled={submitting} >
                            {getValue("novoLabel", okform) || t('crud.actions.new')}
                        </Button>}
                    {getValue("salvar", okform) &&
                        <Button
                            variant='contained'
                            color='secondary'
                            onClick={actionSave}
                            style={{ marginLeft: 10 }}
                            disabled={pristine || submitting}>
                            {getValue("salvarLabel", okform) || t('crud.actions.save')}
                        </Button>}
                </div>
            </div>
        } else {
            return <>  <DialogConfirm open={open} handleClose={handleClose} acaoDeletar={acaoDeletar} /></>
        }
    }

    return <>  <DialogConfirm open={open} handleClose={handleClose} acaoDeletar={acaoDeletar} /></>
}

export function OkFormMetaAba(props) {
    return <OkFormAbaAdapter keyTab={"Ações"} styleProps={{ flex: 0 }} {...props}>
        <OkTypedComponent typeComponente='SwitchComp' field={metaVar + "hideTopBar"} label={"Esconder Barra Superior"} />
        <div style={{ display: 'flex', justifyContent: 'spaceBeteewn' }}>
            <OkTypedComponent typeComponente='ComboBoxComp' field={metaVar + "type"} label='Tipo Visualização' options={['padrao', 'flutuante-inferior']} toStringItem={(v) => {
                switch (v) {
                    case 'padrao': return "Padrão"
                    case 'flutuante-inferior': return "Flutuante Inferior (BETA)"

                    default: return v;
                }
            }} />
            <OkTypedComponent typeComponente='ComboBoxComp' field={metaVar + "typeCrud"} label='Tipo Salvar' options={['itens-multipos', 'unico-user', 'unico-app']} toStringItem={(v) => {
                switch (v) {
                    case 'itens-multipos': return "Salvar multiplos itens"
                    case 'unico-user': return "Salvar item único por usuário"
                    case 'unico-app': return "Salvar item único no projeto"
                    default: return "Salvar multiplos itens";
                }
            }} />
        </div>
        <div style={{ display: 'flex', justifyContent: 'spaceBeteewn' }}>
            <OkTypedComponent typeComponente='SwitchComp' field={metaVar + "salvar"} label={"Exibir Salvar"} />

            <OkTypedComponent typeComponente='InputComp' field={metaVar + "salvarLabel"} label='Texto do botão "Salvar"' />

        </div>
        <div style={{ display: 'flex' }}>
            <OkTypedComponent typeComponente='SwitchComp' field={metaVar + "novo"} label={"Exibir Novo"} />
            <OkTypedComponent typeComponente='InputComp' field={metaVar + "novoLabel"} label='Texto do botão "Novo"' />
        </div>
        <div style={{ display: 'flex' }}>
            <OkTypedComponent typeComponente='SwitchComp' field={metaVar + "deletar"} label={"Exibir Deletar"} />
            <OkTypedComponent typeComponente='InputComp' field={metaVar + "deleteLabel"} label='Texto do botão "Deletar"' />
        </div>
    </OkFormAbaAdapter>
}

export const data = { name: "CrudControl" }