import { useEffect } from "react";
import { useState } from "react";

export function useCoordinates() {
    const [coordinates, setCoordinates] = useState();
    const [error, setError] = useState();

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                setCoordinates({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                });
                setError(null);
            },
            (error) => {
                setCoordinates(null);
                setError(error);

                console.error(error);
            }
        );
    }, []);

    return [coordinates, error];
}

export const poligonStr2List = (value) => {//arrumaValue
    if (value) {
        //de string para lista
        let lista = [];
        var lacations = value.split(';');
        lacations.forEach(element => {
            if (element) {
                let split = element.split(':');
                let lat = split[0];
                let log = split[1];

                let cordinate = { lat: parseFloat(lat), lng: parseFloat(log) }
                lista.push(cordinate);
            }
        });
        return lista;
    }
    return [];
}
export const poligonList2Str = (value) => {//updateValue
    if (value) {
        let str = "";
        value.forEach(item => str += item.lat + ":" + item.lng + ";")
        return str;
    }
    return '';
}
export const calcCenterListCordenada = (listCordenada) => {

    if (!listCordenada.length) {
        return null;
    }
    let lowx,
        highx,
        lowy,
        highy,
        lats = [],
        lngs = [];
    for (var i = 0; i < listCordenada.length; i++) {
        lngs.push(listCordenada[i].lng);
        lats.push(listCordenada[i].lat);
    }
    lats.sort();
    lngs.sort();
    lowx = lats[0];
    highx = lats[listCordenada.length - 1];
    lowy = lngs[0];
    highy = lngs[listCordenada.length - 1];
    let center_x = lowx + ((highx - lowx) / 2);
    let center_y = lowy + ((highy - lowy) / 2);
    return { lat: center_x, lng: center_y }

}


export const calcularAreaPolygonHA = (listCordenada) => {

    if (window.google.maps.geometry) {

        var a = [];
        for (var i = 0; i < listCordenada.length; i++) {
            var uno = listCordenada[i];
            a[i] = new window.google.maps.LatLng(uno.lat, uno.lng);
        }
        let area = window.google.maps.geometry.spherical.computeArea(a, 6371000);

        let areaHa = area / 10000;
        return areaHa;
    } else {
        console.log('sem window.maps.geometry', window.google.maps)
        return null
    }


}

