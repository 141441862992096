import PainelReport from './PainelReport'
import CelulaTextoReport from './CelulaTextoReport'
import ForArrayReport from './ForArrayReport'
import CondicionalReport from './CondicionalReport'
import FunctionReport from './FunctionReport'
import QrCodeReport from './QrCodeReport'
import AnexoImagemReport from './AnexoImagemReport'
export function getComponents() {
    const lista = [PainelReport, CelulaTextoReport, ForArrayReport, CondicionalReport, FunctionReport, QrCodeReport, AnexoImagemReport]
    return lista;
}
export function getComp2CompViewType(comp) {
    return getComp2Type(comp.typeComponent)
}
export function getComp2Type(type) {
    for (let index = 0; index < getComponents().length; index++) {
        const c = getComponents()[index];
        if (c.typeComponent === type) return c;

    }
    return null
}


export async function getValueToPdfFunc2Comp(comp, json,meta) {
    let r = getComp2CompViewType(comp);
    if (r && r.convertToPdfFunc) {
        const rr = await r.convertToPdfFunc(comp, json,meta)
        if (rr === null) {
            return ""
        }
        return rr

    }
}

export async function geraRelatorioDinamicReport(json, componentes,meta) {
    let conteudo = [];


    if (componentes && componentes.lista) {
        for (const key in componentes.lista) {
            if (Object.hasOwnProperty.call(componentes.lista, key)) {
                const linha = componentes.lista[key];
                let v = await getValueToPdfFunc2Comp(linha, json,meta);
                conteudo.push(v)
            }
        }
    }
    return conteudo
}
