import { LabelInputAuto } from 'OkApp'
import OkTypedComponent from 'OkApp/components/OkTypedComponent'
import React from 'react'

export default function EditorComp(props) {
    return <>
        <OkTypedComponent typeComponente='InputComp' field='field' label='Nome Salvo' />
        <LabelInputAuto {...props} />
        <OkTypedComponent typeComponente='InputComp' field='produts_fields' label='Field para produtos' />
        <OkTypedComponent typeComponente='TabelaOkComp' field='map_propriedades' label='Mapeamento de Propriedades'
            exibeLabel={true}
            permiteRemover={true}
            exibeAdd={true}
            colunas={[
                { field: "propStr", label: "String Props", typeComponente: 'InputComp' },
                { field: "propVar", label: "Field Destino", typeComponente: 'InputComp' },
            ]}
        />
        <OkTypedComponent typeComponente='TabelaOkComp' field='map_propriedades_produtos' label='Mapeamento de Propriedades dos Produtos'
            exibeLabel={true}
            permiteRemover={true}
            exibeAdd={true}
            colunas={[
                { field: "propStr", label: "String Props", typeComponente: 'InputComp' },
                { field: "propVar", label: "Field Destino", typeComponente: 'InputComp' },
            ]}
        />

        <OkTypedComponent typeComponente='SwitchComp' field='exibe_aux_console' label='Exibe no console os dados do xml' />
        

    </>
}