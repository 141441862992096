import React, { useCallback, useEffect, useState } from 'react'

import { includesInsensitive } from 'OkApp/functions'
import { useUserUid } from 'OkApp/UseUtils'

import CardProjeto from '../CardProjeto'

import { Box, TextField, useMediaQuery, useTheme } from '@material-ui/core'

export default function ViewFiltroProjetos({ projetos, actionCopiar, updateItem, admin }) {
    const [filtro, setFiltro] = useState("")
    const [listaFiltrada, setListaFiltrada] = useState([])

    const userUid = useUserUid();
    const theme = useTheme()

    const xs = useMediaQuery(theme.breakpoints.down('xs'));
    const sm = useMediaQuery(theme.breakpoints.down('sm'));
    const md = useMediaQuery(theme.breakpoints.down('md'));

    const filtrar = useCallback(
        () => {
            let r = (projetos || []).slice();

            r = r.filter(p => {
                if (!filtro) {
                    return true
                }

                if (p.name && includesInsensitive(p.name, filtro)) {
                    return true;
                }


                if (p.description && includesInsensitive(p.description, filtro)) {
                    return true;
                }

                if (p.autor && includesInsensitive(p.autor.email, filtro)) {
                    return true;
                }
                if (p.autor && p.autor.name && includesInsensitive(p.autor.name, filtro)) {
                    return true;
                }
                return false;
            })

            r.sort((a, b) => {
                if (userUid && a.favorito && a.favorito[userUid]) {
                    return -1
                }
                if (userUid && b.favorito && b.favorito[userUid]) {
                    return 1
                }

                if (a.name > b.name) {
                    return 1;
                }
                if (a.name < b.name) {
                    return -1;
                }

                return 0
            })

            setListaFiltrada(r)
        },
        [projetos, filtro, userUid],
    )

    useEffect(() => {
        filtrar();

    }, [filtro, projetos, filtrar])
    const onKeyPress = (e) => {
        if (e.key === 'Enter') {
            filtrar();
        }
    }

    return (
        <Box width="100%">
            <Box style={{ padding: 10 }}>
                <TextField
                    value={filtro}
                    onChange={event => setFiltro(event.target.value)}
                    label={"Filtro"}
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                    }}
                    style={{ marginBottom: 15 }}
                    onKeyUp={onKeyPress}
                />
            </Box>
            <Box display="grid" style={{ gridTemplateColumns: xs ? 'repeat(1, 1fr)' : sm ? 'repeat(1, 1fr)' : md ? 'repeat(2, 1fr)' : 'repeat(3, 1fr)', }}>
                {listaFiltrada && listaFiltrada.map((p, i) => <CardProjeto key={i} projeto={p} actionCopiar={actionCopiar} updateItem={updateItem} admin={admin} />)}
            </Box>
        </Box>
    )
}
