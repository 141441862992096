import React, { Component } from 'react'
import "./style.css";
import { Typography } from '@material-ui/core';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { sortArrayObj } from 'OkApp/functions';
  
const grid = 2;

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? 'lightgreen' : '#deedea',

    // styles we need to apply on draggables
    borderStyle: 'solid',
    // backgroundColor: '#deedea',
    borderRadius: 8,
    borderWidth: 2,
    borderColor: '#16756f',
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? 'lightblue' : 'none',
    padding: grid,
    width: 250,
    minHeight: 250
});

export default class Kamban extends Component {

    constructor(props) {
        super(props);
        this.renderItem = this.renderItem.bind(this);
        this.renderColuna = this.renderColuna.bind(this);
    }

    onDragEnd = result => {
        if (result.destination) {
            const { lista, colNull } = this.props;
            let indexOf = lista.map(l => l.uid).indexOf(result.draggableId);
            let item = lista[indexOf];
            let colDe = result.source.droppableId;
            let indexDe = result.source.index;
            let colPara = result.destination.droppableId;
            let indexPara = result.destination.index;
            if (colPara === colNull) {
                colPara = null;
            }

            if (this.props.onDrag) {

                this.props.onDrag(item, colPara, indexPara, colDe, indexDe)
            }

        }
    };
    renderItem(item, index, coluna) {
        return (
            <Draggable
                key={"item_" + item.uid}
                draggableId={item.uid}
                index={index}>
                {(provided, snapshot) => (
                    <div ref={provided.innerRef}  {...provided.draggableProps} {...provided.dragHandleProps}
                        style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                        )}>
                        {this.props.renderItem(item)}
                    </div>
                )}
            </Draggable>

        )


    }

    renderColuna(coluna, index) {
        const { lista, matchColumn, colNull, showQt } = this.props;
        let listaFiltrada = lista.filter(item => {
            return matchColumn(item, coluna)
        });
        listaFiltrada = sortArrayObj(listaFiltrada, [(i) => i.indexKanban, i => i.sequencialIndex])

        return (
            <Droppable key={"col_" + index} droppableId={coluna || colNull} >
                {(provided, snapshot) => (
                    <div>
                        <Typography className="tituloColunas" >{coluna || colNull} {showQt && "(" + listaFiltrada.length + ")"}</Typography>
                        <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)} >
                            {listaFiltrada.map((o, i) => this.renderItem(o, i, coluna))}
                            {provided.placeholder}
                        </div>
                    </div>
                )}
            </Droppable>
        )
    }

    render() {
        const { colunas, colNull } = this.props;


        return (<DragDropContext onDragEnd={this.onDragEnd}>

            <div className="kamban">
                {colNull && this.renderColuna()}
                {colunas.map(this.renderColuna)}
            </div>
        </DragDropContext>
        )
    }
}


