import React from 'react'
import OkCompAdapter from 'OkApp/components/OkCompAdapter'
import CompView from './components/CompView'
import Editor from './components/Editor'
const typeComponente = 'RenderOkFormComp'

const AdapterOkComp = (props) => {
    // console.log('AdapterOkComp',props)
    let propsComp = { developer: props.clearDevelorperNext ? false : props.developer,itemList:props.itemList }
    return <OkCompAdapter {...props} propsComp={propsComp} noStyleStart={true}
    //  styleCssOver={{ width: "100%" }} 
     component={CompView} />
}


const modelo = {
    typeComponente,
    field: 'form.',
    label: 'Render Form',
}


const RenderOkFormComp = {
    typeComponente,
    configView: {
        grupo: 'Avançado',
        nome: 'Exibidor de OkForm',
        descricao: 'Exibe outro OkForm como um componente utilizavel',
        helpUrl: 'https://www.google.com.br/',
        modelo
    },
    Component: AdapterOkComp,
    CompEditor: Editor 
}

export default RenderOkFormComp