import Page from './components/Page'

export const routes = () => {
    return [
        { exact: true, path: "/password-recovery", component: Page },
    ]
}
export const menus = ({ history }) => {
    return []
}

