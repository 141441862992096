import React, { useRef } from 'react'

import { OkTypedComponent, DialogAddEditComp, LabelInputAuto } from 'OkApp'
import { Button, IconButton } from '@material-ui/core'
import { useReduxFormProps } from 'OkApp/UseUtils';
import { Add, Edit } from '@material-ui/icons';


export default function Editor(props) {

    const refAddCompDialog = useRef(null)

    const form = useReduxFormProps();

    return (
        <>
            <OkTypedComponent typeComponente='InputComp' field='field' label='Nome Salvo' />
            <LabelInputAuto {...props} />
            <OkTypedComponent typeComponente='InputComp' field='msgSemRegistro' label='Texto sem Registro' />
            <div style={{ display: 'flex' }}>
                <OkTypedComponent typeComponente='SwitchComp' field='exibeAdd' label='Exibir Adicionar' />
                <OkTypedComponent typeComponente='SeletorOkForm' field='okFormAux' label='Formulário' />
                <OkTypedComponent typeComponente='InputComp' field='fieldAdd' label='Field add' />
            </div>
            <div style={{ display: 'flex' }}>
                <OkTypedComponent typeComponente='SwitchComp' field='exibeLabel' label='Exibir Label' />
                <OkTypedComponent typeComponente='SwitchComp' field='permiteRemover' label='Permite Remover' />
                <OkTypedComponent typeComponente='SwitchComp' field='permiteMover' label='Permite Mover' />
                <OkTypedComponent typeComponente='SwitchComp' field='ordenarHeader' label='Ordena ao clicar no titulo' />
            </div>
            {/* <OkTypedComponent typeComponente='InputComp' field='colSortStart' label='Coluna Ordenar Inicial' /> */}
            <OkTypedComponent typeComponente='TabelaOkComp' field='colunas' label='Colunas'
                exibeLabel={true}
                permiteRemover={true}
                permiteMover={true}
                colunas={[
                    {
                        field: "typeComponente", label: " ",
                        sizecol: "42",
                        Component: ({ linha }) => {
                            return <div style={{ display: 'flex', alignItems: 'center' }}><IconButton size='small' onClick={() => refAddCompDialog.current.showEditarComp(linha)}><Edit /></IconButton></div>
                        }
                    },
                    { field: "field", label: "Field", typeComponente: 'InputComp' },
                    { field: "label", label: "Label", typeComponente: 'InputComp' },
                    { field: "sizecol", label: "Tamanho(inteiro ou %)", typeComponente: 'InputComp' },
                ]}
                addButtons={<Button variant="contained" color="secondary" startIcon={<Add />} onClick={() => refAddCompDialog.current.showAddComp()}>Adicionar</Button>}
            />
            <DialogAddEditComp ref={refAddCompDialog}
                updateComp={(comp) => {
                    const { colunas } = form.atualValues()
                    let newArray = colunas ? colunas.slice() : [];
                    let indexOf = newArray.map(c => c.uid ? c.uid : c.field).indexOf(comp.uid);
                    if (indexOf === -1) {
                        newArray.push(comp);
                    } else {
                        newArray[indexOf] = comp;
                    }

                    form.change("colunas", newArray)
                }}

                notInForm />
        </>
    )
}
