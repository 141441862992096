import React, { useState, useEffect } from 'react'

import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';

import CircularProgress from '@material-ui/core/CircularProgress';
import SearchIcon from '@material-ui/icons/Search';
import { useOkApp, useOkForm, useCreateFunctionFromFieldCode } from 'OkApp/UseUtils';

import { getOkObjListSnap } from 'OkApp/OkFunctions'
import _ from 'lodash'
import PesquisaOkFormObj from 'OkApp/modules/DefaultPesquisa/components/PesquisaOkFormObj';

export default function CompView(props) {
    const okApp = useOkApp();
    const { okform } = props.okfunctions
    const { input: { value, onChange }, meta: { touched, error }, disabled } = props//general
    const { multiple, field, label, options, exibePesquisa, tituloPesquisa, typeOpts, textfieldDisable, somenteOpt, noOptionsText, auxOkForm, campoPesquisa, filterListCode } = props//okcomp
    const okFormConsulta = useOkForm(auxOkForm ? auxOkForm.uid : null);
    const [showPesquisa, setShowPesquisa] = useState(false)
    const [loading, setLoading] = useState(false)
    const [text, setText] = useState("")

    const [ListaExibir, setListExibir] = useState([])

    let isTpFixo = false;
    let isTpOutroOkObj = false;
    let isTpFieldValues = false;

    if (!typeOpts || typeOpts === "fixo") isTpFixo = true;
    if (typeOpts === "fieldValues") isTpFieldValues = true;
    if (typeOpts === "outroOkObj") isTpOutroOkObj = true;


    let funcFilter = useCreateFunctionFromFieldCode(filterListCode, 'filterList')

    let editText = textfieldDisable || disabled
    let toStringItem = (i) => i;

    if (isTpOutroOkObj) {
        toStringItem = (item) => {
            return _.get(item, campoPesquisa)
        }
    }
    if (multiple) {
        toStringItem = (item) => {


            let itemUsar = item;
            if (Array.isArray(itemUsar)) {
                itemUsar = item[0];
            }
            if (isTpOutroOkObj) {

                return _.get(itemUsar, campoPesquisa)

            }
            return itemUsar
        }
    }


    useEffect(() => {
        let observer = null;

        let isTpFixo = false;
        let isTpOutroOkObj = false;
        let isTpFieldValues = false;

        if (!typeOpts || typeOpts === "fixo") isTpFixo = true;
        if (typeOpts === "fieldValues") isTpFieldValues = true;
        if (typeOpts === "outroOkObj") isTpOutroOkObj = true;

        if (isTpFixo) {
            setListExibir(options || [])
        }
        if (okform) {
            if (isTpFieldValues) {
                setLoading(true)
                observer = getOkObjListSnap(okApp,
                    okform.uid,
                    null,
                    (r) => {
                        let newList = []

                        r.forEach(o => {
                            let v = _.get(o, field)
                            if (Array.isArray(v)) {
                                v.forEach(vv => newList.push(vv))
                            } else {
                                if (v)
                                    newList.push(v)
                            }
                        });
                        newList.sort();
                        setListExibir(_.uniq(newList))
                        setLoading(false)
                    },

                    (rej) => { console.log('rej', rej) },
                    1500)
            }


        }
        if (isTpOutroOkObj) {
            if (auxOkForm) {

                setLoading(true)
                observer = getOkObjListSnap(okApp,
                    auxOkForm.uid,
                    null,
                    (r) => {
                        if (funcFilter) {
                            let filL = funcFilter(r);
                            setListExibir(filL)
                        } else {
                            setListExibir(r)
                        }


                        setLoading(false)
                    },

                    (rej) => { console.log('rej', rej) },
                    1500)
            } else {
                setListExibir(options || [])
            }
        }
        return () => {

            if (observer) {
                observer()
            }
        }

    }, [auxOkForm, field, funcFilter, okApp, okform, options, typeOpts])

    const selecionouNovo = (novo) => {
        // console.log('selecionouNovo', novo)

        onChange(novo)
    }
    //#region - fcof textfield

    let error1 = touched && error ? true : false;

    //#endregion
    const openPesquisa = () => {
        if (exibePesquisa) {
            setShowPesquisa(true)
        }
    }

    useEffect(() => {
        if (!value && multiple) {
            onChange([])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props])

    let valueUsar = value || (multiple ? [] : '')




    return (
        <>
            <Autocomplete
                multiple={multiple}
                options={ListaExibir}
                style={{ width: '100%' }}
                freeSolo={somenteOpt ? false : true}
                loading={loading}
                disabled={disabled}
                blurOnSelect={multiple ? false : true}
                noOptionsText={noOptionsText || "Sem Opções"}

                getOptionLabel={option => {
                    if (option) {
                        return toStringItem(option)
                    }
                    return ''
                }}

                getOptionSelected={(sel, c1) => {
                    if (sel && c1) {
                        return toStringItem(sel) === toStringItem(c1)
                    }
                    return false
                }}

                value={valueUsar}

                onChange={(event, newValue) => {
                    console.log('onChange')
                    setText('');
                    selecionouNovo(newValue);
                }}
                onBlur={(evt) => {
                    if ((isTpFieldValues && text) || (isTpFixo && !somenteOpt && text)) {
                        if (multiple) {
                            console.log('onBlur', text)
                            let aux = (value || []).slice()
                            aux.push(text)
                            onChange(aux)
                        } else {

                            onChange(text);
                        }
                    }
                }}
                renderInput={params => {

                    return <TextField {...params}
                        disabled={editText}
                        label={label}
                        error={error1}
                        helperText={error1 ? error : ""}
                        fullWidth
                        style={{ marginBottom: 15 }}

                        onChange={(event) => {
                            setText(event.target.value);
                            // if (isTpFixo ) onChange(event.target.value)
                        }}

                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                    {(exibePesquisa && isTpOutroOkObj) &&
                                        <SearchIcon color="inherit" size={20}
                                            onClick={openPesquisa}
                                        />
                                    }


                                </React.Fragment>
                            ),
                        }}


                    />
                }
                }
            />
            {okFormConsulta && <PesquisaOkFormObj
                multiple={multiple}
                okform={okFormConsulta}
                showPesquisa={showPesquisa}
                setShowPesquisa={setShowPesquisa} titulo={tituloPesquisa}
                funcFilter={funcFilter}
                onSelect={(sel) => {
                    if (multiple)
                        onChange(v => (v || []).concat(sel))
                    else
                        onChange(sel[0])
                }} />}
        </>
    )
}
