import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import { InputLabel, TextField } from '@material-ui/core';
import ReactFlow, {
    ReactFlowProvider,
    removeElements,
    addEdge,
    MiniMap,
    Controls,
    Handle,
    getBezierPath,
    getEdgeCenter,
    getMarkerEnd
} from 'react-flow-renderer';

import './style.css'



const onElementClick = (event, element) => console.log('click', element);


const connectionLineStyle = { stroke: 'rgb(80, 80, 80)' };
const snapGrid = [20, 20];





const Sidebar = () => {
    const onDragStart = (event, nodeType) => {
        event.dataTransfer.setData('application/reactflow', nodeType);
        event.dataTransfer.effectAllowed = 'move';
    };
    return (
        <aside style={{ paddingRight: 10 }}>
            <div className="description">Arraste para adicionar:</div>
            <div className="nodeObject" style={{ marginBottom: 10 }} onDragStart={(event) => onDragStart(event, 'nodeObject')} draggable>
                Objeto
            </div>
            <div className="nodeTelaFuncionalidade" style={{ marginBottom: 10 }} onDragStart={(event) => onDragStart(event, 'nodeTelaFuncionalidade')} draggable>
                Tela / Funcionalidade
            </div>
        </aside>
    );
};

const getId = () => Math.random().toString(36).substring(7);

export default function CompView(props) {

    const reactFlowWrapper = useRef(null);
    const [reactflowInstance, setReactflowInstance] = useState(null);

    const { input: { value, onChange } } = props//general
    const { label, } = props//okcomp
    const elements = value || []


    const ItemPropComp = ({ id, index, dataParams, list, changeValueData, data }) => {

        const changeValueDataParam = (field, value) => {

            let newdataParams = { ...dataParams }
            newdataParams[field] = value

            let newList = list ? list.slice() : [];

            newList[index] = newdataParams

            let ultimo = newList[newList.length - 1]

            if (ultimo.nome) {

                newList.push({})
            }
            changeValueData("params", newList)

        }

        const removeRow = () => {
            let newList = list ? list.slice() : [];

            delete newList[index]

            if (!newList.length) {
                newList.push({})
            }
            let ultimo = newList[newList.length - 1]

            if (!ultimo ||ultimo.nome) {

                newList.push({})
            }
            changeValueData("params", newList)

        }



        return <div className="rowData" style={{ position: 'relative' }}>

            <div className="rowRemover" onClick={() => removeRow()}>X</div>
            <TextField
                value={dataParams ? dataParams.nome : ""}
                onChange={evt => changeValueDataParam("nome", evt.target.value)}
            />
            <TextField
                inputProps={{ min: 0, style: { textAlign: 'right', paddingRight: 10 } }}
                style={{ marginLeft: 3 }}
                value={dataParams ? dataParams.obs : ""}
                onChange={evt => changeValueDataParam("obs", evt.target.value)}
            />
            <Handle
                id={id + "-sourceField-" + index}
                type="source"
                position="right"
                style={{ background: '#0F0', paddingLeft: 5 }}
            />

        </div>
    }

    const NodeObject = memo((props) => {
        const { id, data, isConnectable } = props
        const changeValueData = (field, value) => {
            onChange((els) =>
                els.map((e) => {
                    if (e.id === id) {
                        let newData = { ...e.data, }
                        newData[field] = value
                        return {
                            ...e,
                            data: newData,
                        }
                    }
                    return e;

                })
            );
        }


        return (
            <>
                <>{/* Conexoes */}
                    <Handle
                        id="topT"
                        type="target"
                        position="top"
                        style={{ background: '#00F' }}
                        isConnectable={isConnectable}
                    />
                    <Handle
                        id="leftT"
                        type="target"
                        position="left"
                        style={{ background: '#00F' }}
                        isConnectable={isConnectable}
                    />
                    <Handle
                        id="bottomS"
                        type="source"
                        position="bottom"
                        style={{ background: '#0F0' }}
                        isConnectable={isConnectable}
                    />
                </>

                <div className="nodeObject" style={{ padding: 0 }}>
                    <div className="nodeRemover" onClick={() => removeElementId(id)}>X</div>
                    <TextField
                        inputProps={{ min: 0, style: { textAlign: 'center' } }}
                        placeholder='Objeto'
                        value={data.titulo}
                        onChange={(event) => changeValueData('titulo', event.target.value)}
                        fullWidth
                    />
                    <div style={{ padding: 10 }}>
                        {data.params && data.params.map((p, i) => <ItemPropComp key={i} id={id} index={i} dataParams={p} list={data.params} changeValueData={changeValueData} data={data} />)}
                        {/* lista de props */}
                        {/* {(!data.params || !data.params.length) && <ItemPropComp changeValueData={changeValueData} data={data} />} */}
                    </div>
                    {/* <div> */}
                    {/* <div >

                            <input
                                className="nodrag"
                                placeholder="Titulo"
                                onChange={changeTitulo}
                                defaultValue={data.titulo}
                            />
                            <input
                                className="nodrag"
                                type="color"
                                onChange={changeDataColor}
                                defaultValue={data.color}
                            />
                        </div>
                        <div >
                            <textarea
                                className="nodrag"
                                style={{ width: '100%' }}
                                placeholder="Texto..."
                                onChange={changeTexto}
                                defaultValue={data.texto}
                            />
                        </div> */}
                    {/* </div> */}
                </div>
            </>
        );
    });

    const NodeTelaFuncionalidade = memo((props) => {
        const { id, data, isConnectable } = props
        const changeValueData = (field, value) => {
            onChange((els) =>
                els.map((e) => {
                    if (e.id === id) {
                        let newData = { ...e.data, }
                        newData[field] = value
                        return {
                            ...e,
                            data: newData,
                        }
                    }
                    return e;

                })
            );
        }


        return (
            <>
                <>{/* Conexoes */}
                    <Handle
                        id="topT"
                        type="target"
                        position="top"
                        style={{ background: '#00F' }}
                        isConnectable={isConnectable}
                    />
                    <Handle
                        id="leftT"
                        type="target"
                        position="left"
                        style={{ background: '#00F' }}
                        isConnectable={isConnectable}
                    />
                    <Handle
                        id="bottomS"
                        type="source"
                        position="bottom"
                        style={{ background: '#0F0' }}
                        isConnectable={isConnectable}
                    />
                    <Handle
                        id="rightS"
                        type="source"
                        position="right"
                        style={{ background: '#0F0' }}
                        isConnectable={isConnectable}
                    />
                </>

                <div className="nodeTelaFuncionalidade" style={{ padding: 0, minWidth: 350 }}>
                    <div className="nodeRemover" onClick={() => removeElementId(id)}>X</div>
                    <TextField
                        inputProps={{ min: 0, style: { textAlign: 'center' } }}
                        placeholder='Tela'
                        value={data.titulo}
                        onChange={(event) => changeValueData('titulo', event.target.value)}
                        fullWidth
                    />
                    <div style={{ padding: 10 }}>
                        <TextField
                            fullWidth
                            placeholder='Descrição'
                            value={data.descricao}
                            multiline
                            onChange={evt => changeValueData("descricao", evt.target.value)}
                        />
                    </div>
                </div>
            </>
        );
    });



    const ButtonEdge = ({
        id,
        sourceX,
        sourceY,
        targetX,
        targetY,
        sourcePosition,
        targetPosition,
        style = {},
        data,
        arrowHeadType,
        markerEndId,
    }) => {
        const edgePath = getBezierPath({
            sourceX,
            sourceY,
            sourcePosition,
            targetX,
            targetY,
            targetPosition,
        });
        const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);
        const [edgeCenterX, edgeCenterY] = getEdgeCenter({
            sourceX,
            sourceY,
            targetX,
            targetY,
        });

        return (
            <>
                <path
                    id={id}
                    style={style}
                    className="react-flow__edge-path"
                    d={edgePath}
                    markerEnd={markerEnd}
                />
                <foreignObject
                    width={40}
                    height={40}
                    x={edgeCenterX - 40 / 2}
                    y={edgeCenterY - 40 / 2}
                    className="edgebutton-foreignobject"
                    requiredExtensions="http://www.w3.org/1999/xhtml"
                >
                    <body style={{ background: 'none' }}>
                        <button
                            className="edgebutton"
                            onClick={(event) => {
                                removeElementId(id);
                            }}
                        >
                            ×
                        </button>
                    </body>
                </foreignObject>
            </>
        );
    }

    const edgeTypes = {
        buttonedge: ButtonEdge,
    };
    const nodeTypes = {
        nodeObject: NodeObject,
        nodeTelaFuncionalidade: NodeTelaFuncionalidade,
    };

    useEffect(() => {
        if (reactflowInstance && elements.length > 0) {
            console.log('ReactFlow fitView')
            reactflowInstance.fitView();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onNodeDragStop = (event, node1) => {

        // console.log('drag stop', node1)
        onChange((els) =>
            els.map((e) => {
                if (e.id === node1.id) {
                    return node1;
                }
                return e;

            })
        );

    };

    const onElementsRemove = useCallback(
        (elementsToRemove) => {
            let newArray = value ? value.slice() : []
            removeElements(elementsToRemove, newArray)


            onChange(newArray);

        }, [onChange, value]
    );


    const removeElementId = (id) => {
        console.log('remove id', id)
        console.log('value', value)

        onChange(v => v.filter(i => i.id !== id));

    }



    const onConnect = useCallback(
        (params) => {

            onChange((els) =>

                addEdge({ ...params, style: { stroke: 'rgb(119, 119, 119)' }, type: 'buttonedge' }, els)
            )
        }
        ,
        [onChange]
    );

    const onLoad = useCallback(
        (rfi) => {
            if (!reactflowInstance) {
                setReactflowInstance(rfi);

            }
        },
        [reactflowInstance]
    );

    const onDragOver = (event) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';
    };

    const onDrop = (event) => {
        event.preventDefault();

        const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
        const type = event.dataTransfer.getData('application/reactflow');
        const position = reactflowInstance.project({
            x: event.clientX - reactFlowBounds.left,
            y: event.clientY - reactFlowBounds.top,
        });
        const newNode = {
            id: getId(),
            type,
            position,
            data: { titulo: ``, params: [{}] },
        };
        let newArray = value ? value.slice() : []
        newArray.push(newNode)
        onChange(newArray);
    };

    return (
        <>
            {label && <InputLabel style={{
                fontSize: 12,
                paddingBottom: 3,
            }}

            >{label}</InputLabel>}
            <div style={{ display: 'flex', height: '100%' }}>
                <ReactFlowProvider>
                    <Sidebar />
                    <div className="reactflow-wrapper" ref={reactFlowWrapper}>
                        <ReactFlow
                            elements={elements}
                            onElementClick={onElementClick}
                            onElementsRemove={onElementsRemove}
                            onConnect={onConnect}
                            onNodeDragStop={onNodeDragStop}
                            style={{
                                // background: '#1A192B',
                                border: '1px solid rgb(119, 119, 119)',
                                background: '#fff',
                                // flex: 2,

                            }}
                            onLoad={onLoad}
                            nodeTypes={nodeTypes}
                            edgeTypes={edgeTypes}
                            connectionLineStyle={connectionLineStyle}
                            snapToGrid={true}
                            snapGrid={snapGrid}
                            defaultZoom={1}
                            onDrop={onDrop}
                            onDragOver={onDragOver}

                        >
                            <MiniMap
                                style={{ border: '1px solid rgb(119, 119, 119)', }}
                                nodeStrokeColor={(n) => {
                                    if (n.type === 'selectorNode') return "#ccc";
                                    return '#ccc';

                                }}
                                nodeColor={(n) => {
                                    if (n.type === 'selectorNode') return "#ff0";
                                    return '#ccc';
                                }}
                            />
                            <Controls />

                        </ReactFlow>
                    </div>
                </ReactFlowProvider>
            </div>
        </>
    );
}

