import React from 'react'
import ItemMenu from '../ItemMenu'
const container = ({ menus }) => (
    <div>
        {menus.map((menu, index) => <ItemMenu key={'ItemMenu-' + index} menu={menu} />)}
    </div>

)


export default container;
