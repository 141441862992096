import React from 'react';

import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import CardProjeto from 'routes/private/components/CardProjeto';


const Carousel = ({ values }) => {
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        swipeToSlide: true,
        draggable: true,
        rows: values && values.length > 3 ? 2 : 1,
        responsive: [
            {
                breakpoint: 1287,
                settings: {
                    slidesToShow: 2,
                    rows: 2,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    rows: 1,
                },
            },
            {
                breakpoint: 871,
                settings: {
                    slidesToShow: 1,
                    rows: 1
                },
            },
        ],
    };

    return (
        <Slider {...settings}>
            {values.map((p, i) => <CardProjeto key={i} projeto={p} />)}
        </Slider>
    );
};

export default Carousel;