import React, { useEffect } from 'react'
import useAuth from '../../../../../useAuth'
import FavoritosProprio from '../FavoritosProprio';
import FavoritosPublico from '../FavoritosPublico';
import { useCrudContext } from 'crud';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const route = "/Projeto";
export default function PageHome(props) {

    const { addAction, removeActions } = useCrudContext();
    const history = useHistory();
    const { t } = useTranslation();
    const { authenticatedOnly } = useAuth();

    const { setTitle } = useCrudContext()

    useEffect(() => {
        setTitle("Favoritos")
        return () => setTitle("")

    }, [setTitle])

    authenticatedOnly();
    useEffect(() => {
        addAction({ uid: 'crud.actions.new_project', text: t('crud.actions.new_project'), icon: null, action: () => history.push(route), props: {}, })
        return () => { removeActions(['crud.actions.new_project']) }

    }, [addAction, history, removeActions, t])

    return (
        <div style={{ padding: 15 }}>
            <FavoritosProprio />
            <FavoritosPublico />
        </div>
    )
}
