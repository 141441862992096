import React from 'react'
import SeletorPerfis from './SeletorPerfis'
import EditorPermissionGrupos from './EditorPermissionGrupos'
import OkTypedComponent from 'OkApp/components/OkTypedComponent'
import { Field } from 'redux-form';
import { useOkApp, useAtualValues } from 'OkApp/UseUtils';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';



export default function EditorPermissions(props) {
    const okApp = useOkApp();

    const { labelEditor, labelUsers, labelPublico, listPermissions, labelListaPermi, grupoFixePermission, hidePublico, hideEditor, hideGrupos, maxNumUser } = props
    const history = useHistory();
    const gruposPermissao = okApp ? okApp.gruposPermissao : null
    // const publicValue = useAtualValues('metaOkApp.permission.public');
    const usersRead = useAtualValues('metaOkApp.permission.usersRead');
    const usersWrite = useAtualValues('metaOkApp.permission.usersWrite');
    let contUsers = 1;
    if (usersRead) {
        for (const key in usersRead) {
            if (usersRead.hasOwnProperty(key)) {
                if (usersRead[key]) {
                    contUsers++;
                }

            }
        }

    }
    if (usersWrite) {
        for (const key in usersWrite) {
            if (usersWrite.hasOwnProperty(key)) {
                if (usersWrite[key]) {
                    contUsers++;
                }

            }
        }
    }
    let disableAddPorUsers = false;
    if (maxNumUser && maxNumUser <= contUsers) {
        disableAddPorUsers = true;
    }

    let contratarPlano = () => {
        history.push('/Planos')

    }
    return (
        <div>
            {!hidePublico && <OkTypedComponent typeComponente='SwitchComp' field='metaOkApp.permission.public' label={labelPublico || 'Público'} />}
            {disableAddPorUsers && <div style={{
                boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
                transition: '0.3s',
                borderRadius: 5,
                padding: 10,
                display: 'flex',
                justifyContent: "space-between",
                alignItems: 'center'

            }} ><strong>{maxNumUser === 1 ? 'Seu plano permite apenas você como usuário. Contrate mais usuários.' : 'Este projeto atingiu o limite de usuários do seu plano. Contrate mais usuários.'}</strong>
                <Button variant="contained" color="primary" onClick={contratarPlano} >Contratar</Button>
            </div>}
            {/* {!publicValue && */}
            <>
                < Field name='metaOkApp.permission.usersRead' label={labelUsers || "Usuários Utilizar"} component={SeletorPerfis} listPermissions={listPermissions} labelListaPermi={labelListaPermi} disabled={disableAddPorUsers} />
                {!hideEditor && <Field name='metaOkApp.permission.usersWrite' label={labelEditor || "Usuários Editores"} component={SeletorPerfis} disabled={disableAddPorUsers} />}
                {/* {listPermissions && <> */}
                {(gruposPermissao && !hideGrupos) && <Field name='metaOkApp.permission.groups' label={"Grupos"} component={EditorPermissionGrupos} listPermissions={(grupoFixePermission || []).concat(listPermissions || [])} gruposPermissao={gruposPermissao} />}
                {/* </>} */}
            </>
            {/* } */}

        </div>
    )
}
