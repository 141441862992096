import React from 'react'


import AceEditor from "react-ace";
import "ace-builds/webpack-resolver";
import "ace-builds/src-noconflict/mode-css";
import "ace-builds/src-noconflict/snippets/css";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-min-noconflict/ext-searchbox";
import "ace-builds/src-min-noconflict/ext-language_tools";

import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';

import Draggable from 'react-draggable';


function PaperComponent(props) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}
 
export default function DialogWindow(props) {
    const {
        input,
        label,
        open,
        handleClose,
        theme = 'monokai',
        mode = 'css',
        fontSize = 14,
        snippets,
        ...custom
    } = props
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"

            hideBackdrop
        >

            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                {label}
            </DialogTitle>
            <DialogContent  >
                <AceEditor
                    theme={theme}
                    mode={mode}
                    fontSize={fontSize}
                    width={'500px'}
                    showGutter={true}
                    editorProps={{ $blockScrolling: true }}
                    setOptions={{
                        enableBasicAutocompletion: true,
                        enableLiveAutocompletion: true,
                        enableSnippets: true,
                        showLineNumbers: true,
                        tabSize: 2,
                    }}
                    {...input}
                    {...custom}
                    onBlur={() => input.onBlur(props.input.value)}
                />
            </DialogContent>


        </Dialog >
    )
}
