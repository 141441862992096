
import React, { useEffect } from 'react'

import OkTypedComponent from 'OkApp/components/OkTypedComponent';
import OkCompAdapter from 'OkApp/components/OkCompAdapter';
import LabelInputAuto from 'OkApp/components/LabelInputAuto';
import { useAtualValues, useReduxFormProps } from 'OkApp/UseUtils';
import { Parser } from 'expr-eval'
import _ from 'lodash'
const modelo = {
  typeComponente: "CalcComp",
  field: "resultado",
  label: "Calculador",
  type: 'formula',
  exibeTextInput:true
}

const CompView = (props) => {
  const { developer, label, field,  formula, casasDecimais, type, fieldLista, fieldInterno, okfunctions } = props
  const { itemList } = okfunctions;
  const { change } = useReduxFormProps();
  let emptyValue = '0'
  let calcFormNum = formula
  if (type === "somaLista") {
    calcFormNum = '$somaLista(' + fieldLista + ',' + fieldInterno + ')'
  }

  let atualProdValues = useAtualValues();
  if (itemList) {
    atualProdValues = itemList
  }
  const atualV = _.get(atualProdValues, field)
  const defaultValueCalc = {
    somaLista: (parametros) => {
      const [listaF, fieldF] = parametros
      let total = 0
      let lista = _.get(atualProdValues, listaF)
      if (lista) {
        for (let index = 0; index < lista.length; index++) {
          const element = lista[index];
          let valueV = _.get(element, fieldF)
          if (valueV === null || valueV === "" || valueV === undefined) {
            valueV = emptyValue
          }
          total += parseFloat(valueV)
        }
      }
      return total
    },

  }


  for (const key in atualProdValues) {

    let valueV = atualProdValues[key];
    if (valueV === null || valueV === "" || valueV === undefined) {
      valueV = emptyValue
    }
    calcFormNum = calcFormNum.replaceAll('$(' + key + ')', valueV)
  }
  for (const key in defaultValueCalc) {
    const funcF = defaultValueCalc[key];
    const padrao = '\\$' + key; // Padrão como uma variável


    const regex = new RegExp(`(${padrao}\\((.*?)\\))`, 'g');
    let match;
    while ((match = regex.exec(calcFormNum)) !== null) {
      const padraoEncontrado = match[1];
      const parametros = match[2].split(',').map(valor => valor.trim());

      if (padraoEncontrado) {
        let nv = funcF(parametros)
        calcFormNum = calcFormNum.replaceAll(padraoEncontrado, nv)
      }
    }
  }


  let v = '';
  try {

    v = Parser.evaluate(calcFormNum);

    if (casasDecimais) {
      v = v.toFixed(casasDecimais)
    }
  } catch (error) {
  }

  useEffect(() => {
    if (atualV !== v) {
      change(field, v)
    }

  }, [v, atualV])

  return (<>
    {developer && <div style={{ height: 40, width: '100%', backgroundColor: "#0ff", display: 'flex', justifyContent: "center", alignItems: "center" }}>Comp Calculador: {label} - Result({field}): {v}</div>}
    <OkTypedComponent typeComponente='InputComp' field={field} label={label} disabled />

  </>
  )
}

const AdapterOkComp = (props) => {


  let propsComp = { developer: props.developer }

  return <OkCompAdapter  {...props} propsComp={propsComp} component={CompView} />
}
const EditorOkComp = (props) => {

  let type = useAtualValues("type");
  if (!type) {
    type = "Fixo"
  }

  return <>

    <OkTypedComponent typeComponente='InputComp' field='field' label='Nome Salvo' />
    <LabelInputAuto {...props} />
    <OkTypedComponent typeComponente='SwitchComp' field='exibeTextInput' label='Exibir Campo com o Valor' />

    <OkTypedComponent typeComponente='ComboBoxComp' field='type' label='Tipo' options={['formula', 'somaLista']} toStringItem={(v) => {
      switch (v) {
        case 'formula': return "Fórmula"
        case 'somaLista': return "Somar valores da lista"
        default: return v;
      }
    }} />

    {type === "formula" && <>
      <OkTypedComponent typeComponente='InputComp' field='formula' label='Fórmula' helperText="Padrao de Variavel: $(nome_var), Padrão Função: $somaLista(lista, field,)[parametros dentro dos parênteses]" />
    </>}

    {type === "somaLista" && <>
      <OkTypedComponent typeComponente='InputComp' field='fieldLista' label='Campo da lista' />
      <OkTypedComponent typeComponente='InputComp' field='fieldInterno' label='Campo a somar' />
    </>
    }
    <OkTypedComponent typeComponente='InputComp' field='casasDecimais' label='Casas Decimais' type="number" />

  </>
}

const CalcComp = {
  typeComponente: "CalcComp",
  configView: {
    grupo: "Básico",
    nome: "Componente de Cálculo",
    descricao: "Componente de Cálculo",
    helpUrl: "https://okapp.com.br/",
    
    modelo
  },
  Component: AdapterOkComp,
  CompEditor: EditorOkComp,
  // editorFullScreen:false,
}

export default CalcComp;