import React from 'react'

import { OkTypedComponent, LabelInputAuto } from 'OkApp'


export default function Editor(props) {

    return (<>
        <OkTypedComponent typeComponente='InputComp' field='field' label='Nome Salvo' />
        <LabelInputAuto {...props} />

    </>
    )
}
